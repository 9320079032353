/* eslint-disable jsx-a11y/alt-text */
import React, { useState, Component } from "react";
import { TimelineDocIcon, TimelineInfoIcon } from "assets/Iconsv2/Iconsv2";
import { Container, OverlayTrigger, Popover, Modal } from "react-bootstrap";
import { Redirect, withRouter } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import { ResolvePopup, InviteUser } from "app/shared/components/Popups";
import DetailTimelineSection from "../constants/detailtimeline";
import DetailCommentSection from "../constants/detailcomment";
import img from "assets/images/no-image2.jpg";
import { Eye ,BarChart2} from "react-feather";
import Url from "app/shared/constants/Url";
import axios from "axios";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import AudioPlayer from "react-h5-audio-player";
import AlertMessage from "app/shared/components/Popups/AlertMessage";
import CheckFormSettingField from "app/shared/utility/CheckFormSettingField";
import "react-vertical-timeline-component/style.min.css";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { Scrollbars } from "react-custom-scrollbars";
import { CarouselComponent } from "app/shared/components/ReusableComponents/Carousel";
import HighBadgeIcon from "assets/sls-icons/Risk Level/High.svg";
import MediumBadgeIcon from "assets/sls-icons/Risk Level/Medium.svg";
import LowBadgeIcon from "assets/sls-icons/Risk Level/Low.svg";
import ExtremeBadgeIcon from "assets/sls-icons/Risk Level/Extreme.svg";
import { TreeUiComponent } from "app/shared/components/ReusableComponents/TreeView";
import { convertDateFromTimezoneIST } from "app/shared/utility/tzUtilFunction";
import _ from "lodash";
import {
  Modal as SLDModal,
  Button as SLDButton,
} from "@salesforce/design-system-react";
import UaUcGraph from "app/shared/components/PredictiveAnalysis/UaUcGraph";
import { config } from "config.js";
import CountGraph from "app/views/Graphs/Graphs/CountGraph";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import ClideLoader from "app/shared/ui-kit/loader";

const qs = require("qs");

export const badgeEnum = {
  Extreme: ExtremeBadgeIcon,
  "High Critical": ExtremeBadgeIcon,
  High: HighBadgeIcon,
  Medium: MediumBadgeIcon,
  Low: LowBadgeIcon,
};
const mappingName = {
  Department: "deptAnalysisList",
  Contractor: "contractorAnalysisList",
  Zone: "zoneAnalysisList",
  Location: "locationAnalysisList",
};
export const getUserProfileDetails = (id, arr = []) => {
  let cache = {};
  return ((id, arr) => {
    if (id in cache) {
      return cache[id];
    } else {
      cache[id] = arr.find((el) => {
        if (el.userId === id) {
          return el;
        }
      });
      return cache[id];
    }
  })(id, arr);
};
class ReportingDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showUser: false,
      reportDetail: [],
      userLists: [],
      soiId: this.props.match.params.id,
      resolveFlag: this.props.match.params.flag,
      originFlag: this.props.match.params.origin,
      isCommentApiLoading: false,
      dataLoading: true,
      accordionOpen: true,
      lightboxactive: false,
      subcommentlist: [],
      userData: JSON.parse(localStorage.getItem("userData")),
      comment: 0,
      commentAdd: "",
      replycommentAdd: "",
      commentlist: [],
      allComments: false,
      redirectToReferrer: false,
      assignedDetails: {},
      toggler: false,
      deletePopup: false,
      userLikedPopup: false,
      fsToggler: false,
      userLike: [],
      likeNumber: "",
      imageType:
        this.props.match?.params?.flag == "open"
          ? "evidence"
          : "evidence_resolve",
      escalatedList: [],
      controlMeasureList: [],
      showContractor: false,
      showZone: false,
      showLocation: false,
      projectList: [],
      gpcount: [],
      tbtcount: [],
      trainingInternalCount: [],
      trainingExternalCount: [],
      trainingEmployeeCount: [],
      trainingContractorCount: [],
      commentError: false,
      countModalParams: {
        isOpen: false,
        moduleName: "",
        moduleData: [],
      },
    };
    this.myRef = React.createRef();
    this.openResolve = this.openResolve.bind(this);
    this.openInviteUser = this.openInviteUser.bind(this);
  }

  showUserLikedPopup = () => {
    this.setState({
      userLikedPopup: true,
    });
  };

  handleClose = () => {
    this.setState({
      userLikedPopup: false,
    });
  };

  openResolve() {
    const show = !this.state.show;
    this.setState({
      show,
    });
  }

  openInviteUser() {
    const showUser = !this.state.showUser;
    this.setState({
      showUser,
    });
  }

  _fetchProjectListFlag = async () => {
    let requestData = {
      service_id: this.state.reportDetail.service_id,
      module_name: "uauc",
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.projectListForm,
        ContentType,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { showContractor, showZone, showLocation, projectList } =
                prevState;

              projectList = response.data.data;

              // check contractor flag
              const isContractor = projectList.map(
                (item) => item.contractor_flag
              );
              showContractor = isContractor == 0 ? false : true;
              console.log("isContractor == " + isContractor);

              const isZone = projectList.map((item) => item.zone_location_flag);
              showZone = isZone == 0 ? false : true;
              console.log("isZone == " + isZone);

              const isLocation = projectList.map(
                (item) => item.zone_location_flag
              );
              showLocation = isLocation == 0 ? false : true;
              console.log("isLocation == " + isLocation);

              return {
                showContractor,
                showZone,
                showLocation,
                projectList,
              };
            });
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
        });
    });
  };

  async reportDetail() {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    if (this.state.resolveFlag == "resolve") {
      var datailUrl = Url.soiDashboard.resolveDetailList;
    } else {
      var datailUrl = Url.soiDashboard.openDetailList;
    }

    await axios
      .post(
        datailUrl,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          soi_id: this.state.soiId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(async (response) => {
        if (response.data.status == 200) {
          await this.setState(
            {
              reportDetail: response.data.data,
              controlMeasureList: response.data.data.control_measure_list,
              gpcount: response.data.data.gp_count?.length
                ? response.data.data.gp_count
                : [{ gp_count: 0 }],
              tbtcount: response.data.data.tbt_count?.length
                ? response.data.data.tbt_count
                : [{ tbt_count: 0 }],
              trainingInternalCount: response.data.data.training_internal_count
                ?.length
                ? response.data.data.training_internal_count
                : [{ training_internal_count: 0 }],
              trainingExternalCount: response.data.data.training_external_count
                ?.length
                ? response.data.data.training_external_count
                : [{ training_external_count: 0 }],
              trainingEmployeeCount: response.data.data.training_employee_count
                ?.length
                ? response.data.data.training_employee_count
                : [{ training_employee_count: 0 }],
              trainingContractorCount: response.data.data
                .training_contractor_count?.length
                ? response.data.data.training_contractor_count
                : [{ training_contractor_count: 0 }],
              // gpcount: response.data.data.gp_count,
              // tbtcount: response.data.data.tbt_count,
              // trainingInternalCount: response.data.data.training_internal_count,
              // trainingExternalCount: response.data.data.training_external_count,
              // trainingEmployeeCount: response.data.data.training_employee_count,
              // trainingContractorCount:
              //   response.data.data.training_contractor_count,
            },
            () => this.fetchReportingPerson(response.data.data.reported_by_id)
          );
          this.escalatedList();
          this._fetchProjectListFlag();
        }
        // console.log(this.state.reportDetail)
        this.setState({ dataLoading: false });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  }

  async escalatedList() {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    var datailUrl = Url.soiDashboard.escalatedList;

    await axios
      .post(
        datailUrl,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          service_id: this.state.reportDetail.service_id,
          project_id: this.state.reportDetail.project_id,
          oiac_id: this.state.soiId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          const { reportDetail } = this.state;
          const escaletedIds = [];
          if (response.data.data.list?.length) {
            response.data.data.list.forEach((data) => {
              data.user_list.forEach((user) => {
                escaletedIds.push(user.id);
              });
            });
          }
          const voilatorIds =
            reportDetail?.incident_person_details.map((el) =>
              parseInt(el?.searched_incident_person_id)
            ) ?? [];
          const assignId =
            reportDetail?.assigned_user_id !== 0
              ? reportDetail?.assigned_user_id
              : this.state.userData?.user_id;
          const pendingDetailsIds =
            [
              assignId,
              ...reportDetail.invited_user.map((element) => element.userid),
              ...escaletedIds,
              ...voilatorIds,
            ] ?? [];
          if (pendingDetailsIds.length) {
            this.fetchUsersList([...new Set(pendingDetailsIds)]);
          }
          this.setState({ escalatedList: response.data.data.list });
        }
        // console.log(this.state.reportDetail)
        this.setState({ dataLoading: false });
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  }

  removeInviteUser = async (selectedUserId) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    console.log("selected user === " + selectedUserId);

    await axios
      .post(
        Url.smDashboard.removeInviteUser,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          report_id: this.state.soiId,
          selected_user_id: selectedUserId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          AlertMessage({
            message: "User removed successfully",
            type: "success",
          });
          this.reportDetail();
        } else {
          AlertMessage({
            message: "User not removed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  CommentList = async (limitComment = 0) => {
    this.setState({ isCommentApiLoading: true });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.getCommentsList,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          report_id: this.state.soiId,
          report_type: "soi",
          limit: limitComment,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            commentlist: response.data.data,
            total_number: response.data.total_number,
            comment: 0,
          });
          this.myChatRef.scrollToBottom(0, 0);
        }
      })
      .catch((error) => {})
      .finally(() => this.setState({ isCommentApiLoading: false }));
  };
  subCommentList = async () => {
    this.setState({ isCommentApiLoading: true });
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.dashboarddetailcommentsubcommentlist,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          report_id: this.state.soiId,
          comment_id: this.state.commentId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({ subcommentlist: response.data.data, comment: 1 });
        }
      })
      .catch((error) => {})
      .finally(() => this.setState({ isCommentApiLoading: false }));
  };

  _formValidation = async () => {
    const tempObj = this.state;

    await this.setState((prevState) => {
      prevState.commentError = tempObj.commentAdd ? false : true;

      return prevState;
    });
  };

  CommentAdd = async () => {
    await this._formValidation();

    let formErrorStatus;
    for (var propertyName in this.state) {
      if (propertyName.includes("Error")) {
        if (this.state[propertyName]) {
          console.log(propertyName);
          console.log(this.state[propertyName]);
          formErrorStatus = true;
          console.log("erroe");
          return;
        } else {
          formErrorStatus = false;
        }
        console.log(formErrorStatus);
      }
    }

    if (!formErrorStatus) {
      let userId = localStorage.getItem("userId");
      let token = localStorage.getItem("token");

      await axios
        .post(
          Url.smDashboard.addMainComment,
          qs.stringify({
            auth_key: token,
            user_id: userId,
            id: this.state.soiId,
            module: "soi",
            comment: this.state.commentAdd,
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          if (response.data.status == 200) {
            this.CommentList();
            this.setState({ commentAdd: "" });
            this.myChatRef.scrollToBottom(0, 0);
            AlertMessage({
              message: "Comment Added Successfully",
              type: "success",
            });
          } else {
            AlertMessage({
              message: "Comment Add Failed. Please try again.",
              type: "error",
            });
          }
        })
        .catch((error) => {});
    }
  };

  ReplyCommentAdd = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.replyToComment,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          reply_status: this.state.comment,
          comment_reply_id: 0,
          main_comment_id: this.state.commentId,
          comment: this.state.replycommentAdd,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.subCommentList();
          this.setState({ replycommentAdd: "" });
          this.myChatRef.scrollToBottom(0, 0);
          AlertMessage({
            message: "Reply Added Successfully",
            type: "success",
          });
        } else {
          AlertMessage({
            message: "Reply Add Failed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  LikeCommentAdd = async (Cid, ctype) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.addLikeToComment,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          comment_id: Cid,
          comment_type: ctype,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          if (this.state.comment == 1) {
            this.subCommentList();
          } else {
            this.CommentList();
          }
          AlertMessage({
            message: "Comment Liked Successfully",
            type: "success",
          });
        } else {
          AlertMessage({
            message: "Comment Like Failed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  LikeCommentRemove = async (Cid, Ctype) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.dashboardcontentcommentremoveunlike,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          comment_id: Cid,
          comment_type: Ctype,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          if (this.state.comment == 1) {
            this.subCommentList();
          } else {
            this.CommentList();
          }
          AlertMessage({
            message: "Liked Remove Successfully",
            type: "success",
          });
        } else {
          AlertMessage({
            message: "Like Remove Failed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  CommentRemove = async (Cid, ctype) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    var reportData = this.state.reportDetail;

    await axios
      .post(
        Url.soiDashboard.soideletecomment,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          service_id: reportData.service_id,
          project_id: reportData.project_id,
          soi_id: this.state.soiId,
          comment_id: Cid,
          comment_type: ctype,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          if (this.state.comment == 1) {
            this.subCommentList();
          } else {
            this.CommentList();
          }
          AlertMessage({
            message: "Comment Deleted Successfully",
            type: "success",
          });
        } else {
          AlertMessage({
            message: "Comment Deleted Failed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  deleteReport = async (SoiId) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.soiDashboard.soiopendelete,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          soi_id: SoiId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          AlertMessage({
            message: "UA/UC Deleted Successfully",
            type: "success",
          });
          setTimeout(() => {
            this.setState({ redirectToReferrer: true });
          }, 3000);
        } else {
          AlertMessage({
            message: "UA/UC Deleted Failed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  UserWhoLiked = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.dashboarddetaillikelist,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          report_id: this.state.soiId,
          report_type: "soi",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            userLike: response.data.data,
            likeNumber: response.data.like_number,
          });
        }
      })
      .catch((error) => {});
  };

  _addUsersToList = async (newUsers) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    const reportId = this.state.soiId;
    //console.log(newUsers);
    var selectedUserIds = [];
    newUsers.map((item, key) => selectedUserIds.push(item.user_id));

    var data = {
      auth_key: token,
      user_id: userId,
      report_id: reportId,
      selected_user_id: selectedUserIds,
    };

    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);

      if (property == "selected_user_id") {
        data[property].map((item, key) =>
          formBody.push(encodedKey + "=" + item)
        );
      } else {
        formBody.push(encodedKey + "=" + encodedValue);
      }
    }
    formBody = formBody.join("&");

    await axios
      .post(Url.smDashboard.addInviteUser, formBody, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })

      .then((response) => {
        if (response.data.status == 200) {
          AlertMessage({ message: "User added successfully", type: "success" });
          this.reportDetail();
        } else {
          AlertMessage({
            message: "User not added. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  componentDidMount() {
    this.CommentList();
    this.reportDetail();
    this.UserWhoLiked();
  }
  // componentDidUpdate() {
  //   if (this.state.soiId && this.state.dataLoading === false) {

  //   }

  // }
  cancelItem_onClick = () => {
    this.props.history.goBack();
    //window.history.back();
  };

  togglecommentId = async (comment_id) => {
    await this.setState({ commentId: comment_id });
    await this.subCommentList();
  };

  backToMainComment = async () => {
    await this.CommentList();
    await this.setState({ comment: 0, accordionOpen: true });
  };

  showModal = () => {
    this.setState({ deletePopup: true });
  };

  hideModal = () => {
    this.setState({ deletePopup: false });
  };

  comapareTargetDate = (date = "") => {
    if (date) {
      const targetDate = moment(date, "DD/MM/YYYY");
      const today = moment();
      if (targetDate.isSame(today, "day")) {
        return true;
      }
      return false;
    }
  };

  renderTimeLine = (reportData, state) => {
    let details = [
      {
        title: <p className="clide-v2-fs-3">Reported Details</p>,
        cardTitle: (
          <div className="d-flex p-1 text-start">
            <img
              src={reportData.reported_by_pic}
              className="clide-v2-profile-img"
              title={reportData.reported_by}
              alt="avatar"
            />
            <div className="p-1 px-3  w-100 mob-pe">
              <div className="col-12 col-md-12 col-lg-12 px-0">
                Reporting Date & Time{" "}
              </div>
              <div className="clide-v2-title mb-1">
                <label className="">
                  {`${reportData.submission_date} `} |{" "}
                  {reportData.submission_time}
                </label>
              </div>
            </div>
          </div>
        ),
      },
      {
        title: <p className="clide-v2-fs-3">Assigned To Details</p>,
        cardTitle: (
          <div className="d-flex p-1 text-start">
            <img
              src={reportData.reported_by_pic}
              className="clide-v2-profile-img"
              title={reportData.reported_by}
              alt="avatar"
            />
            <div className="p-1 px-3  w-100 mob-pe">
              <div className="col-12 col-md-12 col-lg-12 px-0">Due Date </div>
              <div className="clide-v2-title mb-1">
                <label className="">{`${reportData.target_date} `}</label>
              </div>
            </div>
          </div>
        ),
      },
      {
        title: <p className="clide-v2-fs-3">Closing Details</p>,
        cardTitle: (
          <div className="d-flex p-1 text-start">
            <img
              src={reportData.assigned_user_pic}
              className="clide-v2-profile-img"
              title={reportData.assigned_user}
            />
            <div className="p-1 px-3  w-100 mob-pe">
              <div className="col-12 col-md-12 col-lg-12 px-0">
                Closed Date{" "}
              </div>
              <div className="clide-v2-title mb-1">
                <label className="">{reportData.closing_date} </label>
              </div>
            </div>
          </div>
        ),
        skip: state.resolveFlag == "resolve" ? false : true,
      },
      {
        title: (
          <p className="clide-v2-fs-3">{`${
            state.resolveFlag === "open" ? "Overdue" : "Deviation"
          } Details`}</p>
        ),
        cardTitle: (
          <div className="d-flex p-1 text-start">
            <img
              src={reportData.assigned_user_pic}
              className="clide-v2-profile-img"
              title={reportData.assigned_user}
            />
            <div className="p-1 px-3  w-100 mob-pe">
              <div className="col-12 col-md-12 col-lg-12 px-0">
                {state.resolveFlag === "open" ? "Overdue" : "Deviation"}
              </div>
              <div className="clide-v2-title mb-1">
                <label className="">
                  {" "}
                  {state.resolveFlag === "open"
                    ? reportData.overdue
                    : reportData.deviation}{" "}
                </label>
              </div>
            </div>
          </div>
        ),
        skip:
          (reportData.overdue != undefined && reportData?.overdue != 0) ||
          (reportData.deviation != undefined && reportData?.deviation != 0)
            ? false
            : true,
      },
    ];

    if (reportData.invited_user?.length) {
      details = [
        ...details,
        ...reportData.invited_user?.map((image, index) => {
          return {
            title: <p className="clide-v2-fs-3">Invited Users</p>,
            cardTitle: (
              <div className="d-flex p-1 text-start">
                <img
                  className="clide-v2-profile-img"
                  src={image.userpic}
                  alt=""
                  title={image.username}
                />
                <div className="p-1 px-3  w-100 mob-pe">
                  <div className="col-12 col-md-12 col-lg-12 px-0">
                    Date & Time
                  </div>
                  <div className="clide-v2-title mb-1">
                    <label className="">
                      {convertDateFromTimezoneIST(image.invite_date) || "NA"}{" "}
                    </label>
                  </div>
                </div>
              </div>
            ),
            skip:
              (reportData.overdue != undefined && reportData?.overdue != 0) ||
              (reportData.deviation != undefined && reportData?.deviation != 0)
                ? false
                : true,
          };
        }),
      ];
    }
    // if (this.state.escalatedList?.length > 0) {
    //   details = [
    //     ...details,
    //     ...this.state.escalatedList?.map((data) =>
    //       data.user_list.map((user, idx) => {
    //         return {

    //             title: <p className="clide-v2-fs-3">{" "}
    //             {` Escalated  ${data.level_title}`}</p>,
    //             cardTitle: (
    //               <div className="d-flex p-1 text-start">
    //                 <img
    //                 className="clide-v2-profile-img"
    //                 src={user.pic}
    //                 alt=""
    //                 title={user.name}
    //               />
    //                 <div className="p-1 px-3  w-100 mob-pe">
    //                   <div className="col-12 col-md-12 col-lg-12 px-0">
    //                   Name
    //                   </div>
    //                   <div className="clide-v2-title mb-1">
    //                     <label className="">
    //                     {user.name}{" "}
    //                     </label>
    //                   </div>
    //                 </div>
    //                 <div className="p-1 px-3  w-100 mob-pe">
    //                   <div className="col-12 col-md-12 col-lg-12 px-0">
    //                   Date & Time
    //                   </div>
    //                   <div className="clide-v2-title mb-1">
    //                     <label className="">
    //                     {" "}
    //                   {data.escalatedDate} {data.escalatedTime}
    //                     </label>
    //                   </div>
    //                 </div>
    //               </div>
    //             ),
    //             skip:
    //             (reportData.overdue != undefined && reportData?.overdue != 0) ||
    //               (reportData.deviation != undefined &&
    //                 reportData?.deviation != 0)
    //               ? false
    //               : true,

    //         };
    //       })
    //     ),
    //   ];
    // }
    if (state.commentlist?.length) {
      const { comment, comment_time, user_name, user_pic } =
        state.commentlist[0] ?? {};
      details = [
        ...details,
        {
          title: <p className="clide-v2-fs-3"> Latest Comment</p>,
          cardTitle: (
            <div className="d-flex p-1 text-start">
              <img
                src={user_pic}
                className="clide-v2-profile-img"
                title={user_name}
              />
              <div
                className="p-1 px-3  w-100 mob-pe"
                onClick={() => {
                  this.myRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }}
              >
                <div className="col-12 col-md-12 col-lg-12 px-0">Message</div>
                <div className="clide-v2-title mb-1">
                  <label className=""> {comment} </label>
                  <label className="">{comment_time}</label>
                </div>
              </div>
            </div>
          ),
          skip:
            (reportData.overdue != undefined && reportData?.overdue != 0) ||
            (reportData.deviation != undefined && reportData?.deviation != 0)
              ? false
              : true,
        },
      ];
    }

    return details?.filter((el) => !el?.skip);
  };
  __generateCarouselData = (items = []) => {
    const content = [];
    items.forEach((data) => {
      content.push({
        url: data.link,
        contentType:
          data.format != "text"
            ? data.format == "image" || data.format == "img"
              ? "image"
              : "video"
            : "video",
      });
    });
    return content;
  };

  __generateTreeNodes = (data, activeNode = "") => {
    const nodes = activeNode.split(" ");
    const treeNodes = [];
    const nodeNames = data.map((el) => el.level_id);
    nodeNames.forEach((node, idx) => {
      if (data[idx].level_id === node)
        treeNodes.push({
          label:
            data[idx].level_title === nodes[0].trim()
              ? activeNode
              : data[idx].level_title,
          date: `${data[idx].escalatedDate}  ${data[idx].escalatedTime}`,
          children: data[idx].user_list,
        });
    });
    return treeNodes;
  };
  __renderTreeTemplate = (parent, children) => {
    return (
      <div
        className="feild-group border-layout-4 px-2 py-2 rounded-0 shadow-none text-center "
        style={{ maxWidth: "230px" }}
      >
        <div className="border rounded py-2 ">
          <span className="clide-v2-fs-4 font-weight-bold">
            {children.name}
          </span>
          <div className="image-box my-3" style={{ marginRight: "0.5rem" }}>
            <GetPopoverFrame
              element={
                <>
                  <img
                    src={children.pic}
                    alt=""
                    className="cursor-p clide-v2-profile-img"
                  />
                </>
              }
              mappingKeys={() => {
                const detail =
                  getUserProfileDetails(children?.id, this.state.userLists) ??
                  {};
                return {
                  ID: detail["employeeId"] ?? "NA",
                  Department: detail["field"]?.split(":")[1] ?? "NA",
                  Type: detail["userType"] ?? "NA",
                  Designation: detail["designation"] ?? "NA",
                  Contact: detail["contact_number"] ?? "NA",
                  // Zone: detail['designation'],
                  // Location: detail['designation'],
                };
              }}
            />
          </div>
          <div className="feild-group d-flex justify-content-center">
            <div className=" clide-v2-fs-5">{parent.date}</div>
          </div>
        </div>
      </div>
    );
  };
  fetchReportingPerson = (id) => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    axios
      .post(
        Url.clientURL + "/user/reportedpersonprofile",
        qs.stringify({
          auth_key: token,
          user_id: userId,
          reported_per_id: id,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((res) => {
        this.setState({
          assignedDetails: res.data.data,
        });
      })
      .catch((err) => console.log(err));
  };
  fetchUsersList = async (ids = []) => {
    let databody = new FormData();
    const userList = ids.filter((el) => {
      return !Number.isNaN(el);
    });
    if (!userList.length) {
      return false;
    }
    databody.append("auth_key", localStorage.getItem("token"));
    databody.append("user_id", localStorage.getItem("userId"));
    databody.append("user_id_list", userList);
    await axios
      .post(Url.usersList, databody, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            userLists: response.data?.listUserProfileDetailsData,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  CountDoughnut = (data) => {
    return data.length > 0 ? (
      <div style={{ justifyContent: "center" }}>
        <UaUcGraph
          title=""
          graphData={data}
          list={false}
          height={75}
          cutout={"55%"}
          radius={"100%"}
        />
      </div>
    ) : null;
  };

  debounceFunc = _.debounce(function (callback) {
    callback();
  }, 300);

  onViewCountDetails = (moduleName = "") => {
    
    this.setState({
      countModalParams: {
        isOpen: true,
        moduleName,
        moduleData: this.state.reportDetail[mappingName[moduleName]],
      },
    });
  };
  generateBarData = (data = []) => {
    return {
      data: {
        labels: data.map((item) => item.name),
        datasets: [
          {
            label: "Open Count",
            data: data.map((item) => item.open_count),
            backgroundColor: data.map((item) => item.openCntcolor),
            color: "white",
          },
          {
            label: "Close Count",
            data: data.map((item) => item.close_count),
            backgroundColor: data.map((item) => item.closeCntcolor),
            color: "white",
          },
        ],
      },
    };
  };

  render() {
    const barGraphOptions = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltip: {
          backgroundColor: "white",
          bodyColor: "#666666",
          bodyFont: {
            weight: 500,
          },
          borderColor: "rgba(0, 0, 0, 0.08)",
          borderWidth: 2,
          cornerRadius: 4,
          bodySpacing: 4,
          padding: {
            top: -10,
            bottom: 8,
            left: 0,
            right: 0,
          },
          bodyAlign: "center",
          displayColors: false,
          titleColor: "transparent",
        },
        datalabels: {
          display: true,
          align: "top",
          anchor: "end",
          color: "black",
        },
      },
      scales: {
        x: {
          display: true,
          offset: true,
          grid: {
            display: false,
          },
          ticks: {
            color: "#666666",
            font: {
              size: 11,
              weight: "500",
            },
            padding: 0,
          },
        },
        y: {
          display: true,
          beginAtZero: true,
          grid: {
            color: "#ececec",
          },
          ticks: {
            color: "#666666",
            font: {
              size: 11,
              weight: "500",
            },
            padding: 20,
          },
        },
      },
    };
   

    var reportData = this.state.reportDetail;
    var sourcesList = [];
    var sourcesType = [];
    var inputMessageFlag = 0;
    const { userData } = this.state;
    const isReportedUserEnabled =
      reportData.reported_by_id === userData.user_id;
    const isApproveEnabled = [3, 1].includes(userData?.designation_flag);
    console.log(
      "isReportedUserEnabled",
      isReportedUserEnabled,
      isApproveEnabled
    );

    if (this.state.dataLoading == false) {
      var evidencePics =
        this.state.reportDetail && this.state.reportDetail.evidence
          ? this.state.reportDetail.evidence.map((item) => {
              return {
                url: item.link,
                type: item.format,
              };
            })
          : [];

      if (
        this.state.imageType &&
        this.state.imageType == "evidence" &&
        this.state.reportDetail.evidence
      ) {
        sourcesList = this.state.reportDetail.evidence.map((item) => item.link);
      } else {
        sourcesList = this.state.reportDetail.evidence_resolve.map(
          (item) => item.link
        );
      }

      if (this.state.imageType && this.state.imageType == "evidence") {
        sourcesType = this.state.reportDetail.evidence.map(
          (item) => item.format
        );
      } else {
        sourcesType = this.state.reportDetail.evidence_resolve.map((item) =>
          item.format == "img" ? "image" : item.format
        );
      }
    }

    if (this.state.redirectToReferrer) {
      return <Redirect to={`/ua-uc/open`} />;
    }

    let uaUcChartData = [];
    if (this.state.reportDetail?.ua_number != undefined) {
      uaUcChartData = [
        {
          y: reportData?.ua_number,
          name: "Unsafe Act",
          color: "#C7AC83",
        },
        {
          y: reportData?.uc_number,
          name: "Unsafe Condition",
          color: "#554040",
        },
      ];
    }

    const uaUcChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      title: null,
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          showInLegend: true,
        },
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      series: [
        {
          data: [
            { y: reportData.ua_number, name: "Unsafe Act", color: "#C7AC83" },
            {
              y: reportData.uc_number,
              name: "Unsafe Condition",
              color: "#554040",
            },
          ],
          size: "100%",
          innerSize: "56%",
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };

    let data = [
      {
        y: reportData.extreme_number,
        color: reportData.extreme_number_color,
        name: "Extreme",
      },
      {
        y: reportData.high_number,
        color: reportData.high_number_color,
        name: "HIGH",
      },
      {
        y: reportData.medium_number,
        color: reportData.medium_number_color,
        name: "MEDIUM",
      },
      {
        y: reportData.low_number || 0,
        color: reportData.low_number_color,
        name: "LOW",
      },
    ];

    const severityChartData = {
      labels: ["Extreme", "High", "Medium", "Low"],
      datasets: [
        {
          label: "",
          maxBarThickness: 30,
          backgroundColor: data.map((data) => data.color),
          data: data.map((data) => data.y),
        },
      ],
    };

    const severityChart = {
      chart: {
        type: "column",
        height: 150,
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      title: null,
      xAxis: {
        categories: ["Extreme", "High", "Medium", "Low"],
        lineColor: "#000000",
      },
      yAxis: {
        min: 0,
        // max: 5,
        tickInterval: 1,
        title: {
          enabled: false,
        },
        allowDecimals: false,
        gridLineColor: "rgba(0, 0, 0, 0.04)",
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: "{point.y}",
          },
        },
      },
      series: [
        {
          data: [
            {
              y: reportData.extreme_number,
              color: reportData.extreme_number_color,
              name: "Extreme",
            },
            {
              y: reportData.high_number,
              color: reportData.high_number_color,
              name: "HIGH",
            },
            {
              y: reportData.medium_number,
              color: reportData.medium_number_color,
              name: "MEDIUM",
            },
            {
              y: reportData.low_number || 0,
              color: reportData.low_number_color,
              name: "LOW",
            },
          ],
        },
      ],
    };

    let nmuaUcChartData = [];

    if (this.state.reportDetail?.nm_ua_number != undefined) {
      nmuaUcChartData = [
        {
          y: reportData?.nm_ua_number,
          name: "Unsafe Act",
          color: reportData?.nm_ua_color,
        },
        {
          y: reportData?.nm_uc_number,
          name: "Unsafe Condition",
          color: reportData?.nm_uc_color,
        },
      ];
    }

    const nmuaUcChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      title: null,
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          showInLegend: true,
        },
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      series: [
        {
          data: [
            {
              y: reportData.nm_ua_number,
              name: "Unsafe Act",
              color: reportData.nm_ua_color,
            },
            {
              y: reportData.nm_uc_number,
              name: "Unsafe Condition",
              color: reportData.nm_uc_color,
            },
          ],
          size: "100%",
          innerSize: "56%",
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };

    let analysisChartData = [];
    if (this.state.reportDetail?.activityAnalysis != undefined) {
      analysisChartData = [
        {
          y: reportData?.fa_number ?? 0,
          name: "FA",
          color: "#FF4242",
        },
        {
          y: reportData?.rta_number ?? 0,
          name: "RTA",
          color: "#FAAC15",
        },
        {
          y: reportData?.mtc_number ?? 0,
          name: "MTC",
          color: "#086992",
        },
        {
          y: reportData?.lti_number ?? 0,
          name: "LTI",
          color: "#18DA07",
        },
        {
          y: reportData.activityAnalysis?.first_aid_number ?? 0,
          name: "First Aid",
          color: "#1589EE",
        },
        {
          y: reportData.activityAnalysis?.occupational_illness_number ?? 0,
          name: "OI",
          color: "#C511FF",
        },
        {
          y: reportData.activityAnalysis?.notable_number || 0,
          name: "Notable",
          color: "rgb(161, 255, 206)",
        },
      ];
    }

    const analysisChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      title: null,
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          showInLegend: true,
        },
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      series: [
        {
          data: [
            { y: reportData.fa_number, name: "FA", color: "#FF4242" },
            { y: reportData.rta_number, name: "RTA", color: "#FAAC15" },
            { y: reportData.mtc_number, name: "MTC", color: "#086992" },
            { y: reportData.lti_number, name: "LTI", color: "#18DA07" },
            {
              y: reportData.activityAnalysis?.notable_number || 0,
              name: "Notable",
              color: "#rgb(161, 255, 206)",
            },
            {
              y: reportData.activity_analysis?.first_aid_number || 0,
              name: "First Aid",
              color: "#1589EE",
            },
            {
              y: reportData.activity_analysis?.occupational_illness_number || 0,
              name: "OI",
              color: "#C511FF",
            },
          ],
          size: "100%",
          innerSize: "56%",
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };

    //GP Count
    const gpCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2rem" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.gpcount.map((data) => data.gp_count) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.gpcount.map((data) => data.color),
      series: [
        {
          data: [this.state.gpcount.map((data) => data.gp_count)],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //tbtCount
    const tbtCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2rem" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.tbtcount.map((data) => data.tbt_count) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.tbtcount.map((data) => data.color),
      series: [
        {
          data: [this.state.tbtcount.map((data) => data.tbt_count)],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    const intetlCount = this.state.trainingInternalCount?.length
      ? this.state.trainingInternalCount.map(
          (data) => data.training_internal_count
        )
      : 0;
    //InternalCOunt
    const internalCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2rem" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          intetlCount +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.trainingInternalCount.map((data) => data.color),
      series: [
        {
          data: [
            this.state.trainingInternalCount?.length
              ? this.state.trainingInternalCount.map(
                  (data) => data.training_internal_count
                )
              : "0",
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };
    const externalC = this.state.trainingExternalCount?.length
      ? this.state.trainingExternalCount.map(
          (data) => data.training_external_count
        )
      : 0;
    //externalcount
    const externalCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2rem" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          //  this.state.trainingExternalCount.map((data) => data.training_external_count) +
          externalC +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.trainingExternalCount.map((data) => data.color) ?? [],
      series: [
        {
          // data: [this.state.trainingExternalCount.map((data) => data.training_external_count)],
          data: [
            this.state.trainingExternalCount.length > 0
              ? this.state.trainingExternalCount.map(
                  (data) => data.training_external_count
                )
              : 0,
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //employeeCount
    const employeeCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2rem" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.trainingEmployeeCount.map(
            (data) => data.training_employee_count
          ) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.trainingEmployeeCount.map((data) => data.color),
      series: [
        {
          data: [
            this.state.trainingEmployeeCount.map(
              (data) => data.training_employee_count
            ),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    //contractorCount
    const contractorCount = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      title: {
        useHTML: true,
        text:
          '<span style="color:' +
          "#000" +
          "; font-weight:" +
          "bold" +
          "; font-size: " +
          "2rem" +
          "; text-align:" +
          "center" +
          '; height: 36">' +
          this.state.trainingContractorCount.map(
            (data) => data.training_contractor_count
          ) +
          "</span>",
        verticalAlign: "middle",
      },
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          borderWidth: 0,
          dataLabels: {
            enabled: false,
          },
        },
      },
      colors: this.state.trainingContractorCount.map((data) => data.color),
      series: [
        {
          data: [
            this.state.trainingContractorCount.map(
              (data) => data.training_contractor_count
            ),
          ],
          size: "100%",
          innerSize: "84%",
        },
      ],
    };

    if (this.state.originFlag == "thgD") {
      var originUrl = {
        pathname: `/`,
      };
    } else if (this.state.originFlag == "thgPO") {
      var originUrl = {
        pathname: `/ua-uc/open`,
      };
    } else if (this.state.originFlag == "thgPR") {
      var originUrl = {
        pathname: `/ua-uc/resolve`,
      };
    }

    if (this.state.dataLoading) {
      return (
        <div className="my-3">
          {" "}
          <ClideLoader />
        </div>
      );
    }
    return (
      <div className="clide-v2-pe-5 clide-v2-ps-5 container-fluid mb-5 pe-5 ps-5">
        <DetailTimelineSection
          data={this.renderTimeLine(reportData, this.state)}
          state={this.state}
          url={originUrl}
          functionContent={this}
          variableData={{ isApproveEnabled, isReportedUserEnabled }}
        />

        <div className="row mt-3">
          <div className="col-12 col-md-9 col-lg-9">
            <div className="clide-v2-card-box-1">
              <div id="workexperience" className="sectionClass">
                <div className="row ">
                  <div className="fullWidth eight columns">
                    <ul className="cbp_tmtimeline">
                      <li>
                        <div className="cbp_tmicon cbp_tmicon-phone">
                          <TimelineDocIcon />
                        </div>
                        <div className="cbp_tmlabel clide-v2-white-box">
                          <div className="row">
                            <div className="col-12 col-md-4 col-lg-4">
                              <CarouselComponent
                                items={this.__generateCarouselData(
                                  this.state.reportDetail.evidence
                                )}
                                showArrows={false}
                              />
                            </div>
                            <div className="col-12 col-md-8 col-lg-8">
                              <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold text-center pt-3">
                                Reported Details
                              </p>

                              <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 mb-2">
                                  <div className="col-12 col-md-12 col-lg-12 px-0">
                                    <div className="clide-v2-title mb-1">
                                      <label className="clide-v2-fs-4 mb-0">
                                        Observation :
                                      </label>
                                    </div>
                                  </div>
                                  <div className="clide-v2-title mb-1">
                                    <label className="">
                                      {reportData.observation != "" && (
                                        <div className="output-text">
                                          {reportData.observation}
                                        </div>
                                      )}{" "}
                                      {reportData.observation_recording !=
                                        undefined &&
                                        reportData.observation_recording.substr(
                                          reportData.observation_recording
                                            .length - 3
                                        ) == "mp3" && (
                                          <AudioPlayer
                                            src={
                                              reportData.observation_recording
                                            }
                                            showJumpControls={true}
                                            layout="horizontal-reverse"
                                            customVolumeControls={[]}
                                            customAdditionalControls={[]}
                                            className="border-0  shadow-none"
                                          />
                                        )}
                                    </label>
                                  </div>
                                </div>
                                {CheckFormSettingField(
                                  reportData.form_setting?.setting,
                                  "hazard"
                                ) && reportData?.hazard ? (
                                  <div className="col-12 col-md-6 col-lg-6 mb-2">
                                    <div className="col-12 col-md-12 col-lg-12 px-0">
                                      <div className="clide-v2-title mb-1">
                                        <label className="clide-v2-fs-4 mb-0">
                                          Hazard :
                                        </label>
                                      </div>
                                    </div>
                                    <div className="clide-v2-title mb-1">
                                      <label className="">
                                        {reportData.hazard}
                                      </label>
                                    </div>
                                  </div>
                                ) : null}
                                <div className="col-12 col-md-6 col-lg-6 mb-2">
                                  <div className="col-12 col-md-12 col-lg-12 px-0">
                                    <div className="clide-v2-title mb-1">
                                      <label className="clide-v2-fs-4 mb-0">
                                        Risk Level :
                                      </label>
                                    </div>
                                  </div>
                                  <div className="clide-v2-title  mb-1">
                                    <label className="">
                                      <img
                                        src={badgeEnum[reportData.severity]}
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                      />
                                      &nbsp; &nbsp;
                                      {reportData.severity}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 mb-2">
                                  <div className="col-12 col-md-12 col-lg-12 px-0">
                                    <div className="clide-v2-title mb-1">
                                      <label className="clide-v2-fs-4 mb-0">
                                        Source :
                                      </label>
                                    </div>
                                  </div>
                                  <div className="clide-v2-title mb-1">
                                    <label className="">
                                      {reportData.source}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      {this?.state?.resolveFlag == "resolve" ? (
                        <li>
                          <div className="cbp_tmicon cbp_tmicon-screen">
                            <TimelineInfoIcon />
                          </div>
                          <div className="cbp_tmlabel clide-v2-white-box">
                            <div className="row">
                              <div className="col-12  mb-2">
                                <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold pt-3 mb-4">
                                  Resolved Details
                                </p>
                                <div className="row">
                                  <div className="col-12 col-md-6 col-lg-6">
                                    <div className="col-12 col-md-12 col-lg-12 px-0">
                                      <div className="clide-v2-title mb-1">
                                        <label className="clide-v2-fs-4 mb-0">
                                          Resolving evidence{" "}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="clide-v2-title mb-1">
                                      <label className="">
                                        {this.state.reportDetail
                                          .evidence_resolve &&
                                        this.state.reportDetail.evidence_resolve
                                          .length == 0 ? (
                                          <div>
                                            <img
                                              src={img}
                                              className="img-fluid"
                                            />
                                          </div>
                                        ) : (
                                          <CarouselComponent
                                            items={this.__generateCarouselData(
                                              this.state.reportDetail
                                                .evidence_resolve
                                            )}
                                            showArrows={false}
                                          />
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-6 col-lg-6">
                                    <div className="col-12 col-md-12 col-lg-12 px-0">
                                      <div className="clide-v2-title mb-1">
                                        <label className="clide-v2-fs-4 mb-0">
                                          Resolved Remark
                                        </label>
                                      </div>
                                    </div>
                                    <div className="clide-v2-title mb-1">
                                      <label className="">
                                        <div>
                                          <div className="feild-group rd-box ">
                                            {reportData.closing_remark && (
                                              <div className="output-text">
                                                {reportData.closing_remark}
                                              </div>
                                            )}
                                          </div>

                                          <div className="feild-group rd-box">
                                            {reportData.closing_remark_recording !=
                                              undefined &&
                                              reportData.closing_remark_recording.substr(
                                                reportData
                                                  .closing_remark_recording
                                                  .length - 3
                                              ) == "mp3" && (
                                                <AudioPlayer
                                                  src={
                                                    reportData.closing_remark_recording
                                                  }
                                                  showJumpControls={true}
                                                  layout="horizontal-reverse"
                                                  customVolumeControls={[]}
                                                  customAdditionalControls={[]}
                                                />
                                              )}
                                          </div>
                                        </div>
                                      </label>
                                    </div>
                                  </div>

                                  <div className="col-12 col-md-6 col-lg-6">
                                    <div className="col-12 col-md-12 col-lg-12 px-0">
                                      <div className="clide-v2-title mb-1">
                                        <label className="clide-v2-fs-4 mb-0">
                                          Resolved Date{" "}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="clide-v2-title mb-1">
                                      <label className="">
                                        {reportData.closing_date}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ) : null}
                      <li>
                        <div className="cbp_tmicon cbp_tmicon-screen">
                          <TimelineInfoIcon />
                        </div>
                        <div className="cbp_tmlabel clide-v2-white-box">
                          <div className="row">
                            <div className="col-12 col-md-6 col-lg-6 mb-2">
                              <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold pt-3 mb-4">
                                Activity info
                              </p>
                              <div className="row">
                                <div className="col-12 col-md-6 col-lg-6">
                                  <div className="col-12 col-md-12 col-lg-12 px-0">
                                    <div className="clide-v2-title mb-1">
                                      <label className="clide-v2-fs-4 mb-0">
                                        Service{" "}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="clide-v2-title mb-1">
                                    <label className="">
                                      {reportData.service_name}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-12 col-md-6 col-lg-6">
                                  <div className="col-12 col-md-12 col-lg-12 px-0">
                                    <div className="clide-v2-title mb-1">
                                      <label className="clide-v2-fs-4 mb-0">
                                        Plant/Project{" "}
                                      </label>
                                    </div>
                                  </div>
                                  <div className="clide-v2-title mb-1">
                                    <label className="">
                                      {reportData.project_name}
                                    </label>
                                  </div>
                                </div>
                                {CheckFormSettingField(
                                  reportData.form_setting?.setting,
                                  "barrier"
                                ) ? (
                                  <div className="col-12 col-md-6 col-lg-6">
                                    <div className="col-12 col-md-12 col-lg-12 px-0">
                                      <div className="clide-v2-title mb-1">
                                        <label className="clide-v2-fs-4 mb-0">
                                          Barrier{" "}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="clide-v2-title mb-1">
                                      <label className="">
                                        {reportData.barrier}
                                      </label>
                                    </div>
                                  </div>
                                ) : null}

                                {CheckFormSettingField(
                                  reportData.form_setting?.setting,
                                  "sub hazard"
                                ) && reportData.sub_hazard ? (
                                  <div className="col-12 col-md-6 col-lg-6">
                                    <div className="col-12 col-md-12 col-lg-12 px-0">
                                      <div className="clide-v2-title mb-1">
                                        <label className="clide-v2-fs-4 mb-0">
                                          Sub Hazard{" "}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="clide-v2-title mb-1">
                                      <label className="">
                                        {reportData.sub_hazard}
                                      </label>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-2">
                              <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold pt-3 mb-4">
                                Department/Contractor/Location
                              </p>
                              <div className="row">
                                {CheckFormSettingField(
                                  reportData.form_setting?.setting,
                                  "department"
                                ) ? (
                                  <div className="col-12 col-md-6 col-lg-6">
                                    <div className="col-12 col-md-12 col-lg-12 px-0">
                                      <div className="clide-v2-title mb-1">
                                        <label className="clide-v2-fs-4 mb-0">
                                          Department{" "}  <BarChart2
                                            className="cursor-p ml-2"
                                            title="Show Counts"
                                            onClick={() =>
                                              this.onViewCountDetails(
                                                "Department"
                                              )
                                            }
                                          />
                                        </label>
                                      </div>
                                    </div>
                                    {!reportData.department ? (
                                      <div className="clide-v2-title mb-1">
                                        <label className="">NA</label>
                                      </div>
                                    ) : (
                                      <div className="clide-v2-title mb-1">
                                        <label className="">
                                          {reportData.department}
                                        </label>
                                      </div>
                                    )}
                                  </div>
                                ) : null}

                                {CheckFormSettingField(
                                  reportData.form_setting?.setting,
                                  "contractor"
                                ) &&
                                this.state.showContractor &&
                                reportData.contractor ? (
                                  <div className="col-12 col-md-6 col-lg-6">
                                    <div className="col-12 col-md-12 col-lg-12 px-0">
                                      <div className="clide-v2-title mb-1">
                                        <label className="clide-v2-fs-4 mb-0">
                                          Contractor{" "} <BarChart2
                                            className="cursor-p ml-2"
                                            title="Show Counts"
                                            onClick={() =>
                                              this.onViewCountDetails(
                                                "Contractor"
                                              )
                                            }
                                          />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="clide-v2-title mb-1">
                                      <label className="">
                                        {reportData.contractor}
                                      </label>
                                    </div>
                                  </div>
                                ) : null}

                                {CheckFormSettingField(
                                  reportData.form_setting?.setting,
                                  "zone"
                                ) ? (
                                  <div className="col-12 col-md-6 col-lg-6">
                                    {this.state.showZone && reportData.zone && (
                                      <>
                                        <div className="col-12 col-md-12 col-lg-12 px-0">
                                          <div className="clide-v2-title mb-1">
                                            <label className="clide-v2-fs-4 mb-0">
                                              Zone{" "} <BarChart2
                                            className="cursor-p ml-2"
                                            title="Show Counts"
                                            onClick={() =>
                                              this.onViewCountDetails(
                                                "Zone"
                                              )
                                            }
                                          />
                                            </label>
                                          </div>
                                        </div>
                                        <div className="clide-v2-title mb-1">
                                          <label className="">
                                            {reportData.zone}
                                          </label>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                ) : null}
                                {CheckFormSettingField(
                                  reportData.form_setting?.setting,
                                  "location"
                                ) ? (
                                  <div className="col-12 col-md-12 col-lg-6 px-0">
                                    {this.state.showLocation &&
                                      reportData.location && (
                                        <>
                                          <div className="col-12 col-md-12 col-lg-12 px-0">
                                            <div className="clide-v2-title mb-1">
                                              <label className="clide-v2-fs-4 mb-0">
                                                Location{" "} <BarChart2
                                            className="cursor-p ml-2"
                                            title="Show Counts"
                                            onClick={() =>
                                              this.onViewCountDetails(
                                                "Location"
                                              )
                                            }
                                          />
                                              </label>
                                            </div>
                                          </div>
                                          <div className="clide-v2-title mb-1">
                                            <label className="">
                                              {reportData.location}
                                            </label>
                                          </div>
                                        </>
                                      )}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      {(CheckFormSettingField(
                        reportData.form_setting?.setting,
                        "control measure"
                      ) &&
                        reportData.control_measure) ||
                      this.state.controlMeasureList.length > 0 ? (
                        <li>
                          <div className="cbp_tmicon cbp_tmicon-screen">
                            <TimelineInfoIcon />
                          </div>
                          <div className="cbp_tmlabel clide-v2-white-box">
                            <div className="row">
                              <div className="col-12 col-md-6 col-lg-6 mb-2">
                                <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold pt-3 mb-4">
                                  Control Measure
                                </p>
                                <div className="row">
                                  {reportData.control_measure_recording !=
                                    undefined &&
                                    reportData.control_measure_recording.substr(
                                      reportData.control_measure_recording
                                        .length - 3
                                    ) == "mp3" && (
                                      <div className="col-12 col-md-6 col-lg-6">
                                        <div className="col-12 col-md-12 col-lg-12 px-0">
                                          <div className="clide-v2-title mb-1">
                                            <AudioPlayer
                                              src={
                                                reportData.control_measure_recording
                                              }
                                              showJumpControls={true}
                                              layout="horizontal-reverse"
                                              customVolumeControls={[]}
                                              customAdditionalControls={[]}
                                              className="border-0  shadow-none"
                                            />
                                          </div>
                                        </div>
                                        <div className="clide-v2-title mb-1">
                                          <label className="">
                                            {reportData.service_name}
                                          </label>
                                        </div>
                                      </div>
                                    )}
                                  {reportData.control_measure && (
                                    <div className="col-12 col-md-6 col-lg-6">
                                      <div className="clide-v2-title mb-1">
                                        <label className="">
                                          {reportData.control_measure}
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                  {
                                    <div className="col-12 col-md-6 col-lg-6">
                                      <div className="clide-v2-title mb-1">
                                        {this.state.controlMeasureList.map(
                                          (data, index) => (
                                            <div className="output-text common-card-border-layout-4  px-3 py-2 font-18 shadow-none my-3 ">
                                              {`${index + 1}. ${
                                                data.controlMeasure
                                              }`}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ) : null}

                      <li>
                        <div className="cbp_tmicon cbp_tmicon-screen">
                          <TimelineInfoIcon />
                        </div>
                        <div className="cbp_tmlabel clide-v2-white-box">
                          <div className="row">
                            <div className="col-12 col-md-6 col-lg-6 mb-2">
                              <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold pt-3 mb-4">
                                Assigned Details
                              </p>
                              <div className="row">
                                <div className="col-12 col-md-6 col-lg-6">
                                  <div className="col-12 col-md-12 col-lg-12 px-0">
                                    <div className="clide-v2-title mb-1">
                                      <label className="clide-v2-fs-4">
                                        Resolve By
                                      </label>
                                    </div>
                                  </div>
                                  <div className="border py-2 rounded text-center">
                                    <div className="clide-v2-fs-4 font-weight-bold">
                                      <label className="">
                                        {reportData.assigned_user}
                                      </label>
                                    </div>
                                    <GetPopoverFrame
                                      element={
                                        <>
                                          <img
                                            src={reportData.assigned_user_pic}
                                            alt=""
                                            className="clide-v2-profile-img"
                                          />
                                        </>
                                      }
                                      mappingKeys={() => {
                                        const detail =
                                          getUserProfileDetails(
                                            this.state.reportDetail
                                              ?.assigned_user_id !== 0
                                              ? this.state.reportDetail
                                                  ?.assigned_user_id
                                              : this.state.userData?.user_id,
                                            this.state.userLists
                                          ) ?? {};
                                        return {
                                          ID: detail["employeeId"] ?? "NA",
                                          Department:
                                            detail["field"]?.split(":")[1] ??
                                            "NA",
                                          Type: detail["userType"] ?? "NA",
                                          Designation:
                                            detail["designation"] ?? "NA",
                                          Contact:
                                            detail["contact_number"] ?? "NA",
                                          // Zone: detail['designation'],
                                          // Location: detail['designation'],
                                        };
                                      }}
                                    />
                                    <div className="col-12 col-md-12 col-lg-12 px-0">
                                      <div className="clide-v2-title mb-1">
                                        <label className=" mb-0">
                                          Due Date{" "}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="clide-v2-title mb-1">
                                      <label className="">
                                        {reportData.target_date || "17/05/2000"}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6 mb-2">
                              <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold pt-3 mb-4">
                                Invited Team ({reportData.invited_user?.length})
                              </p>
                              <div className="row">
                                <div className="col-12 col-md-6 col-lg-6">
                                  <div className="col-12 col-md-12 col-lg-12 px-0"></div>
                                  <Scrollbars
                                    style={{ height: `10rem` }}
                                    renderView={(props) => (
                                      <div
                                        {...props}
                                        style={{
                                          paddingRight: "15px",
                                          ...props.style,
                                          overflowX: "hidden",
                                        }}
                                      />
                                    )}
                                  >
                                    <div className="d-flex">
                                      {reportData.invited_user?.map(
                                        (image, index) => (
                                          <div
                                            className="feild-group common-card-border-layout-4 px-2 py-2 rounded-0 shadow-none text-center mr-4 "
                                            key={index}
                                            style={{
                                              maxWidth: "200px",
                                              minWidth: "155px",
                                            }}
                                          >
                                            <div className="output-text ">
                                              <span className="label mt-3 font-14 common-black-1 font-weight-500">
                                                {image.username}
                                              </span>
                                              <div
                                                className="image-box my-3"
                                                style={{
                                                  marginRight: "0.5rem",
                                                }}
                                              >
                                                <GetPopoverFrame
                                                  element={
                                                    <>
                                                      <img
                                                        src={image.userpic}
                                                        alt=""
                                                        className="cursor-p"
                                                      />
                                                    </>
                                                  }
                                                  mappingKeys={() => {
                                                    const detail =
                                                      getUserProfileDetails(
                                                        image.userid,
                                                        this.state.userLists
                                                      ) ?? {};
                                                    return {
                                                      ID:
                                                        detail["employeeId"] ??
                                                        "NA",
                                                      Department:
                                                        detail["field"]?.split(
                                                          ":"
                                                        )[1] ?? "NA",
                                                      Type:
                                                        detail["userType"] ??
                                                        "NA",
                                                      Designation:
                                                        detail["designation"] ??
                                                        "NA",
                                                      Contact:
                                                        detail[
                                                          "contact_number"
                                                        ] ?? "NA",
                                                    };
                                                  }}
                                                />
                                              </div>
                                              <div className="feild-group d-flex justify-content-center">
                                                <div className=" label font-14 font-weight-500 common-black-1 mb-1">
                                                  {convertDateFromTimezoneIST(
                                                    image.invite_date
                                                  ) || "NA"}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </Scrollbars>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="cbp_tmicon cbp_tmicon-screen">
                          <TimelineInfoIcon />
                        </div>
                        <div className="cbp_tmlabel clide-v2-white-box">
                          <div className="row">
                            <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold pt-3 mb-4">
                              Violator Details
                            </p>
                            <div className="col-12 mb-2">
                              <div className="row">
                                <div className="col-12 ">
                                  <Scrollbars
                                    style={{ height: "11rem" }}
                                    renderView={(props) => (
                                      <div
                                        {...props}
                                        style={{
                                          paddingRight: "15px",
                                          ...props.style,
                                          overflowX: "hidden",
                                        }}
                                      />
                                    )}
                                  >
                                    <div className="col-12 d-flex">
                                      {reportData.incident_person_details?.map(
                                        (data, index, arr) => {
                                          const detail =
                                            getUserProfileDetails(
                                              parseInt(
                                                data.searched_incident_person_id
                                              ),
                                              this.state.userLists
                                            ) ?? {};
                                          const isUserFromOrgnization =
                                            data?.searched_incident_person_id;
                                          const position =
                                            arr.length - 1 === index
                                              ? "left"
                                              : "right";
                                          return isUserFromOrgnization ? (
                                            <div
                                              className="feild-group  px-2 py-2 mr-2 rounded-0 shadow-none text-center  "
                                              style={{
                                                minWidth: "11rem",
                                              }}
                                            >
                                              <div className="border rounded">
                                                <span className=" fs-6  font-weight-bold">
                                                  {data.incident_person_name}
                                                </span>
                                                <div
                                                  className="image-box my-3 "
                                                  style={{
                                                    marginRight: "0.5rem",
                                                  }}
                                                >
                                                  <GetPopoverFrame
                                                    element={
                                                      <>
                                                        <img
                                                          src={detail?.user_pic}
                                                          alt=""
                                                          className="clide-v2-profile-img"
                                                        />
                                                      </>
                                                    }
                                                    mappingKeys={() => {
                                                      return {
                                                        ID:
                                                          detail[
                                                            "employeeId"
                                                          ] ?? "NA",
                                                        Department:
                                                          detail[
                                                            "field"
                                                          ]?.split(":")[1] ??
                                                          "NA",
                                                        Type:
                                                          detail["userType"] ??
                                                          "NA",
                                                        Designation:
                                                          detail[
                                                            "designation"
                                                          ] ?? "NA",
                                                        Contact:
                                                          detail[
                                                            "contact_number"
                                                          ] ?? "NA",
                                                        // Zone: detail['designation'],
                                                        // Location: detail['designation'],
                                                      };
                                                    }}
                                                  />
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-12 px-0">
                                                  <div className="col-12 col-md-12 col-lg-12 px-0">
                                                    <div className="clide-v2-title mb-1">
                                                      <label className="clide-v2-fs-4 mb-0">
                                                        Id{" "}
                                                      </label>
                                                    </div>
                                                  </div>
                                                  <div className="clide-v2-title mb-1">
                                                    <label className="">
                                                      {data?.searched_incident_person_id
                                                        ? detail?.employeeId
                                                        : detail.userId ??
                                                          index + 1}
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <>
                                              <div className="col-12 col-md-12 col-lg-12 px-0">
                                                <>
                                                  <div className="col-12 col-md-12 col-lg-12 px-0">
                                                    <div className="clide-v2-title mb-1">
                                                      <label className="clide-v2-fs-4 mb-0">
                                                        {
                                                          data.incident_person_name
                                                        }
                                                      </label>
                                                    </div>
                                                  </div>
                                                  <div className="clide-v2-title mb-1">
                                                    <label className="">
                                                      <img
                                                        src={require("../../../../assets/sls-icons/V2. New Development/user.svg")}
                                                        alt=""
                                                      />
                                                    </label>
                                                  </div>
                                                </>
                                              </div>
                                              <div className="col-12 col-md-12 col-lg-12 px-0">
                                                <>
                                                  <div className="col-12 col-md-12 col-lg-12 px-0">
                                                    <div className="clide-v2-title mb-1">
                                                      <label className="clide-v2-fs-4 mb-0">
                                                        Id{" "}
                                                      </label>
                                                    </div>
                                                  </div>
                                                  <div className="clide-v2-title mb-1">
                                                    <label className="">
                                                      {data.incident_person_id ??
                                                        index + 1}
                                                    </label>
                                                  </div>
                                                </>
                                              </div>
                                            </>
                                          );
                                        }
                                      )}
                                    </div>
                                  </Scrollbars>
                                </div>
                                {/* <div className="col-12 col-md-6 col-lg-6 mb-2">
                      <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold pt-3 mb-4">
                        Department/Contractor/Location
                      </p>
                      <div className="row">
                        {CheckFormSettingField(
                          reportData.form_setting?.setting,
                          "department"
                        ) ? (
                          <div className="col-12 col-md-6 col-lg-6">
                            <div className="col-12 col-md-12 col-lg-12 px-0">
                              <div className="clide-v2-title mb-1">
                                <label className="clide-v2-fs-4 mb-0">
                                  Department{" "}
                                </label>
                              </div>
                            </div>
                            {!reportData.department?(<div className="clide-v2-title mb-1">
                              <label className="">
                              NA
                              </label>
                            </div>):(<div className="clide-v2-title mb-1">
                              <label className="">
                              {reportData.department}
                              </label>
                            </div>)}
                          </div>
                        ) : null}

                        {CheckFormSettingField(
                        reportData.form_setting?.setting,
                          "contractor"
                        ) &&
                        this.state.showContractor &&
                                    reportData.contractor ? (
                          <div className="col-12 col-md-6 col-lg-6">
                            <div className="col-12 col-md-12 col-lg-12 px-0">
                              <div className="clide-v2-title mb-1">
                                <label className="clide-v2-fs-4 mb-0">
                                  Contractor{" "}
                                </label>
                              </div>
                            </div>
                            <div className="clide-v2-title mb-1">
                              <label className="">
                              {reportData.contractor}
                              </label>
                            </div>
                          </div>
                        ) : null}

                        {CheckFormSettingField( reportData.form_setting?.setting, "zone")  ? (
                          <div className="col-12 col-md-6 col-lg-6">
                            {this.state.showZone &&
                                        reportData.zone && (
                              <>
                                <div className="col-12 col-md-12 col-lg-12 px-0">
                                  <div className="clide-v2-title mb-1">
                                    <label className="clide-v2-fs-4 mb-0">
                                      Zone{" "}
                                    </label>
                                  </div>
                                </div>
                                <div className="clide-v2-title mb-1">
                                  <label className="">
                                  {reportData.zone}
                                  </label>
                                </div>
                              </>
                            )}
                          </div>
                        ) : null}
                        {CheckFormSettingField(
                          reportData.form_setting?.setting,
                          "location"
                        )  ? (
                          <div className="col-12 col-md-12 col-lg-12 px-0">
                            {this.state.showLocation &&
                                        reportData.location && (
                              <>
                                <div className="col-12 col-md-12 col-lg-12 px-0">
                                  <div className="clide-v2-title mb-1">
                                    <label className="clide-v2-fs-4 mb-0">
                                      Location{" "}
                                    </label>
                                  </div>
                                </div>
                                <div className="clide-v2-title mb-1">
                                  <label className="">
                                  {reportData.location}
                                  </label>
                                </div>
                              </>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>

                      {this.state.escalatedList.length > 0 ? (
                        <li>
                          <div className="cbp_tmicon cbp_tmicon-screen">
                            <TimelineInfoIcon />
                          </div>
                          <div className="cbp_tmlabel clide-v2-white-box">
                            <div className="row">
                              <div className="col-12  mb-2">
                                <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold pt-3 mb-4">
                                  Escalated Details
                                </p>
                                <div className="row">
                                  <div className="col-12 clide-v2-scroll-bar">
                                    <TreeUiComponent
                                      rootTitle="Escalation Matrix"
                                      nodes={this.__generateTreeNodes(
                                        this.state.escalatedList,
                                        reportData?.escalation_level_timeline
                                      )}
                                      callback={this.__renderTreeTemplate}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ) : null}

                      {(reportData.overdue != undefined &&
                        reportData?.overdue != 0) ||
                      (reportData.deviation != undefined &&
                        reportData?.deviation != 0) ? (
                        <li>
                          <div className="cbp_tmicon cbp_tmicon-screen">
                            <TimelineInfoIcon />
                          </div>
                          <div className="cbp_tmlabel clide-v2-white-box">
                            <div className="row">
                              <div className="col-12 col-md-6 col-lg-6 mb-2">
                                <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold pt-3 mb-4">
                                  {this.state.resolveFlag === "open"
                                    ? "Overdue"
                                    : "Deviation"}
                                </p>
                                <div className="row">
                                  <div className="col-12 ">
                                    <div className="col-12 col-md-12 col-lg-12 px-0">
                                      <div className="clide-v2-title mb-1">
                                        <label className="clide-v2-fs-4">
                                          {this.state.resolveFlag === "open"
                                            ? "Overdue"
                                            : "Deviation"}{" "}
                                          {"By"}{" "}
                                        </label>
                                        <label className="clide-v2-fs-4 ml-3 border round p-2 rounded-5 px-5 clide-v2-active">
                                          {this.state.resolveFlag === "open"
                                            ? reportData.overdue
                                            : reportData.deviation}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      ) : null}

                      <DetailCommentSection
                        state={this.state}
                        functionContent={{ ...this, setState: this.setState }}
                      />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 col-lg-3">
            <div className="clide-v2-card-box-1 p-3">
              <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold text-center pt-3">
                Reported Details
              </p>
              <hr />
              <div className="d-flex p-1 mb-2 text-start">
                <div className="">
                  <OverlayTrigger
                    trigger="hover"
                    placement="left"
                    rootClose={true}
                    overlay={
                      <Popover
                        id="popover-positioned-bottom"
                        style={{ width: "15rem", borderRadius: "10px" }}
                      >
                        <Container>
                          <div className="my-3">
                            <span className="common-black-1 font-weight-500 font-16">
                              Reported Date :
                            </span>
                            <span className="common-black-1 ml-3 font-16">
                              {reportData.submission_date}
                            </span>
                          </div>
                          <div className="my-3">
                            <span className="common-black-1 font-weight-500 font-16">
                              Reported Time :
                            </span>
                            <span className="common-black-1 ml-3 font-16">
                              {reportData.submission_time}
                            </span>
                          </div>
                          <div className="my-3">
                            <span className="common-black-1 font-weight-500 font-16">
                              Reported Month :
                            </span>
                            <span className="common-black-1 ml-3 font-16">
                              {reportData.reportedmonth_name}
                            </span>
                          </div>
                        </Container>
                      </Popover>
                    }
                  >
                    <img
                      className="clide-v2-profile-img"
                      src={reportData.reported_by_pic}
                    />
                  </OverlayTrigger>
                </div>
                <div className="p-1 px-3 fw-bold w-100  ">
                  <div className="col-12 col-md-12 col-lg-12">
                    {reportData.reported_by}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-md-6 col-lg-6 mb-3">
                  <div className="">ID :</div>
                  <div className="clide-v2-title mb-1">
                    <label className="">
                      {" "}
                      {this.state.assignedDetails.emp_id}
                    </label>
                  </div>
                </div>
                <div className="col-6 col-md-6 col-lg-6 mb-3">
                  <div className="">Type</div>
                  <div className="clide-v2-title mb-1">
                    <label className="">
                      {this.state.assignedDetails.type ?? "NA"}
                    </label>
                  </div>
                </div>
                <div className="col-6 col-md-6 col-lg-6 mb-3">
                  <div className="">Department</div>
                  <div className="clide-v2-title mb-1">
                    <label className="">
                      {this.state.assignedDetails?.field?.split(":")[1] ?? "NA"}
                    </label>
                  </div>
                </div>
                <div className="col-6 col-md-6 col-lg-6 mb-3">
                  <div className="">Designation</div>
                  <div className="clide-v2-title mb-1">
                    <label className="">
                      {this.state.assignedDetails.designation ?? "NA"}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="clide-v2-card-box-1 mt-2 p-3">
              <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold text-center pt-3">
                Badges ({this.state.likeNumber})
              </p>
              <hr />
              <div className="text-center clide-v2-border-primary-color border p-3 rounded">
                {this.state.userLike != "" ? (
                  this.state?.userLike?.map(
                    (item, index) =>
                      index < 5 && (
                        <React.Fragment>
                          <img
                            src={item.user_profile}
                            alt=""
                            data-tip
                            data-for={`user-name-${index}`}
                            className="clide-v2-profile-img"
                          />

                          <ReactTooltip
                            effect="solid"
                            place="right"
                            id={`user-name-${index}`}
                          >
                            <>
                              <span>{item.user_name}</span>
                            </>
                          </ReactTooltip>
                        </React.Fragment>
                      )
                    // </div>
                  )
                ) : (
                  <p className="data-not-present mb-0">Badges not available </p>
                )}
              </div>
            </div>
            <div className="clide-v2-card-box-1 p-3 mt-2">
              <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold text-center pt-3">
                IRA Analysis
              </p>
              <hr />
              {reportData.fa_number == 0 &&
              reportData.mtc_number == 0 &&
              reportData.rta_number == 0 &&
              reportData.lti_number == 0 ? (
                <div className="graph-not-available-text">
                  Graph not available
                </div>
              ) : (
                <div className="col-12  mb-3">
                  <div className="clide-v2-white-box">
                    <div className="row align-items-center">
                      <div className="col-12 my-2">
                        <div className="sm-graph-modal-legend-text text-center mb-3">
                          <b>IRA Analysis</b>
                        </div>
                        {config.isChartJsEnabled ? (
                          this.CountDoughnut(analysisChartData)
                        ) : (
                          <div style={{ width: "9rem" }}>
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={analysisChart}
                            />
                          </div>
                        )}
                      </div>
                      <div className="col-12 ">
                        <div className="outer-legend-block">
                          <Scrollbars
                            style={{
                              height: `6rem`,
                              margin: "0.5rem 0.2rem 0.5rem 0",
                            }}
                            renderView={(props) => (
                              <div
                                style={{
                                  paddingRight: "15px",
                                  ...props.style,
                                  overflowX: "hidden",
                                }}
                              />
                            )}
                          >
                            <div className="clide-v2-graph-left-box  d-flex align-items-center  ">
                              <span
                                className="soi-kanban-modal-legend-box clide-v2-square-span "
                                style={{ backgroundColor: "#FF4242" }}
                              ></span>
                              <span className="sm-graph-modal-legend-text ">
                                FA
                              </span>
                              <span className=" ml-auto">
                                {reportData.fa_number}
                              </span>
                            </div>
                            <div className="soi-kanban-inner-legend-block  ">
                              <span
                                className="soi-kanban-modal-legend-box clide-v2-square-span"
                                style={{ backgroundColor: "#086992" }}
                              ></span>
                              <span className="sm-graph-modal-legend-text my-2">
                                MTC
                              </span>
                              <span className="legend-number float-end">
                                {reportData.mtc_number}{" "}
                              </span>
                            </div>
                            <div className="soi-kanban-inner-legend-block  ">
                              <span
                                className="soi-kanban-modal-legend-box clide-v2-square-span"
                                style={{ backgroundColor: "#FAAC15" }}
                              ></span>
                              <span className="sm-graph-modal-legend-text my-2">
                                TRA
                              </span>
                              <span className="legend-number float-end">
                                {reportData.rta_number}
                              </span>
                            </div>
                            {/* /* // Britannia v01
  //  Making this change to ...(Integrate this rta to tra and faid oi notable integration because of britian custoimization ) */}

                            <div className="soi-kanban-inner-legend-block  ">
                              <span
                                className="soi-kanban-modal-legend-box clide-v2-square-span"
                                style={{ backgroundColor: "#18DA07" }}
                              ></span>
                              <span className="sm-graph-modal-legend-text my-2">
                                LTI
                              </span>
                              <span className="legend-number float-end">
                                {reportData.lti_number}
                              </span>
                            </div>
                            <div className="soi-kanban-inner-legend-block  ">
                              <span
                                className="soi-kanban-modal-legend-box clide-v2-square-span"
                                style={{ backgroundColor: "#C511FF" }}
                              ></span>
                              <span className="sm-graph-modal-legend-text my-2">
                                OI
                              </span>
                              <span className="legend-number float-end">
                                {
                                  reportData.activityAnalysis
                                    ?.occupational_illness_number
                                }{" "}
                              </span>
                            </div>

                            <div className="soi-kanban-inner-legend-block  ">
                              <span
                                className="soi-kanban-modal-legend-box clide-v2-square-span"
                                style={{ backgroundColor: "#1589EE" }}
                              ></span>
                              <span className="sm-graph-modal-legend-text my-2">
                                First Aid
                              </span>
                              <span className="legend-number float-end">
                                {reportData.activityAnalysis?.first_aid_number}{" "}
                              </span>
                            </div>

                            <div className="soi-kanban-inner-legend-block  ">
                              <span
                                className="soi-kanban-modal-legend-box clide-v2-square-span"
                                style={{
                                  backgroundColor: "rgb(161, 255, 206)",
                                }}
                              ></span>
                              <span className="sm-graph-modal-legend-text my-2">
                                Notable
                              </span>
                              <span className="legend-number float-end">
                                {reportData.activityAnalysis?.notable_number}{" "}
                              </span>
                            </div>
                          </Scrollbars>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {reportData.nm_ua_number == 0 && reportData.nm_uc_number == 0 ? (
                <div className="graph-not-available-text">
                  Graph not available
                </div>
              ) : (
                <div className="col-12  mb-3">
                  <div className="clide-v2-white-box">
                    <div className="row align-items-center">
                      <div className="col-12  my-2">
                        <div className="sm-graph-modal-legend-text text-center mb-3">
                          <b>NM Analysis</b>
                        </div>
                        {config.isChartJsEnabled ? (
                          this.CountDoughnut(nmuaUcChartData)
                        ) : (
                          <div style={{ width: "9rem" }}>
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={nmuaUcChart}
                            />
                          </div>
                        )}
                      </div>
                      <div className="col-12  ">
                        <div className="outer-legend-block">
                          <Scrollbars
                            style={{
                              height: `6rem`,
                              margin: "0.5rem 0.2rem 0.5rem 0",
                            }}
                            renderView={(props) => (
                              <div
                                style={{
                                  paddingRight: "15px",
                                  ...props.style,
                                  overflowX: "hidden",
                                }}
                              />
                            )}
                          >
                            <div className="clide-v2-graph-left-box my-2 d-flex align-items-center  ">
                              <span
                                className="soi-kanban-modal-legend-box clide-v2-square-span "
                                style={{
                                  backgroundColor: reportData.nm_ua_color,
                                }}
                              ></span>
                              <span className="sm-graph-modal-legend-text ">
                                Unsafe Act
                              </span>
                              <span className=" ml-auto">
                                {reportData.nm_ua_number}
                              </span>
                            </div>
                            <div className="soi-kanban-inner-legend-block  ">
                              <span
                                className="soi-kanban-modal-legend-box clide-v2-square-span"
                                style={{
                                  backgroundColor: reportData.nm_ua_color,
                                }}
                              ></span>
                              <span className="sm-graph-modal-legend-text my-2">
                                Unsafe Condition
                              </span>
                              <span className="legend-number float-end">
                                {reportData.nm_uc_number}
                              </span>
                            </div>
                          </Scrollbars>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {reportData.ua_number == 0 && reportData.uc_number == 0 ? (
                <div className="graph-not-available-text">
                  Graph not available
                </div>
              ) : (
                <div className="col-12  mb-3">
                  <div className="clide-v2-white-box">
                    <div className="row align-items-center">
                      <div className="col-12 my-2">
                        <div className="sm-graph-modal-legend-text text-center mb-3">
                          <b>UA Vs UC Analysis</b>
                        </div>
                        {config.isChartJsEnabled ? (
                          this.CountDoughnut(uaUcChartData)
                        ) : (
                          <div style={{ width: "9rem" }}>
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={uaUcChart}
                            />
                          </div>
                        )}
                      </div>
                      <div className="col-12 ">
                        <div className="outer-legend-block">
                          <Scrollbars
                            style={{
                              height: `6rem`,
                              margin: "0.5rem 0.2rem 0.5rem 0",
                            }}
                            renderView={(props) => (
                              <div
                                style={{
                                  paddingRight: "15px",
                                  ...props.style,
                                  overflowX: "hidden",
                                }}
                              />
                            )}
                          >
                            <div className="clide-v2-graph-left-box my-2 d-flex align-items-center  ">
                              <span
                                className="soi-kanban-modal-legend-box clide-v2-square-span "
                                style={{
                                  backgroundColor: "#C7AC83",
                                }}
                              ></span>
                              <span className="sm-graph-modal-legend-text ">
                                Unsafe Act
                              </span>
                              <span className=" ml-auto">
                                {reportData.ua_number}{" "}
                              </span>
                            </div>
                            <div className="soi-kanban-inner-legend-block  ">
                              <span
                                className="soi-kanban-modal-legend-box clide-v2-square-span"
                                style={{
                                  backgroundColor: "#554040",
                                }}
                              ></span>
                              <span className="sm-graph-modal-legend-text my-2">
                                Unsafe Condition
                              </span>
                              <span className="legend-number float-end">
                                {reportData.uc_number}
                              </span>
                            </div>
                          </Scrollbars>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {reportData.extreme_number == 0 &&
              reportData.high_number == 0 &&
              reportData.medium_number == 0 &&
              reportData.low_number == 0 ? (
                <div className="graph-not-available-text">
                  Graph not available
                </div>
              ) : (
                <div className="col-12  mb-3">
                  <div className="clide-v2-white-box">
                    <div className="row align-items-center">
                      <div className="col-12  col-md-12 ">
                        <div className="outer-legend-block">
                          <div className="sm-graph-modal-legend-text my-2">
                            <b> UA Vs UC Analysis</b>
                          </div>
                        </div>
                      </div>
                      <div className="col-12  col-md-12 my-2">
                        {config.isChartJsEnabled ? (
                          <Bar
                            data={severityChartData}
                            plugins={[ChartDataLabels]}
                            options={barGraphOptions}
                            height={150}
                          />
                        ) : (
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={severityChart}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="clide-v2-card-box-1 p-3 mt-2">
                <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold text-center pt-3">
                  {" "}
                  Good Act & TBT Count{" "}
                </p>
                <hr />
                <div className="row">
                  <div className="col-12 col-lg-6 col-md-12 my-2">
                    {config.isChartJsEnabled ? (
                      <CountGraph
                        count={this.state.gpcount.map((data) => data?.ga_count)}
                        radius={"77%"}
                        cutout={"82%"}
                        height={100}
                        fontWeight={500}
                        fontSize={"1.5rem"}
                        color={this.state.gpcount.map((data) => data.color)}
                      />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={gpCount}
                      />
                    )}
                    <div class="w-100 text-center">
                      <span class="font-weight-bold fs-5">GA</span>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-md-12 my-2">
                    {config.isChartJsEnabled ? (
                      <CountGraph
                        count={this.state.tbtcount.map(
                          (data) => data.tbt_count
                        )}
                        radius={"77%"}
                        cutout={"82%"}
                        height={100}
                        fontWeight={500}
                        fontSize={"1.5rem"}
                        color={this.state.tbtcount.map((data) => data.color)}
                      />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={tbtCount}
                      />
                    )}
                    <div class="w-100 text-center">
                      <span class="font-weight-bold fs-5">TBT </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="clide-v2-card-box-1 p-3 mt-2">
                <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold text-center pt-3">
                  Internal & External Training Count
                </p>
                <hr />
                <div className="row">
                  <div className="col-12 col-lg-6 col-md-12 my-2">
                    {config.isChartJsEnabled ? (
                      <CountGraph
                        count={this.state.trainingInternalCount.map(
                          (data) => data.training_internal_count
                        )}
                        radius={"77%"}
                        cutout={"75%"}
                        height={100}
                        fontWeight={500}
                        fontSize={"1.5rem"}
                        color={this.state.trainingInternalCount.map(
                          (data) => data.color
                        )}
                      />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={internalCount}
                      />
                    )}
                    <div class="w-100 text-center">
                      <span class="font-weight-bold fs-5">Internal</span>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-md-12 my-2">
                    {config.isChartJsEnabled ? (
                      <CountGraph
                        count={
                          this.state.trainingExternalCount.map(
                            (data) => data.training_external_count
                          ) ?? 0
                        }
                        radius={"77%"}
                        cutout={"75%"}
                        height={100}
                        fontWeight={500}
                        fontSize={"1.5rem"}
                        color={this.state.trainingExternalCount.map(
                          (data) => data.color
                        )}
                      />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={externalCount}
                      />
                    )}
                    <div class="w-100 text-center">
                      <span class="font-weight-bold fs-5">External</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="clide-v2-card-box-1 p-3 mt-2">
                <p className="clide-v2-fs-4 clide-v2-primary-color fw-bold text-center pt-3">
                  Employee & Contractor Training Count
                </p>
                <hr />
                <div className="row">
                  <div className="col-12 col-lg-6 col-md-12 my-2">
                    {config.isChartJsEnabled ? (
                      <CountGraph
                        count={
                          this.state.trainingEmployeeCount.map(
                            (data) => data.training_employee_count
                          ) ?? 0
                        }
                        radius={"77%"}
                        cutout={"75%"}
                        height={100}
                        fontWeight={500}
                        fontSize={"1.5rem"}
                        color={this.state.trainingEmployeeCount.map(
                          (data) => data.color
                        )}
                      />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={employeeCount}
                      />
                    )}
                    <div class="w-100 text-center">
                      <span class="font-weight-bold fs-5">Employee</span>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6 col-md-12 my-2">
                    {config.isChartJsEnabled ? (
                      <CountGraph
                        count={
                          this.state.trainingContractorCount.map(
                            (data) => data.training_contractor_count
                          ) ?? 0
                        }
                        radius={"77%"}
                        cutout={"75%"}
                        height={100}
                        fontWeight={500}
                        fontSize={"1.5rem"}
                        color={this.state.trainingContractorCount.map(
                          (data) => data.color
                        )}
                      />
                    ) : (
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={contractorCount}
                      />
                    )}
                    <div class="w-100 text-center">
                      <span class="font-weight-bold fs-5">Contractor</span>
                    </div>
                  </div>
                </div>
              </div>

              <Modal
                centered
                show={this.state.deletePopup}
                className="submit-modal"
              >
                <Modal.Header
                  closeButton
                  onClick={this.hideModal}
                ></Modal.Header>

                <Modal.Body>
                  <img
                    className="submit-img"
                    src={require("assets/images/delete-popup.svg")}
                  />
                  <p className="submit-text">
                    Are you sure you want to delete this?
                  </p>
                  <div className="modal-footer-btn">
                    <button
                      type="submit"
                      className="red-btn"
                      onClick={this.hideModal}
                    >
                      CANCEL
                    </button>
                    <button
                      type="submit"
                      className="red-btn"
                      onClick={() => this.deleteReport(this.state.soiId)}
                    >
                      YES, DELETE
                    </button>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          </div>
        </div>
        {this.state.show ? (
          <ResolvePopup
            show={this.state.show}
            modalClose={this.openResolve}
            reportId={this.state.soiId}
            reportingDetails
            data={this.state.reportDetail}
          />
        ) : null}
        {this.state.showUser ? (
          <InviteUser
            showUser={this.state.showUser}
            closeUser={this.openInviteUser}
            reportId={this.state.soiId}
            onAddNewUsers={this._addUsersToList}
            invitedUsersList={this.state.reportDetail.invited_user}
            removeInviteUser={this.removeInviteUser}
            resolveFlag={this.state.resolveFlag}
            moduleType={"report"}
          />
        ) : null}
        <Modal
          show={this.state.userLikedPopup}
          onHide={this.handleClose}
          className="likeduser"
        >
          <Modal.Header closeButton>
            <Modal.Title>User Who Liked</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="totalLikedUser">
              <span>All</span>
              <span className="count">{this.state.likeNumber}</span>
            </div>
            {this.state.userLike != "" ? (
              this.state.userLike.map((items, index) => (
                <div className="user-list" key={index}>
                  <img src={items.user_profile} alt={items.user_name} />
                  <h6 className="user-name">{items.user_name}</h6>
                </div>
              ))
            ) : (
              <div className="user-list">
                <p className="data-not-present">Likes not available </p>
              </div>
            )}
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.countModalParams.isOpen}
          className="reportPopup "
          dialogClassName="modal-xl"
          onHide={() =>
            this.setState({
              countModalParams: {
                isOpen: false,
                moduleName: "",
              },
            })
          }
        >
          <Modal.Header closeButton>
            <Modal.Title className="clide-v2-fs-4 clide-v2-primary-color">
              {this.state.countModalParams.moduleName} wise &nbsp;{" "}
              {this.state?.reportData?.activity} | Analysis
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Bar
              plugins={[ChartDataLabels]}
              options={barGraphOptions}
              {...this.generateBarData(this.state.countModalParams.moduleData)}
              height={150}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

// This comment is to remove

export default withRouter(ReportingDetail);

export const GetPopoverFrame = ({
  element,
  mappingKeys = {},
  position = "right",
  triggerType = null,
  customElement = "",
  returnTriggerOnly = false,
  isManualTrigger = false,
  extractTrigger = () => {},
  viewContainerClasses = "d-flex-center position-relative",
  viewProps = {
    top: "-13px",
    right: "25%",
    marginLeft: "127px",
  },
}) => {
  const [isModalEnabled, setIsModalEnabled] = useState(false);
  const elements = mappingKeys() ?? {};
  extractTrigger(setIsModalEnabled);
  return (
    <>
      {returnTriggerOnly ? (
        <div title="User Details">
          {" "}
          <Eye
            className="cursor-p w-10 position-absolute d-flex"
            style={viewProps}
            id="properover-content"
            title="user details"
            onClick={() => setIsModalEnabled(true)}
          />{" "}
        </div>
      ) : (
        <div className={viewContainerClasses} id="properover-content">
          <div>{element}</div>
          {!isManualTrigger ? (
            <div title="User Details">
              <Eye
                className="cursor-p w-10 position-absolute"
                style={viewProps}
                onClick={() => setIsModalEnabled(true)}
              />
            </div>
          ) : null}
        </div>
      )}

      <SLDModal
        style={{
          maxWidth: "auto",
        }}
        isOpen={isModalEnabled}
        footer={[
          <SLDButton label="Close" onClick={() => setIsModalEnabled(false)} />,
        ]}
        // onRequestClose={() => setIsModalEnabled(false)}
        heading="User Details"
      >
        <Container>
          {elements &&
            Object?.entries(elements)?.map((el) => (
              <div className="my-3">
                <span className="common-black-1 font-weight-500 font-18">
                  {el[0]} :
                </span>
                <span className="common-black-1 ml-3 font-18">{el[1]}</span>
              </div>
            ))}
          {customElement}
        </Container>
      </SLDModal>
     
    </>
  );
};
