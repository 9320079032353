import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
//assets
import { RoundDelete, RoundEdit, TeamAdd } from "assets/Iconsv2/Iconsv2";
const DetailTimelineSection = ({
  data = [],
  state,
  url,
  functionContent,
  variableData,
}) => {
  const reportData = state.reportDetail;
  const { isApproveEnabled, isReportedUserEnabled } = variableData ?? {};
  return (
    <div className="row  clide-v2-colume_title ">
      <div className="mb-4 clide-v2-fs-3 fw-bold">UAUC Detail</div>
      <div className="row my-4 align-items-center">
        <div className="col-12 col-lg-4 col-md-4 px-0">
          <Link to={url}>
            <Button
              className="border clide-v2-primary clide-v2-white-color"
              variant=""
            >
              <i className="fa fa-angle-left" aria-hidden="true"></i>
            </Button>
          </Link>
        </div>
        <div className="col-12 col-lg-4 col-md-4">
          <div>
            <span className="common-black-1 font-weight-500">
              {" "}
              {state.soiId ?? ""}{" "}
              <span
                style={{
                  borderLeft: "3px Solid Orange",
                  padding: "0 5px 0 5px",
                }}
              >
                {" "}
                {reportData.act_type} - {reportData.activity}{" "}
              </span>
              <span
                style={{
                  borderLeft: "3px Solid Orange",
                  padding: "0 5px 0 5px",
                }}
              >
                {reportData.reportedmonth_name}
              </span>
            </span>
          </div>
        </div>
        <div className="col-12 col-lg-4 col-md-4 d-flex justify-content-end px-0">
          <div className="d-flex align-items-center">
            
              <>
                {reportData.edit_flag == 1 && (
                  <Link
                    to={`/edit-reporting-detail/${reportData.id}/${reportData.service_id}/${reportData.project_id}/edit`}
                  >
                    <button className="clide-v2-icon-btn mr-2" title="Edit">
                      <RoundEdit />
                    </button>
                  </Link>
                )}

                {reportData.delete_status == 1 ? (
                  <div>
                    <button
                      className="clide-v2-icon-btn mr-2"
                      title="Delete"
                      onClick={() => functionContent.showModal()}
                    >
                      <RoundDelete />
                    </button>
                  </div>
                ) : null}
              </>
            

            <div>
              

              {
              (isApproveEnabled || isReportedUserEnabled) &&
              state.resolveFlag == "open" ? (
                <button
                  className="clide-v2-icon-btn mr-2"
                  title="Add Team"
                  onClick={functionContent.openInviteUser}
                >
                  <TeamAdd />
                </button>
              ) : null}
              {state.resolveFlag == "resolve" ? (
                <span className="common-black-1 font-weight-500">
                  {" "}
                  Resolved{" "}
                </span>
              ) : (
                <>
                  {reportData.closing_tab_display == 1 ? (
                    <button
                      className="  border btn clide-v2-active px-2 py-1"
                      title="Add Team"
                      onClick={functionContent.openResolve}
                    >
                      Resolve
                    </button>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="pe-3 clide-v2-p-0">
        <div className="clide-v2-card-box-1 p-3 ">
          <div className="text-center clide-v2-primary-color py-4">
            Timeline
          </div>
          <div className="overflow-x overflow-auto">
            <div
              className="line_box stav_projektu"
              style={{ margin: "40px 0 40px 0" }}
            >
              {data?.map((el) => {
                return (
                  <div className="text_circle done ">
                    <a href="javascript:void(0)" className="tvar">
                      <span />
                    </a>
                    <div className="circle clide-v2-white-box h-75">
                      {el?.title}
                      <hr />
                      {el?.cardTitle}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(DetailTimelineSection);
