/* eslint-disable jsx-a11y/alt-text */
import { DateTableComponent as DataTable } from "app/shared/components/ReusableComponents/DataTable";
import React from "react";
import { Col, Row } from "react-bootstrap";
import Scrollbars from "react-custom-scrollbars";
import { Link } from "react-router-dom";
//components
import { AccordionWrapper } from "app/views/Nm/container/modules/capa";
//utils
import { DisplayPercentage } from "app/views/Ira/container/components/capa/inprogressSection";
const CApaCompletedSection = ({ props, functionContent, state = {} }) => {
  const { completedListCount } = props;
  return (
    <div className={`col-12  mb-2 ${state.listMode === "list" ? "col-md-12 col-lg-12" :" col-md-6 col-lg-6"}  ` }>
      <div className="clide-v2-card-box">
        <div className="  clide-v2-p-0">
          <div className="d-flex flex-direction-m">
            <div className="clide-v2-side-color-box clide-v2-green">
              <div className="clide-v2-side-text ml-0 ml-lg-3">
                {" "}
                COMPLETED | {completedListCount | 0}
              </div>
            </div>
            <Scrollbars
              style={{ height: `calc(100vh - 14vw)` }}
              className="m-2"
              renderView={(props) => (
                <div
                  style={{
                    paddingRight: "15px",
                    ...props.style,
                    overflowX: "hidden",
                  }}
                />
              )}
              onScrollStop={() => {
                Number.isInteger(props.completed_list.length) &&
                  props.completed_list.length != props.completedListCount &&
                  props.nmTrackerCompletedList(props.pageNumberCompleted + 1);
              }}
            >
              <div className=" p-3 w-100">
                {props.completed_list.map((data, index) => (
                  <div
                    key={index}
                    className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10  mb-3"
                    style={{
                      borderColor: data.severity_color,
                    }}
                  >
                    <AccordionWrapper
                      items={[
                        {
                          title: (
                            <>
                              <div className="d-flex w-100 border-bottom mb-2 align-items-center">
                                <div className="w-75 ">
                                  <Link
                                    to={`/nm-detail/${data.nearmiss_id}/kanban/completed`}
                                    className="text-decoration-none clide-v2-secondary-color"
                                  >
                                    <div className="w-75 p-3">
                                      {data.type_of_Act || ""} |{" "}
                                      {data.activity || ""}
                                    </div>
                                  </Link>
                                </div>
                                <div className=" py-0">
                                  <button className="clide-v2-side-button py-1 px-5">
                                    CAPA
                                  </button>
                                </div>
                              </div>
                              <div className="mb-1">
                                <div className="row p-1">
                                  <div className="col-12 col-md-12 col-lg-3">
                                    <div>Service</div>
                                    <div className=" fw-semibold  clide-v2-fs-5">
                                      {data.service}
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-12 col-lg-3">
                                    <div>Plant/Project</div>
                                    <div className=" fw-semibold  clide-v2-fs-5">
                                      {data.project}
                                    </div>
                                  </div>
                                  
                                  <div className="col-12 col-md-6 col-lg-6 d-flex justify-content-end">
                                    {DisplayPercentage(data?.progress)}
                                  
                                  </div>
                                </div>
                              </div>
                            </>
                          ),
                          content: (
                            <>
                              <div className="open-bbody">
                                <hr className="mb-3 mt-1" />
                                {state.listMode === "list" ? (
                                  <>
                                    <div className="mt-3 ">
                                      <DataTable
                                        {...functionContent.getFieldProps(
                                          data.list,
                                          ["Close Date", "Extras"]
                                        )}
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <Scrollbars
                                      style={{
                                        height: `15rem`,
                                        minHeight: "12rem",
                                      }}
                                      renderView={(props) => (
                                        <div
                                          style={{
                                            paddingRight: "15px",
                                            ...props.style,
                                            overflowX: "hidden",
                                          }}
                                        />
                                      )}
                                    >
                                      <div className="mr-3">
                                        {data.list.map(
                                          (capaItem, index, arr) => (
                                            <div
                                              key={`inprogressList-${capaItem.recommendation_id} `}
                                              className={` ${
                                                arr.length - 1 !== index
                                                  ? "border-bottom"
                                                  : ""
                                              } py-2`}
                                            >
                                              <Row>
                                                <Col sm={12} className="my-2 ">
                                                  <div className="d-flex d-flex justify-content-between ">
                                                    <div className="d-flex ">
                                                      <div className="">
                                                        Corrective Action{" "}
                                                        {++index}
                                                      </div>{" "}
                                                      &nbsp; |
                                                      <span
                                                        style={{
                                                          color: "black",
                                                        }}
                                                        className="ml-2  fw-semibold  clide-v2-fs-5"
                                                      >
                                                        {capaItem.recommendation
                                                          ?.recommendation ||
                                                          ""}
                                                      </span>{" "}
                                                    </div>
                                                    <div className="   rounded px-2 border clide-v2-green clide-v2-white-color">
                                                      {capaItem.recommendation
                                                        ?.status || ""}
                                                    </div>
                                                  </div>
                                                </Col>
                                                <Col sm={3}>
                                                  <div className="">
                                                    Priority
                                                  </div>
                                                  <div
                                                    style={{
                                                      color:
                                                        capaItem.priority_color,
                                                    }}
                                                    className="fw-semibold  clide-v2-fs-5 py-2"
                                                  >
                                                    <div
                                                      className="output-text"
                                                      style={{
                                                        color:
                                                          capaItem.priority_color,
                                                      }}
                                                    >
                                                      {capaItem.priority}
                                                    </div>
                                                  </div>
                                                </Col>
                                                <Col sm={3}>
                                                  <div className="">
                                                    Deviation
                                                  </div>
                                                  <div className=" fw-semibold  clide-v2-fs-5">
                                                    {capaItem.overdue_deviation ||
                                                      ""}
                                                  </div>
                                                </Col>
                                                <Col sm={3}>
                                                  <div className="">
                                                    Category
                                                  </div>
                                                  <div className=" fw-semibold  clide-v2-fs-5">
                                                    {capaItem.category}
                                                  </div>
                                                </Col>
                                                <Col sm={3}>
                                                  <div className="">
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgba(0, 0, 0, 0.7)",
                                                      }}
                                                      className={`${
                                                        functionContent.comapareTargetDate(
                                                          capaItem.target_date
                                                        )
                                                          ? "bg-danger"
                                                          : "bg-warning"
                                                      } text-white rounded px-1`}
                                                    >
                                                      Due Date
                                                    </span>
                                                  </div>
                                                  <div className=" fw-semibold  clide-v2-fs-5">
                                                    {capaItem.target_date ||
                                                      "N/A"}
                                                  </div>
                                                </Col>
                                                <Col sm={3}>
                                                  <div className="">
                                                    Proposed By
                                                  </div>
                                                  <div className="d-flex ">
                                                    <img
                                                      src={
                                                        capaItem?.recommendedby_pic
                                                      }
                                                      className="clide-v2-profile-img mr-2"
                                                    />
                                                    <div className=" fw-semibold  clide-v2-fs-5">
                                                      {capaItem?.recommendedby ||
                                                        ""}
                                                    </div>
                                                  </div>
                                                </Col>
                                                <Col sm={3}>
                                                  <div className="">
                                                    Assigned To
                                                  </div>
                                                  {capaItem.tracking_responsible_person.map(
                                                    (person, index) => (
                                                      <div
                                                        className="d-flex "
                                                        key={index}
                                                      >
                                                        <img
                                                          src={
                                                            person?.person_pic
                                                          }
                                                          className="clide-v2-profile-img mr-2"
                                                        />
                                                        <div className=" fw-semibold  clide-v2-fs-5">
                                                          {person.person_name}
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                                </Col>
                                                <Col sm={3}>
                                                  <div className="">
                                                    {" "}
                                                    Closing Date
                                                  </div>
                                                  <div
                                                    key={index}
                                                    className=" fw-semibold  clide-v2-fs-5"
                                                  >
                                                    {capaItem.recommendation
                                                      ?.closing_date || "N/A"}
                                                  </div>
                                                </Col>
                                                <Col sm={3}>
                                                  <div className="">
                                                    {" "}
                                                    Extras{" "}
                                                  </div>
                                                  {capaItem.recommendation
                                                    ?.recommendation_evidence
                                                    ?.length && (
                                                    <div
                                                      style={{
                                                        display: "inline-block",
                                                      }}
                                                      onClick={() =>
                                                        functionContent._handleLightBox(
                                                          capaItem
                                                            .recommendation
                                                            ?.recommendation_evidence
                                                        )
                                                      }
                                                    >
                                                      <img
                                                        className="tracker-right cursor-pointer"
                                                        style={{
                                                          height: "1.8vw",
                                                        }}
                                                        src={require("assets/images/icons/fimage.svg")}
                                                      />
                                                    </div>
                                                  )}
                                                  {capaItem.recommendation
                                                    ?.observation_rec?.length >
                                                  0 ? (
                                                    <div
                                                      style={{
                                                        display: "inline-block",
                                                      }}
                                                    >
                                                      <h6
                                                        onClick={() =>
                                                          functionContent.modalShowSearch(
                                                            capaItem
                                                              .recommendation
                                                              .observation_rec
                                                          )
                                                        }
                                                      >
                                                        <img
                                                          style={{
                                                            height: "1.8rem",
                                                            paddingLeft: "1rem",
                                                            verticalAlign:
                                                              "initial",
                                                          }}
                                                          src={require("assets/images/icons/play.svg")}
                                                          className="cursor-pointer"
                                                        />
                                                      </h6>
                                                    </div>
                                                  ) : null}
                                                </Col>
                                              </Row>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </Scrollbars>
                                  </>
                                )}
                              </div>
                            </>
                          ),
                        },
                      ]}
                      customHeader
                      classes={{
                        mainWrapper: "pl-0",
                        wrapperItemSummary: "pb-0 pl-0",
                      }}
                    />
                  </div>
                ))}
                {/* <div className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10 clide-v2-border-primary-color mb-3">
                  <div className="d-flex w-100 border-bottom mb-2">
                    <div className="w-75 p-3">UA | Chemical Handling</div>
                    <div className="p-3">
                      <button className="clide-v2-side-button">CAPA</button>
                    </div>
                  </div>
                  <div className="mb-1">
                    <div className="row p-1">
                      <div className="col-12 col-md-12 col-lg-4">
                        <div>Service</div>
                        <div className=" fw-semibold  clide-v2-fs-5">
                          PHARMA SERVICES
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-4">
                        <div>Plant/Project</div>
                        <div className=" fw-semibold  clide-v2-fs-5">
                          Pharma Plant
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-4 text-center">
                        <img className="w-75" src="img/halfcircle.png" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10 clide-v2-border-secondary-color mb-3">
                  <div className="d-flex w-100 border-bottom mb-2">
                    <div className="w-75 p-3">UA | Chemical Handling</div>
                    <div className="p-3">
                      <button className="clide-v2-side-button">CAPA</button>
                    </div>
                  </div>
                  <div className="mb-1">
                    <div className="row p-1">
                      <div className="col-12 col-md-12 col-lg-4">
                        <div>Service</div>
                        <div className=" fw-semibold  clide-v2-fs-5">
                          PHARMA SERVICES
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-4">
                        <div>Plant/Project</div>
                        <div className=" fw-semibold  clide-v2-fs-5">
                          Pharma Plant
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-4 text-center">
                        <img className="w-75" src="img/halfcircle.png" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10 clide-v2-border-blue-color mb-3">
                  <div className="d-flex w-100 border-bottom mb-2">
                    <div className="w-75 p-3">UA | Chemical Handling</div>
                    <div className="p-3">
                      <button className="clide-v2-side-button">CAPA</button>
                    </div>
                  </div>
                  <div className="mb-1">
                    <div className="row p-1">
                      <div className="col-12 col-md-12 col-lg-4">
                        <div>Service</div>
                        <div className=" fw-semibold  clide-v2-fs-5">
                          PHARMA SERVICES
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-4">
                        <div>Plant/Project</div>
                        <div className=" fw-semibold  clide-v2-fs-5">
                          Pharma Plant
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-4 text-center">
                        <img className="w-75" src="img/halfcircle.png" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10 clide-v2-border-green-color mb-3">
                  <div className="d-flex w-100 border-bottom mb-2">
                    <div className="w-75 p-3">UA | Chemical Handling</div>
                    <div className="p-3">
                      <button className="clide-v2-side-button">CAPA</button>
                    </div>
                  </div>
                  <div className="mb-1">
                    <div className="row p-1">
                      <div className="col-12 col-md-12 col-lg-4">
                        <div>Service</div>
                        <div className=" fw-semibold  clide-v2-fs-5">
                          PHARMA SERVICES
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-4">
                        <div>Plant/Project</div>
                        <div className=" fw-semibold  clide-v2-fs-5">
                          Pharma Plant
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-4 text-center">
                        <img className="w-75" src="img/halfcircle.png" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10 clide-v2-border-ghostwhite-color mb-3">
                  <div className="d-flex w-100 border-bottom mb-2">
                    <div className="w-75 p-3">UA | Chemical Handling</div>
                    <div className="p-3">
                      <button className="clide-v2-side-button">CAPA</button>
                    </div>
                  </div>
                  <div className="mb-1">
                    <div className="row p-1">
                      <div className="col-12 col-md-12 col-lg-4">
                        <div>Service</div>
                        <div className=" fw-semibold  clide-v2-fs-5">
                          PHARMA SERVICES
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-4">
                        <div>Plant/Project</div>
                        <div className=" fw-semibold  clide-v2-fs-5">
                          Pharma Plant
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-4 text-center">
                        <img className="w-75" src="img/halfcircle.png" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clide-v2-white-box clide-v2-border-radius-capa clide-v2-border-10 clide-v2-border-Neonpink-color mb-3">
                  <div className="d-flex w-100 border-bottom mb-2">
                    <div className="w-75 p-3">UA | Chemical Handling</div>
                    <div className="p-3">
                      <button className="clide-v2-side-button">CAPA</button>
                    </div>
                  </div>
                  <div className="mb-1">
                    <div className="row p-1">
                      <div className="col-12 col-md-12 col-lg-4">
                        <div>Service</div>
                        <div className=" fw-semibold  clide-v2-fs-5 ">
                          PHARMA SERVICES
                        </div>
                      </div>
                      <div className="col-12 col-md-12 col-lg-4">
                        <div>Plant/Project</div>
                        <div className=" fw-semibold  clide-v2-fs-5">
                          Pharma Plant
                        </div>
                      </div>
                      <div className="col-12 col-md-4 col-lg-4 text-center">
                        <img className="w-75" src="img/halfcircle.png" />
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </Scrollbars>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CApaCompletedSection;
