import React from "react";
import styled from "styled-components";

const month = [
  {
    id: 0,
    name: "0",
  },
  {
    id: 1,
    name: "1",
  },
  {
    id: 2,
    name: "2",
  },
  {
    id: 3,
    name: "3",
  },
  {
    id: 4,
    name: "4",
  },
  {
    id: 5,
    name: "5",
  },
  {
    id: 6,
    name: "6",
  },
  {
    id: 7,
    name: "7",
  },
  {
    id: 8,
    name: "8",
  },
  {
    id: 9,
    name: "9",
  },
  {
    id: 10,
    name: "10",
  },
  {
    id: 11,
    name: "11",
  },
];
const YearSelect = [
  {
    id: 0,
    name: "0",
  },
  {
    id: 1,
    name: "1",
  },
  {
    id: 2,
    name: "2",
  },
  {
    id: 3,
    name: "3",
  },
  {
    id: 4,
    name: "4",
  },
  {
    id: 5,
    name: "5",
  },
  {
    id: 6,
    name: "6",
  },
  {
    id: 7,
    name: "7",
  },
  {
    id: 8,
    name: "8",
  },
  {
    id: 9,
    name: "9",
  },
  {
    id: 10,
    name: "10",
  },
  {
    id: 11,
    name: "11",
  },
  {
    id: 12,
    name: "12",
  },
  {
    id: 13,
    name: "13",
  },
  {
    id: 14,
    name: "14",
  },
  {
    id: 15,
    name: "15",
  },
  {
    id: 16,
    name: "16",
  },
  {
    id: 17,
    name: "17",
  },
  {
    id: 18,
    name: "18",
  },
  {
    id: 19,
    name: "19",
  },
  {
    id: 20,
    name: "20",
  },
  {
    id: 21,
    name: "21",
  },
  {
    id: 22,
    name: "22",
  },
  {
    id: 23,
    name: "23",
  },
  {
    id: 24,
    name: "24",
  },
  {
    id: 25,
    name: "25",
  },
  {
    id: 26,
    name: "26",
  },
  {
    id: 27,
    name: "27",
  },
  {
    id: 28,
    name: "28",
  },
  {
    id: 29,
    name: "29",
  },
  {
    id: 30,
    name: "30",
  },
  {
    id: 31,
    name: "31",
  },
  {
    id: 32,
    name: "32",
  },
  {
    id: 33,
    name: "33",
  },
  {
    id: 34,
    name: "34",
  },
  {
    id: 35,
    name: "35",
  },
  {
    id: 36,
    name: "36",
  },
  {
    id: 37,
    name: "37",
  },
  {
    id: 38,
    name: "38",
  },
  {
    id: 39,
    name: "39",
  },
  {
    id: 40,
    name: "40",
  },
  {
    id: 41,
    name: "41",
  },
  {
    id: 42,
    name: "42",
  },
  {
    id: 43,
    name: "43",
  },
  {
    id: 44,
    name: "44",
  },
  {
    id: 45,
    name: "45",
  },
  {
    id: 46,
    name: "46",
  },
  {
    id: 47,
    name: "47",
  },
  {
    id: 48,
    name: "48",
  },
  {
    id: 49,
    name: "49",
  },
  {
    id: 50,
    name: "50",
  },
];
const postUrl = {
  paper: {
    add: "/listira/step3addpaperdigital",
    edit: "/listira/step3editpaperdigital",
  },
  physcial: {
    add: "/listira/step3addphysicalinfo",
    edit: "/listira/step3editphysicalinfo",
  },
  position: {
    add: "/listira/step3addpositionequipments",
    edit: "/listira/step3editpositionequipments",
  },
  sketch: {
    add: "/listira/step3addsketch",
    edit: "/listira/step3editsketch",
  },
};
const postTooltip = {
  paper: "Procedure / Work Permit / Method statement /Risk matrix",
  physcial: "PPE/Tools/Chemical/Raw Material",
  position:
    "Location of items: people, equipment, materials,structures, EnviroIRAent Condition - Noise, temperature, ventilation",
  sketch: "Incident sketch what exactly happened and how?",
};
const analysisType = {
  Manpower: 1,
  Method: 2,
  Machinery: 3,
  Material: 4,
  Management: 5,
};
const analysisListType = {
  1: "manpowerList",
  2: "methodList",
  3: "machineryList",
  4: "materialList",
  5: "managementList",
};

const analysisContent = {
  Manpower: "manpowerList",
  Method: "methodList",
  Machinery: "machineryList",
  Material: "materialList",
  Management: "managementList",
};
const injuredParts = [
  {
    name: "Bench Press",
    muscles: ["chest", "triceps", "front-deltoids"],
  },
];
const tabs = [
  {
    tabName: "Proposed CAPA",
    key: "prop",
  },
  {
    tabName: "Final CAPA",
    key: "final",
  },
];
const rootCauseMenus = [
  {
    tabName: "Probable Cause",
    key: "1",
  },
  {
    tabName: "Root Cause Analysis",
    key: "2",
  },
];

function toDataURL(file, callback) {
  let fileInfo;
  let baseURL = "";
  // Make new FileReader
  let reader = new FileReader();

  // Convert the file to base64 text
  reader.readAsDataURL(file);

  // on reader load somthing...
  reader.onload = () => {
    // Make a fileInfo Object
    console.log("Called", reader);
    baseURL = reader.result;
    console.log(baseURL);
    callback(baseURL);
  };
  console.log(fileInfo);
}
const StyledNode = styled.div`
  padding: 20px 18px;
  background: #ffffff;
  color: #000000;
  display: inline-block;
  font-size: 0.6vw;
  font-family: inherit;
  border-radius: 0.4vw;
  margin: 5px 0px;
  font-weight: 500;
  width: max-content;
`;
const apiUrl = {
  parameterList: "/listira/addparameterlist",
  step5: {
    propsed: {
      add: "/listira/step5addcorrectiveaction",
      edit: "/listira/editcorrectiveaction",
      approve: "/listira/praposedrecommendationApproved",
      delete: "/listira/deletepraposedcorrectiveaction",
    },
    final: {
      freeze: "/listira/finalrecommendationfreezeall",
      delete: "/listira/finalrecommendationdelete",
    },
    filter: "/listira/recommendationfilter",
  },
  step2: {
    add: "/listira/addinjuredpersondetails",
    edit: "/listira/editinjuredpersondetails",
  },
  step4: {
    chronology: {
      add: "/listira/step4addeventschronology",
      edit: "/listira/editchronologyofevents",
    },
    rootCause: {
      parameterList: "/listira/step4addparameterlist",
      addPobableCause: "/listira/step4addprobablecause",
      addRouteCause: "/listira/addcontributingfactors",
      editRouteCause: "/listira/editcontributingfactors",
    },
  },
  step1: {
    delete: "/listira/deleteinitialresponse",
    edit: "/listira/editinitialresponse",
  },
  step3: {},
};
const intialValue = {
  categoryList: {
    isLoading: false,
    data: [],
  },
  dataGathering: {
    investigation: {
      isLoading: false,
      isImageProcessing: false,
      editId: "",
      deleteId: "",
      proofType: "",
      isAddEnable: false,
      injuredPersonType: "",
      employee_contractor_status: "",
      injured_person_name: [],
      contractor_company_name: "",
      OtherInfomasterlist: [],
      identity_proof: [],
      person_work_type: "",
      joining_date: new Date(),
      month_with_this_emp: "",
      year_with_this_emp: "",
      injured_person_gender: [],
      month_doing_this_job: "",
      year_doing_this_job: "",
      time_of_incident: "",
      working_shift: "",
      injured_parts: [],
    },
    witness: {
      isLoading: false,
      isEdit: "",
      isAddEnable: false,
      employee_contractor_status: null,
      contractor_company: "",
      witness_name: "",
      witness_statement: "",
      files: [],
    },
    posts: {
      editId: "",
      isLoading: false,
      isAddEnable: false,
      module: "",
      files: [],
      savedPalyoad: [],
      file_description: "",
    },
    analysis: {
      editId: "",
      isLoading: false,
      isAddEnable: false,
      questions: [],
    },
    chronology: {
      isEdit: "",
      isLoading: false,
      isAddEnable: false,
      date: "",
      time: "",
      dateTimeOf: null,
      description: "",
      info_source: "",
    },
    recommendation: {
      editId: "",
      isLoading: false,
      isAddEnable: false,
      approveId: "",
      deleteId: "",
      isPropEnable: false,
      category_l: [],
      responsible_p: [],
      correctiveActionText: "",
      selectedDate: null,
    },
    filterParams: {
      isLoading: false,
      isFilterEnable: false,
      category: "",
      recommendation_by: "",
      responsible_person_id: "",
      startDate: null,
      endDate: null,
      page_no: 1,
    },
    rootCause: {
      editId: "",
      isLoading: false,
      isAddEnable: false,
      probable_cause: "",
      section: "1",
      description: "",
      contributing_factor: "",
      masterList: [],
      main_category: [],
      root_cause_type: [],
    },
    cost: {
      isEdit: "",
      isLoading: false,
      isAddEnable: false,
      cost_involved: null,
      mandays_lost: null,
      return_to_work_id: [],
      files: null,
    },
  },
  deleteParams: {
    isLoading: false,
    isDeleteEnable: false,
    parameters: {},
    stepNo: "",
    url: "",
  },
};

const nestedListParser = {
  main_category: {
    id: "main_category_id",
    label: "main_category",
  },
  sub_category: {
    id: "sub_category_id",
    label: "sub_category",
  },
  major_root_cause_category: {
    id: "major_root_cause_category_id",
    label: "major_root_cause_category",
  },
  near_root_cause_category: {
    id: "near_root_cause_category_id",
    label: "near_root_cause_category",
  },
  intermediate_cause_category: {
    id: "intermediate_cause_category_id",
    label: "intermediate_cause_category",
  },
  root_cause_type: {
    id: "root_cause_type_id",
    label: "root_cause_type",
  },
  root_cause: {
    id: "root_cause_id",
    label: "root_cause",
  },
};
const _getAddedInjuredPersonName = (injured_person_details) => {
  try {
    let { person_details } = injured_person_details;
    let tempList = person_details.map((item) => item.injured_person_name);
    return tempList;
  } catch (error) {
    return [];
  }
};

const refreshUrls = [
  "/listira/deletechronologyofevents",
  "/listira/deletepaperdigital",
  "/listira/deletepositionequipments",
  "/listira/deletephysicalinfo",
  "/listira/deletesketch",
];

const nmConstant = {
  state: {
    details: (props) => {
      return {
        isDetailsLoadingFirstTime: true,
        show: false,
        isFreezeEnabled: false,
        showUser: false,
        iraDetail: {},
        userList: [],
        iraCache: {},
        iraBasicInfo: {},
        iAssignErrorEnabled: false,
        isCategoryErrorEnabled: false,
        isCorretiveActionErrorEnabled: false,
        isCAPAErrorEnabled: false,
        isDueDateErrorEnabled: false,
        isPriortiyErrorEnabled: false,
        // iraDetailStep1: {},
        iraDetailStep1: [],
        iraDetailStep2: {},
        iraDetailStep3: {},
        iraDetailStep4: {},
        iraDetailStep5: {},
        iraFormSetting: [],
        isRootCauseTooltipEnabed: false,
        userData: JSON.parse(localStorage.getItem("userData")),
        workingShift: [],
        masterList: [],
        stepValidations: {
          isIdProofTypeError: false,
          isIdProofDocumentError: false,
        },
        main_category: [],
        rootfactorParams: {},
        rootCauseActiveType: "5Y Analysis",
        root_cause_type: [],
        questionMaster: [],
        questionArray: [],
        questionResponse: [
          {
            question_id: 0,
            question: "",
            response: "",
          },
        ],
        priority_l: null,
        intermediateRouteCause: false,
        iraInjuredParts: [],
        toggleStep2: false,
        cflist: [],
        Iflist: [],
        rcalist: [],
        step2Type: "none",
        isFactorLoading: false,
        FishData: {},
        iraidentityProof: [],
        iraTimeOfIncident: [],
        tempQuestionList: [],
        selectedInjuredPerson: "",
        identity_proof_base64: [],
        genderList: [],
        injurtType: [],
        iraWorkType: [],
        injuredPersonList: [],
        iraId: props?.match.params.id,
        originFlag: props?.match.params.origin,
        reportStatus: props?.match.params.reportStatus,
        priority: { extreme: false, high: false, medium: false, low: false },
        dataLoading: true,
        accordionOpen: true,
        currentTab: "prop",
        lightboxactive: false,
        capaType: "",
        subcommentlist: [],
        addIntialResponse: false,
        categoryList: {
          ...intialValue.categoryList,
        },
        comment: 0,
        commentAdd: "",
        commentlist: [],
        actionsmaster: [],
        allComments: false,
        isFactorLoading: false,
        redirectToReferrer: false,
        toggler: false,
        deletePopup: false,
        confirmPopup: false,
        afterTargetDatePopup: false,
        assignedDetails: {},
        initialResponse: {
          editId: "",
          isLoading: false,
          description: "",
          actions: [],
        },
        initialInvestigation: {
          isLoading: false,
          editId: "",
          isAddEnable: false,
        },
        dataGathering: {
          ...intialValue.dataGathering,
        },
        deleteParams: {
          ...intialValue.deleteParams,
        },
        userLikedPopup: false,
        teamLeadID: null,
        fsToggler1: false,
        userLike: [],
        likeNumber: "",
        imageType: "evidence" || "evidence_resolve",
        showContractor: false,
        showZone: false,
        showLocation: false,
        projectList: [],
        incidentPersonName: [],
        completioncount: [],
        capacount: [],
        opencount: [],
        closecount: [],
        gpcount: [],
        tbtcount: [],
        trainingInternalCount: [],
        trainingExternalCount: [],
        trainingEmployeeCount: [],
        trainingContractorCount: [],
        commentError: false,
        fsToggler: false,
        selectedTargetDate: null,
        docUrl: "",
        fishData: {
          Excavation: {
            Management: [],
            Machinery: [],
            Manpower: [],
            Material: [],
            Method: [],
          },
        },
        analysisData: [],
        isAnalysisDataFetching: false,
        activeAnalysisItem: null,
        factorParams: {},
        activeModule: "",
        activePopperParams: {},
        activeEditPopperParams: {
          isLoading: false,
          isEnable: false,
          five_m_analysis_type: "",
          sub_factor_id: null,
        },
        desc: "",
        extremeSelect: 0,
        highSelect: 0,
        mediumSelect: 0,
        lowSelect: 0,
        all: 0,
      };
    },
  },
};

export {
  nmConstant,
  refreshUrls,
  _getAddedInjuredPersonName,
  nestedListParser,
  intialValue,
  apiUrl,
  StyledNode,
  toDataURL,
  tabs,
  injuredParts,
  rootCauseMenus,
  analysisContent,
  analysisListType,
  analysisType,
  postTooltip,
  postUrl,
  YearSelect,
  month,
};
