import React from "react";

// Assumed item => 0 means String name
// item => 1 means Id

const CommonDropdown = ({
  title,
  optionList,
  onChangeHandle,
  isRequired = false,
  titleview = true,
  value,
  containerClass = "",
  labelClass = "",
  selectClass = "",
  wrapClass = "",
  controlClass = "",
  hasError = false,
  errorMessage = "",
  customTitle = <></>,
  customPlaceHolder = null
}) => {
  return (
    <div className={` ${containerClass !== "" ? containerClass : "slds-form-element"} select-remove-icon  ${hasError ? "slds-has-error" : ""} mb-2`}>
      {titleview && <label className={` ${labelClass !== "" ? labelClass : "slds-form-element__label"} `} > {title}{isRequired ? <abbr class="slds-required" title="required">* </abbr> : null}</label>}
      {customTitle}
      {/* {isRequired && <span className="inputErrorMsg ">&nbsp;*</span>} */}
      <div className={wrapClass + " " + "slds-form-element__control"}>
        <div className={controlClass + " " + "slds-select_container"}>
          <select

            className={` ${labelClass !== "" ? selectClass : "slds-select"} `}
            onChange={(event) => onChangeHandle(event.target.value)}
            value={value}
          >
            {optionList.length ? (
              <>
                <option value={""}>Select {!customPlaceHolder ? title : customPlaceHolder}</option>
                {optionList.map((item, index) => (
                  <option
                    key={`project-option-${index}`}
                    value={
                      Object.keys(item)[1] === "id"
                        ? item[`${Object.keys(item)[1]}`]
                        : item[`${Object.keys(item)[0]}`]
                    }
                  >
                    {Object.keys(item)[1] !== "id"
                      ? item[`${Object.keys(item)[1]}`]
                      : item[`${Object.keys(item)[0]}`]}
                  </option>
                ))}
              </>
            ) : (
              <option value={""}>No {title} Available</option>
            )}
          </select>
        </div>
      </div>
      {hasError ? <div class="slds-form-element__help" id="error-01">{errorMessage !== "" ? errorMessage : "This is mandatory field."}</div> : null}
    </div>
  );
};

CommonDropdown.defaultProps = {
  title: "",
  optionList: [],
  isRequired: true,
  onChangeHandle: () => { },
  value: ""
};

export default CommonDropdown;
