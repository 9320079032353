import { Modal } from "react-bootstrap";
import React from "react";
import ConfirmPopupImage from "../Loader/ConfirmPopupImage";

const ConfirmPopup = ({
  showConfirmPopup,
  _handleCancel,
  _handleConfirmAction,
  actionText,
  message,
}) => {
  return (
    <Modal centered show={showConfirmPopup} className="submit-modal">
      <Modal.Body className="p-0">
        <ConfirmPopupImage />

        <p className="submit-text">{message}</p>
      </Modal.Body>
      <Modal.Footer className="d-flex-center gap-3">
        <button className="btn border" onClick={_handleCancel}>
          NO
        </button>
        <button className="btn clide-v2-active" onClick={_handleConfirmAction}>
          {actionText}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmPopup.defaultProps = {
  showConfirmPopup: false,
  _handleCancel: () => {},
  cancelSuccess: () => {},
  actionText: "OKAY",
  _handleConfirmAction: () => {},
  message: "",
};

export default ConfirmPopup;
