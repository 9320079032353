/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import { Button, Table, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AssignedProject } from "app/shared/components/Popups";
import Url from "app/shared/constants/Url";
import ClideLoader from "app/shared/ui-kit/loader";
import ReactTooltip from "react-tooltip";
import CloseModal from "app/shared/components/CloseModal/CloseModal";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import AlertMessage from "app/shared/components/Popups/AlertMessage";
import axios from "axios";
import { CHANGE_TAB } from "app/redux/constant/ActionType";
import { withRouter } from "react-router-dom";
//components

import TabsV2 from "app/shared/ui-kit/tabs";
import { connect } from "react-redux";
import moment from "moment";
import FormTypeDropDown from "../../../shared/components/ReportModal/FormTypeDropDown";
import {
  ALL_COMPLETED_TAB,
  CLOSED_TAB,
} from "app/shared/utility/helperStrings";
import _ from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";

const qs = require("qs");

const rfdc = require("rfdc")();

const mapStateToProps = (state) => {
  const data = { ...state.audit.audit };

  console.log("data125 " + data.project_id);
  return {
    service_id: data.service_id,
    project_id: data.project_id,
    service_name: data.service_name,
    project_name: data.project_name,
  };
};

let debounceFunc = _.debounce(function (callback) {
  callback();
}, 300);

const mapDispatchToProps = (dispatch) => {
  return {
    changeTab: (tabName) => {
      dispatch({ type: CHANGE_TAB, payload: tabName });
    },
  };
};

export const searchInArrary = (arr = [], mappingKeys = {}) => {
  const { key, value } = mappingKeys;
  const result =
    arr.filter((el) => {
      if (el[key] === value) return el;
    }) ?? [];

  return result.length;
};
const whiteListAllSchedue = ["2", "3", "4", "5", "6", "7", "9", "11", "13"];
const whiteListAssign = ["2"];
const FormApprovalTableView = (props) => {
  let formType = 0;
  const formTypeProps = props.formType;
  //const project_id = props.project_id;

  const selectFormType = (type) => {
    switch (type) {
      case "Daily":
        formType = 1;
        break;
      case "Weekly":
        formType = 2;
        break;
      case "15 Days":
        formType = 3;
        break;
      case "Monthly":
        formType = 4;
        break;
      case "Quaterly":
        formType = 5;
        break;
      case "Half":
        formType = 6;
        break;
      case "Yearly":
        formType = 7;
        break;
      default:
        formType = 0;
    }
    console.log(formType);
    return formType;
  };

  const [assignedPopup, setAssignedPopup] = useState(false);
  const [formData, setFormData] = useState();
  const [tableData, setTableData] = useState([]);
  const [assignList, setAssignList] = useState([]);
  const [approvalList, setApprovalList] = useState([]);
  const [allScheduleData, setAllScheduleData] = useState({});
  const [totalResponse, setTotalResponse] = useState();
  const [archivedData, setArchivedData] = useState([]);
  const [dataloading, setDataloading] = useState(true);
  const [closeModal, setCloseModal] = useState(false);
  const [declineModal, setDeclineModal] = useState(false);
  const [closeWorkPermitWpuId, setCloseWorkPermitWpuId] = useState(null);
  const [showRemarkPopup, setShowRemarkPopup] = useState(false);
  const [remark, setRemark] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteWpuId, setDeleteWpuId] = useState(null);
  const [extendModal, setExtendModal] = useState(false);
  const [extendData, setExtendData] = useState({});
  const [archivedTotalData, setArchivedTotalData] = useState({});
  const [tableTotalData, setTableTotalData] = useState({});
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [userInfo, setUserInfo] = useState(
    JSON.parse(localStorage.getItem("userData")) || null
  );
  const [archivePageNumber, setArchivePageNumber] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [categoryPageNumber, setCategoryPageNumber] = useState(1);
  const [categoryData, setCategoryData] = useState([]);
  const [totalCategoryCount, setTotalCategoryCount] = useState(0);
  const [allTabCategoryPageNo, setAllTabCategoryPageNo] = useState(1);
  const [previousCategory, setPreviousCategory] = useState(0);
  const [currentTab, setCurrentTab] = useState({});
  const [user] = useState(JSON.parse(localStorage.getItem("userData")));

  // const [project_id, setSelectedProjectId] = useState(props.project_id);

  useEffect(() => {
    //Original Code
    // if (
    //   props.screen == 4 ||
    //   props.screen == 5 ||
    //   (props.screen == 6 &&
    //     (formTypeProps == 8 || formTypeProps == 11 || formTypeProps == 13))
    // ) {
    //   surveyList();
    // }
    debugger
    if (whiteListAllSchedue.includes(formTypeProps)) {
      fetchAllSchedulData(formTypeProps);
    }
    if (
      !(props.screen == 6) &&
      (formTypeProps == 8 || formTypeProps == 11 || formTypeProps == 13)
    ) {
      surveyListTotalResponse();
    }
    // } else if (props.screen == 1 && formTypeProps == 12) {
    //   elearningList();
    // } else

    if (
      userInfo.designation_flag == 1 &&
      props.screen == 3 &&
      props.activeTab == ALL_COMPLETED_TAB &&
      (formTypeProps != 8 ||
        formTypeProps != 11 ||
        formTypeProps != 13 ||
        formTypeProps != 12)
    ) {
      workPermitListClosed();
    } else if (
      userInfo.designation_flag == 3 &&
      props.screen == 3 &&
      props.activeTab == CLOSED_TAB &&
      (formTypeProps != 8 ||
        formTypeProps != 11 ||
        formTypeProps != 13 ||
        formTypeProps != 12)
    ) {
      workPermitListClosed();
    } else if (
      userInfo.designation_flag == 3 &&
      props.activeTab == ALL_COMPLETED_TAB &&
      (formTypeProps != 8 ||
        formTypeProps != 11 ||
        formTypeProps != 13 ||
        formTypeProps != 12)
    ) {
      workPermitListClosed();
    } else {
      workPermitList();
    }

    fetchSurveyDataOnScroll();

    if (
      formTypeProps != 5 &&
      props.screen == 6 &&
      !(formTypeProps == 8 || formTypeProps == 11 || formTypeProps == 13)
    ) {
      archivedWorkPermitList();
      archivedWorkPermitListCount();
    }

    if (
      props.screen == 4 ||
      props.screen == 5 ||
      (props.screen == 6 &&
        !(formTypeProps == 8 || formTypeProps == 11 || formTypeProps == 13))
    ) {
      workPermitListScheduleCount();
    }

    // if (
    //   props.screen == 6 &&
    //   (formTypeProps == 8 || formTypeProps == 11 || formTypeProps == 13)
    // ) {
    //   archivedSurveyList();
    // }

    _fetchArchiveDataOnScroll();

    if (
      formTypeProps == 5 &&
      (props.screen == 1 ||
        props.screen == 0 ||
        props.screen == 3 ||
        props.screen == 2)
    ) {
      workPermitList("PROJECT", 1);
    }

    console.log(
      "current Date with format ==" +
        moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
    );

    _fetchCategoryList();
  }, [props.project_id]);

  const _fetchArchiveDataOnScroll = async () => {
    // if (
    //   formTypeProps != 5 &&
    //   props.screen == 6 &&
    //   !(formTypeProps == 8 || formTypeProps == 11 || formTypeProps == 13)
    // ) {
    //   archivedWorkPermitList();

    // }

    if (
      props.screen == 6 &&
      (formTypeProps == 8 || formTypeProps == 11 || formTypeProps == 13)
    ) {
      archivedSurveyList();
    }
  };

  //Pagination Code
  const fetchSurveyDataOnScroll = async () => {
    if (
      props.screen == 4 ||
      props.screen == 5 ||
      (props.screen == 6 &&
        (formTypeProps == 8 || formTypeProps == 11 || formTypeProps == 13))
    ) {
      surveyList();
      // if (!(props.screen == 6)) {
      //   surveyListTotalResponse();
      // }
    } else if (props.screen == 1 && formTypeProps == 12) {
      elearningList();
    }

    // else
    //   if ((userInfo.designation_flag == 1) && props.screen == 3 && props.activeTab == ALL_COMPLETED_TAB && (formTypeProps != 8 || formTypeProps != 11 || formTypeProps != 13 || formTypeProps != 12)) {
    //     workPermitListClosed();
    //   }
    //   else if (userInfo.designation_flag == 3 && props.screen == 3 && props.activeTab == CLOSED_TAB && (formTypeProps != 8 || formTypeProps != 11 || formTypeProps != 13 || formTypeProps != 12)) {
    //     workPermitListClosed();
    //   } else if (userInfo.designation_flag == 3 && props.activeTab == ALL_COMPLETED_TAB && (formTypeProps != 8 || formTypeProps != 11 || formTypeProps != 13 || formTypeProps != 12)) {
    //     workPermitListClosed();
    //   }
    //   else {
    //     workPermitList();
    //   }
  };

  const typeList = [
    { type_id: 1, form_type: "Data Collection" },
    { type_id: 2, form_type: "Audit" },
    { type_id: 3, form_type: "Inspection" },
    { type_id: 4, form_type: "Checklist" },
    { type_id: 5, form_type: "Work Permit" },
    { type_id: 6, form_type: "PSSR" },
    { type_id: 7, form_type: "BBS" },
    { type_id: 8, form_type: "Survey" },
    { type_id: 9, form_type: "Mock Drill" },
    { type_id: 10, form_type: "Contractor Assessment" },
    { type_id: 11, form_type: "Feedback" },
    { type_id: 12, form_type: "E-Learning" },
    { type_id: 13, form_type: "Assessment" },
  ];
  const fetchAllSchedulData = async (formType) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let URL = Url.formType1.allSchedule;
    let ContentType = {
      "Content-Type": "multipart/form-data",
    };

    let fd = new FormData();
    const type = ["11", "13"].includes(formType) ? 1 : 6;
    fd.append("auth_key", token);
    fd.append("user_id", userId);
    fd.append("form_type", formType);
    fd.append("type", type);
    fd.append("page_no", 1);
    fd.append("is_extended", 0);
    await axios
      .post(URL, fd, ContentType)
      .then((response) => {
        if (response.data.status == 200) {
          setAllScheduleData(response.data);
        }
      })
      .catch((error) => {});
  };
  const assignedProjectModal = async (index) => {
    const tempData = props.tableData[index].location_list
      ? rfdc(props.tableData[index].location_list)
      : [];
    setFormData(tempData);
    setAssignedPopup(true);
  };

  const hideAssignedProjectPopup = async () => {
    setAssignedPopup(false);
  };

  const workPermitList = (type = "PROJECT", isExtended = 0, pageNo) => {
    setDataloading(true);
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    // await setPageNumber(pageNo);

    let URL = Url.formType1.workPermitList;
    var ContentType = {
      "Content-Type": "multipart/form-data",
    };

    let data;
    data = new FormData();

    if (type == "PROJECT") {
      if (props.project_id && props.project_id.length > 0) {
        props.project_id.forEach((item) => {
          data.append("project_id", item);
        });
      }
    }
    // console.log("project" +project_id);

    data.append("auth_key", token);
    data.append("user_id", userId);
    data.append("type", props.screen);
    data.append("form_type", formTypeProps);
    data.append("is_extended", isExtended);

    // data.append("page_no", pageNo ? pageNo : pageNumber);

    axios
      .post(URL, data, ContentType)
      .then((response) => {
        if (response.data.status == 200) {
          const isScheduleScreen = props.activeTab === "scheduled";
          if (isExtended == 1) {
            // setArchivedData(response.data.data);
            setArchivedData([...archivedData, ...response.data.data]);
          } else {
            // setTableData(response.data.data);
            let scheduleList = [];
            const isAssignedModuleEnabled =
              whiteListAssign.includes(formTypeProps);
            if (isAssignedModuleEnabled) {
              const assignList = [];
              const { designation_flag, user_id } = userInfo ?? {};
              const isSafetyHeadLoggedIn = designation_flag === 1;
              const approveData = [];
              response.data.data.forEach((el) => {
                const isApprovalEnabed = searchInArrary(el?.approvalLevelList, {
                  key: "user_id",
                  value: user_id,
                });
                const isAuditSechduleBySelf = el?.user_id === user_id;
                if (el?.all_assignedscheduledFlag) {
                  if (isSafetyHeadLoggedIn) {
                    assignList.push(el);
                    return;
                  }
                  if (el?.assignedUser?.assignedFlag) {
                    assignList.push(el);
                  }
                  if (el?.assignedUser?.assignedFlag && isApprovalEnabed) {
                    assignList.push(el);
                  }
                  if (!el?.assignedUser?.assignedFlag && isApprovalEnabed) {
                    approveData.push(el);
                  }
                } else if (!el?.all_assignedscheduledFlag) {
                  if (isApprovalEnabed && isAuditSechduleBySelf) {
                    scheduleList.push(el);
                    return;
                  }
                  if (!isAuditSechduleBySelf && isApprovalEnabed) {
                    approveData.push(el);
                    return;
                  }
                  if (isAuditSechduleBySelf && !isApprovalEnabed) {
                    scheduleList.push(el);
                    return;
                  }
                }
              });
              setAssignList(assignList);

              // scheduleList = response.data.data.filter(el => !el?.all_assignedscheduledFlag)
              if (approveData.length) {
                setApprovalList(approveData);
              }
            }
            console.log("response ", response);

            console.log("scheduleList", scheduleList);
            setTableData(
              pageNo > 1 || pageNumber > 1
                ? [
                    ...tableData,
                    ...(isAssignedModuleEnabled && isScheduleScreen
                      ? scheduleList
                      : response.data.data),
                  ]
                : isAssignedModuleEnabled && isScheduleScreen
                ? scheduleList
                : response.data.data
            );

            // setTableTotalData(response.data);
            // setTableTotalData(response.data);
            // setTableTotalData({...tableTotalData, ...response.data});
            setPageNumber(pageNumber + 1);
            // setPageNumber(pageNo ? pageNo + 1 : pageNumber + 1);
          }
          setDataloading(false);
          console.log("pagenumber 222 === " + pageNumber);
        }
      })
      .catch((error) => {
        setDataloading(false);
      });
  };

  const handleScrollingPagination = () => {
    if (selectedCategory) {
      if (formTypeProps == 8 || formTypeProps == 11 || formTypeProps == 13) {
        surveyFilter();
      } else {
        fetchCategoryData();
      }
    } else {
      if (pageNumber > 1) {
        fetchSurveyDataOnScroll();
      }
    }
  };

  const workPermitListScheduleCount = async (
    type = "PROJECT",
    isExtended = 0
  ) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let URL = Url.formType1.workPermitListScheduleCount;
    var ContentType = {
      "Content-Type": "multipart/form-data",
    };

    let data;
    data = new FormData();

    if (type == "PROJECT") {
      if (props.project_id && props.project_id.length > 0) {
        props.project_id.forEach((item) => {
          data.append("project_id", item);
        });
      }
    }

    data.append("auth_key", token);
    data.append("user_id", userId);
    data.append("type", props.screen);
    data.append("form_type", formTypeProps);
    data.append("is_extended", isExtended);

    await axios
      .post(URL, data, ContentType)
      .then((response) => {
        if (response.data.status == 200) {
          setTableTotalData(response.data);
          setDataloading(false);
        }
      })
      .catch((error) => {
        setDataloading(false);
      });
  };

  const workPermitListClosed = async (
    type = "PROJECT",
    isExtended = 0,
    pageNo
  ) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let URL = Url.formType1.workPermitListClosed;
    var ContentType = {
      "Content-Type": "multipart/form-data",
    };

    // var pageNum = (pageNo === 1) ? pageNo : pageNumber;
    // await  setPageNumber(pageNum);

    let data;
    data = new FormData();

    if (type == "PROJECT") {
      if (props.project_id && props.project_id.length > 0) {
        props.project_id.forEach((item) => {
          data.append("project_id", item);
        });
      }
    }

    data.append("auth_key", token);
    data.append("user_id", userId);

    if (
      userInfo.designation_flag == 3 &&
      props.activeTab == ALL_COMPLETED_TAB
    ) {
      data.append("type", 4);
    } else {
      data.append("type", props.screen);
    }
    data.append("form_type", formTypeProps);
    data.append("is_extended", isExtended);
    // data.append("page_no", pageNo ? pageNo : pageNumber);

    await axios
      .post(URL, data, ContentType)
      .then((response) => {
        if (response.data.status == 200) {
          if (isExtended == 1) {
            setArchivedData(response.data.data);
          } else {
            console.log("pageNumber 11 === " + pageNumber);
            setTableData(
              pageNo > 1 || pageNumber > 1
                ? [...tableData, ...response.data.data]
                : response.data.data
            );
            setTableTotalData(response.data);
            // setPageNumber(pageNo ? pageNo + 1 : pageNumber + 1);
            setPageNumber(pageNumber + 1);

            // if(pageNo === 1){
            //   setPageNumber(1);
            // }else{
            //   setPageNumber(pageNumber + 1);
            // }
          }
          setDataloading(false);
          console.log("pageNo === " + pageNo);
          console.log("pageNumber === " + pageNumber);
        }
        if (
          userInfo.designation_flag == 1 &&
          props.activeTab == ALL_COMPLETED_TAB &&
          formTypeProps === "5" &&
          isExtended !== 1
        ) {
          workPermitListClosed("PROJECT", 1, 0);
        }
      })
      .catch((error) => {
        setDataloading(false);
      });
  };

  const elearningList = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    var requestData = {
      auth_key: token,
      user_id: userId,
      form_type: formTypeProps,
      type: props.screen,
    };

    let URL = Url.formType1.elearningList;

    let ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    await axios
      .post(URL, qs.stringify(requestData), ContentType)
      .then(async (response) => {
        if (response.data.status == 200) {
          setTableData(response.data.data);
          setDataloading(false);
        }
      })
      .catch(async (error) => {
        setDataloading(false);
      });
  };

  //start
  const surveyList = async (type = "PROJECT") => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let URL = Url.formType1.surveyList;

    let ContentType = {
      "Content-Type": "multipart/form-data",
    };

    var screenType;
    if (props.screen == 6) {
      screenType = 1;
    } else if (props.screen == 4) {
      screenType = 2;
    }
    // else {
    //   screenType = 3;
    // }
    else {
      if (props.activeTab == ALL_COMPLETED_TAB) {
        screenType = 4;
      } else {
        screenType = 3;
      }
    }

    //   let data = {
    //     type: type,
    //     form_type: formTypeProps,
    //   }

    let fd = new FormData();

    if (type == "PROJECT") {
      if (props.project_id && props.project_id.length > 0) {
        props.project_id.forEach((item) => {
          fd.append("project_id", item);
        });
      }
    }

    fd.append("auth_key", token);
    fd.append("user_id", userId);
    fd.append("form_type", formTypeProps);
    fd.append("type", screenType);
    fd.append("page_no", pageNumber);

    await axios
      .post(URL, fd, ContentType)
      .then((response) => {
        if (response.data.status == 200) {
          // setTableData(response.data.data);

          console.log("pageNumber == " + pageNumber);
          console.log("data === ", response.data.data);
          setTableData(
            pageNumber > 1
              ? [...tableData, ...response.data.data]
              : response.data.data
          );
          setTableTotalData(response.data);
          // setDataloading(false);
          setPageNumber(pageNumber + 1);
        }
        console.log("survey Data == ", tableData);
      })
      .catch((error) => {
        setDataloading(false);
      });
  };

  //Total Response
  const surveyListTotalResponse = async (type = "PROJECT") => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let URL = Url.formType1.surveyListTotalResponse;

    let ContentType = {
      "Content-Type": "multipart/form-data",
    };

    var screenType;
    if (props.screen == 6) {
      screenType = 1;
    } else if (props.screen == 4) {
      screenType = 2;
    } else {
      screenType = 3;
    }

    //   let data = {
    //     type: type,
    //     form_type: formTypeProps,
    //   }

    let fd = new FormData();

    if (type == "PROJECT") {
      if (props.project_id && props.project_id.length > 0) {
        props.project_id.forEach((item) => {
          fd.append("project_id", item);
        });
      }
    }

    fd.append("auth_key", token);
    fd.append("user_id", userId);
    fd.append("form_type", formTypeProps);
    fd.append("type", screenType);

    await axios
      .post(URL, fd, ContentType)
      .then((response) => {
        if (response.data.status == 200) {
          setTotalResponse(response.data.data);
          setDataloading(false);
        }
      })
      .catch((error) => {
        setDataloading(false);
      });
  };

  const archivedSurveyList = async () => {
    let URL = Url.formType1.archivedSurveyList;
    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    var type;
    if (props.screen == 6) {
      type = 1;
    } else if (props.screen == 4) {
      type = 2;
    } else {
      type = 3;
    }

    let data = {
      type: type,
      form_type: formTypeProps,
      page_no: archivePageNumber,
    };

    // axiosAPI function for axios http call
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(URL, ContentType, data);

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            // setArchivedData(response.data.data);
            setArchivedData([...archivedData, ...response.data.data]);
            setArchivedTotalData(response.data);
            setDataloading(false);
            setArchivePageNumber(archivePageNumber + 1);
          }
        })
        .catch(async (error) => {
          setDataloading(false);
        });
    });
  };

  const archivedWorkPermitList = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let URL = Url.formType1.archivedWorkPermitList;

    var ContentType = {
      "Content-Type": "multipart/form-data",
    };

    let data = new FormData();
    data.append("auth_key", token);
    data.append("user_id", userId);
    data.append("type", props.screen);
    data.append("form_type", formTypeProps);
    // data.append("page_no", pageNumber);

    // axiosAPI function for axios http call

    await axios
      .post(URL, data, ContentType)
      .then(async (response) => {
        if (response.data.status === 200) {
          setArchivedData(response.data.data);
          // setArchivedData([...archivedData, ...response.data.data]);
          // setArchivedTotalData(response.data);

          // let archiveCount = 0;
          // let totalCount = response.data;
          // archiveCount = archiveCount + totalCount ;
          // setArchivedTotalData(archiveCount);
          setDataloading(false);
          setPageNumber(pageNumber + 1);
        }
      })
      .catch(async (error) => {
        setDataloading(false);
      });
  };

  const archivedWorkPermitListCount = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let URL = Url.formType1.archivedWorkpPrmitCount;

    var ContentType = {
      "Content-Type": "multipart/form-data",
    };

    let data = new FormData();
    data.append("auth_key", token);
    data.append("user_id", userId);
    data.append("type", props.screen);
    data.append("form_type", formTypeProps);

    await axios
      .post(URL, data, ContentType)
      .then(async (response) => {
        if (response.data.status == 200) {
          setArchivedTotalData(response.data);
          setDataloading(false);
        }
      })
      .catch((error) => {
        setDataloading(false);
      });
  };

  const extendedWorkPermitList = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    let URL = Url.formType1.extendedWorkPermitList;

    var ContentType = {
      "Content-Type": "multipart/form-data",
    };

    let data = new FormData();
    data.append("auth_key", token);
    data.append("user_id", userId);

    // axiosAPI function for axios http call

    await axios
      .post(URL, data, ContentType)
      .then(async (response) => {
        if (response.data.status === 200) {
          setArchivedData(response.data.data);
          setDataloading(false);
        }
      })
      .catch(async (error) => {
        setDataloading(false);
      });
  };

  const handleLevelApproval = async (wpuId) => {
    let URL = Url.formType1.workPermitApprove;
    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    setDataloading(true);
    let data = {
      work_permit_use_id: wpuId,
      type: formTypeProps == 10 ? 2 : 1,
      approval_datetime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    };

    //axiosAPI function for axios http call
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(URL, ContentType, data);

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            // setTableData(response.data.data);
            window.location.reload();
            setPageNumber(1);
            workPermitList("PROJECT", 0, 1);
            workPermitList("PROJECT", 1, 1);
            setDataloading(false);
          }
        })
        .catch(async (error) => {
          setDataloading(false);
        });
    });
  };

  const _handleCloseModal = async (wpuId) => {
    await setCloseWorkPermitWpuId(wpuId);
    await setCloseModal(!closeModal);
    console.log(closeModal);
  };

  const _handleDeclineModal = async (wpuId) => {
    await setCloseWorkPermitWpuId(wpuId);
    await setDeclineModal(!declineModal);
  };

  const _handleExtendModal = async (wpuId, sDate, eDate, duration) => {
    await setCloseWorkPermitWpuId(wpuId);
    await setExtendModal(!extendModal);
    await setExtendData({
      startDate: sDate,
      endDate: eDate,
      timeDuration: duration,
    });
  };

  const _handleRemarkPopup = async (data) => {
    await setRemark(data);
    await setShowRemarkPopup(!remark);
  };

  const selectColor = (type) => {
    switch (type) {
      case "Work Completed":
        return { backgroundColor: "#9AE66E" };
      case "Work Stopped":
        return { backgroundColor: "#FF5151" };
      case "Work Cancelled":
        return { backgroundColor: "#FF9300" };
      case "Monthly":
        return { backgroundColor: "#F4D19B" };
      case "Quarterly":
        return { backgroundColor: "#DBD0C0" };
      case "Half Yearly":
        return { backgroundColor: "#FDFCE5" };
      case "Yearly":
        return { backgroundColor: "#FFF47D" };
      default:
        break;
    }
  };

  const showModal = async (selectedWpuId) => {
    await setDeleteWpuId(selectedWpuId);
    await setDeletePopup(true);
  };

  const deletePwtUse = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.formType1.workPermitUseDelete,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          work_permit_use_id: deleteWpuId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(async (response) => {
        if (response.data.status === 200) {
          AlertMessage({
            message: "Audit Deleted Sucessfully.",
            type: "success",
          });
          setDeletePopup(false);
          window.location.reload();
          await setPageNumber(1);
          workPermitList("PROJECT", 0, 0);
          archivedWorkPermitList();
        } else {
          AlertMessage({
            message: "Unpublished Form not removed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  const deleteSurveyUse = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.formType1.surveyDelete,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          survey_use_id: deleteWpuId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          AlertMessage({
            message: "Archived successfully",
            type: "success",
          });
          setDeletePopup(false);
          window.location.reload();
          //workPermitList();
          // archivedSurveyList();
          surveyList();
          archivedSurveyList();
        } else {
          AlertMessage({
            message: "Form not removed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {});
  };

  const _fetchCategoryList = async () => {
    const tempUserId = localStorage.getItem("userId");
    const tempToken = localStorage.getItem("token");

    let requestData = {
      auth_key: tempToken,
      user_id: tempUserId,
      form_type: formTypeProps,
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    axios
      .post(Url.formCategoryList, qs.stringify(requestData), {
        headers: ContentType,
      })
      .then((response) => {
        if (response.data.status === 200) {
          var list = response.data.data.map((data) => {
            delete data.description;
            return data;
          });
          setCategoryList(list);
          setDataloading(false);
        }
      })
      .catch((error) => {
        setDataloading(false);
      });
  };

  const _applyFilter = async (selectedCategoryId) => {
    if (selectedCategoryId != 0) {
      setSelectedCategory(selectedCategoryId);
      console.log("selectedCategoryId == " + selectedCategoryId);
    } else {
      setSelectedCategory(0);
      setPreviousCategory(0);
      console.log("selectedCategoryId == " + selectedCategoryId);
    }

    setPageNumber(1);

    console.log("Previouse category in apply filter == " + previousCategory);

    if (formTypeProps == 8 || formTypeProps == 11 || formTypeProps == 13) {
      if (selectedCategoryId) {
        surveyFilter("PROJECT", selectedCategoryId);
        // surveyFilter(selectedCategoryId);
      } else {
        surveyList();
      }
    } else {
      fetchCategoryData(selectedCategoryId);
    }
  };

  const fetchCategoryData = async (selectedCategoryId) => {
    if (
      selectedCategoryId != 0 &&
      ((userInfo.designation_flag == 1 &&
        props.screen == 3 &&
        props.activeTab == ALL_COMPLETED_TAB) ||
        (userInfo.designation_flag == 3 &&
          props.screen == 3 &&
          (props.activeTab == ALL_COMPLETED_TAB || CLOSED_TAB))) &&
      (formTypeProps != 8 ||
        formTypeProps != 11 ||
        formTypeProps != 13 ||
        formTypeProps != 12)
    ) {
      _allTabCategorywiseFilter("PROJECT", 0, selectedCategoryId);
    } else if (
      selectedCategoryId != 0 &&
      (formTypeProps != 8 ||
        formTypeProps != 11 ||
        formTypeProps != 13 ||
        formTypeProps != 12)
    ) {
      workPermitFilter("PROJECT", 0, selectedCategoryId);
      workPermitFilterCount("PROJECT", 0, selectedCategoryId);
    } else if (
      (userInfo.designation_flag == 1 || userInfo.designation_flag == 3) &&
      props.screen == 3 &&
      props.activeTab == ALL_COMPLETED_TAB &&
      (formTypeProps != 8 ||
        formTypeProps != 11 ||
        formTypeProps != 13 ||
        formTypeProps != 12)
    ) {
      workPermitListClosed();
    } else {
      workPermitList();
    }
  };

  const workPermitFilter = async (
    type = "PROJECT",
    isExtended = 0,
    selectedCategoryId
  ) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let pageNo =
      (selectedCategoryId || selectedCategory) == previousCategory
        ? categoryPageNumber
        : 1;
    // await  setCategoryPageNumber(pageNo);

    console.log("page no === " + pageNo);

    let URL = Url.formType1.categoryWiseFilter;

    var ContentType = {
      "Content-Type": "multipart/form-data",
    };

    let data;
    data = new FormData();

    if (type == "PROJECT") {
      if (props.project_id && props.project_id.length > 0) {
        props.project_id.forEach((item) => {
          data.append("project_id", item);
        });
      }
    }

    data.append("auth_key", token);
    data.append("user_id", userId);
    data.append("type", props.screen);
    data.append("form_type", formTypeProps);
    data.append("is_extended", isExtended);
    data.append(
      "category_id",
      selectedCategoryId ? selectedCategoryId : selectedCategory
    );
    // data.append("page_no", pageNo);

    await axios
      .post(URL, data, ContentType)
      .then((response) => {
        if (response.data.status == 200) {
          // setTableData(pageNo > 1 ? [...tableData, ...response.data.data] : response.data.data);
          setTableData(response.data.data);
          setCategoryPageNumber(pageNo + 1);
          setPreviousCategory(
            selectedCategoryId ? selectedCategoryId : selectedCategory
          );
          setDataloading(false);
        }
      })
      .catch((error) => {
        setDataloading(false);
      });
  };

  const workPermitFilterCount = async (
    type = "PROJECT",
    isExtended = 0,
    selectedCategoryId
  ) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let URL = Url.formType1.categoryWiseFilterCount;
    var ContentType = {
      "Content-Type": "multipart/form-data",
    };

    let data;
    data = new FormData();

    if (type == "PROJECT") {
      if (props.project_id && props.project_id.length > 0) {
        props.project_id.forEach((item) => {
          data.append("project_id", item);
        });
      }
    }

    data.append("auth_key", token);
    data.append("user_id", userId);
    data.append("type", props.screen);
    data.append("form_type", formTypeProps);
    data.append("is_extended", isExtended);
    data.append(
      "category_id",
      selectedCategoryId ? selectedCategoryId : selectedCategory
    );

    await axios
      .post(URL, data, ContentType)
      .then((response) => {
        if (response.data.status == 200) {
          setTotalCategoryCount(response.data.total_category_count);
          setDataloading(false);
        }
      })
      .catch((error) => {
        setDataloading(false);
      });
  };

  const _allTabCategorywiseFilter = async (
    type = "PROJECT",
    isExtended = 0,
    selectedCategoryId
  ) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let pageNo =
      (selectedCategoryId || selectedCategory) == previousCategory
        ? categoryPageNumber
        : 1;
    // await  setCategoryPageNumber(pageNo);

    console.log("page no === " + pageNo);

    let URL = Url.formType1.allTabCategorywiseFilter;

    var ContentType = {
      "Content-Type": "multipart/form-data",
    };

    let data;
    data = new FormData();

    if (type == "PROJECT") {
      if (props.project_id && props.project_id.length > 0) {
        props.project_id.forEach((item) => {
          data.append("project_id", item);
        });
      }
    }
    // console.log("project" +project_id);

    data.append("auth_key", token);
    data.append("user_id", userId);
    // data.append("type", props.screen);
    data.append("form_type", formTypeProps);
    data.append("is_extended", isExtended);

    if (
      userInfo.designation_flag == 3 &&
      props.activeTab == ALL_COMPLETED_TAB
    ) {
      data.append("type", 4);
    } else {
      data.append("type", props.screen);
    }

    data.append(
      "category_id",
      selectedCategoryId ? selectedCategoryId : selectedCategory
    );

    // data.append("page_no", pageNo);

    await axios
      .post(URL, data, ContentType)
      .then((response) => {
        if (response.data.status == 200) {
          // setTableData(pageNo > 1 ? [...tableData, ...response.data.data] : response.data.data);
          setTableData(response.data.data);
          setTotalCategoryCount(response.data.total_category_count);
          setCategoryPageNumber(pageNo + 1);
          setPreviousCategory(
            selectedCategoryId ? selectedCategoryId : selectedCategory
          );
          setDataloading(false);
        }
        console.log(
          "previous category === " + previousCategory,
          selectedCategory
        );
      })
      .catch((error) => {
        setDataloading(false);
      });
  };

  const surveyFilter = async (type = "PROJECT", selectedCategoryId) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let pageNo =
      (selectedCategoryId || selectedCategory) == previousCategory
        ? categoryPageNumber
        : 1;
    // await  setCategoryPageNumber(pageNo);

    console.log("page no === " + pageNo);

    let URL = Url.formType1.categoryWiseSurveyFilter;

    let ContentType = {
      "Content-Type": "multipart/form-data",
    };

    var screenType;
    if (props.screen == 6) {
      screenType = 1;
    } else if (props.screen == 4) {
      screenType = 2;
    } else {
      // screenType = 3;
      if (props.screen == 5 && props.activeTab == CLOSED_TAB) {
        screenType = 3;
      } else if (props.screen == 5 && props.activeTab == ALL_COMPLETED_TAB) {
        screenType = 4;
      }
    }

    let fd = new FormData();

    if (type == "PROJECT") {
      if (props.project_id && props.project_id.length > 0) {
        props.project_id.forEach((item) => {
          fd.append("project_id", item);
        });
      }
    }

    fd.append("auth_key", token);
    fd.append("user_id", userId);
    fd.append("form_type", formTypeProps);
    fd.append("type", screenType);
    fd.append(
      "category_id",
      selectedCategoryId ? selectedCategoryId : selectedCategory
    );
    fd.append("page_no", pageNo);

    // if((selectedCategoryId || selectedCategory) === previousCategory){
    //   fd.append("page_no", categoryPageNumber);
    //   console.log("categoryPageNumber in If ==== "+categoryPageNumber);
    // }else{
    //   fd.append("page_no", 1);
    //   console.log("categoryPageNumber in else ==== " +1);
    // }

    // if (selectedCategoryId) {
    //   fd.append("category_id", selectedCategoryId);
    // } else {
    //   fd.append("category_id", selectedCategory);
    // }

    await axios
      .post(URL, fd, ContentType)
      .then((response) => {
        if (response.data.status == 200) {
          setTableData(
            pageNo > 1
              ? [...tableData, ...response.data.data]
              : response.data.data
          );
          // setCategoryPageNumber(categoryPageNumber + 1);
          setCategoryPageNumber(pageNo + 1);
          setPreviousCategory(
            selectedCategoryId ? selectedCategoryId : selectedCategory
          );
          setTotalCategoryCount(response.data.catfilteredlist_count);
          setDataloading(false);
        }
      })

      .finally(() => setDataloading(false));
  };
  const buildTabs = (schCount, arcCount) => {
    const isAssignedModuleEnabled = whiteListAssign.includes(formTypeProps);
    let tabs = [
      {
        tabName: `Schedule (${
          isAssignedModuleEnabled ? tableData?.length : schCount ?? 0
        })`,
        key: "sch",
      },

      {
        tabName: `Assigned (${assignList?.length ?? 0})`,
        key: "asg",
        skip: !isAssignedModuleEnabled,
      },
      {
        tabName: `Approval (${approvalList?.length ?? 0})`,
        key: "apr",
        skip: !isAssignedModuleEnabled,
      },
    ];
    {
      /* // Britannia v01
              //  Making this change to ...(Integrate the All schedule tab  because of britian custoimization ) */
    }

    if (
      whiteListAllSchedue.includes(formTypeProps) &&
      user.designation_flag === 1
    ) {
      tabs.push({
        tabName: `All Schedule (${allScheduleData?.schedule_count ?? ""})`,
        key: "all",
      });
    }
    tabs = [
      ...tabs,
      {
        tabName: `Archive (${arcCount ?? 0})`,
        key: "arc",
      },
    ];
    return tabs;
  };
  console.log("flgs", props);
  const isExtendedListAvailable =
    (props.screen == 6 || formTypeProps == 5) &&
    ((currentTab?.key === "arc" &&
      ["initiated", "scheduled"].includes(props.activeTab)) ||
      !["initiated", "scheduled"].includes(props.activeTab));

  return (
    <div>
      {props.screen == 6 ? (
        <div className="mb-3">
          <TabsV2
            currentItem={currentTab}
            handleChange={(key) => setCurrentTab(key)}
            tabs={buildTabs(
              !!tableTotalData && tableTotalData.schedule_count,
              !!archivedTotalData && archivedTotalData.archived_count
            )}
          />
        </div>
      ) : null}

      {dataloading ? (
        <ClideLoader />
      ) : (
        <div>
          {props.screen == 6 && currentTab?.key === "sch" ? (
            <>
              <InfiniteScroll
                dataLength={tableData.length}
                // next={selectedCategory ? (formTypeProps == 8 || formTypeProps == 11 || formTypeProps == 13) ? surveyFilter : fetchCategoryData : fetchSurveyDataOnScroll }
                next={
                  selectedCategory
                    ? (formTypeProps == 8 ||
                        formTypeProps == 11 ||
                        formTypeProps == 13) &&
                      surveyFilter
                    : surveyList != props.formTypeCount &&
                      fetchSurveyDataOnScroll
                }
                hasMore={
                  tableData.length !==
                  (selectedCategory ? totalCategoryCount : props.formTypeCount)
                }
              >
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12 mb-2">
                    {/* <div className="d-flex align-items-center justify-content-end">
                      <nav aria-label="Page navigation" id="pagination-map">
                        <Pagination
                          activePage={1}
                          itemsCountPerPage={10}
                          totalItemsCount={450}
                          pageRangeDisplayed={5}
                          onChange={() => {}}
                          itemClass="page-item mx-1"
                          linkClass="border-0 page-link  rounded-5 text-decoration-none undefined shadow-none "
                        />
                      </nav>
                    </div> */}
                    <div className="clide-v2-card-box p-3 h-100 pb-0">
                      <div className="clide-v2-scroll-bar pe-3 clide-v2-p-0" style={{height:"79vw"}}>
                        <table
                          id="example5"
                          className="table table-striped nowrap"
                          style={{ width: "100%" }}
                        >
                          <thead>
                            <tr>
                              <th
                                className="text-center"
                                style={{ width: "70px" }}
                              >
                                #
                              </th>
                              <th className="text-center">
                                {typeList[formTypeProps - 1].form_type}
                              </th>
                              {formTypeProps != 7 && formTypeProps != 6 && (
                                <th
                                  style={{ width: "50px" }}
                                  className="text-center"
                                >
                                  <div style={{ marginTop: "1vw" }}>
                                    <FormTypeDropDown
                                      // title="Do's & Dont's / Training / Policy"
                                      title="Category"
                                      isRequired={false}
                                      optionList={categoryList}
                                      onChangeHandle={_applyFilter}
                                      value={selectedCategory}
                                    />
                                  </div>
                                </th>
                              )}
                              <th
                                className="justify-content-center"
                                style={{ width: "130px" }}
                              >
                                Start Date{" "}
                              </th>
                              <th
                                className="justify-content-center"
                                style={{ width: "130px" }}
                              >
                                End Date{" "}
                              </th>
                              {!(
                                props.screen == 6 ||
                                props.screen == 1 ||
                                props.screen == 0 ||
                                props.screen == 3 ||
                                formTypeProps == 5
                              ) ? (
                                <th className="text-center">Response</th>
                              ) : null}
                              {!(props.screen == 1 && formTypeProps != 5) &&
                                formTypeProps != 10 && (
                                  <th className="justify-content-center">
                                    Duration
                                  </th>
                                )}
                              {(formTypeProps == 8 ||
                                formTypeProps == 11 ||
                                formTypeProps == 13) && (
                                <th className="text-center">Owner</th>
                              )}
                              {props.screen == 1 &&
                                formTypeProps != 5 &&
                                formTypeProps != 10 && (
                                  <th className="text-center">Time</th>
                                )}
                              {props.screen == 4 && (
                                <th
                                  className="text-center"
                                  style={{ width: "12vw" }}
                                >
                                  Link
                                </th>
                              )}
                              {!(
                                props.screen == 4 ||
                                props.screen == 5 ||
                                formTypeProps == 8 ||
                                formTypeProps == 11 ||
                                formTypeProps == 13 ||
                                formTypeProps == 12 ||
                                formTypeProps == 10
                              ) && (
                                <th
                                  className="justify-content-center"
                                  style={{ width: "12vw" }}
                                >
                                  Approval Level
                                </th>
                              )}
                              {formTypeProps == 10 && (
                                <th className="text-center">Assigned User</th>
                              )}
                              {props.screen == 6 ? (
                                <th className="justify-content-center">
                                  Action
                                </th>
                              ) : null}
                              {formTypeProps == 5 && props.screen == 1 ? (
                                <th className=" justify-content-center">
                                  Action
                                </th>
                              ) : null}
                              {props.screen == 2 && (
                                <th className="text-center">Decline Remark</th>
                              )}
                              {props.screen == 2 && formTypeProps == 5 && (
                                <th className="text-center">Comment</th>
                              )}
                              {props.screen == 3 && formTypeProps == 5 && (
                                <th className="text-center">Closing Remark</th>
                              )}
                              {formTypeProps == 5 && props.screen == 3 ? (
                                <th className="text-center">Comment</th>
                              ) : null}
                              {props.screen == 0 && (
                                <>
                                  <th className="text-center">Status</th>
                                </>
                              )}
                              {(props.screen == 0 ||
                                props.screen == 1 ||
                                props.screen == 3) &&
                                formTypeProps != 5 &&
                                formTypeProps != 12 && (
                                  <>
                                    {/* <th>Score</th> */}
                                    <th
                                      className="text-center"
                                      style={{ width: "12vw" }}
                                    >
                                      Score
                                    </th>
                                  </>
                                )}
                            </tr>
                          </thead>
                          <tbody>
                            {tableData &&
                            (tableData.length === 0 ||
                              tableData === undefined) ? (
                              <tr>
                                <td colSpan="6">
                                  <p className="list-error">
                                    Data not available
                                  </p>
                                </td>
                              </tr>
                            ) : (
                              tableData &&
                              tableData.map((data, index) => (
                                <tr
                                  key={`${index}-${data.reporting_form_id}`}
                                  style={{
                                    borderBottom: "5px solid #f5f5f5",
                                    backgroundColor:
                                      data.is_extended == 1
                                        ? "#D9D7F1"
                                        : "none",
                                  }}
                                >
                                  {!(
                                    formTypeProps == 8 ||
                                    formTypeProps == 11 ||
                                    formTypeProps == 13
                                  ) ? (
                                    <td className="profile-flex">
                                      <React.Fragment>
                                        <div
                                          className={"image-box"}
                                          style={{ marginRight: "0.5vw" }}
                                        >
                                          <img
                                            className="clide-v2-profile-img"
                                            src={data.pic_link}
                                            alt=""
                                            data-tip
                                            data-for={`user-name-${data.user_id}-user`}
                                          />
                                        </div>
                                        <ReactTooltip
                                          effect="solid"
                                          place="right"
                                          id={`user-name-${data.user_id}-user`}
                                        >
                                          <>
                                            <span>{data.user_name}</span>
                                          </>
                                        </ReactTooltip>
                                      </React.Fragment>
                                    </td>
                                  ) : (
                                    <td>
                                      {data.file_name != null ? (
                                        <img
                                          className="clide-v2-profile-img"
                                          src={data.file_name}
                                        />
                                      ) : null}
                                    </td>
                                  )}

                                  <td className="btn clide-v2-border-primary-color btn-sm text-center w-100">
                                    {
                                      props.screen == 1 &&
                                      formTypeProps == 5 ? (
                                        <Link
                                          to={`/submit-form-listing/${formTypeProps}/${
                                            props.activeTab
                                          }/view-form/${
                                            data.reporting_form_id
                                          }/${data.service_id || 0}/${
                                            data.project_id || 0
                                          }/${data.work_permit_use_id || 0}/${
                                            data.timeline_id || 0
                                          }/${data.survey_use_id || 0}/${
                                            formTypeProps === 12
                                              ? data.elearningId
                                              : 0
                                          }/0/0`}
                                        >
                                          {data.title}
                                        </Link>
                                      ) : (props.screen == 6 ||
                                          props.screen == 1) &&
                                        (data.edit_submit_right == 1 ||
                                          (props.activeTab === "scheduled" &&
                                            formTypeProps == 10)) ? (
                                        <Link
                                          to={`/submit-form-listing/${formTypeProps}/${
                                            props.activeTab
                                          }/user-form-view/${
                                            data.reporting_form_id
                                          }/${data.service_id || 0}/${
                                            data.project_id || 0
                                          }/${data.work_permit_use_id || 0}/${
                                            data.timeline_id || 0
                                          }/${
                                            formTypeProps === 12
                                              ? data.elearningId
                                              : 0
                                          }/0`}
                                        >
                                          {data.title}
                                        </Link>
                                      ) : props.screen == 6 &&
                                        (formTypeProps == 8 ||
                                          formTypeProps == 11 ||
                                          formTypeProps == 13) ? (
                                        <Link
                                          to={`/submit-form-listing/${formTypeProps}/${props.activeTab}/published-view/${data.reporting_form_id}`}
                                        >
                                          {data.title}
                                        </Link>
                                      ) : props.screen == 6 &&
                                        props.activeTab === "scheduled" &&
                                        (formTypeProps != 8 ||
                                          formTypeProps != 11 ||
                                          formTypeProps != 13 ||
                                          formTypeProps != 10) ? (
                                        <Link
                                          to={`/submit-form-listing/${formTypeProps}/${
                                            props.activeTab
                                          }/user-form-view/${
                                            data.reporting_form_id
                                          }/${data.service_id || 0}/${
                                            data.project_id || 0
                                          }/${data.work_permit_use_id || 0}/${
                                            data.timeline_id || 0
                                          }/${
                                            formTypeProps === 12
                                              ? data.elearningId
                                              : 0
                                          }/0`}
                                        >
                                          {data.title}
                                        </Link>
                                      ) : (
                                        <Link
                                          to={`/submit-form-listing/${formTypeProps}/${
                                            props.activeTab
                                          }/view-form/${
                                            data.reporting_form_id
                                          }/${data.service_id || 0}/${
                                            data.project_id || 0
                                          }/${data.work_permit_use_id || 0}/${
                                            data.timeline_id || 0
                                          }/${data.survey_use_id || 0}/${
                                            formTypeProps === 12
                                              ? data.elearningId
                                              : 0
                                          }/0/0`}
                                        >
                                          {data.title}
                                        </Link>
                                      )

                                      // (
                                      //   <Link
                                      //     to={`/submit-form-listing/${formTypeProps}/${props.activeTab
                                      //       }/view-form/${data.reporting_form_id}/${data.service_id || 0
                                      //       }/${data.project_id || 0}/${data.work_permit_use_id || 0
                                      //       }/${data.timeline_id || 0}/${data.survey_use_id || 0}/${formTypeProps === 12 ? data.elearningId : 0}/0/0`}
                                      //   >
                                      //     {data.title}
                                      //   </Link>
                                      // )
                                    }
                                  </td>

                                  {formTypeProps != 7 && formTypeProps != 6 && (
                                    <td>
                                      <span className="btn clide-v2-border-primary-color btn-sm text-center w-100">
                                        {data.category}
                                      </span>
                                    </td>
                                  )}

                                  {/* <td>{convertDateFromTimezone(data.start_date)}</td>
                  <td>{convertDateFromTimezone(data.end_date)}</td> */}

                                  <td style={{ width: "100px" }}>
                                    {data.start_date}
                                  </td>
                                  <td style={{ width: "100px" }}>
                                    {data.end_date}
                                  </td>

                                  {!(props.screen == 6) &&
                                  (formTypeProps == 8 ||
                                    formTypeProps == 11 ||
                                    formTypeProps == 13) ? (
                                    <td>
                                      {!!totalResponse &&
                                        totalResponse
                                          .filter(
                                            (e) =>
                                              e.survey_use_id ===
                                              data.survey_use_id
                                          )
                                          .map((item) => item.response_count)}
                                    </td>
                                  ) : null}

                                  {!(props.screen == 1 && formTypeProps != 5) &&
                                    formTypeProps != 8 &&
                                    formTypeProps != 10 &&
                                    formTypeProps != 11 &&
                                    formTypeProps != 13 && (
                                      <td>{`${data.duration}`}</td>
                                    )}

                                  {(formTypeProps == 8 ||
                                    formTypeProps == 11 ||
                                    formTypeProps == 13) && (
                                    <td>{`${data.time_duration}`}</td>
                                  )}

                                  {(formTypeProps == 8 ||
                                    formTypeProps == 11 ||
                                    formTypeProps == 13) && (
                                    <td className="profile-flex">
                                      {/* {`${data.user_name}`} */}

                                      <React.Fragment>
                                        <div
                                          className="image-box"
                                          style={{ marginRight: "0.5vw" }}
                                        >
                                          <img
                                            className="clide-v2-profile-img"
                                            src={data.pic_link}
                                            alt="user-pic"
                                            data-tip
                                            data-for={`user-name-${data.user_id}-user-2`}
                                          />
                                        </div>
                                        <ReactTooltip
                                          effect="solid"
                                          place="right"
                                          id={`user-name-${data.user_id}-user-2`}
                                        >
                                          <span>{data.user_name}</span>
                                        </ReactTooltip>
                                      </React.Fragment>
                                    </td>
                                  )}

                                  {props.screen == 1 && formTypeProps == 12 && (
                                    <td>{`${data.time_duration} days`}</td>
                                  )}

                                  {props.screen == 1 &&
                                    formTypeProps != 5 &&
                                    formTypeProps != 10 && (
                                      <td>{data.remaining_period}</td>
                                    )}
                                  {props.screen == 4 && (
                                    <td>{data.survey_link || 0}</td>
                                  )}

                                  {!(
                                    props.screen == 4 ||
                                    props.screen == 5 ||
                                    (props.screen == 6 &&
                                      (formTypeProps == 8 ||
                                        formTypeProps == 11 ||
                                        formTypeProps == 13)) ||
                                    formTypeProps == 12 ||
                                    formTypeProps == 10
                                  ) && (
                                    <td className="profile-flex">
                                      {data.approvalLevelList?.map(
                                        (user, idx) => (
                                          <React.Fragment
                                            key={`user-name-${idx}`}
                                          >
                                            <div
                                              className={
                                                user.approve_decline_status > 0
                                                  ? user.approve_decline_status ==
                                                    2
                                                    ? "image-box-blur-decline"
                                                    : "image-box-blur-approve"
                                                  : "image-box"
                                              }
                                              style={{ marginRight: "0.5vw" }}
                                            >
                                              {/* <div className="image_grid" > */}

                                              {user.level_id > 0 && (
                                                <img
                                                  className="clide-v2-profile-img"
                                                  src={user.pic_link}
                                                  alt=""
                                                  data-tip
                                                  // data-for={`user-name-${idx}`}

                                                  data-for={`user-name-${user.level_id}`}
                                                />
                                              )}
                                              {/* </div> */}
                                            </div>
                                            {user.level_id > 0 && (
                                              <ReactTooltip
                                                //  id={`user-name-${idx}`}
                                                id={`user-name-${user.level_id}`}
                                                effect="solid"
                                                place="left"
                                              >
                                                <>
                                                  <span>
                                                    {user.level}
                                                    {" | "}
                                                    {user.user_name}
                                                  </span>
                                                </>
                                              </ReactTooltip>
                                            )}
                                          </React.Fragment>
                                        )
                                      )}
                                    </td>
                                  )}

                                  {formTypeProps == 10 && (
                                    <td className="profile-flex">
                                      <React.Fragment>
                                        <div
                                          className="clide-v2-profile-img"
                                          style={{ marginRight: "0.5vw" }}
                                        >
                                          <img
                                            src={data.assigned_contractor_pic}
                                            alt="assigned user"
                                            data-tip
                                            data-for={`user-name-${data.assigned_contractor_id}`}
                                          />
                                        </div>
                                        <ReactTooltip
                                          effect="solid"
                                          place="right"
                                          id={`user-name-${data.assigned_contractor_id}`}
                                        >
                                          <>
                                            <span>
                                              {data.assigned_contractor_name}
                                            </span>
                                          </>
                                        </ReactTooltip>
                                      </React.Fragment>
                                    </td>
                                  )}
                                  {props.screen == 2 && (
                                    <>
                                      <td>{data.decline_reason}</td>
                                      <td className="cursor-pointer">
                                        <Popup
                                          trigger={
                                            <button
                                              style={{
                                                border: "none",
                                                background: "none",
                                              }}
                                            >
                                              {" "}
                                              <img
                                                src={require("assets/sls-icons/V2. New Development/Comment.svg")}
                                                className="svg-icons "
                                              />
                                            </button>
                                          }
                                          position="bottom"
                                        >
                                          <div style={{ textAlign: "center" }}>
                                            {data.decline_remark}
                                          </div>
                                        </Popup>
                                      </td>
                                    </>
                                  )}
                                  {props.screen == 3 && formTypeProps == 5 && (
                                    <>
                                      <td
                                        style={selectColor(data.closing_reason)}
                                      >
                                        {data.closing_reason}
                                      </td>
                                      <td className="cursor-pointer">
                                        {data.closing_remark ? (
                                          <Popup
                                            trigger={
                                              <button
                                                style={{
                                                  border: "none",
                                                  background: "none",
                                                }}
                                              >
                                                {" "}
                                                <img
                                                  src={require("assets/sls-icons/V2. New Development/Comment.svg")}
                                                  className="svg-icons "
                                                />
                                              </button>
                                            }
                                            position="bottom"
                                          >
                                            <div
                                              style={{ textAlign: "center" }}
                                            >
                                              {data.closing_remark}
                                            </div>
                                          </Popup>
                                        ) : (
                                          "NA"
                                        )}
                                      </td>
                                    </>
                                  )}
                                  {props.screen == 0 &&
                                    (data.approved == 1 ? (
                                      <td
                                        style={{
                                          backgroundColor: "#6ECB63",
                                          color: "white",
                                        }}
                                      >
                                        Approved
                                      </td>
                                    ) : data.approve_decline_status == 1 ? (
                                      <td
                                        style={{ padding: 0 }}
                                        className="cursor-pointer"
                                      >
                                        <div
                                          onClick={() => {
                                            handleLevelApproval(
                                              data.work_permit_use_id
                                            );
                                          }}
                                          style={{
                                            color: "tomato",
                                            backgroundColor: "#ECE7B4",
                                          }}
                                        >
                                          Approve
                                        </div>
                                        {formTypeProps == 5 && (
                                          <div
                                            onClick={() => {
                                              _handleDeclineModal(
                                                data.work_permit_use_id
                                              );
                                            }}
                                            style={{
                                              color: "white",
                                              backgroundColor: "red",
                                            }}
                                          >
                                            Decline
                                          </div>
                                        )}
                                      </td>
                                    ) : (
                                      <td>Approval Pending</td>
                                    ))}
                                  {props.screen == 6 && (
                                    <td>
                                      {data.delete_right == 1 && (
                                        <svg
                                          width="33"
                                          height="33"
                                          viewBox="0 0 33 33"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          onClick={() =>
                                            showModal(
                                              data.work_permit_use_id ||
                                                data.survey_use_id
                                            )
                                          }
                                        >
                                          <circle
                                            cx="16.5488"
                                            cy="16.376"
                                            r="15.5"
                                            fill="white"
                                            stroke="#FF6347"
                                          />
                                          <path
                                            d="M23.6325 11.376H9.46582"
                                            stroke="#F56620"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                          />
                                          <path
                                            d="M11.9658 11.3751H12.0575C12.3929 11.3666 12.7178 11.257 12.9899 11.0608C13.262 10.8646 13.4685 10.5909 13.5825 10.2755L13.6108 10.1897L13.6917 9.94728C13.7608 9.73985 13.7958 9.63656 13.8417 9.54825C13.9318 9.3753 14.0612 9.22585 14.2196 9.11186C14.3779 8.99787 14.5607 8.92251 14.7533 8.8918C14.8508 8.87598 14.96 8.87598 15.1783 8.87598H17.92C18.1383 8.87598 18.2475 8.87598 18.345 8.8918C18.5377 8.92251 18.7205 8.99787 18.8788 9.11186C19.0371 9.22585 19.1665 9.3753 19.2567 9.54825C19.3025 9.63656 19.3375 9.73985 19.4067 9.94728L19.4875 10.1897C19.5931 10.5406 19.8115 10.847 20.1089 11.0613C20.4063 11.2756 20.766 11.3859 21.1325 11.3751"
                                            stroke="#F56620"
                                            stroke-width="1.5"
                                          />
                                          <path
                                            d="M21.8613 19.209C21.7138 21.4207 21.6396 22.5265 20.9188 23.2007C20.198 23.8757 19.0896 23.8757 16.8721 23.8757H16.2271C14.0105 23.8757 12.9021 23.8757 12.1805 23.2007C11.4596 22.5265 11.3863 21.4207 11.2388 19.209L10.8555 13.459M22.2438 13.459L22.0771 15.959"
                                            stroke="#F56620"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                          />
                                        </svg>
                                      )}
                                    </td>
                                  )}
                                  {props.screen == 1 &&
                                  formTypeProps == 5 &&
                                  data.edit_submit_right ? (
                                    <td
                                      style={{ padding: 0 }}
                                      className="cursor-pointer"
                                    >
                                      <div
                                        onClick={() => {
                                          _handleExtendModal(
                                            data.work_permit_use_id,
                                            data.start_date,
                                            data.end_date,
                                            data.time_duration
                                          );
                                        }}
                                        style={{
                                          color: "white",
                                          backgroundColor: "#6ECB63",
                                        }}
                                      >
                                        Extend
                                      </div>
                                      <div
                                        onClick={() => {
                                          _handleCloseModal(
                                            data.work_permit_use_id
                                          );
                                        }}
                                        style={{
                                          color: "white",
                                          backgroundColor: "red",
                                        }}
                                      >
                                        Close
                                      </div>
                                    </td>
                                  ) : null}
                                  {formTypeProps != 5 &&
                                    (props.screen == 3 ||
                                      props.screen == 1 ||
                                      props.screen == 0) &&
                                    data.total_score > 0 && (
                                      <>
                                        <td>{`${data.obtained_score}/${data.total_score}`}</td>
                                        {/* <td>Progress</td> */}
                                      </>
                                      // :
                                      // <td>NA</td>
                                    )}
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </InfiniteScroll>
            </>
          ) : null}
          {currentTab?.key === "asg" ? (
            <>
              <InfiniteScroll
                dataLength={assignList.length}
                // next={selectedCategory ? (formTypeProps == 8 || formTypeProps == 11 || formTypeProps == 13) ? surveyFilter : fetchCategoryData : fetchSurveyDataOnScroll }
                next={null}
                hasMore={false}
              >
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12 mb-2">
                    <div className="clide-v2-card-box p-3  h-100">
                      <div className="clide-v2-scroll-bar pe-3 clide-v2-p-0" style={{height:"79vw"}}>
                        <table
                          id="example5"
                          className="table table-striped nowrap"
                          style={{ width: "100%" }}
                        >
                          <thead>
                            <tr>
                              <th
                                className="text-center"
                                style={{ width: "70px" }}
                              >
                                #
                              </th>
                              <th className="text-center">
                                {typeList[formTypeProps - 1].form_type}
                              </th>
                              {formTypeProps != 7 && formTypeProps != 6 && (
                                <th
                                  style={{ width: "12vw" }}
                                  className="justify-content-center"
                                >
                                  Category
                                </th>
                              )}
                              <th
                                className="justify-content-center"
                                style={{ width: "130px" }}
                              >
                                Start Date{" "}
                              </th>
                              <th
                                className="justify-content-center"
                                style={{ width: "130px" }}
                              >
                                End Date{" "}
                              </th>
                              {!(
                                props.screen == 6 ||
                                props.screen == 1 ||
                                props.screen == 0 ||
                                props.screen == 3 ||
                                formTypeProps == 5
                              ) ? (
                                <th className="text-center">Response</th>
                              ) : null}
                              {!(props.screen == 1 && formTypeProps != 5) &&
                                formTypeProps != 10 && (
                                  <th className="justify-content-center">
                                    Duration
                                  </th>
                                )}
                              {(formTypeProps == 8 ||
                                formTypeProps == 11 ||
                                formTypeProps == 13) && (
                                <th className="text-center">Owner</th>
                              )}
                              {props.screen == 1 &&
                                formTypeProps != 5 &&
                                formTypeProps != 10 && (
                                  <th className="text-center">Time</th>
                                )}
                              {props.screen == 4 && (
                                <th
                                  className="text-center"
                                  style={{ width: "12vw" }}
                                >
                                  Link
                                </th>
                              )}
                              {!(
                                props.screen == 4 ||
                                props.screen == 5 ||
                                formTypeProps == 8 ||
                                formTypeProps == 11 ||
                                formTypeProps == 13 ||
                                formTypeProps == 12 ||
                                formTypeProps == 10
                              ) && (
                                <th
                                  className="justify-content-center"
                                  style={{ width: "12vw" }}
                                >
                                  Approval Level
                                </th>
                              )}
                              {formTypeProps == 10 && (
                                <th className="text-center">Assigned User</th>
                              )}
                              {props.screen == 6 ? (
                                <th className="justify-content-center">
                                  Action
                                </th>
                              ) : null}
                              {formTypeProps == 5 && props.screen == 1 ? (
                                <th className=" justify-content-center">
                                  Action
                                </th>
                              ) : null}
                              {props.screen == 2 && (
                                <th className="text-center">Decline Remark</th>
                              )}
                              {props.screen == 2 && formTypeProps == 5 && (
                                <th className="text-center">Comment</th>
                              )}
                              {props.screen == 3 && formTypeProps == 5 && (
                                <th className="text-center">Closing Remark</th>
                              )}
                              {formTypeProps == 5 && props.screen == 3 ? (
                                <th className="text-center">Comment</th>
                              ) : null}
                              {props.screen == 0 && (
                                <>
                                  <th className="text-center">Status</th>
                                </>
                              )}
                              {(props.screen == 0 ||
                                props.screen == 1 ||
                                props.screen == 3) &&
                                formTypeProps != 5 &&
                                formTypeProps != 12 && (
                                  <>
                                    {/* <th>Score</th> */}
                                    <th
                                      className="text-center"
                                      style={{ width: "12vw" }}
                                    >
                                      Score
                                    </th>
                                  </>
                                )}
                            </tr>
                          </thead>
                          <tbody>
                            {assignList &&
                            (assignList.length === 0 ||
                              assignList === undefined) ? (
                              <tr>
                                <td colSpan="6">
                                  <p className="list-error">
                                    Data not available
                                  </p>
                                </td>
                              </tr>
                            ) : (
                              assignList &&
                              assignList?.map((data, index) => (
                                <tr
                                  key={`${index}-${data.reporting_form_id}`}
                                  style={{
                                    borderBottom: "5px solid #f5f5f5",
                                    backgroundColor:
                                      data.is_extended == 1
                                        ? "#D9D7F1"
                                        : "none",
                                  }}
                                >
                                  {!(
                                    formTypeProps == 8 ||
                                    formTypeProps == 11 ||
                                    formTypeProps == 13
                                  ) ? (
                                    <td className="clide-v2-profile-img">
                                      <React.Fragment>
                                        <div
                                          className="clide-v2-profile-img"
                                          style={{ marginRight: "0.5vw" }}
                                        >
                                          <img
                                            src={
                                              data.assignedUser
                                                ?.assigned_UserPicLink
                                            }
                                            alt=""
                                            data-tip
                                            data-for={`user-name-${data.assignedUser?.assigned_userName}-user`}
                                          />
                                        </div>
                                        <ReactTooltip
                                          effect="solid"
                                          place="right"
                                          id={`user-name-${data.assignedUser?.assigned_userName}-user`}
                                        >
                                          <>
                                            <span>
                                              {
                                                data.assignedUser
                                                  ?.assigned_userName
                                              }
                                            </span>
                                          </>
                                        </ReactTooltip>
                                      </React.Fragment>
                                    </td>
                                  ) : (
                                    <td>
                                      {data.file_name != null ? (
                                        <img
                                          className="clide-v2-profile-img"
                                          src={data.file_name}
                                        />
                                      ) : null}
                                    </td>
                                  )}

                                  <td className="btn clide-v2-border-primary-color btn-sm text-center w-100">
                                    {
                                      props.screen == 1 &&
                                      formTypeProps == 5 ? (
                                        <Link
                                          to={`/submit-form-listing/${formTypeProps}/${
                                            props.activeTab
                                          }/view-form/${
                                            data.reporting_form_id
                                          }/${data.service_id || 0}/${
                                            data.project_id || 0
                                          }/${data.work_permit_use_id || 0}/${
                                            data.timeline_id || 0
                                          }/${data.survey_use_id || 0}/${
                                            formTypeProps === 12
                                              ? data.elearningId
                                              : 0
                                          }/0/0`}
                                        >
                                          {data.title}
                                        </Link>
                                      ) : (props.screen == 6 ||
                                          props.screen == 1) &&
                                        (data.edit_submit_right == 1 ||
                                          (props.activeTab === "scheduled" &&
                                            formTypeProps == 10)) ? (
                                        <Link
                                          to={`/submit-form-listing/${formTypeProps}/${
                                            props.activeTab
                                          }/user-form-view/${
                                            data.reporting_form_id
                                          }/${data.service_id || 0}/${
                                            data.project_id || 0
                                          }/${data.work_permit_use_id || 0}/${
                                            data.timeline_id || 0
                                          }/${
                                            formTypeProps === 12
                                              ? data.elearningId
                                              : 0
                                          }/0`}
                                        >
                                          {data.title}
                                        </Link>
                                      ) : props.screen == 6 &&
                                        (formTypeProps == 8 ||
                                          formTypeProps == 11 ||
                                          formTypeProps == 13) ? (
                                        <Link
                                          to={`/submit-form-listing/${formTypeProps}/${props.activeTab}/published-view/${data.reporting_form_id}`}
                                        >
                                          {data.title}
                                        </Link>
                                      ) : props.screen == 6 &&
                                        props.activeTab === "scheduled" &&
                                        (formTypeProps != 8 ||
                                          formTypeProps != 11 ||
                                          formTypeProps != 13 ||
                                          formTypeProps != 10) ? (
                                        <Link
                                          to={`/submit-form-listing/${formTypeProps}/${
                                            props.activeTab
                                          }/user-form-view/${
                                            data.reporting_form_id
                                          }/${data.service_id || 0}/${
                                            data.project_id || 0
                                          }/${data.work_permit_use_id || 0}/${
                                            data.timeline_id || 0
                                          }/${
                                            formTypeProps === 12
                                              ? data.elearningId
                                              : 0
                                          }/0`}
                                        >
                                          {data.title}
                                        </Link>
                                      ) : (
                                        <Link
                                          to={`/submit-form-listing/${formTypeProps}/${
                                            props.activeTab
                                          }/view-form/${
                                            data.reporting_form_id
                                          }/${data.service_id || 0}/${
                                            data.project_id || 0
                                          }/${data.work_permit_use_id || 0}/${
                                            data.timeline_id || 0
                                          }/${data.survey_use_id || 0}/${
                                            formTypeProps === 12
                                              ? data.elearningId
                                              : 0
                                          }/0/0`}
                                        >
                                          {data.title}
                                        </Link>
                                      )

                                      // (
                                      //   <Link
                                      //     to={`/submit-form-listing/${formTypeProps}/${props.activeTab
                                      //       }/view-form/${data.reporting_form_id}/${data.service_id || 0
                                      //       }/${data.project_id || 0}/${data.work_permit_use_id || 0
                                      //       }/${data.timeline_id || 0}/${data.survey_use_id || 0}/${formTypeProps === 12 ? data.elearningId : 0}/0/0`}
                                      //   >
                                      //     {data.title}
                                      //   </Link>
                                      // )
                                    }
                                  </td>

                                  {formTypeProps != 7 && formTypeProps != 6 && (
                                    <td>
                                      <span className="btn clide-v2-border-primary-color btn-sm text-center w-100">
                                        {data.category}
                                      </span>
                                    </td>
                                  )}

                                  {/* <td>{convertDateFromTimezone(data.start_date)}</td>
                  <td>{convertDateFromTimezone(data.end_date)}</td> */}

                                  <td style={{ width: "100px" }}>
                                    {data.start_date}
                                  </td>
                                  <td style={{ width: "100px" }}>
                                    {data.end_date}
                                  </td>

                                  {!(props.screen == 6) &&
                                  (formTypeProps == 8 ||
                                    formTypeProps == 11 ||
                                    formTypeProps == 13) ? (
                                    <td>
                                      {!!totalResponse &&
                                        totalResponse
                                          .filter(
                                            (e) =>
                                              e.survey_use_id ===
                                              data.survey_use_id
                                          )
                                          .map((item) => item.response_count)}
                                    </td>
                                  ) : null}

                                  {!(props.screen == 1 && formTypeProps != 5) &&
                                    formTypeProps != 8 &&
                                    formTypeProps != 10 &&
                                    formTypeProps != 11 &&
                                    formTypeProps != 13 && (
                                      <td>{`${data.duration}`}</td>
                                    )}

                                  {(formTypeProps == 8 ||
                                    formTypeProps == 11 ||
                                    formTypeProps == 13) && (
                                    <td>{`${data.time_duration}`}</td>
                                  )}

                                  {(formTypeProps == 8 ||
                                    formTypeProps == 11 ||
                                    formTypeProps == 13) && (
                                    <td className="profile-flex">
                                      {/* {`${data.user_name}`} */}

                                      <React.Fragment>
                                        <div
                                          className="image-box"
                                          style={{ marginRight: "0.5vw" }}
                                        >
                                          <img
                                            className="clide-v2-profile-img"
                                            src={data.pic_link}
                                            alt="user-pic"
                                            data-tip
                                            data-for={`user-name-${data.user_id}-user-2`}
                                          />
                                        </div>
                                        <ReactTooltip
                                          effect="solid"
                                          place="right"
                                          id={`user-name-${data.user_id}-user-2`}
                                        >
                                          <span>{data.user_name}</span>
                                        </ReactTooltip>
                                      </React.Fragment>
                                    </td>
                                  )}
                                  {(formTypeProps == 8 ||
                                    formTypeProps == 11 ||
                                    formTypeProps == 13) && (
                                    <td className="profile-flex">
                                      {/* {`${data.user_name}`} */}

                                      <React.Fragment>
                                        <div
                                          className="clide-v2-profile-img"
                                          style={{ marginRight: "0.5vw" }}
                                        >
                                          <img
                                            src={data.pic_link}
                                            alt="user-pic"
                                            data-tip
                                            data-for={`user-name-${data.user_id}-user-2`}
                                          />
                                        </div>
                                        <ReactTooltip
                                          effect="solid"
                                          place="right"
                                          id={`user-name-${data.user_id}-user-2`}
                                        >
                                          <span>{data.user_name}</span>
                                        </ReactTooltip>
                                      </React.Fragment>
                                    </td>
                                  )}

                                  {props.screen == 1 && formTypeProps == 12 && (
                                    <td>{`${data.time_duration} days`}</td>
                                  )}

                                  {props.screen == 1 &&
                                    formTypeProps != 5 &&
                                    formTypeProps != 10 && (
                                      <td>{data.remaining_period}</td>
                                    )}
                                  {props.screen == 4 && (
                                    <td>{data.survey_link || 0}</td>
                                  )}

                                  {!(
                                    props.screen == 4 ||
                                    props.screen == 5 ||
                                    (props.screen == 6 &&
                                      (formTypeProps == 8 ||
                                        formTypeProps == 11 ||
                                        formTypeProps == 13)) ||
                                    formTypeProps == 12 ||
                                    formTypeProps == 10
                                  ) && (
                                    <td className="profile-flex">
                                      {data.approvalLevelList?.map(
                                        (user, idx) => (
                                          <React.Fragment
                                            key={`user-name-${idx}`}
                                          >
                                            <div
                                              className={
                                                user.approve_decline_status > 0
                                                  ? user.approve_decline_status ==
                                                    2
                                                    ? "image-box-blur-decline"
                                                    : "image-box-blur-approve"
                                                  : "image-box"
                                              }
                                              style={{ marginRight: "0.5vw" }}
                                            >
                                              {/* <div className="image_grid" > */}

                                              {user.level_id > 0 && (
                                                <img
                                                  className="clide-v2-profile-img"
                                                  src={user.pic_link}
                                                  alt=""
                                                  data-tip
                                                  // data-for={`user-name-${idx}`}

                                                  data-for={`user-name-${user.level_id}`}
                                                />
                                              )}
                                              {/* </div> */}
                                            </div>
                                            {user.level_id > 0 && (
                                              <ReactTooltip
                                                //  id={`user-name-${idx}`}
                                                id={`user-name-${user.level_id}`}
                                                effect="solid"
                                                place="left"
                                              >
                                                <>
                                                  <span>
                                                    {user.level}
                                                    {" | "}
                                                    {user.user_name}
                                                  </span>
                                                </>
                                              </ReactTooltip>
                                            )}
                                          </React.Fragment>
                                        )
                                      )}
                                    </td>
                                  )}

                                  {formTypeProps == 10 && (
                                    <td className="profile-flex">
                                      <React.Fragment>
                                        <div
                                          className="clide-v2-profile-img"
                                          style={{ marginRight: "0.5vw" }}
                                        >
                                          <img
                                            src={data.assigned_contractor_pic}
                                            alt="assigned user"
                                            data-tip
                                            data-for={`user-name-${data.assigned_contractor_id}`}
                                          />
                                        </div>
                                        <ReactTooltip
                                          effect="solid"
                                          place="right"
                                          id={`user-name-${data.assigned_contractor_id}`}
                                        >
                                          <>
                                            <span>
                                              {data.assigned_contractor_name}
                                            </span>
                                          </>
                                        </ReactTooltip>
                                      </React.Fragment>
                                    </td>
                                  )}
                                  {props.screen == 2 && (
                                    <>
                                      <td>{data.decline_reason}</td>
                                      <td className="cursor-pointer">
                                        <Popup
                                          trigger={
                                            <button
                                              style={{
                                                border: "none",
                                                background: "none",
                                              }}
                                            >
                                              {" "}
                                              <img
                                                src={require("assets/sls-icons/V2. New Development/Comment.svg")}
                                                className="svg-icons "
                                              />
                                            </button>
                                          }
                                          position="bottom"
                                        >
                                          <div style={{ textAlign: "center" }}>
                                            {data.decline_remark}
                                          </div>
                                        </Popup>
                                      </td>
                                    </>
                                  )}
                                  {props.screen == 3 && formTypeProps == 5 && (
                                    <>
                                      <td
                                        style={selectColor(data.closing_reason)}
                                      >
                                        {data.closing_reason}
                                      </td>
                                      <td className="cursor-pointer">
                                        {data.closing_remark ? (
                                          <Popup
                                            trigger={
                                              <button
                                                style={{
                                                  border: "none",
                                                  background: "none",
                                                }}
                                              >
                                                {" "}
                                                <img
                                                  src={require("assets/sls-icons/V2. New Development/Comment.svg")}
                                                  className="svg-icons "
                                                />
                                              </button>
                                            }
                                            position="bottom"
                                          >
                                            <div
                                              style={{ textAlign: "center" }}
                                            >
                                              {data.closing_remark}
                                            </div>
                                          </Popup>
                                        ) : (
                                          "NA"
                                        )}
                                      </td>
                                    </>
                                  )}
                                  {props.screen == 0 &&
                                    (data.approved == 1 ? (
                                      <td
                                        style={{
                                          backgroundColor: "#6ECB63",
                                          color: "white",
                                        }}
                                      >
                                        Approved
                                      </td>
                                    ) : data.approve_decline_status == 1 ? (
                                      <td
                                        style={{ padding: 0 }}
                                        className="cursor-pointer"
                                      >
                                        <div
                                          onClick={() => {
                                            handleLevelApproval(
                                              data.work_permit_use_id
                                            );
                                          }}
                                          style={{
                                            color: "tomato",
                                            backgroundColor: "#ECE7B4",
                                          }}
                                        >
                                          Approve
                                        </div>
                                        {formTypeProps == 5 && (
                                          <div
                                            onClick={() => {
                                              _handleDeclineModal(
                                                data.work_permit_use_id
                                              );
                                            }}
                                            style={{
                                              color: "white",
                                              backgroundColor: "red",
                                            }}
                                          >
                                            Decline
                                          </div>
                                        )}
                                      </td>
                                    ) : (
                                      <td>Approval Pending</td>
                                    ))}
                                  {props.screen == 6 && (
                                    <td>
                                      {data.delete_right == 1 && (
                                        <svg
                                          width="33"
                                          height="33"
                                          viewBox="0 0 33 33"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          onClick={() =>
                                            showModal(
                                              data.work_permit_use_id ||
                                                data.survey_use_id
                                            )
                                          }
                                        >
                                          <circle
                                            cx="16.5488"
                                            cy="16.376"
                                            r="15.5"
                                            fill="white"
                                            stroke="#FF6347"
                                          />
                                          <path
                                            d="M23.6325 11.376H9.46582"
                                            stroke="#F56620"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                          />
                                          <path
                                            d="M11.9658 11.3751H12.0575C12.3929 11.3666 12.7178 11.257 12.9899 11.0608C13.262 10.8646 13.4685 10.5909 13.5825 10.2755L13.6108 10.1897L13.6917 9.94728C13.7608 9.73985 13.7958 9.63656 13.8417 9.54825C13.9318 9.3753 14.0612 9.22585 14.2196 9.11186C14.3779 8.99787 14.5607 8.92251 14.7533 8.8918C14.8508 8.87598 14.96 8.87598 15.1783 8.87598H17.92C18.1383 8.87598 18.2475 8.87598 18.345 8.8918C18.5377 8.92251 18.7205 8.99787 18.8788 9.11186C19.0371 9.22585 19.1665 9.3753 19.2567 9.54825C19.3025 9.63656 19.3375 9.73985 19.4067 9.94728L19.4875 10.1897C19.5931 10.5406 19.8115 10.847 20.1089 11.0613C20.4063 11.2756 20.766 11.3859 21.1325 11.3751"
                                            stroke="#F56620"
                                            stroke-width="1.5"
                                          />
                                          <path
                                            d="M21.8613 19.209C21.7138 21.4207 21.6396 22.5265 20.9188 23.2007C20.198 23.8757 19.0896 23.8757 16.8721 23.8757H16.2271C14.0105 23.8757 12.9021 23.8757 12.1805 23.2007C11.4596 22.5265 11.3863 21.4207 11.2388 19.209L10.8555 13.459M22.2438 13.459L22.0771 15.959"
                                            stroke="#F56620"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                          />
                                        </svg>
                                      )}
                                    </td>
                                  )}
                                  {props.screen == 1 &&
                                  formTypeProps == 5 &&
                                  data.edit_submit_right ? (
                                    <td
                                      style={{ padding: 0 }}
                                      className="cursor-pointer"
                                    >
                                      <div
                                        onClick={() => {
                                          _handleExtendModal(
                                            data.work_permit_use_id,
                                            data.start_date,
                                            data.end_date,
                                            data.time_duration
                                          );
                                        }}
                                        style={{
                                          color: "white",
                                          backgroundColor: "#6ECB63",
                                        }}
                                      >
                                        Extend
                                      </div>
                                      <div
                                        onClick={() => {
                                          _handleCloseModal(
                                            data.work_permit_use_id
                                          );
                                        }}
                                        style={{
                                          color: "white",
                                          backgroundColor: "red",
                                        }}
                                      >
                                        Close
                                      </div>
                                    </td>
                                  ) : null}
                                  {formTypeProps != 5 &&
                                    (props.screen == 3 ||
                                      props.screen == 1 ||
                                      props.screen == 0) &&
                                    data.total_score > 0 && (
                                      <>
                                        <td>{`${data.obtained_score}/${data.total_score}`}</td>
                                        {/* <td>Progress</td> */}
                                      </>
                                      // :
                                      // <td>NA</td>
                                    )}
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </InfiniteScroll>
            </>
          ) : null}
          {currentTab?.key === "apr" ? (
            <>
              <InfiniteScroll
                dataLength={approvalList.length}
                // next={selectedCategory ? (formTypeProps == 8 || formTypeProps == 11 || formTypeProps == 13) ? surveyFilter : fetchCategoryData : fetchSurveyDataOnScroll }
                next={null}
                hasMore={false}
              >
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12 mb-2">
                    <div className="clide-v2-card-box p-3 pb-0 h-100">
                      <div className="clide-v2-scroll-bar pe-3 clide-v2-p-0" style={{height:"79vw"}}>
                        <table
                          id="example5"
                          className="table table-striped nowrap"
                          style={{ width: "100%" }}
                        >
                          <thead>
                            <tr>
                              <th
                                className="text-center"
                                style={{ width: "70px" }}
                              >
                                #
                              </th>
                              <th className="text-center">
                                {typeList[formTypeProps - 1].form_type}
                              </th>
                              {formTypeProps != 7 && formTypeProps != 6 && (
                                <th
                                  style={{ width: "50px" }}
                                  className="text-center"
                                >
                                  <div style={{ marginTop: "1vw" }}>
                                    <FormTypeDropDown
                                      // title="Do's & Dont's / Training / Policy"
                                      title="Category"
                                      isRequired={false}
                                      optionList={categoryList}
                                      onChangeHandle={_applyFilter}
                                      value={selectedCategory}
                                    />
                                  </div>
                                </th>
                              )}
                              <th
                                className="justify-content-center"
                                style={{ width: "130px" }}
                              >
                                Start Date{" "}
                              </th>
                              <th
                                className="justify-content-center"
                                style={{ width: "130px" }}
                              >
                                End Date{" "}
                              </th>
                              {!(
                                props.screen == 6 ||
                                props.screen == 1 ||
                                props.screen == 0 ||
                                props.screen == 3 ||
                                formTypeProps == 5
                              ) ? (
                                <th className="text-center">Response</th>
                              ) : null}
                              {!(props.screen == 1 && formTypeProps != 5) &&
                                formTypeProps != 10 && (
                                  <th className="justify-content-center">
                                    Duration
                                  </th>
                                )}
                              {(formTypeProps == 8 ||
                                formTypeProps == 11 ||
                                formTypeProps == 13) && (
                                <th className="text-center">Owner</th>
                              )}
                              {props.screen == 1 &&
                                formTypeProps != 5 &&
                                formTypeProps != 10 && (
                                  <th className="text-center">Time</th>
                                )}
                              {props.screen == 4 && (
                                <th
                                  className="text-center"
                                  style={{ width: "12vw" }}
                                >
                                  Link
                                </th>
                              )}
                              {!(
                                props.screen == 4 ||
                                props.screen == 5 ||
                                formTypeProps == 8 ||
                                formTypeProps == 11 ||
                                formTypeProps == 13 ||
                                formTypeProps == 12 ||
                                formTypeProps == 10
                              ) && (
                                <th
                                  className="justify-content-center"
                                  style={{ width: "12vw" }}
                                >
                                  Approval Level
                                </th>
                              )}
                              {formTypeProps == 10 && (
                                <th className="text-center">Assigned User</th>
                              )}
                              {props.screen == 6 ? (
                                <th className="justify-content-center">
                                  Action
                                </th>
                              ) : null}
                              {formTypeProps == 5 && props.screen == 1 ? (
                                <th className=" justify-content-center">
                                  Action
                                </th>
                              ) : null}
                              {props.screen == 2 && (
                                <th className="text-center">Decline Remark</th>
                              )}
                              {props.screen == 2 && formTypeProps == 5 && (
                                <th className="text-center">Comment</th>
                              )}
                              {props.screen == 3 && formTypeProps == 5 && (
                                <th className="text-center">Closing Remark</th>
                              )}
                              {formTypeProps == 5 && props.screen == 3 ? (
                                <th className="text-center">Comment</th>
                              ) : null}
                              {props.screen == 0 && (
                                <>
                                  <th className="text-center">Status</th>
                                </>
                              )}
                              {(props.screen == 0 ||
                                props.screen == 1 ||
                                props.screen == 3) &&
                                formTypeProps != 5 &&
                                formTypeProps != 12 && (
                                  <>
                                    {/* <th>Score</th> */}
                                    <th
                                      className="text-center"
                                      style={{ width: "12vw" }}
                                    >
                                      Score
                                    </th>
                                  </>
                                )}
                            </tr>
                          </thead>
                          <tbody>
                            {approvalList &&
                            (approvalList.length === 0 ||
                              approvalList === undefined) ? (
                              <tr>
                                <td colSpan="6">
                                  <p className="list-error">
                                    Data not available
                                  </p>
                                </td>
                              </tr>
                            ) : (
                              approvalList &&
                              approvalList?.map((data, index) => (
                                <tr
                                  key={`${index}-${data.reporting_form_id}`}
                                  style={{
                                    borderBottom: "5px solid #f5f5f5",
                                    backgroundColor:
                                      data.is_extended == 1
                                        ? "#D9D7F1"
                                        : "none",
                                  }}
                                >
                                  {!(
                                    formTypeProps == 8 ||
                                    formTypeProps == 11 ||
                                    formTypeProps == 13
                                  ) ? (
                                    <td className="profile-flex">
                                      <React.Fragment>
                                        <div
                                          className="clide-v2-profile-img"
                                          style={{ marginRight: "0.5vw" }}
                                        >
                                          <img
                                            className="clide-v2-profile-img"
                                            src={data.pic_link}
                                            alt=""
                                            data-tip
                                            data-for={`user-name-${data.user_id}-user`}
                                          />
                                        </div>
                                        <ReactTooltip
                                          effect="solid"
                                          place="right"
                                          id={`user-name-${data.user_id}-user`}
                                        >
                                          <>
                                            <span>{data.user_name}</span>
                                          </>
                                        </ReactTooltip>
                                      </React.Fragment>
                                    </td>
                                  ) : (
                                    <td>
                                      {data.file_name != null ? (
                                        <img
                                          className="clide-v2-profile-img"
                                          src={data.file_name}
                                        />
                                      ) : null}
                                    </td>
                                  )}

                                  <td className="btn clide-v2-border-primary-color btn-sm text-center w-100">
                                    {
                                      props.screen == 1 &&
                                      formTypeProps == 5 ? (
                                        <Link
                                          to={`/submit-form-listing/${formTypeProps}/${
                                            props.activeTab
                                          }/view-form/${
                                            data.reporting_form_id
                                          }/${data.service_id || 0}/${
                                            data.project_id || 0
                                          }/${data.work_permit_use_id || 0}/${
                                            data.timeline_id || 0
                                          }/${data.survey_use_id || 0}/${
                                            formTypeProps === 12
                                              ? data.elearningId
                                              : 0
                                          }/0/0`}
                                        >
                                          {data.title}
                                        </Link>
                                      ) : (props.screen == 6 ||
                                          props.screen == 1) &&
                                        (data.edit_submit_right == 1 ||
                                          (props.activeTab === "scheduled" &&
                                            formTypeProps == 10)) ? (
                                        <Link
                                          to={`/submit-form-listing/${formTypeProps}/${
                                            props.activeTab
                                          }/user-form-view/${
                                            data.reporting_form_id
                                          }/${data.service_id || 0}/${
                                            data.project_id || 0
                                          }/${data.work_permit_use_id || 0}/${
                                            data.timeline_id || 0
                                          }/${
                                            formTypeProps === 12
                                              ? data.elearningId
                                              : 0
                                          }/0`}
                                        >
                                          {data.title}
                                        </Link>
                                      ) : props.screen == 6 &&
                                        (formTypeProps == 8 ||
                                          formTypeProps == 11 ||
                                          formTypeProps == 13) ? (
                                        <Link
                                          to={`/submit-form-listing/${formTypeProps}/${props.activeTab}/published-view/${data.reporting_form_id}`}
                                        >
                                          {data.title}
                                        </Link>
                                      ) : props.screen == 6 &&
                                        props.activeTab === "scheduled" &&
                                        (formTypeProps != 8 ||
                                          formTypeProps != 11 ||
                                          formTypeProps != 13 ||
                                          formTypeProps != 10) ? (
                                        <Link
                                          to={`/submit-form-listing/${formTypeProps}/${
                                            props.activeTab
                                          }/user-form-view/${
                                            data.reporting_form_id
                                          }/${data.service_id || 0}/${
                                            data.project_id || 0
                                          }/${data.work_permit_use_id || 0}/${
                                            data.timeline_id || 0
                                          }/${
                                            formTypeProps === 12
                                              ? data.elearningId
                                              : 0
                                          }/0`}
                                        >
                                          {data.title}
                                        </Link>
                                      ) : (
                                        <Link
                                          to={`/submit-form-listing/${formTypeProps}/${
                                            props.activeTab
                                          }/view-form/${
                                            data.reporting_form_id
                                          }/${data.service_id || 0}/${
                                            data.project_id || 0
                                          }/${data.work_permit_use_id || 0}/${
                                            data.timeline_id || 0
                                          }/${data.survey_use_id || 0}/${
                                            formTypeProps === 12
                                              ? data.elearningId
                                              : 0
                                          }/0/0`}
                                        >
                                          {data.title}
                                        </Link>
                                      )

                                      // (
                                      //   <Link
                                      //     to={`/submit-form-listing/${formTypeProps}/${props.activeTab
                                      //       }/view-form/${data.reporting_form_id}/${data.service_id || 0
                                      //       }/${data.project_id || 0}/${data.work_permit_use_id || 0
                                      //       }/${data.timeline_id || 0}/${data.survey_use_id || 0}/${formTypeProps === 12 ? data.elearningId : 0}/0/0`}
                                      //   >
                                      //     {data.title}
                                      //   </Link>
                                      // )
                                    }
                                  </td>

                                  {formTypeProps != 7 && formTypeProps != 6 && (
                                    <td>
                                      <span className="btn clide-v2-border-primary-color btn-sm text-center w-100">
                                        {data.category}
                                      </span>
                                    </td>
                                  )}

                                  {/* <td>{convertDateFromTimezone(data.start_date)}</td>
                  <td>{convertDateFromTimezone(data.end_date)}</td> */}

                                  <td style={{ width: "100px" }}>
                                    {data.start_date}
                                  </td>
                                  <td style={{ width: "100px" }}>
                                    {data.end_date}
                                  </td>

                                  {!(props.screen == 6) &&
                                  (formTypeProps == 8 ||
                                    formTypeProps == 11 ||
                                    formTypeProps == 13) ? (
                                    <td>
                                      {!!totalResponse &&
                                        totalResponse
                                          .filter(
                                            (e) =>
                                              e.survey_use_id ===
                                              data.survey_use_id
                                          )
                                          .map((item) => item.response_count)}
                                    </td>
                                  ) : null}

                                  {!(props.screen == 1 && formTypeProps != 5) &&
                                    formTypeProps != 8 &&
                                    formTypeProps != 10 &&
                                    formTypeProps != 11 &&
                                    formTypeProps != 13 && (
                                      <td>{`${data.duration}`}</td>
                                    )}

                                  {(formTypeProps == 8 ||
                                    formTypeProps == 11 ||
                                    formTypeProps == 13) && (
                                    <td>{`${data.time_duration}`}</td>
                                  )}

                                  {(formTypeProps == 8 ||
                                    formTypeProps == 11 ||
                                    formTypeProps == 13) && (
                                    <td className="profile-flex">
                                      {/* {`${data.user_name}`} */}

                                      <React.Fragment>
                                        <div
                                          className="clide-v2-profile-img"
                                          style={{ marginRight: "0.5vw" }}
                                        >
                                          <img
                                            className="clide-v2-profile-img"
                                            src={data.pic_link}
                                            alt="user-pic"
                                            data-tip
                                            data-for={`user-name-${data.user_id}-user-2`}
                                          />
                                        </div>
                                        <ReactTooltip
                                          effect="solid"
                                          place="right"
                                          id={`user-name-${data.user_id}-user-2`}
                                        >
                                          <span>{data.user_name}</span>
                                        </ReactTooltip>
                                      </React.Fragment>
                                    </td>
                                  )}

                                  {props.screen == 1 && formTypeProps == 12 && (
                                    <td>{`${data.time_duration} days`}</td>
                                  )}

                                  {props.screen == 1 &&
                                    formTypeProps != 5 &&
                                    formTypeProps != 10 && (
                                      <td>{data.remaining_period}</td>
                                    )}
                                  {props.screen == 4 && (
                                    <td>{data.survey_link || 0}</td>
                                  )}

                                  {!(
                                    props.screen == 4 ||
                                    props.screen == 5 ||
                                    (props.screen == 6 &&
                                      (formTypeProps == 8 ||
                                        formTypeProps == 11 ||
                                        formTypeProps == 13)) ||
                                    formTypeProps == 12 ||
                                    formTypeProps == 10
                                  ) && (
                                    <td className="profile-flex">
                                      {data.approvalLevelList?.map(
                                        (user, idx) => (
                                          <React.Fragment
                                            key={`user-name-${idx}`}
                                          >
                                            <div
                                              className={
                                                user.approve_decline_status > 0
                                                  ? user.approve_decline_status ==
                                                    2
                                                    ? "image-box-blur-decline"
                                                    : "image-box-blur-approve"
                                                  : "image-box"
                                              }
                                              style={{ marginRight: "0.5vw" }}
                                            >
                                              {/* <div className="image_grid" > */}

                                              {user.level_id > 0 && (
                                                <img
                                                  className="clide-v2-profile-img"
                                                  src={user.pic_link}
                                                  alt=""
                                                  data-tip
                                                  // data-for={`user-name-${idx}`}

                                                  data-for={`user-name-${user.level_id}`}
                                                />
                                              )}
                                              {/* </div> */}
                                            </div>
                                            {user.level_id > 0 && (
                                              <ReactTooltip
                                                //  id={`user-name-${idx}`}
                                                id={`user-name-${user.level_id}`}
                                                effect="solid"
                                                place="left"
                                              >
                                                <>
                                                  <span>
                                                    {user.level}
                                                    {" | "}
                                                    {user.user_name}
                                                  </span>
                                                </>
                                              </ReactTooltip>
                                            )}
                                          </React.Fragment>
                                        )
                                      )}
                                    </td>
                                  )}

                                  {formTypeProps == 10 && (
                                    <td className="profile-flex">
                                      <React.Fragment>
                                        <div
                                          className={"image-box"}
                                          style={{ marginRight: "0.5vw" }}
                                        >
                                          <img
                                            src={data.assigned_contractor_pic}
                                            alt="assigned user"
                                            data-tip
                                            data-for={`user-name-${data.assigned_contractor_id}`}
                                          />
                                        </div>
                                        <ReactTooltip
                                          effect="solid"
                                          place="right"
                                          id={`user-name-${data.assigned_contractor_id}`}
                                        >
                                          <>
                                            <span>
                                              {data.assigned_contractor_name}
                                            </span>
                                          </>
                                        </ReactTooltip>
                                      </React.Fragment>
                                    </td>
                                  )}
                                  {props.screen == 2 && (
                                    <>
                                      <td>{data.decline_reason}</td>
                                      <td className="cursor-pointer">
                                        <Popup
                                          trigger={
                                            <button
                                              style={{
                                                border: "none",
                                                background: "none",
                                              }}
                                            >
                                              {" "}
                                              <img
                                                src={require("assets/sls-icons/V2. New Development/Comment.svg")}
                                                className="svg-icons "
                                              />
                                            </button>
                                          }
                                          position="bottom"
                                        >
                                          <div style={{ textAlign: "center" }}>
                                            {data.decline_remark}
                                          </div>
                                        </Popup>
                                      </td>
                                    </>
                                  )}
                                  {props.screen == 3 && formTypeProps == 5 && (
                                    <>
                                      <td
                                        style={selectColor(data.closing_reason)}
                                      >
                                        {data.closing_reason}
                                      </td>
                                      <td className="cursor-pointer">
                                        {data.closing_remark ? (
                                          <Popup
                                            trigger={
                                              <button
                                                style={{
                                                  border: "none",
                                                  background: "none",
                                                }}
                                              >
                                                {" "}
                                                <img
                                                  src={require("assets/sls-icons/V2. New Development/Comment.svg")}
                                                  className="svg-icons "
                                                />
                                              </button>
                                            }
                                            position="bottom"
                                          >
                                            <div
                                              style={{ textAlign: "center" }}
                                            >
                                              {data.closing_remark}
                                            </div>
                                          </Popup>
                                        ) : (
                                          "NA"
                                        )}
                                      </td>
                                    </>
                                  )}
                                  {props.screen == 0 &&
                                    (data.approved == 1 ? (
                                      <td
                                        style={{
                                          backgroundColor: "#6ECB63",
                                          color: "white",
                                        }}
                                      >
                                        Approved
                                      </td>
                                    ) : data.approve_decline_status == 1 ? (
                                      <td
                                        style={{ padding: 0 }}
                                        className="cursor-pointer"
                                      >
                                        <div
                                          onClick={() => {
                                            handleLevelApproval(
                                              data.work_permit_use_id
                                            );
                                          }}
                                          style={{
                                            color: "tomato",
                                            backgroundColor: "#ECE7B4",
                                          }}
                                        >
                                          Approve
                                        </div>
                                        {formTypeProps == 5 && (
                                          <div
                                            onClick={() => {
                                              _handleDeclineModal(
                                                data.work_permit_use_id
                                              );
                                            }}
                                            style={{
                                              color: "white",
                                              backgroundColor: "red",
                                            }}
                                          >
                                            Decline
                                          </div>
                                        )}
                                      </td>
                                    ) : (
                                      <td>Approval Pending</td>
                                    ))}
                                  {props.screen == 6 && (
                                    <td>
                                      {data.delete_right == 1 && (
                                        <svg
                                          width="33"
                                          height="33"
                                          viewBox="0 0 33 33"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          onClick={() =>
                                            showModal(
                                              data.work_permit_use_id ||
                                                data.survey_use_id
                                            )
                                          }
                                        >
                                          <circle
                                            cx="16.5488"
                                            cy="16.376"
                                            r="15.5"
                                            fill="white"
                                            stroke="#FF6347"
                                          />
                                          <path
                                            d="M23.6325 11.376H9.46582"
                                            stroke="#F56620"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                          />
                                          <path
                                            d="M11.9658 11.3751H12.0575C12.3929 11.3666 12.7178 11.257 12.9899 11.0608C13.262 10.8646 13.4685 10.5909 13.5825 10.2755L13.6108 10.1897L13.6917 9.94728C13.7608 9.73985 13.7958 9.63656 13.8417 9.54825C13.9318 9.3753 14.0612 9.22585 14.2196 9.11186C14.3779 8.99787 14.5607 8.92251 14.7533 8.8918C14.8508 8.87598 14.96 8.87598 15.1783 8.87598H17.92C18.1383 8.87598 18.2475 8.87598 18.345 8.8918C18.5377 8.92251 18.7205 8.99787 18.8788 9.11186C19.0371 9.22585 19.1665 9.3753 19.2567 9.54825C19.3025 9.63656 19.3375 9.73985 19.4067 9.94728L19.4875 10.1897C19.5931 10.5406 19.8115 10.847 20.1089 11.0613C20.4063 11.2756 20.766 11.3859 21.1325 11.3751"
                                            stroke="#F56620"
                                            stroke-width="1.5"
                                          />
                                          <path
                                            d="M21.8613 19.209C21.7138 21.4207 21.6396 22.5265 20.9188 23.2007C20.198 23.8757 19.0896 23.8757 16.8721 23.8757H16.2271C14.0105 23.8757 12.9021 23.8757 12.1805 23.2007C11.4596 22.5265 11.3863 21.4207 11.2388 19.209L10.8555 13.459M22.2438 13.459L22.0771 15.959"
                                            stroke="#F56620"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                          />
                                        </svg>
                                      )}
                                    </td>
                                  )}
                                  {props.screen == 1 &&
                                  formTypeProps == 5 &&
                                  data.edit_submit_right ? (
                                    <td
                                      style={{ padding: 0 }}
                                      className="cursor-pointer"
                                    >
                                      <div
                                        onClick={() => {
                                          _handleExtendModal(
                                            data.work_permit_use_id,
                                            data.start_date,
                                            data.end_date,
                                            data.time_duration
                                          );
                                        }}
                                        style={{
                                          color: "white",
                                          backgroundColor: "#6ECB63",
                                        }}
                                      >
                                        Extend
                                      </div>
                                      <div
                                        onClick={() => {
                                          _handleCloseModal(
                                            data.work_permit_use_id
                                          );
                                        }}
                                        style={{
                                          color: "white",
                                          backgroundColor: "red",
                                        }}
                                      >
                                        Close
                                      </div>
                                    </td>
                                  ) : null}
                                  {formTypeProps != 5 &&
                                    (props.screen == 3 ||
                                      props.screen == 1 ||
                                      props.screen == 0) &&
                                    data.total_score > 0 && (
                                      <>
                                        <td>{`${data.obtained_score}/${data.total_score}`}</td>
                                        {/* <td>Progress</td> */}
                                      </>
                                      // :
                                      // <td>NA</td>
                                    )}
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </InfiniteScroll>
            </>
          ) : null}
          {props.screen != 6 ? (
            <InfiniteScroll
              dataLength={tableData.length}
              // next={selectedCategory ? (formTypeProps == 8 || formTypeProps == 11 || formTypeProps == 13) ? surveyFilter : fetchCategoryData : fetchSurveyDataOnScroll }
              next={
                selectedCategory
                  ? (formTypeProps == 8 ||
                      formTypeProps == 11 ||
                      formTypeProps == 13) &&
                    surveyFilter
                  : surveyList != props.formTypeCount && fetchSurveyDataOnScroll
              }
              hasMore={
                tableData.length !==
                (selectedCategory ? totalCategoryCount : props.formTypeCount)
              }
            >
              <div className="tab-content p-0" id="nav-tabContent">
                <div
                  className="tab-pane fade active show"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 mb-2">
                      <div className="clide-v2-card-box p-3 pb-0 h-100">
                        <div className="clide-v2-scroll-bar pe-3 clide-v2-p-0" style={{height:"79vw"}}>
                          <table
                            id="example"
                            className="table table-striped nowrap"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              <tr>
                                <th
                                  className="text-center"
                                  style={{ width: "10px" }}
                                >
                                  #
                                </th>
                                <th
                                  className="text-center"
                                  style={{ width: "250px" }}
                                >
                                  {typeList[formTypeProps - 1].form_type}
                                </th>
                                {formTypeProps != 7 && formTypeProps != 6 && (
                                  <th
                                    className="text-center"
                                    style={{ width: "250px" }}
                                  >
                                    <FormTypeDropDown
                                      // title="Do's & Dont's / Training / Policy"
                                      title="Category"
                                      isRequired={false}
                                      optionList={categoryList}
                                      onChangeHandle={_applyFilter}
                                      value={selectedCategory}
                                    />
                                  </th>
                                )}
                                <th
                                  className="text-center"
                                  style={{ width: "130px" }}
                                >
                                  Start Date
                                </th>
                                <th
                                  className="text-center"
                                  style={{ width: "130px" }}
                                >
                                  End Date
                                </th>
                                {!(
                                  props.screen == 6 ||
                                  props.screen == 1 ||
                                  props.screen == 0 ||
                                  props.screen == 3 ||
                                  formTypeProps == 5
                                ) ? (
                                  <th
                                    className="text-center"
                                    style={{ width: "130px" }}
                                  >
                                    Response
                                  </th>
                                ) : null}
                                {!(props.screen == 1 && formTypeProps != 5) &&
                                  formTypeProps != 10 && (
                                    <th
                                      className="text-center"
                                      style={{ width: "130px" }}
                                    >
                                      Duration
                                    </th>
                                  )}
                                <th
                                  className="text-center"
                                  style={{ width: "130px" }}
                                >
                                  Owner / Assigned
                                </th>

                                {(formTypeProps == 8 ||
                                  formTypeProps == 11 ||
                                  formTypeProps == 13) && (
                                  <th className="text-center">Owner</th>
                                )}

                                {props.screen == 1 &&
                                  formTypeProps != 5 &&
                                  formTypeProps != 10 && (
                                    <th
                                      className="text-center"
                                      style={{ width: "130px" }}
                                    >
                                      Time
                                    </th>
                                  )}
                                {props.screen == 4 && (
                                  <th
                                    className="text-center"
                                    style={{ width: "130px" }}
                                  >
                                    Link
                                  </th>
                                )}
                                {!(
                                  props.screen == 4 ||
                                  props.screen == 5 ||
                                  formTypeProps == 8 ||
                                  formTypeProps == 11 ||
                                  formTypeProps == 13 ||
                                  formTypeProps == 12 ||
                                  formTypeProps == 10
                                ) && (
                                  <th
                                    className="text-center justify-content-center"
                                    style={{ width: "130px" }}
                                  >
                                    Approval Level
                                  </th>
                                )}
                                {formTypeProps == 10 && (
                                  <th
                                    className="text-center"
                                    style={{ width: "130px" }}
                                  >
                                    Assigned User
                                  </th>
                                )}
                                {props.screen == 6 ? (
                                  <th style={{ width: "130px" }}>Action</th>
                                ) : null}
                                {formTypeProps == 5 && props.screen == 1 ? (
                                  <th style={{ width: "130px" }}>Action</th>
                                ) : null}
                                {props.screen == 2 && (
                                  <th style={{ width: "130px" }}>
                                    Decline Remark
                                  </th>
                                )}
                                {props.screen == 2 && formTypeProps == 5 && (
                                  <th style={{ width: "130px" }}>Comment</th>
                                )}
                                {props.screen == 3 && formTypeProps == 5 && (
                                  <th style={{ width: "130px" }}>
                                    Closing Remark
                                  </th>
                                )}
                                {formTypeProps == 5 && props.screen == 3 ? (
                                  <th style={{ width: "130px" }}>Comment</th>
                                ) : null}
                                {props.screen == 0 && (
                                  <>
                                    <th style={{ width: "130px" }}>Status</th>
                                  </>
                                )}
                                {(props.screen == 0 ||
                                  props.screen == 1 ||
                                  props.screen == 3) &&
                                  formTypeProps != 5 &&
                                  formTypeProps != 12 && (
                                    <>
                                      {/* <th>Score</th> */}
                                      <th style={{ width: "12px" }}>Score</th>
                                    </>
                                  )}
                              </tr>
                            </thead>
                            <tbody>
                              {tableData &&
                                tableData.map((data, index) => (
                                  <tr>
                                    {!(
                                      formTypeProps == 8 ||
                                      formTypeProps == 11 ||
                                      formTypeProps == 13
                                    ) ? (
                                      <td
                                        style={{ width: 70 }}
                                        className="px-5"
                                      >
                                        <React.Fragment>
                                          <div
                                            style={{ width: 70 }}
                                            className=""
                                          >
                                            <img
                                              className="clide-v2-profile-img"
                                              src={data.pic_link}
                                              alt=""
                                              data-tip
                                              data-for={`user-name-${data.user_id}`}
                                            />
                                          </div>
                                          <ReactTooltip
                                            effect="solid"
                                            place="right"
                                            id={`user-name-${data.user_id}`}
                                          >
                                            <>
                                              <span>{data.user_name}</span>
                                            </>
                                          </ReactTooltip>
                                        </React.Fragment>
                                      </td>
                                    ) : (
                                      <td>
                                        {data.file_name != null ? (
                                          <img
                                            className="clide-v2-profile-img"
                                            src={data.file_name}
                                          />
                                        ) : null}
                                      </td>
                                    )}
                                    <td className="btn clide-v2-border-primary-color btn-sm text-center w-100">
                                      {props.screen == 1 &&
                                      formTypeProps == 5 ? (
                                        <Link
                                          to={`/submit-form-listing/${formTypeProps}/${
                                            props.activeTab
                                          }/view-form/${
                                            data.reporting_form_id
                                          }/${data.service_id || 0}/${
                                            data.project_id || 0
                                          }/${data.work_permit_use_id || 0}/${
                                            data.timeline_id || 0
                                          }/${data.survey_use_id || 0}/${
                                            formTypeProps === 12
                                              ? data.elearningId
                                              : 0
                                          }/0/0`}
                                        >
                                          {data.title}
                                        </Link>
                                      ) : (props.screen == 6 ||
                                          props.screen == 1) &&
                                        (data.edit_submit_right == 1 ||
                                          (props.activeTab === "scheduled" &&
                                            formTypeProps == 10)) ? (
                                        <Link
                                          to={`/submit-form-listing/${formTypeProps}/${
                                            props.activeTab
                                          }/user-form-view/${
                                            data.reporting_form_id
                                          }/${data.service_id || 0}/${
                                            data.project_id || 0
                                          }/${data.work_permit_use_id || 0}/${
                                            data.timeline_id || 0
                                          }/${
                                            formTypeProps === 12
                                              ? data.elearningId
                                              : 0
                                          }/0`}
                                        >
                                          {data.title}
                                        </Link>
                                      ) : props.screen == 6 &&
                                        (formTypeProps == 8 ||
                                          formTypeProps == 11 ||
                                          formTypeProps == 13) ? (
                                        <Link
                                          to={`/submit-form-listing/${formTypeProps}/${props.activeTab}/published-view/${data.reporting_form_id}`}
                                        >
                                          {data.title}
                                        </Link>
                                      ) : props.screen == 6 &&
                                        props.activeTab === "scheduled" &&
                                        (formTypeProps != 8 ||
                                          formTypeProps != 11 ||
                                          formTypeProps != 13 ||
                                          formTypeProps != 10) ? (
                                        <Link
                                          to={`/submit-form-listing/${formTypeProps}/${
                                            props.activeTab
                                          }/user-form-view/${
                                            data.reporting_form_id
                                          }/${data.service_id || 0}/${
                                            data.project_id || 0
                                          }/${data.work_permit_use_id || 0}/${
                                            data.timeline_id || 0
                                          }/${
                                            formTypeProps === 12
                                              ? data.elearningId
                                              : 0
                                          }/0`}
                                        >
                                          {data.title}
                                        </Link>
                                      ) : (
                                        <Link
                                          to={`/submit-form-listing/${formTypeProps}/${
                                            props.activeTab
                                          }/view-form/${
                                            data.reporting_form_id
                                          }/${data.service_id || 0}/${
                                            data.project_id || 0
                                          }/${data.work_permit_use_id || 0}/${
                                            data.timeline_id || 0
                                          }/${data.survey_use_id || 0}/${
                                            formTypeProps === 12
                                              ? data.elearningId
                                              : 0
                                          }/0/0`}
                                        >
                                          {data.title}
                                        </Link>
                                      )}
                                    </td>
                                    {formTypeProps != 7 &&
                                      formTypeProps != 6 && (
                                        <td className="text-center">
                                          <span className="btn clide-v2-border-primary-color btn-sm text-center w-100">
                                            {data.category}
                                          </span>
                                        </td>
                                      )}
                                    <td className="text-center">
                                      {data.start_date}
                                    </td>
                                    <td className="text-center">
                                      {data.end_date}
                                    </td>
                                    {!(props.screen == 6) &&
                                    (formTypeProps == 8 ||
                                      formTypeProps == 11 ||
                                      formTypeProps == 13) ? (
                                      <td className="text-center">
                                        {!!totalResponse &&
                                          totalResponse
                                            .filter(
                                              (e) =>
                                                e.survey_use_id ===
                                                data.survey_use_id
                                            )
                                            .map((item) => item.response_count)}
                                      </td>
                                    ) : null}
                                    {!(
                                      props.screen == 1 && formTypeProps != 5
                                    ) &&
                                      formTypeProps != 8 &&
                                      formTypeProps != 10 &&
                                      formTypeProps != 11 &&
                                      formTypeProps != 13 && (
                                        <td className="text-center">
                                          {`${data.duration}`}
                                        </td>
                                      )}
                                    {(formTypeProps == 8 ||
                                      formTypeProps == 11 ||
                                      formTypeProps == 13) && (
                                      <td className="text-center">
                                        {`${data.time_duration}`}
                                      </td>
                                    )}
                                    {(formTypeProps == 8 ||
                                      formTypeProps == 11 ||
                                      formTypeProps == 13) && (
                                      <td className="text-center">
                                        <React.Fragment>
                                          <div
                                            className="clide-v2-profile-img"
                                            style={{ marginRight: "0.5vw" }}
                                          >
                                            <img
                                              className="clide-v2-profile-img"
                                              src={data.pic_link}
                                              alt="user-pic"
                                              data-tip
                                              data-for={`user-name-${data.user_id}`}
                                            />
                                          </div>
                                          <ReactTooltip
                                            effect="solid"
                                            place="right"
                                            id={`user-name-${data.user_id}`}
                                          >
                                            <span>{data.user_name}</span>
                                          </ReactTooltip>
                                        </React.Fragment>
                                      </td>
                                    )}
                                    {props.screen == 1 &&
                                      formTypeProps == 12 && (
                                        <td className="text-center">{`${data.time_duration} days`}</td>
                                      )}
                                    <td className="text-center">
                                      {data?.user_name}
                                    </td>
                                    {props.screen == 1 &&
                                      formTypeProps != 5 &&
                                      formTypeProps != 10 && (
                                        <td className="text-center">
                                          {/* {formTypeProps == 2  ? data.duration : data.remaining_period} */}
                                          {/* integrated to display the time commenting the remaining period variable 18-1-24 */}
                                          {data.duration}
                                        </td>
                                      )}
                                    {props.screen == 4 && (
                                      <td className="text-center">
                                        {data.survey_link || 0}
                                      </td>
                                    )}

                                    {!(
                                      props.screen == 4 ||
                                      props.screen == 5 ||
                                      (props.screen == 6 &&
                                        (formTypeProps == 8 ||
                                          formTypeProps == 11 ||
                                          formTypeProps == 13)) ||
                                      formTypeProps == 12 ||
                                      formTypeProps == 10
                                    ) && (
                                      <td className="profile-flex  justify-content-center">
                                        {data.approvalLevelList?.map(
                                          (user, idx) => (
                                            <React.Fragment
                                              key={`user-name-${idx}`}
                                            >
                                              <div
                                                className={
                                                  user.approve_decline_status >
                                                  0
                                                    ? user.approve_decline_status ==
                                                      2
                                                      ? "image-box-blur-decline"
                                                      : "image-box-blur-approve"
                                                    : "image-box"
                                                }
                                                style={{ marginRight: "0.5vw" }}
                                              >
                                                {/* <div className="image_grid" > */}

                                                {user.level_id > 0 && (
                                                  <img
                                                    className="clide-v2-profile-img"
                                                    src={user.pic_link}
                                                    alt=""
                                                    data-tip
                                                    // data-for={`user-name-${idx}`}

                                                    data-for={`user-name-${user.level_id}`}
                                                  />
                                                )}
                                                {/* </div> */}
                                              </div>
                                              {user.level_id > 0 && (
                                                <ReactTooltip
                                                  //  id={`user-name-${idx}`}
                                                  id={`user-name-${user.level_id}`}
                                                  effect="solid"
                                                  place="left"
                                                >
                                                  <>
                                                    <span>
                                                      {user.level}
                                                      {" | "}
                                                      {user.user_name}
                                                    </span>
                                                  </>
                                                </ReactTooltip>
                                              )}
                                            </React.Fragment>
                                          )
                                        )}
                                      </td>
                                    )}

                                    {formTypeProps == 10 && (
                                      <td className="profile-flex">
                                        <React.Fragment>
                                          <div
                                            className={"image-box"}
                                            style={{ marginRight: "0.5vw" }}
                                          >
                                            <img
                                              className="clide-v2-profile-img"
                                              src={data.assigned_contractor_pic}
                                              alt="assigned user"
                                              data-tip
                                              data-for={`user-name-${data.assigned_contractor_id}`}
                                            />
                                          </div>
                                          <ReactTooltip
                                            effect="solid"
                                            place="right"
                                            id={`user-name-${data.assigned_contractor_id}`}
                                          >
                                            <>
                                              <span>
                                                {data.assigned_contractor_name}
                                              </span>
                                            </>
                                          </ReactTooltip>
                                        </React.Fragment>
                                      </td>
                                    )}
                                    {props.screen == 2 && (
                                      <>
                                        <td>{data.decline_reason}</td>
                                        <td className="text-center">
                                          <Popup
                                            trigger={
                                              <button
                                                style={{
                                                  border: "none",
                                                  background: "none",
                                                }}
                                              >
                                                {" "}
                                                <img
                                                  src={require("assets/sls-icons/V2. New Development/Comment.svg")}
                                                  className="svg-icons "
                                                />
                                              </button>
                                            }
                                            position="bottom"
                                          >
                                            <div
                                              style={{ textAlign: "center" }}
                                            >
                                              {data.decline_remark}
                                            </div>
                                          </Popup>
                                        </td>
                                      </>
                                    )}
                                    {props.screen == 3 &&
                                      formTypeProps == 5 && (
                                        <>
                                          <td
                                            className="text-center"
                                            style={selectColor(
                                              data.closing_reason
                                            )}
                                          >
                                            {data.closing_reason}
                                          </td>
                                          <td className="text-center">
                                            {data.closing_remark ? (
                                              <Popup
                                                trigger={
                                                  <button
                                                    style={{
                                                      border: "none",
                                                      background: "none",
                                                    }}
                                                  >
                                                    {" "}
                                                    <img
                                                      src={require("assets/sls-icons/V2. New Development/Comment.svg")}
                                                      className="svg-icons "
                                                    />
                                                  </button>
                                                }
                                                position="bottom"
                                              >
                                                <div
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {data.closing_remark}
                                                </div>
                                              </Popup>
                                            ) : (
                                              "NA"
                                            )}
                                          </td>
                                        </>
                                      )}
                                    {props.screen == 0 &&
                                      (data.approved == 1 ? (
                                        <td
                                          className="text-center"
                                          style={{
                                            backgroundColor: "#6ECB63",
                                            color: "white",
                                          }}
                                        >
                                          Approved
                                        </td>
                                      ) : data.approve_decline_status == 1 ? (
                                        <td
                                          style={{ padding: 0 }}
                                          className="text-center"
                                        >
                                          <div
                                            onClick={() => {
                                              handleLevelApproval(
                                                data.work_permit_use_id
                                              );
                                            }}
                                            style={{
                                              color: "tomato",
                                              backgroundColor: "#ECE7B4",
                                            }}
                                          >
                                            Approve
                                          </div>
                                          {formTypeProps == 5 && (
                                            <div
                                              onClick={() => {
                                                _handleDeclineModal(
                                                  data.work_permit_use_id
                                                );
                                              }}
                                              style={{
                                                color: "white",
                                                backgroundColor: "red",
                                              }}
                                            >
                                              Decline
                                            </div>
                                          )}
                                        </td>
                                      ) : (
                                        <td className="text-center">
                                          Approval Pending
                                        </td>
                                      ))}

                                    {props.screen == 6 && (
                                      <td className="text-center">
                                        {data.delete_right == 1 && (
                                          <Button
                                            className="icon-btn"
                                            onClick={() =>
                                              showModal(
                                                data.work_permit_use_id ||
                                                  data.survey_use_id
                                              )
                                            }
                                          >
                                            <img
                                              src={require("assets/sls-icons/V2. New Development/delete.svg")}
                                              className="svg-icons"
                                            />
                                          </Button>
                                        )}
                                      </td>
                                    )}

                                    {props.screen == 1 &&
                                    formTypeProps == 5 &&
                                    data.edit_submit_right ? (
                                      <td
                                        style={{ padding: 0 }}
                                        className="text-center"
                                      >
                                        <div
                                          onClick={() => {
                                            _handleExtendModal(
                                              data.work_permit_use_id,
                                              data.start_date,
                                              data.end_date,
                                              data.time_duration
                                            );
                                          }}
                                          style={{
                                            color: "white",
                                            backgroundColor: "#6ECB63",
                                          }}
                                        >
                                          Extend
                                        </div>
                                        <div
                                          onClick={() => {
                                            _handleCloseModal(
                                              data.work_permit_use_id
                                            );
                                          }}
                                          style={{
                                            color: "white",
                                            backgroundColor: "red",
                                          }}
                                        >
                                          Close
                                        </div>
                                      </td>
                                    ) : null}
                                    {formTypeProps != 5 &&
                                      (props.screen == 3 ||
                                        props.screen == 1 ||
                                        props.screen == 0) &&
                                      data.total_score > 0 && (
                                        <>
                                          <td className="text-center">{`${data.obtained_score}/${data.total_score}`}</td>
                                          {/* <td>Progress</td> */}
                                        </>
                                        // :
                                        // <td>NA</td>
                                      )}
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </InfiniteScroll>
          ) : null}
          {allScheduleData?.data?.length && currentTab?.key === "all" ? (
            <>
              <InfiniteScroll
                dataLength={allScheduleData?.data?.length}
                // next={selectedCategory ? (formTypeProps == 8 || formTypeProps == 11 || formTypeProps == 13) ? surveyFilter : fetchCategoryData : fetchSurveyDataOnScroll }
                // next={selectedCategory ? ((formTypeProps == 8 || formTypeProps == 11 || formTypeProps == 13) && surveyFilter) : ((surveyList != props.formTypeCount) && fetchSurveyDataOnScroll)}
                // hasMore={tableData.length !== (selectedCategory ? totalCategoryCount : props.formTypeCount)}
              >
                <Table striped className="data-table-wrapper">
                  <thead>
                    <tr>
                      {/* <th>#</th> */}
                      <th style={{ width: "10px" }}>#</th>
                      <th className="left" style={{ width: "10vw" }}>
                        {typeList[formTypeProps - 1].form_type}
                      </th>

                      {formTypeProps != 7 && formTypeProps != 6 && (
                        <th
                          style={{ width: "12vw" }}
                          className="b-tomato rounded px-2 py-1"
                        >
                          Category
                          {/* <div style={{ marginTop: "1vw" }} >
                        <FormTypeDropDown
                          // title="Do's & Dont's / Training / Policy"
                          title="Category"

                          isRequired={false}
                          optionList={categoryList}
                          onChangeHandle={_applyFilter}
                          value={selectedCategory}
                        />
                      </div> */}
                        </th>
                      )}

                      <th style={{ width: "130px" }}>Start Date </th>
                      <th style={{ width: "130px" }}>End Date </th>
                      {!(
                        props.screen == 6 ||
                        props.screen == 1 ||
                        props.screen == 0 ||
                        props.screen == 3 ||
                        formTypeProps == 5
                      ) ? (
                        <th>Response</th>
                      ) : null}
                      {!(props.screen == 1 && formTypeProps != 5) &&
                        formTypeProps != 10 && <th>Duration</th>}

                      {(formTypeProps == 8 ||
                        formTypeProps == 11 ||
                        formTypeProps == 13) && <th>Owner</th>}

                      {/* {(props.screen == 1 && formTypeProps != 5) && */}
                      {props.screen == 1 &&
                        formTypeProps != 5 &&
                        formTypeProps != 10 && <th>Time</th>}

                      {props.screen == 4 && (
                        <th style={{ width: "12vw" }}>Link</th>
                      )}

                      {!(
                        props.screen == 4 ||
                        props.screen == 5 ||
                        formTypeProps == 8 ||
                        formTypeProps == 11 ||
                        formTypeProps == 13 ||
                        formTypeProps == 12 ||
                        formTypeProps == 10
                      ) && <th style={{ width: "12vw" }}>Approval Level</th>}

                      {formTypeProps == 10 && <th>Assigned User</th>}

                      {/* {props.screen == 6 ? <th>Action</th> : null}
                  {formTypeProps == 5 && props.screen == 1 ? <th>Action</th> : null} */}

                      {props.screen == 2 && <th>Decline Remark</th>}
                      {props.screen == 2 && formTypeProps == 5 && (
                        <th>Comment</th>
                      )}
                      {props.screen == 3 && formTypeProps == 5 && (
                        <th>Closing Remark</th>
                      )}
                      {formTypeProps == 5 && props.screen == 3 ? (
                        <th>Comment</th>
                      ) : null}

                      {props.screen == 0 && (
                        <>
                          <th>Status</th>
                        </>
                      )}
                      {(props.screen == 0 ||
                        props.screen == 1 ||
                        props.screen == 3) &&
                        formTypeProps != 5 &&
                        formTypeProps != 12 && (
                          <>
                            {/* <th>Score</th> */}
                            <th style={{ width: "12vw" }}>Score</th>
                          </>
                        )}
                    </tr>
                  </thead>

                  <tbody>
                    {!allScheduleData?.data?.length ? (
                      <tr>
                        <td colSpan="6">
                          <p className="list-error">Data not available</p>
                        </td>
                      </tr>
                    ) : (
                      allScheduleData?.data?.length &&
                      allScheduleData?.data?.map((data, index) => (
                        <tr
                          key={`${index}-${data.reporting_form_id}`}
                          style={{
                            borderBottom: "5px solid #f5f5f5",
                            backgroundColor:
                              data.is_extended == 1 ? "#D9D7F1" : "none",
                          }}
                        >
                          {/* {(formTypeProps == 8 || formTypeProps == 11 || formTypeProps == 13)  && (
                  <td>
                    {data.file_name != null ? (
                      <img className="clide-v2-profile-img" src={data.file_name} />
                    ) : null}
                  </td>
                  )} */}

                          {!(
                            formTypeProps == 8 ||
                            formTypeProps == 11 ||
                            formTypeProps == 13
                          ) ? (
                            <td className="profile-flex border-0  justify-content-center">
                              <React.Fragment>
                                <div className={"image-box"}>
                                  <img
                                    className="clide-v2-profile-img"
                                    src={data.pic_link}
                                    alt=""
                                    data-tip
                                    data-for={`user-name-${data.user_id}-user`}
                                  />
                                </div>
                                <ReactTooltip
                                  effect="solid"
                                  place="right"
                                  id={`user-name-${data.user_id}-user`}
                                >
                                  <>
                                    <span>{data.user_name}</span>
                                  </>
                                </ReactTooltip>
                              </React.Fragment>
                            </td>
                          ) : (
                            <td>
                              {data.file_name != null ? (
                                <img
                                  className="clide-v2-profile-img"
                                  src={data.file_name}
                                />
                              ) : null}
                            </td>
                          )}

                          <td className="btn clide-v2-border-primary-color btn-sm text-center w-100">
                            {
                              props.screen == 1 && formTypeProps == 5 ? (
                                <Link
                                  to={`/submit-form-listing/${formTypeProps}/${
                                    props.activeTab
                                  }/view-form/${data.reporting_form_id}/${
                                    data.service_id || 0
                                  }/${data.project_id || 0}/${
                                    data.work_permit_use_id || 0
                                  }/${data.timeline_id || 0}/${
                                    data.survey_use_id || 0
                                  }/${
                                    formTypeProps === 12 ? data.elearningId : 0
                                  }/0/0`}
                                >
                                  {data.title}
                                </Link>
                              ) : (props.screen == 6 || props.screen == 1) &&
                                (data.edit_submit_right == 1 ||
                                  (props.activeTab === "scheduled" &&
                                    formTypeProps == 10)) ? (
                                <Link
                                  to={`/submit-form-listing/${formTypeProps}/${
                                    props.activeTab
                                  }/user-form-view/${data.reporting_form_id}/${
                                    data.service_id || 0
                                  }/${data.project_id || 0}/${
                                    data.work_permit_use_id || 0
                                  }/${data.timeline_id || 0}/${
                                    formTypeProps === 12 ? data.elearningId : 0
                                  }/0`}
                                >
                                  {data.title}
                                </Link>
                              ) : props.screen == 6 &&
                                (formTypeProps == 8 ||
                                  formTypeProps == 11 ||
                                  formTypeProps == 13) ? (
                                <Link
                                  to={`/submit-form-listing/${formTypeProps}/${props.activeTab}/published-view/${data.reporting_form_id}`}
                                >
                                  {data.title}
                                </Link>
                              ) : props.screen == 6 &&
                                props.activeTab === "scheduled" &&
                                (formTypeProps != 8 ||
                                  formTypeProps != 11 ||
                                  formTypeProps != 13 ||
                                  formTypeProps != 10) ? (
                                <Link
                                  to={`/submit-form-listing/${formTypeProps}/${
                                    props.activeTab
                                  }/user-form-view/${data.reporting_form_id}/${
                                    data.service_id || 0
                                  }/${data.project_id || 0}/${
                                    data.work_permit_use_id || 0
                                  }/${data.timeline_id || 0}/${
                                    formTypeProps === 12 ? data.elearningId : 0
                                  }/0`}
                                >
                                  {data.title}
                                </Link>
                              ) : (
                                <Link
                                  to={`/submit-form-listing/${formTypeProps}/${
                                    props.activeTab
                                  }/view-form/${data.reporting_form_id}/${
                                    data.service_id || 0
                                  }/${data.project_id || 0}/${
                                    data.work_permit_use_id || 0
                                  }/${data.timeline_id || 0}/${
                                    data.survey_use_id || 0
                                  }/${
                                    formTypeProps === 12 ? data.elearningId : 0
                                  }/0/0`}
                                >
                                  {data.title}
                                </Link>
                              )

                              // (
                              //   <Link
                              //     to={`/submit-form-listing/${formTypeProps}/${props.activeTab
                              //       }/view-form/${data.reporting_form_id}/${data.service_id || 0
                              //       }/${data.project_id || 0}/${data.work_permit_use_id || 0
                              //       }/${data.timeline_id || 0}/${data.survey_use_id || 0}/${formTypeProps === 12 ? data.elearningId : 0}/0/0`}
                              //   >
                              //     {data.title}
                              //   </Link>
                              // )
                            }
                          </td>

                          {formTypeProps != 7 && formTypeProps != 6 && (
                            <td>
                              <span className="btn clide-v2-border-primary-color btn-sm text-center w-100">
                                {data.category}
                              </span>
                            </td>
                          )}

                          {/* <td>{convertDateFromTimezone(data.start_date)}</td>
                  <td>{convertDateFromTimezone(data.end_date)}</td> */}

                          <td>{data.start_date}</td>
                          <td>{data.end_date}</td>

                          {!(props.screen == 6) &&
                          (formTypeProps == 8 ||
                            formTypeProps == 11 ||
                            formTypeProps == 13) ? (
                            <td>
                              {!!totalResponse &&
                                totalResponse
                                  .filter(
                                    (e) =>
                                      e.survey_use_id === data.survey_use_id
                                  )
                                  .map((item) => item.response_count)}
                            </td>
                          ) : null}

                          {!(props.screen == 1 && formTypeProps != 5) &&
                            formTypeProps != 8 &&
                            formTypeProps != 10 &&
                            formTypeProps != 11 &&
                            formTypeProps != 13 && (
                              <td>{`${data.duration}`}</td>
                            )}

                          {(formTypeProps == 8 ||
                            formTypeProps == 11 ||
                            formTypeProps == 13) && (
                            <td>{`${data.time_duration}`}</td>
                          )}

                          {(formTypeProps == 8 ||
                            formTypeProps == 11 ||
                            formTypeProps == 13) && (
                            <td className="profile-flex">
                              {/* {`${data.user_name}`} */}

                              <React.Fragment>
                                <div
                                  className="image-box"
                                  style={{ marginRight: "0.5vw" }}
                                >
                                  <img
                                    className="clide-v2-profile-img"
                                    src={data.pic_link}
                                    alt="user-pic"
                                    data-tip
                                    data-for={`user-name-${data.user_id}-user-2`}
                                  />
                                </div>
                                <ReactTooltip
                                  effect="solid"
                                  place="right"
                                  id={`user-name-${data.user_id}-user-2`}
                                >
                                  <span>{data.user_name}</span>
                                </ReactTooltip>
                              </React.Fragment>
                            </td>
                          )}

                          {props.screen == 1 && formTypeProps == 12 && (
                            <td>{`${data.time_duration} days`}</td>
                          )}

                          {props.screen == 1 &&
                            formTypeProps != 5 &&
                            formTypeProps != 10 && (
                              <td>{data.remaining_period}</td>
                            )}
                          {props.screen == 4 && (
                            <td>{data.survey_link || 0}</td>
                          )}

                          {!(
                            props.screen == 4 ||
                            props.screen == 5 ||
                            (props.screen == 6 &&
                              (formTypeProps == 8 ||
                                formTypeProps == 11 ||
                                formTypeProps == 13)) ||
                            formTypeProps == 12 ||
                            formTypeProps == 10
                          ) && (
                            <td className="profile-flex">
                              {data.approvalLevelList?.map((user, idx) => (
                                <React.Fragment key={`user-name-${idx}`}>
                                  <div
                                    className={
                                      user.approve_decline_status > 0
                                        ? user.approve_decline_status == 2
                                          ? "image-box-blur-decline"
                                          : "image-box-blur-approve"
                                        : "image-box"
                                    }
                                    style={{ marginRight: "0.5vw" }}
                                  >
                                    {/* <div className="image_grid" > */}

                                    {user.level_id > 0 && (
                                      <img
                                        className="clide-v2-profile-img"
                                        src={user.pic_link}
                                        alt=""
                                        data-tip
                                        // data-for={`user-name-${idx}`}

                                        data-for={`user-name-${user.level_id}`}
                                      />
                                    )}
                                    {/* </div> */}
                                  </div>
                                  {user.level_id > 0 && (
                                    <ReactTooltip
                                      //  id={`user-name-${idx}`}
                                      id={`user-name-${user.level_id}`}
                                      effect="solid"
                                      place="left"
                                    >
                                      <>
                                        <span>
                                          {user.level}
                                          {" | "}
                                          {user.user_name}
                                        </span>
                                      </>
                                    </ReactTooltip>
                                  )}
                                </React.Fragment>
                              ))}
                            </td>
                          )}

                          {formTypeProps == 10 && (
                            <td className="profile-flex">
                              <React.Fragment>
                                <div
                                  className={"image-box"}
                                  style={{ marginRight: "0.5vw" }}
                                >
                                  <img
                                    className="clide-v2-profile-img"
                                    src={data.assigned_contractor_pic}
                                    alt="assigned user"
                                    data-tip
                                    data-for={`user-name-${data.assigned_contractor_id}`}
                                  />
                                </div>
                                <ReactTooltip
                                  effect="solid"
                                  place="right"
                                  id={`user-name-${data.assigned_contractor_id}`}
                                >
                                  <>
                                    <span>{data.assigned_contractor_name}</span>
                                  </>
                                </ReactTooltip>
                              </React.Fragment>
                            </td>
                          )}
                          {props.screen == 2 && (
                            <>
                              <td>{data.decline_reason}</td>
                              <td className="cursor-pointer">
                                <Popup
                                  trigger={
                                    <button
                                      style={{
                                        border: "none",
                                        background: "none",
                                      }}
                                    >
                                      {" "}
                                      <img
                                        src={require("assets/sls-icons/V2. New Development/Comment.svg")}
                                        className="svg-icons "
                                      />
                                    </button>
                                  }
                                  position="bottom"
                                >
                                  <div style={{ textAlign: "center" }}>
                                    {data.decline_remark}
                                  </div>
                                </Popup>
                              </td>
                            </>
                          )}
                          {props.screen == 3 && formTypeProps == 5 && (
                            <>
                              <td style={selectColor(data.closing_reason)}>
                                {data.closing_reason}
                              </td>
                              <td className="cursor-pointer">
                                {data.closing_remark ? (
                                  <Popup
                                    trigger={
                                      <button
                                        style={{
                                          border: "none",
                                          background: "none",
                                        }}
                                      >
                                        {" "}
                                        <img
                                          src={require("assets/sls-icons/V2. New Development/Comment.svg")}
                                          className="svg-icons "
                                        />
                                      </button>
                                    }
                                    position="bottom"
                                  >
                                    <div style={{ textAlign: "center" }}>
                                      {data.closing_remark}
                                    </div>
                                  </Popup>
                                ) : (
                                  "NA"
                                )}
                              </td>
                            </>
                          )}
                          {props.screen == 0 &&
                            (data.approved == 1 ? (
                              <td
                                style={{
                                  backgroundColor: "#6ECB63",
                                  color: "white",
                                }}
                              >
                                Approved
                              </td>
                            ) : data.approve_decline_status == 1 ? (
                              <td
                                style={{ padding: 0 }}
                                className="cursor-pointer"
                              >
                                <div
                                  onClick={() => {
                                    handleLevelApproval(
                                      data.work_permit_use_id
                                    );
                                  }}
                                  style={{
                                    color: "tomato",
                                    backgroundColor: "#ECE7B4",
                                  }}
                                >
                                  Approve
                                </div>
                                {formTypeProps == 5 && (
                                  <div
                                    onClick={() => {
                                      _handleDeclineModal(
                                        data.work_permit_use_id
                                      );
                                    }}
                                    style={{
                                      color: "white",
                                      backgroundColor: "red",
                                    }}
                                  >
                                    Decline
                                  </div>
                                )}
                              </td>
                            ) : (
                              <td>Approval Pending</td>
                            ))}
                          {/* {props.screen == 6 && (
                          <td>
                            {data.delete_right == 1 && (
                              <Button
                                className="icon-btn"
                                onClick={() =>
                                  showModal(
                                    data.work_permit_use_id || data.survey_use_id
                                  )
                                }
                              >
                                <img src={require("assets/sls-icons/V2. New Development/delete.svg")} className="svg-icons" />
                              </Button>
                            )}
                          </td>
                        )} */}
                          {props.screen == 1 &&
                          formTypeProps == 5 &&
                          data.edit_submit_right ? (
                            <td
                              style={{ padding: 0 }}
                              className="cursor-pointer"
                            >
                              <div
                                onClick={() => {
                                  _handleExtendModal(
                                    data.work_permit_use_id,
                                    data.start_date,
                                    data.end_date,
                                    data.time_duration
                                  );
                                }}
                                style={{
                                  color: "white",
                                  backgroundColor: "#6ECB63",
                                }}
                              >
                                Extend
                              </div>
                              <div
                                onClick={() => {
                                  _handleCloseModal(data.work_permit_use_id);
                                }}
                                style={{
                                  color: "white",
                                  backgroundColor: "red",
                                }}
                              >
                                Close
                              </div>
                            </td>
                          ) : null}
                          {formTypeProps != 5 &&
                            (props.screen == 3 ||
                              props.screen == 1 ||
                              props.screen == 0) &&
                            data.total_score > 0 && (
                              <>
                                <td>{`${data.obtained_score}/${data.total_score}`}</td>
                                {/* <td>Progress</td> */}
                              </>
                              // :
                              // <td>NA</td>
                            )}
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </InfiniteScroll>
            </>
          ) : null}
          {isExtendedListAvailable && (
            <div className="published-section published-table">
              {!(
                currentTab?.key === "arc" &&
                ["initiated", "scheduled"].includes(props.activeTab)
              ) && (
                <h6 style={{ color: "tomato", marginBottom: "1vw" }}>
                  Extended
                </h6>
              )}

              <InfiniteScroll
                dataLength={archivedData.length}
                next={_fetchArchiveDataOnScroll}
                hasMore={
                  archivedData.length !== archivedTotalData?.archived_count
                }
              >
                <Table className="table table-striped nowrap">
                  <thead>
                    <tr>
                      <th style={{ width: "10px" }}>#</th>
                      {/* <th className="left">Work Permit</th> */}

                      <th className="left" style={{ width: "20vw" }}>
                        {typeList[formTypeProps - 1].form_type}
                      </th>

                      <th>Category</th>
                      <th style={{ width: "100px" }}>Start Date</th>
                      <th style={{ width: "100px" }}>End Date</th>

                      {props.screen != 6 && (
                        <>
                          <th>Extend by</th>
                          <th>Extend Reason</th>
                          {/* <th>Extend Remark</th> */}
                        </>
                      )}
                      {!(
                        formTypeProps == 8 ||
                        formTypeProps == 11 ||
                        formTypeProps == 13
                      ) && <th>Approval Level </th>}

                      {(props.screen == 0 ||
                        props.screen == 1 ||
                        props.screen == 2) && <th>Action</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {archivedData &&
                    (archivedData.length === 0 ||
                      archivedData === undefined) ? (
                      <tr>
                        <td colSpan="6">
                          <p className="list-error">Data not available</p>
                        </td>
                      </tr>
                    ) : (
                      archivedData &&
                      archivedData.map((data, index) => (
                        <tr
                          key={`${index}-${data.reporting_form_id}`}
                          style={{ borderBottom: "5px solid #f5f5f5" }}
                        >
                          <td>
                            <img
                              className="clide-v2-profile-img"
                              src={data.pic_link}
                              alt="user-pic"
                              data-tip
                              data-for={`user-name-${data.user_id}`}
                            />
                          </td>
                          <td className="btn clide-v2-border-primary-color btn-sm text-center w-100">
                            <ReactTooltip
                              effect="solid"
                              place="right"
                              id={`user-name-${data.user_id}`}
                            >
                              <>
                                <span>{data.user_name}</span>
                              </>
                            </ReactTooltip>
                            {props.screen == 6 ? (
                              data.title
                            ) : (
                              <Link
                                to={`/submit-form-listing/${formTypeProps}/${
                                  props.activeTab
                                }/view-form/${data.reporting_form_id}/${
                                  data.service_id || 0
                                }/${data.project_id || 0}/${
                                  data.work_permit_use_id || 0
                                }/${data.timeline_id || 0}/${
                                  data.survey_use_id || 0
                                }/${
                                  formTypeProps === 12 ? data.elearningId : 0
                                }/0/0`}
                              >
                                {data.title}
                              </Link>
                            )}
                          </td>
                          <td>
                            <span className="btn clide-v2-border-primary-color btn-sm text-center w-100">
                              {data.category}
                            </span>
                          </td>
                          {/* <td>{`${convertDateFromTimezone(data.start_date)}`}</td>
                    <td>{`${convertDateFromTimezone(data.end_date)}`}</td> */}

                          <td>{data.start_date}</td>
                          <td>{data.end_date}</td>

                          {props.screen != 6 && (
                            <>
                              <td>{`${data.extended_by_time} hrs`}</td>
                              <td>{data.extend_reason}</td>
                              {/* <td>{data.extend_remark}</td> */}
                            </>
                          )}

                          {!(
                            formTypeProps == 8 ||
                            formTypeProps == 11 ||
                            formTypeProps == 13
                          ) && (
                            <td className="profile-flex">
                              {data.approvalLevelList?.map((user, idx) => (
                                <React.Fragment key={`user-name-${idx}`}>
                                  <div
                                    className={
                                      user.approve_decline_status > 0
                                        ? user.approve_decline_status == 2
                                          ? "image-box-blur-decline"
                                          : "image-box-blur-approve"
                                        : "image-box"
                                    }
                                    style={{ marginRight: "0.5vw" }}
                                  >
                                    {user.level_id > 0 && (
                                      <img
                                        className="clide-v2-profile-img"
                                        src={user.pic_link}
                                        alt=""
                                        data-tip
                                        data-for={`user-name-${user.user_name}`}
                                      />
                                    )}
                                  </div>

                                  {user.level_id > 0 && (
                                    <ReactTooltip
                                      id={`user-name-${user.user_name}`}
                                      effect="solid"
                                      place="left"
                                    >
                                      <>
                                        <span>
                                          {user.level}
                                          {" | "}
                                          {user.user_name}
                                        </span>
                                      </>
                                    </ReactTooltip>
                                  )}
                                </React.Fragment>
                              ))}
                            </td>
                          )}
                          {props.screen == 1 &&
                            (data.approved == 1 &&
                            data.edit_submit_right == 1 ? (
                              <td
                                style={{ padding: 0 }}
                                className="cursor-pointer"
                              >
                                <div
                                  onClick={() => {
                                    _handleCloseModal(data.work_permit_use_id);
                                  }}
                                  style={{
                                    color: "white",
                                    backgroundColor: "red",
                                  }}
                                >
                                  Close
                                </div>
                              </td>
                            ) : (
                              <td>
                                <div
                                  style={{
                                    color: "tomato",
                                    backgroundColor: "#ECE7B4",
                                  }}
                                >
                                  Pending
                                </div>
                              </td>
                            ))}

                          {props.screen == 0 &&
                            (data.approved == 1 ? (
                              <td
                                style={{
                                  backgroundColor: "#6ECB63",
                                  color: "white",
                                }}
                              >
                                Approved
                              </td>
                            ) : data.approve_decline_status == 1 ? (
                              <td
                                style={{ padding: 0 }}
                                className="cursor-pointer"
                              >
                                <div
                                  onClick={() => {
                                    handleLevelApproval(
                                      data.work_permit_use_id
                                    );
                                  }}
                                  style={{
                                    color: "tomato",
                                    backgroundColor: "#ECE7B4",
                                  }}
                                >
                                  Approve
                                </div>
                                {formTypeProps == 5 && (
                                  <div
                                    onClick={() => {
                                      _handleDeclineModal(
                                        data.work_permit_use_id
                                      );
                                    }}
                                    style={{
                                      color: "white",
                                      backgroundColor: "red",
                                    }}
                                  >
                                    Decline
                                  </div>
                                )}
                              </td>
                            ) : (
                              <td>Approval Pending</td>
                            ))}
                          {props.screen == 2 &&
                            (data.edit_submit_right == 1 ? (
                              <td
                                style={{ padding: 0 }}
                                className="cursor-pointer"
                              >
                                <div
                                  onClick={() => {
                                    _handleCloseModal(data.work_permit_use_id);
                                  }}
                                  style={{
                                    color: "white",
                                    backgroundColor: "red",
                                  }}
                                >
                                  Close
                                </div>
                              </td>
                            ) : null)}
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </InfiniteScroll>
            </div>
          )}
        </div>
      )}

      {assignedPopup ? (
        <AssignedProject
          show={assignedPopup}
          modalClose={hideAssignedProjectPopup}
          assignData={formData}
        />
      ) : null}
      {!!closeModal && (
        <CloseModal
          handleClose={_handleCloseModal}
          wpuId={closeWorkPermitWpuId}
          type="close"
          refresh={() => {
            
            workPermitList();
            workPermitList("PROJECT", 1,1);
          }}
        />
      )}
      {!!declineModal && (
        <CloseModal
          handleClose={_handleDeclineModal}
          wpuId={closeWorkPermitWpuId}
          type="decline"
          refresh={() => {
            workPermitList();
            workPermitList("PROJECT", 1,1);
          }}
        />
      )}
      {!!extendModal && (
        <CloseModal
          handleClose={_handleExtendModal}
          wpuId={closeWorkPermitWpuId}
          type="extend"
          refresh={() => {
            workPermitList();
            workPermitList("PROJECT", 1,1);
          }}
          extendData={extendData}
        />
      )}

      <Modal
        centered
        show={deletePopup}
        className="submit-modal delete-modal-sm"
      >
        <Modal.Header onClick={() => setDeletePopup(false)}></Modal.Header>

        <Modal.Body className="d-flex-center flex-column">
          <img src={require("assets/images/delete-popup.svg")} />
          <p className="submit-text">Are you sure you want to delete this?</p>
          <div className="modal-footer-btn">
            <button
              type="submit"
              className="red-btn"
              onClick={() => setDeletePopup(false)}
            >
              CANCEL
            </button>
            <button
              type="submit"
              className="red-btn"
              onClick={() =>
                formTypeProps == 8 || formTypeProps == 11 || formTypeProps == 13
                  ? deleteSurveyUse()
                  : deletePwtUse()
              }
            >
              DELETE
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormApprovalTableView)
);
