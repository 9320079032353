const routeConstants = {
  userPoral: [
    "/ua-uc",
    "/nm",
    "/ira",
    "/good-practices",
    "/training",
    "/tbt",
    "/audit",
    "/training-program",
    "/article",
    "/investigation",
    "/comparative-dashboard",
    "/financial-graphs",
    "/h-triangle",
    "/kpis",
    "/profile",
    "/reporting-detail",
    "/nm-detail",
    "/ira-detail",
    "/edit-reporting-detail",
    "/edit-nm-reporting-detail",
    "/edit-ira-reporting-detail",
    "/create-form",
    "/published-from",

    "/user-form-view",
    "/predictive-analysis",
    "/H-TriangleAnalysis",
    "/edit-form",
    "/setting",
    "/archive",
    "/escalation",
    "/assignform",
    "/market-place",
    "/dashboard",
    "/published-view",
    "/marketplace",
    "/safe-man-hours/rights",

    // "/safety-meeting",

    "/safety-meeting/dashboard",

    "/safety-meeting/scheduled",
    "/safety-meeting/discussion/:meeting_id/scheduled",

    "/safety-meeting/inprogress",
    "/safety-meeting/discussion/:meeting_id/inprogress",

    "/safety-meeting/completed",
    "/safety-meeting/discussion/:meeting_id/completed",

    "/safety-meeting/all-closed",
    "/safety-meeting/discussion/:meeting_id/all-closed",

    "/safety-meeting/assignUsers",

    "/safety-meeting/deleted",

    // SUBMIT FORM LISTING UI ROUTES HERE
    // -- WARNING DONT CHANGE THE ORDER --
    "/submit-form-listing/unlock-form-view/:service_id/:project_id/:form_id/:unlock_request_status",
    // "/submit-form-listing/:formType/:tab/view-form/:form_id/:service_id/:project_id/:wpu_id/:timeline_id/:survey_id",
    // "/submit-form-listing/:formType/:tab/user-form-view/:form_id/:service_id/:project_id/:wpu_id/:timeline_id",
    // "/submit-form-listing/:formType/:tab/view-form/:form_id/:service_id/:project_id/:wpu_id/:timeline_id/:survey_id/:elearning_id",
    // "/submit-form-listing/:formType/:tab/view-form/:form_id/:service_id/:project_id/:wpu_id/:timeline_id/:survey_id/:elearning_id/:user_id",
    "/submit-form-listing/:formType/:tab/view-form/:form_id/:service_id/:project_id/:wpu_id/:timeline_id/:survey_id/:elearning_id/:user_id/:form_reporting_assign_id",
    "/submit-form-listing/:formType/:tab/user-form-view/:form_id/:service_id/:project_id/:wpu_id/:timeline_id/:elearning_id/:form_reporting_assign_id",
    "/submit-form-listing/:formType/:tab/analysis/:form_id/:project_id/:category_id/:user_id",
    "/submit-form-listing/:formType/:tab/published-view/:form_id",
    // "/submit-form-listing/:formType/:tab/:training_id",
    "/submit-form-listing/:formType/:tab/:training_id/:status",
    "/submit-form-listing/:formType/:tab",
    "/submit-form-listing/:formType",
    "/submit-form-listing/:formType/assign-user",

    // E-LEARNING UI ROUTES REGISTERED HERE
    "/elearning/:formType/:tab",
    // "/elearning/:formType/:tab/view-form/:form_id/:service_id/:project_id/:wpu_id/:timeline_id/:survey_id/:elearning_id/:user_id/:form_reporting_assign_id",
    // "/elearning/:formType/:tab/user-form-view/:form_id/:service_id/:project_id/:wpu_id/:timeline_id/:elearning_id/:form_reporting_assign_id",

    // POLLING UI ROUTES REGISTERED HERE
    "/polls",
    "/polls-dashboard",
    "/poll/management/:pollId",

    // GOAL UI ROUTES REGISTERED HERE
    "/goal-dashboard",
    "/goal-create",
    "/goal-inprogress",
    "/goal-completed",
    "/goal-inactivated",
    "/goal-deleted",

    "/goal-create/:goalId",
    "/goal-inprogress/:goalId",
    "/goal-completed/:goalId",
    "/goal-inactivated/:goalId",
    "/goal-deleted/:goalId",

    //Training Ui Routes registered here

    "/training/:type/dashboard",

    "/training/:type/schedule",
    "/training/:type/schedule/:id/:type/:status",

    "/training/:type/completed",
    "/training/:type/completed/:id/:type/:status",

    "/training/:type/assignUsers",

    // "/task-management",
    "/task-management/dashboard",
    "/task-management/task",
    "/task-management/task/:id/:module",
    "/task-management/task/:id/:module/:tab",
    "/safety-thought",
    "/safety-champion",
    "/capa",
    "/mis",
    "/bsc",
    "/safety-man",
    "/tool-box-talk",
    "/policy-management",
    "/inspection",
    "/checklist",
    "/survey",
    "/other",
    "/safetyadmin-map",
    "/userside-help",
    "/tbtactivitylist",
    "/policyactivitylist",
    "/dosdontsactivitylist",
    "/goal",
    "/msdsactivitylist",
    "/standardactivitylist",
  ],
  adminPortal: [
    "/admin-dashboard",
    "/services",
    "/project",
    "/company-profile",
    "/admin-master",
    "/admin-ira",
    "/admin-profile",
    "/admin-source",
    "/adminmaster-contributing-factor",
    "/adminmaster-immediate-factor",
    "/adminmaster-rootcause-analysis",
    "/adminmaster-category",
    "/admin-performance-setting",
    "/admin-user",
    "/admin-space",
    "/admin-department",
    "/admin-contractor",
    "/admin-module",
    "/admin-hira",
    "/admin-category",
    "/admin-costing",
    "/admin-help",
    "/admin-good-practice",
    "/admin-nm-workflow-setting",
    "/admin-ira-workflow-setting",
    "/admin-mis-workflow-setting",
    "/admin-uauc-workflow-setting",
    "/admin-ga-workflow-setting",
    "/admin-nm",
    "/admin-designation",
    "/admin-bsc-report",
  ],
};

export { routeConstants };
