import React from "react";
import { Modal } from "react-bootstrap";

const FullViewModal = ({ containerClass, children, showModal, hideModal,...rest }) => {
  return (
    <Modal centered show={showModal} className={containerClass} onHide={hideModal} {...rest} dialogClassName="modal-fullscreen">
      <Modal.Body>
        {children}
        <div className="modal-footer-btn mt-5">
          <button
            type="submit"
            className="red-btn"
            onClick={hideModal}
          >
            CLOSE
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

FullViewModal.defaultProps = {
  containerClass : "helpTBT"
}

export default FullViewModal;
