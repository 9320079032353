import { combineReducers } from 'redux'
import summaryReducer from "./summaryReducer";
import uaucReducer from "./uaucReducer";
import nmReducer from "./nmReducer";
import iraReducer from "./iraReducer";
import trainingReducer from './trainingReducer';
import adminReducer from "./adminReducer";
import unlockFormRequestCountReducer from "./unlockFormRequestCountReducer";
import permittedModuleReducer from './permittedModuleReducer';
import gpReducer from './gpReducer';
import graphReducer from './graphReducer';
import formReducer from './formReducer';
import auditReducer from './auditReducer';
import inspectionReducer from './inspectionReducer';
import checklistReducer from './checklistReducer';
import ptwReducer from './ptwReducer';
import pssrReducer from './pssrReducer';
import bbsReducer from './bbsReducer';
import surveyReducer from './surveyReducer';
import mockdrillReducer from './mockdrillReducer';
import contractorReducer from './contractorReducer';
import trainingFeedbackReducer from './trainingFeedbackReducer';
import eLearningReducer from './eLearningReducer';
import preTestReducer from './preTestReducer';
import postTestReducer from './postTestReducer';
import templatesReducer from './templatesReducer';
import pollReducer from './pollReducer';
import policyReducer from './policyReducer';
import skillupReducer from './skillupReducer';
import comparativeReducer from './comparativeReducer';
import tmReducer from './tmReducer';
import safetyReducer from './safetyReducer';
import taskReducer from './taskReducer';
import goalReducer from './goalReducer';

export default combineReducers({
  summary: summaryReducer,
  uauc: uaucReducer,
  nm: nmReducer,
  ira: iraReducer,
  training: trainingReducer,
  gp: gpReducer,
  graph: graphReducer,
  admin: adminReducer,
  form: formReducer,
  audit: auditReducer,
  inspection: inspectionReducer,
  checklist: checklistReducer,
  ptw: ptwReducer,
  pssr: pssrReducer,
  bbs: bbsReducer,
  survey: surveyReducer,
  mockdrill: mockdrillReducer,
  contractor: contractorReducer,
  trainingFeedback: trainingFeedbackReducer,
  elearning: eLearningReducer,
  pretest: preTestReducer,
  posttest: postTestReducer,
  templates: templatesReducer,
  unlockRequestFormCount: unlockFormRequestCountReducer,
  permitModule: permittedModuleReducer,
  poll: pollReducer,
  policy: policyReducer,
  skillup: skillupReducer,
  comparative: comparativeReducer,
  tm: tmReducer,
  safety: safetyReducer,
  task: taskReducer,
  goal: goalReducer,
})
