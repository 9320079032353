//bootstrap 5 styles
// import 'bootstrap/dist/css/bootstrap.min.css';
// //clide v2  styles
// import "assets/styles/_clide.scss"
//clide v1  styles
// import "./index.css";


import * as serviceWorker from "./serviceWorker";

import App from "./App";
import React from "react";
import ReactDOM from "react-dom";
// import "plyr-react/plyr.css";



ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// provider: "youtube"
