import React, { Component } from "react";
import _ from "lodash";
import { Plus, X } from "react-feather";
import { Button, Modal } from "react-bootstrap";
import Url from "app/shared/constants/Url";
import axios from "axios";
import { Scrollbars } from 'react-custom-scrollbars';
const qs = require("qs");


export default class InviteUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUser: false,
      inviteUserList: [],
      initialUsersList: [],
      showInvitedUser: this.props.showInvitedUser ?? true,
      noUserFound: false,
      selectedUsers: [],
      moduleType: this.props.moduleType,
      project_id: this.props.project_id,
      service_id: this.props.service_id,
      reportId: this.props.reportId,
      invitedUsersList: this.props.invitedUsersList,
      resolveFlag: this.props.resolveFlag
    };
    this.closeModal = this.closeModal.bind(this);
    this._searchPeople = this._searchPeople.bind(this);
  }

  closeModal() {
    this.props.closeUser();
  }

  async inviteUserList() {
    if (this.state.moduleType === "nm") {
      let URL = Url.clientURL + "/listnm/teammemberlistforaddition";

      let userId = localStorage.getItem("userId");
      let token = localStorage.getItem("token");

      await axios
        .post(
          URL,
          qs.stringify({
            auth_key: token,
            user_id: userId,
            nearmiss_id: this.state.reportId,
            project_id: this.state.project_id,
            service_id: this.state.service_id
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded"
            }
          }
        )
        .then(response => {
          if (response.data.status == 200) {
            var newData = response.data.data.map(item => {
              item.isSelect = false;
              item.selectedColor = "rgba(0, 0, 0, 0.25)";
              return item;
            });
            this.setState({
              inviteUserList: newData,
              initialUsersList: newData
            });
          }
          this.setState({ selectedUsers: [] });
        })
        .catch(error => { });
    }
    else if (this.state.moduleType === "report") {
      let URL = Url.smDashboard.inviteUserList;

      let userId = localStorage.getItem("userId");
      let token = localStorage.getItem("token");

      await axios
        .post(
          URL,
          qs.stringify({
            auth_key: token,
            user_id: userId,
            report_id: this.state.reportId
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded"
            }
          }
        )
        .then(response => {
          if (response.data.status == 200) {
            var newData = response.data.data.map(item => {
              item.isSelect = false;
              item.selectedColor = "rgba(0, 0, 0, 0.25)";
              return item;
            });
            this.setState({
              inviteUserList: newData,
              initialUsersList: newData
            });
          }
          this.setState({ selectedUsers: [] });
        })
        .catch(error => { });
    }
    else if (this.state.moduleType === "ira") {
      let URL = Url.clientURL + "/listira/teammemberlistforaddition";
      // console.log(URL);

      let userId = localStorage.getItem("userId");
      let token = localStorage.getItem("token");

      await axios
        .post(
          URL,
          qs.stringify({
            auth_key: token,
            user_id: userId,
            service_id: this.state.service_id,
            project_id: this.state.project_id,
            ira_id: this.state.reportId,
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded"
            }
          }
        )
        .then(response => {
          if (response.data.status == 200) {
            var newData = response.data.data.map(item => {
              item.isSelect = false;
              item.selectedColor = "rgba(0, 0, 0, 0.25)";
              return item;
            });
            this.setState({
              inviteUserList: newData,
              initialUsersList: newData
            });
          }
          this.setState({ selectedUsers: [] });
        })
        .catch(error => { });
    }
  }

  _searchPeople = e => {
    let inputValue = e.target.value;
    let text = inputValue.toString().toLowerCase();
    let users = this.state.inviteUserList;

    let filteredName = users.filter(item => {
      if (this.state.moduleType === "report") {
        return item.user_name.toString().toLowerCase().match(text);
      } else if (this.state.moduleType === "nm") {
        return item.team_member.toString().toLowerCase().match(text);
      }
    });
    console.log(text);
    if (!text || text === "") {
      this.setState({
        inviteUserList: this.state.initialUsersList,
        noUserFound: false
      });
    } else if (filteredName.length == 0) {
      this.setState({
        noUserFound: true
      });
      return false;
    } else if (Array.isArray(filteredName)) {
      this.setState({
        noUserFound: false,
        inviteUserList: filteredName
      });
    }
  };

  _selectUser = data => {
    if (!data.isSelect) {
      var joined = this.state.selectedUsers.concat(data);
      this.setState({ selectedUsers: joined });
    } else {
      let newUserList = [...this.state.selectedUsers]; // make a separate copy of the array

      const userIndex = this.state.selectedUsers.findIndex(
        item => data === item
      );

      if (userIndex !== -1) {
        newUserList.splice(userIndex, 1);
        this.setState({ selectedUsers: newUserList });
      }
    }

    data.isSelect = !data.isSelect;
    data.selectedColor = data.isSelect ? "#FF6347" : "rgba(0, 0, 0, 0.25)";

    const index = this.state.inviteUserList.findIndex(item => data === item);

    this.state.inviteUserList[index] = data;
    this.setState({
      inviteUserList: this.state.inviteUserList
    });
  };

  _addUsers = async () => {
    var selectedUserIds = [];

    this.state.selectedUsers.map((item, key) =>
      selectedUserIds.push(item.user_id)
    );
    // console.log(this.state.selectedUsers);
    this.closeModal(false);
    await this.props.onAddNewUsers(this.state.selectedUsers);
  };

  componentDidMount() {
    this.setState({
      showUser: this.props.showUser
    });
    this.inviteUserList();
  }
  debounceFunc = _.debounce(function (callback) {
    callback()
  }, 300);
  render() {
    return (
      <div className="report-section">
        <Modal centered onHide={this.closeModal} show={this.state.showUser}>
          <Modal.Header
            className="align-items-center"
          ><div className="align-items-center d-flex justify-content-between">
              <div><span className="font-weight-500 common-white-2 font-20">Invite Team</span></div>
            </div>
            <div className="d-flex  align-items-center">
              <div className="modal-controls">
                <button 
                className="clide-v2-primary clide-v2-white-color"
                onClick={() =>
                  this.debounceFunc(() => {this._addUsers() })
                }
                
                // onClick={this._addUsers}
                
                >
                  Done
                </button>
              </div>
              <X style={{
                height: "1.4vw",
                width: "1.4vw"
              }} className="cursor-pointer mx-2 common-white-2" onClick={() => this.setState({
                showUser: false
              })} />
            </div></Modal.Header>
          <Scrollbars style={{ height: '35vw' }}>
            <Modal.Body>
              <form>
                <div className="search-section">
                  <input
                    type="text"
                    placeholder="Search.."
                    name="search"
                    onChange={this._searchPeople}
                  />
                  <button type="button">
                    <i className="fa fa-search" />
                  </button>
                </div>
              </form>
              {!this.state.noUserFound
                ? <div className="rd-left-bottom invite-user-section">
                  {this.state.inviteUserList &&
                    this.state.inviteUserList.length == 0
                    ? <p className="data-not-present">User not found</p>
                    : this.state.inviteUserList &&
                    this.state.inviteUserList.map(
                      (data, index, arr) =>
                        this.state.moduleType === "report"
                          ? <div className={`user-list ${arr.length - 1 !== index ? "border-bottom" : ""}  pb-4 d-flex justify-content-lg-between align-items-center`} key={index}>
                            <div className="d-flex">
                              <div>
                                <img className="clide-v2-profile-img" src={data.user_pic} alt="" />
                              </div>
                              <div className="ml-2">
                                <h5 className="user-name common-black-1 font-16">
                                  {data.user_name}
                                </h5>
                                <div>
                                  <label className="font-weight-500 common-black-1 font-14">Role:</label>
                                  <span className=" ml-2 font-14">{data.role
                                  }</span>
                                </div>
                                <div>
                                  <label className="font-weight-500 common-black-1 font-14">Project / Plant :</label>
                                  <span className=" ml-2 font-14">{data.projectList[0]?.project}</span>
                                </div>
                              </div>
                            </div>
                            <Button
                              className="btn "
                              variant=""
                              onClick={() =>
                                this.debounceFunc(() => {this._selectUser(data) })
                              }
                              // onClick={() => this._selectUser(data)}
                            >

                              <Plus style={{ color: data.selectedColor }} />
                            </Button>
                          </div>
                          : <div className={`user-list ${arr.length - 1 !== index ? "border-bottom" : ""} pb-4 d-flex justify-content-lg-between align-items-center`} key={index}>
                            <div className="d-flex">
                              <div>
                                <img className="clide-v2-profile-img" src={data.team_member_pic} alt="" />
                              </div>
                              <div className="ml-2">
                                <h5 className="user-name common-black-1 font-16">
                                  {data.team_member}
                                </h5>
                                <div>
                                  <label className="font-weight-500 common-black-1 font-14">Role:</label>
                                  <span className=" ml-2 font-14">{data.team_member_role}</span>
                                </div>
                                <div>
                                  <label className="font-weight-500 common-black-1 font-14">Project / Plant :</label>
                                  <span className=" ml-2 font-14">{data.projectList[0]?.project}</span>
                                </div>
                              </div>
                            </div>
                            <Button
                              className="btn "
                              variant=""
                              onClick={() =>
                                this.debounceFunc(() => {this._selectUser(data) })
                              }
                              // onClick={() => this._selectUser(data)}
                            >
                              <i
                                className="fa fa-plus plus-icon"
                                style={{ color: data.selectedColor }}
                                aria-hidden="true"
                              />
                            </Button>
                          </div>
                    )}
                </div>
                : <p className="data-not-present mb-3">Users not available </p>}
              {this.state.showInvitedUser ? <div className="rd-left-bottom invite-user-section">
                <span className="font-20 common-black-1 font-weight-500">Invited Users</span>
                <div className="common-grey-border-30 my-4"></div>
                {this.state.invitedUsersList &&
                  this.state.invitedUsersList.length == 0
                  ? <p className="data-not-present">Users not available </p>
                  : this.state.invitedUsersList &&
                  this.state.invitedUsersList.map((data, index, arr) =>
                    <div className={`user-list ${arr.length - 1 !== index ? "border-bottom" : ""} 
                    pb-4 d-flex justify-content-lg-between align-items-center`} key={index}>
                      <div className="d-flex">
                        <img className="clide-v2-profile-img" src={data.userpic} alt="" />
                        <div className="ml-2">
                          <h5 className="user-name common-black-1 font-16">
                            {data.username}
                          </h5>
                          <div>
                            <label className="font-weight-500 common-black-1 font-14">Role:</label>
                            <span className=" ml-2 font-14">{data.role}</span>
                          </div>
                          <div>
                            <label className="font-weight-500 common-black-1 font-14">Project / Plant :</label>
                            <span className=" ml-2 font-14">{data.project}</span>
                          </div>
                        </div>
                      </div>
                      {this.state.resolveFlag == "resolve"
                        ? <Button
                          className="remove-btn resolve"
                          onClick={() =>
                            this.debounceFunc(() => { this.props.removeInviteUser(data.userid) })
                          }
                          // onClick={() =>
                          //   this.props.removeInviteUser(data.userid)}
                        >
                          <i
                            className="fa fa-trash delete-icon"
                            aria-hidden="true"
                          />
                        </Button>
                        : <Button
                          className="remove-btn"
                          onClick={() =>
                            this.debounceFunc(() => { this.props.removeInviteUser(data.userid ? data.userid : data.uoserid) })
                          }
                          // onClick={() =>
                          //   // this.props.removeInviteUser(data.uoserid)}
                          //   this.props.removeInviteUser(data.userid ? data.userid : data.uoserid)}
                        >
                          <i
                            className="fa fa-trash delete-icon"
                            aria-hidden="true"
                          />
                        </Button>}
                    </div>
                  )}
              </div> : null}

            </Modal.Body>
          </Scrollbars>
        </Modal>
      </div>
    );
  }
}
