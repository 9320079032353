import React from "react";
//component
import DetailBasicInfo from "./components/basicInfo";
import DetailStepForms from "./components/stepForms";
import DetailCommentSection from "./components/commentSection";
const DetailLeftSectionindex = ({
  state,
  functionContent,
  variablesObj,
  chartsVariableObj,
}) => {
  return (
    <div className="clide-v2-card-box-1">
      <div id="workexperience" className="sectionClass">
        <div className="row ">
          <div className="fullWidth eight columns">
            <ul className="cbp_tmtimeline">
              <DetailBasicInfo
                state={state}
                functionContent={functionContent}
                variablesObj={variablesObj}
                chartsVariableObj={chartsVariableObj}
              />
              {["progress", "completed"].includes(state.reportStatus) ? (
                <DetailStepForms
                  state={state}
                  functionContent={functionContent}
                  variablesObj={variablesObj}
                />
              ) : null}

              <DetailCommentSection
                state={state}
                functionContent={functionContent}
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailLeftSectionindex;
