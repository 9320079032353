import React, { Component } from "react";
import _ from "lodash";
import {
  Button,
  Row,
  Col,
  Container,
  Accordion,
  OverlayTrigger,
  Modal,
} from "react-bootstrap";
import {
  Calendar,
  Mic,
  MicOff,
  ChevronDown,
  ChevronRight,
  Image,
} from "react-feather";
import { Redirect, withRouter } from "react-router-dom";
import { InviteUser } from "app/shared/components/Popups";
import AsyncSelect from 'react-select/async';
import Url from "app/shared/constants/Url";
import axios from "axios";
import DatePicker from "react-datepicker";
import TimePicker from "react-times";
import AudioPlayer from "react-h5-audio-player";
import dateFormat from "dateformat";
import { ReactMic } from "react-mic";
import moment from "moment";
import Lightbox from "react-awesome-lightbox";
import { Link } from "react-router-dom";
import AlertMessage from "app/shared/components/Popups/AlertMessage";
import { INCIDENT_TYPE, SEVERITY } from "app/shared/constants/GlobalArray";
import LowSpace from "app/shared/components/Popups/LowSpace";
import CheckFormSettingField from "app/shared/utility/CheckFormSettingField";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { XCircle, PlusCircle } from "react-feather";
import {
  SuccessToast,
  ErrorToast,
} from "app/shared/components/Popups/ToastMessage";
import { convertDateFromTimezone, convertDateToUTC } from "app/shared/utility/tzUtilFunction";
import ClideLoader from "app/shared/ui-kit/loader";
import LoadingImage from "app/shared/components/Loader/LoadingImage";


const qs = require("qs");
const DEPT_URL = Url.departmentList;
const CONT_URL = Url.contractorList;
const ZONE_URL = Url.zoneList;
const ACT_URL = Url.activitylist;
const HZD_URL = Url.hazardList;
const BARRIER_URL = Url.barrierList;
const userId = localStorage.getItem("userId");
const token = localStorage.getItem("token");

const ErrorMessage = <div className="invalid-feedback">Enter valid name</div>;
const ErrorBlock = ({ message }) => (
  <div className="invalid-feedback">{message || ""}</div>
);

class IraEditReportingDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showUser: false,
      reportDetail: [],
      searchFiledUsers: [],
      iraId: this.props.match.params.id,
      dataLoading: true,
      accordionOpen: true,
      lightboxactive: false,
      subcommentlist: [],
      comment: 0,
      commentAdd: "",
      commentlist: [],
      allComments: false,
      redirectToReferrer: false,
      projectList: [],
      departmentList: [],
      contractorList: [],
      zoneList: [],
      locationList: [],
      incidentTypeList: INCIDENT_TYPE,
      activityList: [],
      severityList: SEVERITY,
      barrierList: [],
      hazardList: [],
      subHazardList: [],
      selectedServiceId: null,
      selectedProjectId: null,
      selectedDepartmentId: null,
      selectedContractorId: null,
      selectedZoneId: null,
      selectedLocationId: null,
      selectedTypeIncidence: null,
      selectedActivity: null,
      selectedSeverity: 0,
      selectedBarrier: null,
      selectedHazardId: null,
      selectedSubHazardId: null,
      selectedTargetDate: new Date(),
      selectedIncidentDate: new Date(),
      selectedOldDate: new Date(),
      maxDatepickerDate: new Date(),
      observationText: "",
      evidenceFileList: [],
      evidenceUrlList: [],
      serviceId: this.props.match.params.serviceId,
      projectId: this.props.match.params.projectId,
      reportDetailevidence: [],
      showRecorderModal: false,
      recordAudio: false,
      controlMeasureAudio: "",
      controlMeasureURL: "",
      observationAudio: "",
      observationURL: "",
      recordingFor: "observation",
      controlMeasureFileStatus: 0,
      observationFileStatus: 0,
      evidenceFileStatus: 0,
      isCheckLowSpace: false,
      lowStorageMessage: "",
      lowStorageStatus: 200,
      showLowStoragePopup: false,
      formSetting: [],
      selectedIncidentTime: "10:00",
      selectedTimeMeridiem: "",
      incidentPersonName: [],
      userLike: [],
      likeNumber: "",
      addedFiles: [],
      files: [],
      deletedEvidencesIds: [],
      incidentPersonNameError: false,
      injuredPersonName: "",
      injuredPersonID: "",
      // deletePersonId: null,
    };
    this.openInviteUser = this.openInviteUser.bind(this);
  }

  openInviteUser() {
    const showUser = !this.state.showUser;
    this.setState({
      showUser,
    });
  }

  componentDidMount() {
    this.reportDetail();
    this.UserWhoLiked();
    this._fetchProjectListFlag(this.state.serviceId);
  }

  _fetchProjectListFlag = async (serviceId) => {
    const { reportType } = this.state;
    let moduleId = 2;
    // if (reportType == "soi") {
    //   moduleId = 1;
    // } else if (reportType == "nm") {
    //   moduleId = 2;
    // } else if (reportType == "ira") {
    //   moduleId = 3;
    // }

    let requestData = {
      service_id: serviceId,
      module_name: "ira",
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.projectListForm,
        ContentType,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let {
                showContractorDropdown,
                showZoneLocationDropdown,
                projectList,
              } = prevState;

              projectList = response.data.data;

              // check contractor flag
              const isContractor = projectList.map(
                (item) => item.contractor_flag
              );
              showContractorDropdown = isContractor == 0 ? false : true;
              console.log("isContractor == " + isContractor);

              const isZoneLocation = projectList.map(
                (item) => item.zone_location_flag
              );
              showZoneLocationDropdown = isZoneLocation == 0 ? false : true;
              console.log("isZoneLocation == " + isZoneLocation);

              return {
                showContractorDropdown,
                showZoneLocationDropdown,
                projectList,
              };
            });
          }
        })
        .catch((error) => {
          this.setState({ dataLoading: false });
        });
    });
  };

  async reportDetail() {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    var datailUrl = Url.iraModule.iraDetails;

    await axios
      .post(
        datailUrl,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          ira_id: this.state.iraId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then(async (response) => {
        if (response.data.status == 200) {
          const oldDate = response.data.data.target_date
            ? this._momentDate(response.data.data.target_date)
            : new Date();
          await this.setState({
            reportDetail: response.data.data.basic_ira,
            formSetting: response.data.form_setting,
            selectedActivity: response.data.data.basic_ira.activity_id,
            selectedEvidence: response.data.data.basic_ira.evidence_images,
            observationURL: response.data.data.basic_ira.observation_record,
            observationText: response.data.data.basic_ira.observation,
            incidentPersonName:
              response.data.data.basic_ira.incident_person_details.map((el) => {
                return {
                  ...el,
                  status: "NoChange"
                }
              }),
            selectedDepartmentId: response.data.data.basic_ira.department_id,
            selectedContractorId: response.data.data.basic_ira.contractor_id,
            selectedZoneId: response.data.data.basic_ira.zone_id,
            selectedLocationId: response.data.data.basic_ira.location_id,
            invited_user: response.data.data.basic_ira.invited_user,
            selectedSeverity: response.data.data.basic_ira.severity_number,
            selectedTargetDate: oldDate,
            selectedIncidentDate: this._momentDate(
              response.data.data.basic_ira.incident_date
            ),
            selectedOldDate: oldDate,
            evidenceImage: response.data.data.basic_ira.evidence_images,
            selectedIncidentTime: response.data.data.basic_ira.incident_time,
          });
          if (response.data.data.basic_ira.type_of_incidence == "LTI") {
            await this.setState({ selectedTypeIncidence: 1 });
          } else if (response.data.data.basic_ira.type_of_incidence == "MTC") {
            await this.setState({ selectedTypeIncidence: 2 });
          } else if (response.data.data.basic_ira.type_of_incidence == "TRA") {
            await this.setState({ selectedTypeIncidence: 3 });
          }
          else if (response.data.data.basic_ira.type_of_incidence == "FA") {
            await this.setState({ selectedTypeIncidence: 4 });
          }

          /* // Britannia v01
  //  Making this change to ...(Integrate the another incident option becuase of customization ) */
          else if (response.data.data.basic_ira.type_of_incidence == "First Aid") {
            await this.setState({ selectedTypeIncidence: 5 });
          }
          else if (response.data.data.basic_ira.type_of_incidence == "Occupational Illness") {
            await this.setState({ selectedTypeIncidence: 6 });
          }
          else if (response.data.data.basic_ira.type_of_incidence == "Notable") {
            await this.setState({ selectedTypeIncidence: 7 });
          }

          await this._fetchOptionList();
          await this.setState({
            dataLoading: false,
            // selectedTargetDate: response.data.data.target_date
          });
        }
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
      });
  }

  _redirectToDetail = () => {
    this.setState({ redirectToReferrer: true });
  };

  _momentDate = (stringDate) => moment(stringDate, "DD-MM-YYYY").toDate();

  EditreportDetail = async () => {
    this.setState({ dataLoading: true });

    let { ...eData } = this.state;

    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    let targetDate = moment(this.state.selectedTargetDate).format("YYYY-MM-DD");
    let incidentDate = moment(this.state.selectedIncidentDate).format(
      "YYYY-MM-DD"
    );

    // let targetDate = convertDateToUTC(this.state.selectedTargetDate, "YYYY-MM-DD");
    // let incidentDate = convertDateToUTC(this.state.selectedIncidentDate, "YYYY-MM-DD");

    var controlMeasureURL =
      eData.controlMeasureFileStatus == 2
        ? eData.controlMeasureAudio
        : eData.controlMeasureURL;
    var observationURL =
      eData.observationFileStatus == 2
        ? eData.observationAudio
        : eData.observationURL;

    let nmData = new FormData();

    nmData.append("auth_key", token);
    nmData.append("user_id", userId);
    nmData.append("ira_id", eData.iraId);
    nmData.append("service_id", eData.serviceId);
    nmData.append("project_id", eData.projectId);
    nmData.append("activity", eData.selectedActivity);
    nmData.append("type_of_act", 0);
    nmData.append("incident_type", eData.selectedTypeIncidence);
    nmData.append("hazard", eData.selectedHazardId || 0);
    nmData.append("severity", eData.selectedSeverity);
    nmData.append("department", eData.selectedDepartmentId);
    nmData.append("contractor", eData.selectedContractorId);
    nmData.append("zone", eData.selectedZoneId);
    nmData.append("location", eData.selectedLocationId);
    nmData.append("observation", eData.observationText);
    //nmData.append("person", eData.incidentPersonName[0].person_name);
    nmData.append("target_date", targetDate);
    nmData.append("incident_time", eData.selectedIncidentTime);
    nmData.append("incident_date", incidentDate);
    // nmData.append('person', "1|hhh");

    // let tempPerson = [];
    // this.state.incidentPersonName.map((data) => {
    //   let obj = {
    //     personId: data.personId,
    //     person_name: data.person_name,
    //   }

    //   tempPerson.push(obj);
    //   //nmData.append('person', JSON.stringify(obj));
    // });

    nmData.append("person", JSON.stringify([...this.state.incidentPersonName, ...this.state.searchFiledUsers]));

    // this.state.incidentPersonName.forEach(item => {
    //   nmData.append('person', item);
    //  });

    // this.state.incidentPersonName.forEach((item) => {
    //   if (item.personId === 0) {
    //     nmData.append("person", 0 +"|"+ item.person_name);
    //   } else {
    //     nmData.append("person", item.personId +"|"+ item.person_name);
    //   }
    // });

    // this.state.incidentPersonName.forEach(item => {
    //   if(item.personId === 0){
    //     nmData.append('person_id', 0);
    //     nmData.append('person_name', item.person_name);
    //   }
    //   else{
    //   nmData.append('person_id', item.personId);
    //   nmData.append('person_name', item.person_name);
    // }
    //  });

    // eData.reportDetailevidence.forEach((item) => {
    //   nmData.append("file", item);
    // });
    // if (eData.reportDetailevidence?.length === 0) {
    //   nmData.append("file", "");
    // }
    // nmData.append("observation_record", observationURL);

    // // nmData.append("observation_file_status", eData.observationFileStatus);

    // nmData.append("file_status", eData.reportDetailevidence?.length > 0 ? 1 : 0);

    nmData.append(
      "delete_evidence_ids",
      this.state.deletedEvidencesIds.toString()
    );
    console.log(this.state.files);

    this.state.files.forEach((item) => {
      nmData.append("file", item.file);
      console.log(item.file);
      // nmData.append("file_status", item.file_status);
      // nmData.append("file_id", item.file_id);
    });

    var datailUrl = Url.iraModule.iraEdit;
    let headerType = "multipart/form-data";
    await axios
      .post(datailUrl, nmData, {
        headers: {
          "Content-Type": headerType,
        },
      })
      .then(async (response) => {
        await this.setState({ dataLoading: false });
        if (response.data.status == 200) {
          AlertMessage(
            { message: "Report edited successfully", type: "success" },
            this._redirectToDetail
          );
        } else {
          AlertMessage({
            message: "Edit Report Failed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => {
        this.setState({ dataLoading: false });
        AlertMessage({ message: error, type: "error" });
      });
  };

  checkLowStorage = () => {
    return import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.reportSetting.checkStorage
      );

      return responseObj;
    });
  };

  _handleLowSpace = async (statusFlag) => {
    await this.setState({ showLowStoragePopup: false });
  };

  _fetchOptionList = async () => {
    let requestData = {
      auth_key: token,
      user_id: userId,
      service_id: this.state.serviceId,
      project_id: this.state.projectId,
    };

    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    axios
      .all([
        // Department list
        axios.post(DEPT_URL, qs.stringify(requestData), {
          headers: ContentType,
        }),
        // Contractor List
        axios.post(CONT_URL, qs.stringify(requestData), {
          headers: ContentType,
        }),
        // Zone List
        axios.post(ZONE_URL, qs.stringify(requestData), {
          headers: ContentType,
        }),
        // Activity List
        axios.post(ACT_URL, qs.stringify(requestData), {
          headers: ContentType,
        }),
        // Hazard List
        axios.post(HZD_URL, qs.stringify(requestData), {
          headers: ContentType,
        }),
        // Barrier List
        axios.post(BARRIER_URL, qs.stringify(requestData), {
          headers: ContentType,
        }),
      ])
      .then(
        axios.spread(
          async (department, contractor, zone, activity, hazard, barrier) => {
            await this.setState((prevState) => {
              let {
                contractorList,
                departmentList,
                zoneList,
                hazardList,
                activityList,
                barrierList,
              } = prevState;

              departmentList =
                department.data.status === 200 ? department.data.data : [];
              contractorList =
                contractor.data.status === 200 ? contractor.data.data : [];
              zoneList = zone.data.status === 200 ? zone.data.data : [];
              hazardList = hazard.data.status === 200 ? hazard.data.data : [];
              activityList =
                activity.data.status === 200 ? activity.data.data : [];
              barrierList =
                barrier.data.status === 200 ? barrier.data.data : [];
              //  dataLoading = false;
              return {
                contractorList,
                departmentList,
                zoneList,
                hazardList,
                activityList,
                barrierList,
              };
            });
            // await this._severityChangeHandle(this.state.selectedSeverity)
            await this._activityChangeHandle(this.state.selectedActivity);
            await this._barrierChangeHandle(this.state.selectedBarrier);
            await this._hazardChangeHandle(this.state.selectedHazardId);
            await this._subHazardChangeHandle(this.state.selectedSubHazardId);
            await this._departmentChangeHandle(this.state.selectedDepartmentId);
            await this._contractorChangeHandle(this.state.selectedContractorId);
            await this._zoneChangeHandle(this.state.selectedZoneId);
            await this._locationChangeHandle(this.state.selectedLocationId);
          }
        )
      )
      .catch((error) => {
        this._setDataLoading(false);
      });
  };

  _zoneChangeHandle = async (zoneId) => {
    await this.setState((prevState) => {
      let { selectedZoneId } = prevState;
      selectedZoneId = zoneId;
      //zoneError = zoneId ? false : true;
      return { selectedZoneId };
    });
    await this._fetchLocationList();
  };

  _fetchLocationList = async (zoneId) => {
    let requestData = {
      auth_key: token,
      user_id: userId,
      service_id: this.state.serviceId,
      project_id: this.state.projectId,
      zone_id: this.state.selectedZoneId,
    };
    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.locationList,
        ContentType,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { locationList } = prevState;
              locationList = response.data.data;
              return { locationList };
            });
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
        });
    });
  };

  _fetchSubHazardList = async () => {
    let requestData = {
      auth_key: token,
      user_id: userId,
      hazard_Id: this.state.selectedHazardId,
    };
    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(
        Url.subhazardList,
        ContentType,
        requestData
      );

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState((prevState) => {
              let { subHazardList } = prevState;
              subHazardList = response.data.data;
              return { subHazardList };
            });
          }
        })
        .catch((error) => {
          this._setDataLoading(false);
        });
    });
  };

  _hazardChangeHandle = async (hazardId) => {
    await this.setState((prevState) => {
      let { selectedHazardId } = prevState;
      selectedHazardId = hazardId;
      return { selectedHazardId };
    });
    await this._fetchSubHazardList();
  };

  _departmentChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedDepartmentId } = prevState;
      selectedDepartmentId = id;
      //departmentError = id ? false : true;
      return { selectedDepartmentId };
    });
  };

  _locationChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedLocationId } = prevState;
      selectedLocationId = id;
      //locationError = id ? false : true;
      return { selectedLocationId };
    });
  };

  _subHazardChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedSubHazardId } = prevState;
      selectedSubHazardId = id;
      //subHazardError = id ? false : true;
      return { selectedSubHazardId };
    });
  };

  _barrierChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedBarrier } = prevState;
      selectedBarrier = id;
      return { selectedBarrier };
    });
  };

  _activityChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedActivity } = prevState;
      selectedActivity = id;
      return { selectedActivity };
    });
  };

  _contractorChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedContractorId } = prevState;
      selectedContractorId = id;
      //contractorError = id ? false : true;
      return { selectedContractorId };
    });
  };

  _typeactChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedTypeIncidence } = prevState;
      selectedTypeIncidence = id;
      return { selectedTypeIncidence };
    });
  };

  _severityChangeHandle = async (id) => {
    await this.setState((prevState) => {
      let { selectedSeverity } = prevState;
      selectedSeverity = id;
      return { selectedSeverity };
    });
  };

  _handleChange = async (item = null, type = "observation") => {
    await this.setState((prevState) => {
      switch (type) {
        case "observation":
          let { observationText, observationError, observationURL } = prevState;
          observationText = item;
          observationError = !observationURL && !item ? true : false;
          return { observationText, observationError };
          break;
        case "incidentpersonname":
          let { incidentPersonName, incidentPersonError } = prevState;
          incidentPersonName[0].person_name = item;
          incidentPersonError = !incidentPersonName && !item ? true : false;
          return { incidentPersonName, incidentPersonError };
          break;
        case "target_date":
          let { selectedTargetDate } = prevState;
          selectedTargetDate = item;
          return { selectedTargetDate };
          break;
        case "incident_date":
          let { selectedIncidentDate } = prevState;
          selectedIncidentDate = item;
          return { selectedIncidentDate };
          break;
        case "person_name":
          let { personName, personNameError } = prevState;
          personName = item;
          personNameError = item ? false : true;
          return { personName, personNameError };
          break;
        case "resolve_checkbox":
          let { resolveCheckbox } = prevState;
          resolveCheckbox = item ? 1 : 0;
          return { resolveCheckbox };
          break;
        default:
          return prevState;
          break;
      }
    });
  };

  _removeAudioFile = async (type) => {
    await this.setState((prevState) => {
      let {
        observationAudio,
        observationURL,
        controlMeasureAudio,
        controlMeasureURL,
        controlMeasureFileStatus,
        observationFileStatus,
      } = prevState;

      if (type === "observation") {
        observationAudio = "";
        observationURL = "";
        observationFileStatus = 1;
      }

      if (type === "control_measure") {
        controlMeasureAudio = "";
        controlMeasureURL = "";
        controlMeasureFileStatus = 1;
      }
      return {
        observationAudio,
        observationURL,
        controlMeasureAudio,
        controlMeasureURL,
        controlMeasureFileStatus,
        observationFileStatus,
      };
    });
  };

  removeInviteUser = async (selectedUserId) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.removeInviteUser,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          report_id: this.state.iraId,
          selected_user_id: selectedUserId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          AlertMessage({
            message: "User removed successfully",
            type: "success",
          });
          this.reportDetail();
        } else {
          AlertMessage({
            message: "User not removed. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => { });
  };

  _addUsersToList = async (newUsers) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    const reportId = this.state.iraId;
    var selectedUserIds = [];
    newUsers.map((item, key) => selectedUserIds.push(item.user_id));

    var data = {
      auth_key: token,
      user_id: userId,
      report_id: reportId,
      selected_user_id: selectedUserIds,
    };

    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);

      if (property == "selected_user_id") {
        data[property].map((item, key) =>
          formBody.push(encodedKey + "=" + item)
        );
      } else {
        formBody.push(encodedKey + "=" + encodedValue);
      }
    }
    formBody = formBody.join("&");

    await axios
      .post(Url.smDashboard.addInviteUser, formBody, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })

      .then((response) => {
        if (response.data.status == 200) {
          AlertMessage({
            message: "User added successfully",
            type: "success",
          });
          this.reportDetail();
        } else {
          AlertMessage({
            message: "User not added. Please try again.",
            type: "error",
          });
        }
      })
      .catch((error) => { });
  };

  cancelItem_onClick = () => {
    this.props.history.goBack();
    //window.history.back();
  };

  _showRecorder = async (flag = false, type = "observation") => {
    await this.setState((prevState) => {
      let { showRecorderModal, recordingFor } = prevState;
      showRecorderModal = flag;
      recordingFor = type;
      return { showRecorderModal, recordingFor };
    });
  };

  startRecording = async () => {
    await this.setState({ recordAudio: true });
  };

  stopRecording = async () => {
    await this.setState({ recordAudio: false });
  };

  onStop = async (recordedBlob) => {
    let promise = new Promise((resolve, error) => {
      let reader = new FileReader();
      reader.readAsDataURL(recordedBlob.blob);
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });

    promise.then(async (response) => {
      await this.setState((prevState) => {
        let {
          recordingFor,
          controlMeasureAudio,
          controlMeasureURL,
          observationAudio,
          observationURL,
          controlMeasureFileStatus,
          observationFileStatus,
        } = prevState;

        if (recordingFor === "control_measure") {
          controlMeasureAudio = recordedBlob.blob;
          controlMeasureURL = response;
          controlMeasureFileStatus = 2;
        }
        if (recordingFor === "observation") {
          observationAudio = recordedBlob.blob;
          observationURL = response;
          observationFileStatus = 2;
        }
        return {
          recordingFor,
          controlMeasureAudio,
          controlMeasureURL,
          observationAudio,
          observationURL,
          controlMeasureFileStatus,
          observationFileStatus,
        };
      });
    });
  };

  _removeAudioFile = async (type) => {
    await this.setState((prevState) => {
      if (type === "observation") {
        prevState.observationAudio = "";
        prevState.observationURL = "";
        return prevState;
      }

      if (type === "control_measure") {
        prevState.controlMeasureAudio = "";
        prevState.controlMeasureURL = "";
        return prevState;
      }
    });
  };

  _removeImageFile = async (value, type) => {
    if (type == "exist") {
      var array = this.state.evidenceImage;
      //added by Tushar for handling multiple images and remove functionality
      var deleteEvidences = this.state.deletedEvidencesIds;
      deleteEvidences.push(array[value].evidence_id);
      // var index = array.indexOf(value);
      array.splice(value, 1);
      this.setState({ evidenceImage: array, deleteFilesId: deleteEvidences });
    }
    if (type == "new") {
      var array = this.state.reportDetailevidence;
      //added by Tushar for handling multiple images and remove functionality
      var files = this.state.files;
      // var index = array.indexOf(value);
      array.splice(value, 1);
      files.splice(value, 1);
      this.setState({ reportDetailevidence: array, files });
    }
  };

  _onTimeChange = async (options) => {
    const { hour, minute, meridiem } = options;

    // const time = `${hour}:${minute}:00`;
    // const formattedTime = moment(time, "HH:mm:").format("hh:mm");

    const time = `${hour}:${minute}:00 ${meridiem}`;
    const formattedTime = moment(time, ["h:mm A"]).format("HH:mm");

    console.log("number == " + formattedTime);

    await this.setState({
      selectedIncidentTime: formattedTime,
      selectedTimeMeridiem: meridiem,
    });
  };

  _handleImageChange = async (e) => {
    e.preventDefault();

    let fileObj = [];
    let fileArray = this.state.reportDetailevidence;
    let files = this.state.files;
    fileObj.push(e.target.files);

    const lowStorage = this.checkLowStorage();
    lowStorage
      .then(async (response) => {
        const { status, msg } = response.data;

        if (status == 200) {
          for (let i = 0; i < fileObj[0].length; i++) {
            fileArray.push(fileObj[0][i]);
            //added by Tushar for handling multiple images and remove functionality
            let obj = {
              file: fileObj[0][i],
              file_status: 1,
              file_id: "",
            };
            files.push(obj);
          }
          this.setState({ reportDetailevidence: fileArray, files });
        }
        await this.setState({
          lowStorageStatus: status,
          lowStorageMessage: msg,
          showLowStoragePopup: status == 200 ? false : true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  UserWhoLiked = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.smDashboard.dashboarddetaillikelist,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          report_id: this.state.iraId,
          report_type: "ira",
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            userLike: response.data.data,
            likeNumber: response.data.like_number,
          });
        }
      })
      .catch((error) => { });
  };

  _handleEditIncidentPersonName = async (event = "", index = 0, moduleName) => {
    let val = event.target.value;
    let tempIncidentPersonList = [...this.state.incidentPersonName];

    let item = tempIncidentPersonList[index];
    item[moduleName] = val;
    if (Object.hasOwn(item, "person_unique_record_id")) {
      item = {
        ...item,
        status: "Updated"
      }
    }
    tempIncidentPersonList[index] = item;

    this.setState({
      incidentPersonName: tempIncidentPersonList,
    });
  };

  _addInjuredPersonName = async (index = 0) => {
    if (this.state.injuredPersonName) {
      let tempIncidentPersonList = [...this.state.incidentPersonName];
      const tempData = {
        incident_person_name: this.state.injuredPersonName.trim(),
        incident_person_id: this.state.injuredPersonID,
        status: "Created"
      };

      let newValue = [...tempIncidentPersonList, tempData];
      this.setState({
        incidentPersonName: newValue,
        incidentPersonNameError: false,
        injuredPersonName: '',
        injuredPersonID: "",
      });
    } else {
      ErrorToast("Enter Valid Injured Employee Name");
    }
  };

  // _deletePersonName = async(deletePersonId) => {
  //   console.log("deletePersonId == "+id);
  //   this.setState({
  //     deletePersonId: id,
  //   });
  // }

  _deletePersonName = async (deletePersonId) => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    let requestData = {
      auth_key: token,
      user_id: userId,
      id: deletePersonId,
    };

    let URL = Url.iraModule.deleteIncidentPerson;
    var ContentType = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    await axios
      .post(URL, qs.stringify(requestData), ContentType)
      .then(async (response) => {
        if (response.data.status === 200) {

          let tempIncidentPerson = [...this.state.incidentPersonName];
          let newData = tempIncidentPerson.filter((item) => item.personId !== deletePersonId);

          tempIncidentPerson = newData;
          this.setState({
            incidentPersonName: tempIncidentPerson,
          });
        }
      }).catch((error) => {

        AlertMessage({
          message: "Incident person Id cannot be deleted. Try again",
          type: "error",
        });
      });

  }

  _removePerson = async (index) => {
    console.log("removePersonID == " + index);
    let updateIncidentPersons = this.state.incidentPersonName.map((el, ind) => {
      if (ind === index) {
        el = {
          ...el,
          status: "Deleted",
        }
      }
      return el
    }
    );
    this.setState({
      incidentPersonName: updateIncidentPersons,
    });
  }


  searchEmployee = async (inputString, callback) => {
    const contentType = { "Content-Type": "application/x-www-form-urlencoded" };
    await axios
      .post(Url.userSearch, qs.stringify({
        service_id: this.state.reportDetail?.service_id,
        auth_key: localStorage.getItem("token"),
        project_id: this.state.reportDetail?.project_id,
        user_id: localStorage.getItem("userId"),
        search_user_name: inputString
      }), contentType)
      .then(async (response) => {
        if (response.data.status === 200) {
          callback(response.data)
          await this.setState({
            searchData: { ...this.state.searchData, ...response.data },
          });
        }
      })
      .catch((error) => {

      });
  }
  debounceFunc = _.debounce(function (callback) {
    callback()
  }, 300);
  render() {
    var reportData = this.state.reportDetail;
    if (this.state.redirectToReferrer) {
      return (
        <Redirect to={`/ira-detail/${reportData.ira_id}/kanban/pending`} />
      );
    }
    const uaUcChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      title: null,
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          showInLegend: true,
        },
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      series: [
        {
          data: [
            {
              y: reportData.analysis?.unsafe_act,
              name: "Unsafe Act",
              color: "#C7AC83",
            },
            {
              y: reportData.analysis?.unsafe_condition,
              name: "Unsafe Condition",
              color: "#554040",
            },
          ],
          size: "100%",
          innerSize: "56%",
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };
    const nmuaUcChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      title: null,
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          showInLegend: true,
        },
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      series: [
        {
          data: [
            {
              y: reportData.analysis?.nm_ua_number,
              name: "Unsafe Act",
              color: reportData.analysis?.nm_ua_color,
            },
            {
              y: reportData.analysis?.nm_uc_number,
              name: "Unsafe Condition",
              color: reportData.analysis?.nm_uc_color,
            },
          ],
          size: "100%",
          innerSize: "56%",
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };
    const analysisChart = {
      chart: {
        type: "pie",
        height: "100%",
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      title: null,
      yAxis: {
        title: {
          enabled: false,
        },
      },
      plotOptions: {
        pie: {
          shadow: false,
          showInLegend: true,
        },
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      series: [
        {
          data: [
            {
              y: reportData.analysis?.fa_number || 0,
              name: "FA",
              color: "#FF4242",
            },
            {
              y: reportData.analysis?.rta_number || 0,
              name: "RTA",
              color: "#086992",
            },
            {
              y: reportData.analysis?.mtc_number || 0,
              name: "MTC",
              color: "#FAAC15",
            },
            {
              y: reportData.analysis?.lti_number || 0,
              name: "LTI",
              color: "#18DA07",
            },
          ],
          size: "100%",
          innerSize: "56%",
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };
    return (
      <div className="report-detail">
        <div
          className="loader"
          style={{ display: this.state.dataLoading ? "block" : "none" }}
        >
          <ClideLoader />
        </div>
        <div className="rd-head">
          <div className="rd-heading">
            <h2>
              <Link
                to={{
                  pathname: `/ira-detail/${reportData.ira_id}/kanban/pending`,
                }}
              >
                <Button className="back-btn">
                  <i className="fa fa-angle-left" aria-hidden="true"></i>
                </Button>
              </Link>
              EDIT | {reportData.activity}
            </h2>
          </div>
        </div>
        <div className="rd-content">
          <div className="rd-left-content">
            <div className="rd-box">
              <div className="rd-box-head">
                <h3>Activity info</h3>

                {/* <div className="rd-head-right">
                  <div
                    className="severity-box"
                    style={{ backgroundColor: reportData.severity_color }}
                  ></div> */}
                {/* <select
                    value={this.state.selectedSeverity}
                    onChange={(event) =>
                      this._severityChangeHandle(event.target.value)
                    }
                  >
                    <option>Select Severity</option>
                    {SEVERITY.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select> */}
                {/* </div> */}
              </div>
              <div className="rd-box-bottom">
                <Row className="row-gap">
                  <Col>
                    <div className="feild-group">
                      <div className="label">Service</div>
                      <div className="output-text">{reportData.service}</div>
                    </div>
                  </Col>
                  <Col>
                    <div className="feild-group">
                      <div className="label">Project</div>
                      <div className="output-text">{reportData.project}</div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="rd-box">
              <div className="rd-box-head">
                <h3>About Incidence</h3>
              </div>
              <div className="rd-box-bottom">
                <Row className="row-gap">
                  {CheckFormSettingField(
                    this.state.formSetting?.setting,
                    "incident_type"
                  ) ? (
                    <Col>
                      <div className="feild-group">
                        <div className="label">Incident Type</div>
                        <select
                          className="form-control"
                          value={this.state.selectedTypeIncidence || ""}
                          onChange={(event) =>
                            this._typeactChangeHandle(event.target.value)
                          }
                        >
                          <option value="0">Select Type</option>
                          {this.state.incidentTypeList.map((item, index) => (
                            <option
                              value={item.id}
                              key={`typeActList-${index}`}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                  ) : null}
                  {CheckFormSettingField(
                    this.state.formSetting?.setting,
                    "activity"
                  ) ? (
                    <Col>
                      <div className="feild-group">
                        <div className="label">Activity</div>
                        <select
                          className="form-control"
                          value={this.state.selectedActivity || ""}
                          onChange={(event) =>
                            this._activityChangeHandle(event.target.value)
                          }
                        >
                          <option value="0">Select Activity</option>
                          {this.state.activityList.map((item, index) => (
                            <option
                              value={item.activity_id}
                              key={`activityList-${index}`}
                            >
                              {item.actvity}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                  ) : null}
                  {CheckFormSettingField(
                    this.state.formSetting?.setting,
                    "incident date"
                  ) ? (
                    <Col>
                      <div className="feild-group">
                        <div className="label">Incident Date</div>
                        <div className="calender-div">
                          <DatePicker
                            selected={this.state.selectedIncidentDate}
                            dateFormat="dd/MM/yyyy"
                            maxDate={this.state.selectedOldDate}
                            onChange={(jsDate) =>
                              this._handleChange(jsDate, "incident_date")
                            }
                          />
                          <Calendar className="calender-icon" />
                        </div>
                      </div>
                    </Col>
                  ) : null}
                  {CheckFormSettingField(
                    this.state.formSetting?.setting,
                    "incident time"
                  ) ? (
                    <Col>
                      <div className="feild-group">
                        <div className="label">Incident Time</div>
                        <div className="calender-div">
                          <TimePicker
                            timeMode="12"
                            onTimeChange={(timeOptions) =>
                              this._onTimeChange(timeOptions)
                            }
                            time={this.state.selectedIncidentTime}
                            closeOnOutsideClick={false}
                            meridiem={this.state.selectedTimeMeridiem}
                          />
                        </div>
                      </div>
                    </Col>
                  ) : null}
                </Row>
                {/* <Row>
                  {CheckFormSettingField(
                    this.state.formSetting?.setting,
                    "name of injured person"
                  ) ?
                    <Col>
                      <div className="feild-group">
                        <div className="label">
                          Name of Injured Person
                        </div>
                        <input
                          className="form-control"
                          onChange={(event) =>
                            this._handleChange(
                              event.target.value,
                              "incidentpersonname"
                            )
                          }
                          value={this.state.incidentPersonName[0].person_name}
                        ></input>
                      </div>
                    </Col>
                    : null}

                </Row> */}
                {CheckFormSettingField(
                  this.state.formSetting?.setting,
                  "Employee Search"
                ) ? <Row className="my-3 flex-column">
                  <label className="label ml-3 font-weight-500">Search Employee</label>
                  <Col sm={6} className="font-weight-light my-2">
                    <AsyncSelect
                      isMulti
                      cacheOptions
                      onChange={(data) => {
                        const items = data?.map((el) => {
                          return {
                            incident_person_id: el?.employeeId,
                            incident_person_name: el?.label,
                            status: "Created",
                            searched_incident_person_id: el?.value,
                          }
                        });
                        this.setState({
                          searchFiledUsers: items
                        })
                      }}
                      placeholder="Enter employee name"
                      // loadOptions={(input, callback) => {
                      //   if (this.timeout) clearTimeout(this.timeout);
                      //   this.timeout = setTimeout(() => {
                      //     this.searchEmployee(input, (data) => {
                      //       const excudleIds = this.state.incidentPersonName?.map(((el) => el.incident_person_id))
                      //       const items = []
                      //       data.data.forEach((element) => {
                      //         if (!excudleIds.includes(element?.id?.toString())) {
                      //           items.push({
                      //             label: element?.name,
                      //             value: element?.id,

                      //           })
                      //         }
                      //       })
                      //       callback(items)
                      //     })
                      //   }, 1000);

                      // }}
                      loadOptions={(input, callback) => {
                        if (this.timeout) clearTimeout(this.timeout);
                        this.timeout = setTimeout(() => {
                          this.searchEmployee(input, (data) => {
                            const excudleIds = this.state.incidentPersonName?.map(((el) => {
                              if (el?.searched_incident_person_id && el?.status !== "Deleted") {
                                return el?.searched_incident_person_id?.toString()
                              }
                            }))
                            const items = []
                            data.data.forEach((element) => {
                              if (!excudleIds.includes(element?.id?.toString())) {
                                items.push({
                                  ...element,
                                  label: element?.name,
                                  value: element?.id,

                                })
                              }
                            })
                            callback(items)
                          })
                        }, 1000);

                      }}
                    />
                  </Col>
                </Row> : null}
                <Row>

                  <Col>
                    <div className="feild-group">
                      {this.state.incidentPersonName.map((data, index) => {
                        return data?.status !== "Deleted" ? <div key={index}>
                          <div className="form-group d-flex space-between align-items-center">
                            {CheckFormSettingField(
                              this.state.formSetting?.setting,
                              "name of injured person"
                            ) ?
                              <div >
                                <label className="label">Name of Injured Person</label>
                                <input
                                  type="text"
                                  className="form-control col-lg-11"
                                  value={data.incident_person_name}
                                  disabled={data?.searched_incident_person_id}
                                  onChange={(event) => this._handleEditIncidentPersonName(event, index, "incident_person_name")}
                                />


                              </div> : null}
                            {CheckFormSettingField(
                              this.state.formSetting?.setting,
                              "person id"
                            ) ?
                              <div >
                                <label className="label">Id</label>
                                <input
                                  type="text"
                                  className="form-control col-lg-11"
                                  value={data.incident_person_id}
                                  disabled={data?.searched_incident_person_id}
                                  onChange={(event) => this._handleEditIncidentPersonName(event, index, "incident_person_id")}
                                />


                              </div> : null}

                            <XCircle
                              className="cursor-pointer mt-4"
                              onClick={() => this._removePerson(index)}

                            />
                          </div>
                        </div> : null
                      }

                      )}
                    </div>
                  </Col>
                  {/* <Col>
                        <div className="form-group">
                          <div className="d-flex justify-content-around">
                            <input
                              type="text"
                              className={`form-control col-lg-11 ${this.state.incidentPersonNameError
                                ? "is-invalid"
                                : "border"
                                }`}
                              value={this.state.injuredPersonName}
                              onChange={(event) =>
                                this.setState({
                                  injuredPersonName: event.target.value,
                                })
                              }
                            />
                            <ErrorBlock
                              message={`Enter at least one incident peroson name`}
                            />

                            <PlusCircle
                              onClick={() => this._addInjuredPersonName(null)}
                              className="cursor-pointer"
                            />
                          </div>
                        </div>
                      </Col> */}
                  <Col className="d-flex align-items-end">
                    <div className="form-group">
                      <div className="d-flex justify-content-around align-items-center">
                        <input
                          type="text"
                          className={`form-control w-50  ${this.state.incidentPersonNameError
                            ? "is-invalid"
                            : "border"
                            }`}
                          value={this.state.injuredPersonName}
                          placeholder="Enter person name"
                          onChange={(event) => this.setState({
                            injuredPersonName: event.target.value
                          })}
                        />
                        <input
                          type="text"
                          className={`form-control w-50 mx-2  ${this.state.incidentPersonNameError
                            ? "is-invalid"
                            : "border"
                            }`}
                          value={this.state.injuredPersonID}
                          placeholder="Enter id"
                          onChange={(event) => this.setState({
                            injuredPersonID: event.target.value
                          })}
                        />
                        <ErrorBlock
                          message={`Enter at least one incident peroson name`}
                        />

                        <PlusCircle className="cursor-pointer"
                          onClick={() => this._addInjuredPersonName(null)}
                        />
                      </div>

                    </div>
                  </Col>


                </Row>
              </div>
            </div>
            <div>
              <Row>
                <Col>
                  <div className="rd-box">
                    <div className="rd-box-head">
                      <h3>
                        View Evidence
                        <div className="file-upload nm-mic">
                          {/* <label className="file-upload__label">
                            Add Evidence
                          </label> */}
                          <Image className={"nm-mic"}></Image>
                          <input
                            type="file"
                            multiple
                            accept="image/*, video/*"
                            name={`field.file_path`}
                            className="file-upload__input"
                            onChange={this._handleImageChange}
                          />
                        </div>
                      </h3>
                    </div>
                    <div className="rd-box-bottom">
                      <div className="images-section">
                        {this.state.reportDetail != undefined && (
                          <div>
                            {this.state.evidenceImage &&
                              this.state.evidenceImage.map((data, index) => (
                                <div className="image-box" key={index}>
                                  {data.evidence_format == "image" ||
                                    data.evidence_format == null ? (
                                    <div>
                                      <img
                                        onClick={() =>
                                          this.setState({
                                            lightboxactive: true,
                                          })
                                        }
                                        src={data.evidence_image}
                                        alt=""
                                      />
                                      <Button
                                        variant="outline-link"
                                        onClick={() => {
                                          this._removeImageFile(index, "exist");
                                        }}
                                      >
                                        X
                                      </Button>
                                    </div>
                                  ) : (
                                    <div>
                                      <video poster="">
                                        <source
                                          src={data.link}
                                          type="video/mp4"
                                        />
                                      </video>
                                      <Button
                                        variant="outline-link"
                                        onClick={() =>
                                          this._removeImageFile(index, "exist")
                                        }
                                      >
                                        X
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              ))}
                          </div>
                        )}
                        {/* {this.state.reportDetailevidence &&
                          this.state.reportDetailevidence.map((data, index) => (
                            <div className="image-box" key={index}>
                              <div>
                                <img src={URL.createObjectURL(data)} alt="" />
                                <Button
                                  variant="outline-link"
                                  onClick={() =>
                                    this._removeImageFile(index, "new")
                                  }
                                >
                                  X
                                </Button>
                              </div>
                            </div>
                          ))} */}

                        {this.state.files &&
                          this.state.files.map((data, index) => (
                            <div className="image-box" key={index}>
                              <div>
                                <img
                                  src={URL.createObjectURL(data.file)}
                                  alt=""
                                />
                                <Button
                                  variant="outline-link"
                                  onClick={() =>
                                    this._removeImageFile(index, "new")
                                  }
                                >
                                  X
                                </Button>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col>
                  <div className="rd-box">
                    <div className="rd-box-head">
                      <h3>
                        Observation
                        {/* {this.state.observationURL != undefined &&
                          this.state.observationURL.substr(
                            this.state.observationURL.length - 3
                          ) != "mp3" && (
                            <Mic
                              className="nm-mic"
                              onClick={() =>
                                this._showRecorder(true, "observation")
                              }
                            />
                          )} */}
                      </h3>
                    </div>
                    <div className="rd-box-bottom">
                      <Row>
                        <Col>
                          <div className="feild-group rd-box">
                            <textarea
                              className="form-control"
                              onChange={(event) =>
                                this._handleChange(
                                  event.target.value,
                                  "observation"
                                )
                              }
                              value={this.state.observationText}
                            ></textarea>
                          </div>
                          <div className="feild-group rd-box">
                            {this.state.observationURL != undefined &&
                              this.state.observationURL.substr(
                                this.state.observationURL.length - 3
                              ) == ".mp3" && (
                                <div className="audioPreviewer">
                                  <AudioPlayer
                                    src={this.state.observationURL}
                                    showJumpControls={true}
                                    layout="horizontal-reverse"
                                    customVolumeControls={[]}
                                    customAdditionalControls={[]}
                                    style={{
                                      width: "400px",
                                    }}
                                  />
                                  {/* <audio controls>
                                    <source
                                      src={this.state.observationURL}
                                    ></source>
                                  </audio> */}
                                  <Button
                                    variant="outline-link ml-2"
                                    onClick={() =>
                                      this._removeAudioFile("observation")
                                    }
                                  >
                                    X
                                  </Button>
                                </div>
                              )}
                            {this.state.observationAudio && (
                              <div className="audioPreviewer">
                                <AudioPlayer
                                  src={this.state.observationURL}
                                  showJumpControls={true}
                                  layout="horizontal-reverse"
                                  customVolumeControls={[]}
                                  customAdditionalControls={[]}
                                  style={{
                                    width: "400px",
                                  }}
                                />
                                <Button
                                  variant="outline-link ml-2"
                                  onClick={() =>
                                    this._removeAudioFile("observation")
                                  }
                                >
                                  X
                                </Button>
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            {CheckFormSettingField(
              this.state.formSetting?.setting,
              "control measure"
            ) ? (
              <div className="rd-box">
                <div className="rd-box-head">
                  <h3>
                    Control Measure
                    {/* {this.state.controlMeasureURL != undefined &&
                      this.state.controlMeasureURL.substr(
                        this.state.controlMeasureURL.length - 3
                      ) != "mp3" && (
                        <Mic
                          className="nm-mic"
                          onClick={() =>
                            this._showRecorder(true, "control_measure")
                          }
                        />
                      )} */}
                  </h3>
                </div>
                <div className="rd-box-bottom">
                  <Row>
                    <Col>
                      <div className="feild-group rd-box">
                        <textarea
                          className="form-control"
                          onChange={(event) =>
                            this._handleChange(
                              event.target.value,
                              "control_measure"
                            )
                          }
                          value={this.state.controlMeasure}
                        ></textarea>
                      </div>
                      <div className="feild-group rd-box">
                        {this.state.controlMeasureURL != undefined &&
                          this.state.controlMeasureURL.substr(
                            this.state.controlMeasureURL.length - 3
                          ) == "mp3" && (
                            <div className="audioPreviewer">
                              <AudioPlayer
                                src={this.state.controlMeasureURL}
                                showJumpControls={true}
                                layout="horizontal-reverse"
                                customVolumeControls={[]}
                                customAdditionalControls={[]}
                                style={{
                                  width: "400px",
                                }}
                              />
                              <Button
                                variant="outline-link ml-2"
                                onClick={() =>
                                  this._removeAudioFile("control_measure")
                                }
                              >
                                X
                              </Button>
                            </div>
                          )}
                        {this.state.controlMeasureAudio && (
                          <div className="audioPreviewer">
                            <AudioPlayer
                              src={this.state.controlMeasureURL}
                              showJumpControls={true}
                              layout="horizontal-reverse"
                              customVolumeControls={[]}
                              customAdditionalControls={[]}
                              style={{
                                width: "400px",
                              }}
                            />
                            <Button
                              variant="outline-link ml-2"
                              onClick={() =>
                                this._removeAudioFile("control_measure")
                              }
                            >
                              X
                            </Button>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            ) : null}

            <div className="rd-box">
              <div className="rd-box-head">
                <h3>Department/Contractor/Location</h3>
              </div>
              <div className="rd-box-bottom">
                <Row>
                  {CheckFormSettingField(
                    this.state.formSetting?.setting,
                    "department"
                  ) ? (
                    <Col>
                      <div className="feild-group">
                        <div className="label">Department</div>
                        <select
                          className="form-control"
                          value={this.state.selectedDepartmentId || ""}
                          onChange={(event) =>
                            this._departmentChangeHandle(event.target.value)
                          }
                        >
                          <option value="0">Select Department</option>
                          {this.state.departmentList.map((item, index) => (
                            <option
                              value={item.id}
                              key={`departmentList-${index}`}
                            >
                              {item.department}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                  ) : null}

                  {this.state.showContractorDropdown &&
                    CheckFormSettingField(
                      this.state.formSetting?.setting,
                      "contractor"
                    ) ? (
                    <Col>
                      <div className="feild-group">
                        <div className="label">Contractor</div>
                        <select
                          className="form-control"
                          value={this.state.selectedContractorId || ""}
                          onChange={(event) =>
                            this._contractorChangeHandle(event.target.value)
                          }
                        >
                          <option value="0">Select Contractor</option>
                          {this.state.contractorList.map((item, index) => (
                            <option
                              value={item.id}
                              key={`contractorList-${index}`}
                            >
                              {item.contractor}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                  ) : null}

                  {this.state.showZoneLocationDropdown &&
                    CheckFormSettingField(
                      this.state.formSetting?.setting,
                      "zone"
                    ) ? (
                    <Col>
                      <div className="feild-group">
                        <div className="label">Zone</div>
                        <select
                          className="form-control"
                          value={this.state.selectedZoneId || ""}
                          onChange={(event) =>
                            this._zoneChangeHandle(event.target.value)
                          }
                        >
                          <option value="0">Select Zone</option>
                          {this.state.zoneList.map((item, index) => (
                            <option value={item.id} key={`zoneList-${index}`}>
                              {item.zone}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                  ) : null}

                  {this.state.showZoneLocationDropdown &&
                    CheckFormSettingField(
                      this.state.formSetting?.setting,
                      "location"
                    ) ? (
                    <Col>
                      <div className="feild-group">
                        <div className="label">Location</div>
                        <select
                          className="form-control"
                          value={this.state.selectedLocationId || ""}
                          onChange={(event) =>
                            this._locationChangeHandle(event.target.value)
                          }
                        >
                          <option value="0">Select Location</option>
                          {this.state.locationList.map((item, index) => (
                            <option
                              value={item.id}
                              key={`locationList-${index}`}
                            >
                              {item.location}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                  ) : null}
                </Row>
              </div>
            </div>
            <div className="rd-box">
              <div className="rd-box-head">
                <h3>Action By & Target Date</h3>
              </div>
              <div className="rd-box-bottom">
                <Row>
                  <Col>
                    <div className="feild-group">
                      <div className="label">Reported By</div>
                      {reportData.reported_by == "" ||
                        reportData.reported_by == undefined ? (
                        <p className="na-text">NA</p>
                      ) : (
                        <div className="output-text">
                          {reportData.reported_by}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col>
                    <div className="feild-group">
                      <div className="label">Reported Date</div>
                      {reportData.reported_date == "" ||
                        reportData.reported_date == undefined ? (
                        <p className="na-text">NA</p>
                      ) : (
                        <div className="output-text">
                          {reportData.reported_date}
                        </div>
                      )}
                    </div>
                  </Col>
                  {/* {reportData.assign_to ? (
                    <Col>
                      <div className="feild-group">
                        <div className="label">Assign User</div>
                        {reportData.assign_to == "" ||
                          reportData.assign_to == undefined ? (
                          <p className="na-text">NA</p>
                        ) : (
                          <div className="output-text">
                            {reportData.assign_to}
                          </div>
                        )}
                      </div>
                    </Col>
                  ) : null} */}

                  {CheckFormSettingField(
                    this.state.formSetting?.setting,
                    "target date"
                  ) ? (
                    <Col>
                      <div className="feild-group">
                        <div className="label">Target Date</div>
                        <div className="calender-div">
                          <DatePicker
                            selected={this.state.selectedTargetDate}
                            dateFormat="dd/MM/yyyy"
                            minDate={this.state.selectedOldDate}
                            onChange={(jsDate) =>
                              this._handleChange(jsDate, "target_date")
                            }
                          />
                          <Calendar className="calender-icon" />
                        </div>
                      </div>
                    </Col>
                  ) : null}
                </Row>
              </div>
            </div>
          </div>
          <div className="rd-right-content">
            <div className="publish-btn">
              <Button
                variant="secondary"
                onClick={this.cancelItem_onClick}
                className="gray-btn"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() =>
                  this.debounceFunc(() => {
                    this.EditreportDetail()
                   })
                }
                className="red-btn"
              >
                Save
              </Button>
            </div>
            <div className="rd-box liked-btn">
              <Button className="invite-btn">Users Liked</Button>

              <div className="rd-left-bottom side-invite-user">
                <div className="totalLikedUser">
                  <span>All</span>
                  <span className="count">{this.state.likeNumber}</span>
                </div>

                {this.state.userLike != "" ? (
                  this.state.userLike?.map((items, index) => (
                    <div className="user-list" key={index}>
                      <img src={items.user_profile} alt={items.user_name} />
                      <h6 className="user-name">{items.user_name}</h6>
                    </div>
                  ))
                ) : (
                  <p className="data-not-present">Likes not available </p>
                )}
              </div>
            </div>

            {this.state.reportStatus == "progress" ||
              this.state.reportStatus == "completed" ? (
              <div className="rd-box">
                <Accordion defaultActiveKey="0">
                  <Accordion.Toggle
                    eventKey="0"
                    as={Button}
                    variant="link"
                    onClick={() =>
                      this.setState({
                        accordionOpen: !this.state.accordionOpen,
                      })
                    }
                  >
                    <div className="rd-box-head1">
                      <h3>Investigation Team</h3>
                      {this.state.accordionOpen ? (
                        <ChevronDown className="indicator-icon" />
                      ) : (
                        <ChevronRight className="indicator-icon" />
                      )}
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <div className="rd-left-bottom">
                      <Row>
                        <Col>
                          {reportData.people_involved_list?.length
                            ? reportData.people_involved_list.map(
                              (item, index) => (
                                <div
                                  className="chip m-2"
                                  key={`investigation-team-${index}`}
                                >
                                  <img
                                    src={item.user_pic}
                                    width="96"
                                    height="96"
                                  />
                                  {item.user_name}
                                </div>
                              )
                            )
                            : null}
                        </Col>
                      </Row>
                    </div>
                  </Accordion.Collapse>
                </Accordion>
              </div>
            ) : null}

            <div className="rd-box">
              <Accordion defaultActiveKey="0">
                <Accordion.Toggle
                  eventKey="0"
                  as={Button}
                  variant="link"
                  onClick={() =>
                    this.setState({ accordionOpen: !this.state.accordionOpen })
                  }
                >
                  <div className="rd-box-head1">
                    <h3>{reportData.activity} | Analysis</h3>
                    {this.state.accordionOpen ? (
                      <ChevronDown className="indicator-icon" />
                    ) : (
                      <ChevronRight className="indicator-icon" />
                    )}
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <div className="rd-left-bottom">
                    <Row>
                      <Col>
                        <div className="report-detail-graph-label">
                          IRA Analysis
                        </div>
                        {reportData.analysis?.fa_number == 0 &&
                          reportData.analysis?.mtc_number == 0 &&
                          reportData.analysis?.rta_number == 0 &&
                          reportData.analysis?.lti_number == 0 ? (
                          <div className="graph-not-available-text">
                            Graph not available
                          </div>
                        ) : (
                          <>
                            <Row>
                              <Col sm={6}>
                                <div style={{ width: "9vw" }}>
                                  <HighchartsReact
                                    highcharts={Highcharts}
                                    options={analysisChart}
                                  />
                                </div>
                              </Col>
                              <Col
                                sm={3}
                                className="report-detail-graph-legend-block"
                              >
                                <div className="report-detail-graph-legend-inner-block">
                                  <div
                                    className="report-detail-graph-legend-box"
                                    style={{ backgroundColor: "#FF4242" }}
                                  ></div>
                                  <div className="report-detail-graph-legend-value">
                                    {reportData.analysis?.fa_number}
                                  </div>
                                </div>
                                <div className="report-detail-graph-legend-text">
                                  FA
                                </div>
                                <div className="report-detail-graph-legend-inner-block">
                                  <div
                                    className="report-detail-graph-legend-box"
                                    style={{ backgroundColor: "#FAAC15" }}
                                  ></div>
                                  <div className="report-detail-graph-legend-value">
                                    {reportData.analysis?.mtc_number}
                                  </div>
                                </div>
                                <div className="report-detail-graph-legend-text">
                                  MTC
                                </div>
                              </Col>
                              <Col
                                sm={3}
                                className="report-detail-graph-legend-block"
                              >
                                <div className="report-detail-graph-legend-inner-block">
                                  <div
                                    className="report-detail-graph-legend-box"
                                    style={{ backgroundColor: "#086992" }}
                                  ></div>
                                  <div className="report-detail-graph-legend-value">
                                    {reportData.analysis?.rta_number}
                                  </div>
                                </div>
                                <div className="report-detail-graph-legend-text">
                                  RTA
                                </div>
                                <div className="report-detail-graph-legend-inner-block">
                                  <div
                                    className="report-detail-graph-legend-box"
                                    style={{ backgroundColor: "#18DA07" }}
                                  ></div>
                                  <div className="report-detail-graph-legend-value">
                                    {reportData.analysis?.lti_number}
                                  </div>
                                </div>
                                <div className="report-detail-graph-legend-text">
                                  LTI
                                </div>
                              </Col>
                            </Row>
                          </>
                        )}
                        <div className="report-detail-graph-label">
                          NM Analysis
                        </div>
                        {reportData.nm_ua_number == 0 &&
                          reportData.nm_uc_number == 0 ? (
                          <div className="graph-not-available-text">
                            Graph not available
                          </div>
                        ) : (
                          <Row>
                            <Col>
                              <Row
                                style={{
                                  borderRight:
                                    "0.03vw solid rgba(0, 0, 0, 0.1)",
                                }}
                              >
                                <Col sm={6}>
                                  <div style={{ width: "9vw" }}>
                                    <HighchartsReact
                                      highcharts={Highcharts}
                                      options={nmuaUcChart}
                                    />
                                  </div>
                                </Col>
                                <Col
                                  sm={6}
                                  className="report-detail-graph-legend-block"
                                >
                                  <div className="report-detail-graph-legend-inner-block">
                                    <div
                                      className="report-detail-graph-legend-box"
                                      style={{
                                        backgroundColor:
                                          reportData.analysis?.nm_ua_color,
                                      }}
                                    ></div>
                                    <div className="report-detail-graph-legend-value">
                                      {reportData.analysis?.nm_ua_number}
                                    </div>
                                  </div>
                                  <div className="report-detail-graph-legend-text">
                                    Unsafe Act
                                  </div>
                                  <div className="report-detail-graph-legend-inner-block">
                                    <div
                                      className="report-detail-graph-legend-box"
                                      style={{
                                        backgroundColor:
                                          reportData.analysis?.nm_uc_color,
                                      }}
                                    ></div>
                                    <div className="report-detail-graph-legend-value">
                                      {reportData.analysis?.nm_uc_number}
                                    </div>
                                  </div>
                                  <div className="report-detail-graph-legend-text">
                                    Unsafe Condition
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        )}
                        <div className="report-detail-graph-label">
                          UA Vs UC Analysis
                        </div>
                        {reportData.analysis?.ua_number == 0 &&
                          reportData.analysis?.uc_number == 0 ? (
                          <div className="graph-not-available-text">
                            Graph not available
                          </div>
                        ) : (
                          <Row>
                            <Col>
                              <Row
                                style={{
                                  borderRight:
                                    "0.03vw solid rgba(0, 0, 0, 0.1)",
                                }}
                              >
                                <Col sm={6}>
                                  <div style={{ width: "9vw" }}>
                                    <HighchartsReact
                                      highcharts={Highcharts}
                                      options={uaUcChart}
                                    />
                                  </div>
                                </Col>
                                <Col
                                  sm={6}
                                  className="report-detail-graph-legend-block"
                                >
                                  <div className="report-detail-graph-legend-inner-block">
                                    <div
                                      className="report-detail-graph-legend-box"
                                      style={{ backgroundColor: "#C7AC83" }}
                                    ></div>
                                    <div className="report-detail-graph-legend-value">
                                      {reportData.analysis?.unsafe_act}
                                    </div>
                                  </div>
                                  <div className="report-detail-graph-legend-text">
                                    Unsafe Act
                                  </div>
                                  <div className="report-detail-graph-legend-inner-block">
                                    <div
                                      className="report-detail-graph-legend-box"
                                      style={{ backgroundColor: "#554040" }}
                                    ></div>
                                    <div className="report-detail-graph-legend-value">
                                      {reportData.analysis?.unsafe_condition}
                                    </div>
                                  </div>
                                  <div className="report-detail-graph-legend-text">
                                    Unsafe Condition
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Row>
                  </div>
                </Accordion.Collapse>
              </Accordion>
            </div>
          </div>
        </div>

        {this.state.showUser ? (
          <InviteUser
            showUser={this.state.showUser}
            closeUser={this.openInviteUser}
            reportId={this.state.iraId}
            onAddNewUsers={this._addUsersToList}
          />
        ) : null}

        <Modal
          show={this.state.showRecorderModal}
          dialogClassName="modal-90w"
          aria-labelledby="Voice Recording"
          onHide={() => this._showRecorder(false)}
          className="recordingModal"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="obervationRecording">
              <ReactMic
                record={this.state.recordAudio}
                className="sound-wave"
                onStop={this.onStop}
                //strokeColor="#000000"
                mimeType="audio/mp3"
                strokeColor="#000000"
                backgroundColor="#FF4081"
              />
            </div>

            <div className="modal-footer-btn">
              <Button onClick={this.startRecording} className="red-btn">
                <Mic /> Start Recording
              </Button>
              <Button onClick={this.stopRecording} className="gray-btn">
                <MicOff /> Stop Recording
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        {this.state.showLowStoragePopup ? (
          <LowSpace
            message={this.state.lowStorageMessage}
            handleLowSpace={this._handleLowSpace}
          />
        ) : null}
      </div>
    );
  }
}

export default withRouter(IraEditReportingDetail);
