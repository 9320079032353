import React, { Component } from "react";
import CommonDropdown from "../../../../app/shared/components/ReportModal/CommonDropdown";
import { Link } from "react-router-dom";
import { Filter, Search, ChevronDown, Calendar, X, Video } from "react-feather";
import { ReactComponent as SearchIcon } from 'assets/sls-icons/Search/search.svg';
import { Scrollbars } from "react-custom-scrollbars";
import {
  Row,
  Col,
  ProgressBar,
  Accordion,
  Modal,
  Button,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Url from "app/shared/constants/Url";
import axios from "axios";
import InfoComponent from "app/shared/utility/InfoComponent";
import moment from "moment";
import { tooltipMessage } from "app/shared/constants/Messages";
import { ErrorToast } from "app/shared/components/Popups/ToastMessage";
import ExcelIcon from "assets/images/icons/Excel.svg";
import Workbook from "react-excel-workbook";
import FsLightbox from "fslightbox-react";
import { convertDateToUTC } from "app/shared/utility/tzUtilFunction";
import LoadingImage from "app/shared/components/Loader/LoadingImage";
import { Input } from "@salesforce/design-system-react"
import { DateTableComponent as DataTable } from "app/shared/components/ReusableComponents/DataTable";
const qs = require("qs");

export default class IraList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 1,
      dataLoading: false,
      pendingPopup: false,
      inProgressPopup: false,
      completedPopup: false,
      iraListPopup: false,
      startDate: null,
      endDate: null,
      listMode: "kanban",
      pendingStartDate: null,
      pendingEndDate: null,

      progressStartDate: null,
      progressEndDate: null,

      completedStartDate: null,
      completedEndDate: null,

      overdue: 0,
      deviation: 0,
      completed: [],
      pending: [],
      progress: [],
      service: [],
      pendingService: [],
      progressService: [],
      completedService: [],
      serviceSelect: "",
      pendingServiceSelect: "",
      progressServiceSelect: "",
      completedServiceSelect: "",
      activitylist: [],
      pendingActivitylist: [],
      progressActivitylist: [],
      completedActivitylist: [],
      activitySelect: "",
      pendingActivitySelect: "",
      progressActivitySelect: "",
      completedActivitySelect: "",
      departmentlist: [],
      pendingDepartmentlist: [],
      progressDepartmentlist: [],
      completedDepartmentlist: [],
      departmentSelect: "",
      pendingDepartmentSelect: "",
      progressDepartmentSelect: "",
      completedDepartmentSelect: "",
      project: [],
      pendingProject: [],
      progressProject: [],
      completedProject: [],

      projectSelect: "",
      pendingProjectSelect: "",
      progressProjectSelect: "",
      completedProjectSelect: "",
      R_person: [],
      extremeValue: 0,

      fa: 0,
      mtc: 0,
      rta: 0,
      lti: 0,
      fAid: 0,
      oi: 0,
      not: 0,

      pendingFa: 0,
      pendingMtc: 0,
      pendingRta: 0,
      pendingLti: 0,
      pendingfirstAid: 0,
      pendingOi: 0,
      pendingNotable: 0,

      progressFa: 0,
      progressMtc: 0,
      progressRta: 0,
      progressLti: 0,
      progressfirstAid: 0,
      progressOi: 0,
      progressNotable: 0,

      completedFa: 0,
      completedMtc: 0,
      completedRta: 0,
      completedLti: 0,
      completedfirstAid: 0,
      completedOi: 0,
      completedNotable: 0,

      all: 0,
      allValue: [],
      allS: [],
      severity: [],
      pendingSeverity: [],
      progressSeverity: [],
      completedSeverity: [],
      personSelect: [],
      CategoryFilter: [],
      hasMore: [],
      dflag: JSON.parse(localStorage.getItem("userData")),
      iraFromOrigin: "kanban",
      isFilterApplied: false,
      isProgressFilterApplied: false,
      isCompletedFilterApplied: false,

      fsToggler: false,
      toggler: false,
      lightboxactive: false,
      evidencelightbox: [],
      evidencelightboxType: [],

      pendingFilterFlag: 0,
      pendingFilterPageNo: 1,

      inprogressFilterFlag: 0,
      inprogressFilterPageNo: 1,

      completedFilterFlag: 0,
      completedFilterPageNo: 1,
    };
    this._getService();
    //  this._getProject();
    this._getActivity();
    this._getDepartment();
    this.selectSeverity = this.selectSeverity.bind(this);
    this.child = React.createRef();
  }

  // componentDidMount() {
  //   this._getService();
  //   this._getProject();
  //   this._getActivity();
  //   this._getDepartment();
  // }

  pendingModal = (visible) => {
    if (this.props.iraPending?.length || this.state.isFilterApplied) {
      this.setState({ pendingPopup: visible, isFilterApplied: true });
    } else {
      ErrorToast("Filter Can't apply on empty list");
    }
  };

  inProgressModal = (visible) => {
    if (this.props.iraProgress?.length || this.state.isProgressFilterApplied) {
      this.setState({
        inProgressPopup: visible,
        isProgressFilterApplied: true,
      });
    } else {
      ErrorToast("Filter Can't apply on empty list");
    }
  };

  completedModal = (visible) => {
    if (
      this.props.iraCompleted?.length ||
      this.state.isCompletedFilterApplied
    ) {
      this.setState({
        completedPopup: visible,
        isCompletedFilterApplied: true,
      });
    } else {
      ErrorToast("Filter Can't apply on empty list");
    }
  };

  _getService = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    axios
      .post(
        Url.serviceList,
        qs.stringify({
          auth_key: token,
          user_id: userId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            service: response.data.data,
            pendingService: response.data.data,
            progressService: response.data.data,
            completedService: response.data.data,
            dataLoading: false,
          });
          if (this.state.dflag.designation_flag == 4) {
            var serviceobs = this.state.service.map((item) => item.service_id);
            this.setState({ serviceSelect: serviceobs[0] });
            this._getProject("pending", serviceobs[0]);
            this._getProject("completed", serviceobs[0]);
            this._getProject("progress", serviceobs[0]);
          }
        }
        // console.log(this.state.service ? this.state.service : 0)
      })
      .catch((error) => {
        throw error;
      });
  };

  _getProject = async (
    type = "pending",
    serviceId = this.state.pendingServiceSelect
  ) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    axios
      .post(
        Url.projectList,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          service_id: serviceId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          switch (type) {
            case "pending":
              this.setState({
                pendingProject: response.data.data,
                dataLoading: false,
              });
              if (this.state.dflag.designation_flag == 4) {
                var projectobs = this.state.pendingProject.map(
                  (item) => item.project_id
                );
                this.setState({ pendingProjectSelect: projectobs[0] });
              }
              break;

            case "progress":
              this.setState({
                progressProject: response.data.data,
                dataLoading: false,
              });
              if (this.state.dflag.designation_flag == 4) {
                var projectobs = this.state.progressProject.map(
                  (item) => item.project_id
                );
                this.setState({ progressProjectSelect: projectobs[0] });
              }
              break;

            case "completed":
              this.setState({
                completedProject: response.data.data,
                dataLoading: false,
              });
              if (this.state.dflag.designation_flag == 4) {
                var projectobs = this.state.completedProject.map(
                  (item) => item.project_id
                );
                this.setState({ completedProjectSelect: projectobs[0] });
              }
              break;

            default:
              break;
          }
        }
        // console.log('result: ',this.state.project ? this.state.project : 0)
      })
      .catch((error) => {
        throw error;
      });
  };
    
  _fetchActivity= async (service,project) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");
    axios
      .post(
        Url.activitylist,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          service_id: service,
          project_id: project,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          // console.log(response.data)
          this.setState({
            pendingActivitylist: response.data.data,
            progressActivitylist: response.data.data,
            completedActivitylist: response.data.data,
            dataLoading: false,
          });
        }
      })
      .catch((error) => {
        throw error;
      });

  }
    
  _getActivity = async () => {
    
    
    // if(!this.state.serviceSelect || !this.state.projectSelect)return;
    if(this.state.serviceSelect && this.state.projectSelect){
      this._fetchActivity(this.state.serviceSelect,this.state.projectSelect)
    }
    if(this.state.pendingServiceSelect && this.state.pendingProjectSelect){
      this._fetchActivity(this.state.pendingServiceSelect,this.state.pendingProjectSelect)
    }
    if(this.state.progressServiceSelect && this.state.progressProjectSelect){
      this._fetchActivity(this.state.progressServiceSelect,this.state.progressProjectSelect)
    }
  };

  _fetchDepartment=async (service,project) => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    axios
      .post(
        Url.departmentList,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          service_id: service,
          project_id: project,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({
            pendingDepartmentlist: response.data.data,
            progressDepartmentlist: response.data.data,
            completedDepartmentlist: response.data.data,
            dataLoading: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });

   
  };

  _getDepartment =async () => {
    
    
    // if(!this.state.serviceSelect || !this.state.projectSelect)return;
    if(this.state.serviceSelect && this.state.projectSelect){
      this._fetchDepartment(this.state.serviceSelect,this.state.projectSelect)
    }
    if(this.state.pendingServiceSelect && this.state.pendingProjectSelect){
      this._fetchDepartment(this.state.pendingServiceSelect,this.state.pendingProjectSelect)
    }
    if(this.state.progressServiceSelect && this.state.progressProjectSelect){
      this._fetchDepartment(this.state.progressServiceSelect,this.state.progressProjectSelect)
    }
  };

  

  resetState() {
    this.setState({
      fa: 0,
      mtc: 0,
      rta: 0,
      lti: 0,
      not: 0,
      fAid: 0,
      oi: 0,
      startDate: null,
      severity: [],
      endDate: null,
      serviceSelect: null,
      activitySelect: null,
      departmentSelect: null,
      projectSelect: null,
      categorySelect: null,
      personSelect: null,
      overdue: 0,
      all: 0,
    });
  }

  pendingResetState() {
    this.setState({
      pendingFa: 0,
      pendingMtc: 0,
      pendingRta: 0,
      pendingLti: 0,
      pendingfirstAid: 0,
      pendingOi: 0,
      pendingNotable: 0,
      pendingStartDate: null,
      pendingSeverity: [],
      pendingEndDate: null,
      pendingServiceSelect: null,
      pendingActivitySelect: null,
      pendingDepartmentSelect: null,
      pendingProjectSelect: null,
      pendingCategorySelect: null,
      pendingPersonSelect: null,
      overdue: 0,
      pendingAll: 0,
    });
  }

  progressResetState() {
    this.setState({
      progressFa: 0,
      progressMtc: 0,
      progressRta: 0,
      progressLti: 0,
      progressfirstAid: 0,
      progressOi: 0,
      progressNotable: 0,
      progressStartDate: null,
      progressSeverity: [],
      progressEndDate: null,
      progressServiceSelect: null,
      progressActivitySelect: null,
      progressDepartmentSelect: null,
      progressProjectSelect: null,
      progressCategorySelect: null,
      progressPersonSelect: null,
      overdue: 0,
      progressAll: 0,
    });
  }

  completedResetState() {
    this.setState({
      completedFa: 0,
      completedMtc: 0,
      completedRta: 0,
      completedLti: 0,
      completedfirstAid: 0,
      completedOi: 0,
      completedNotable: 0,
      completedStartDate: null,
      completedSeverity: [],
      completedEndDate: null,
      completedServiceSelect: null,
      completedActivitySelect: null,
      completedDepartmentSelect: null,
      completedProjectSelect: null,
      completedCategorySelect: null,
      completedPersonSelect: null,
      deviation: 0,
      completedAll: 0,
    });
  }

  selectSeverity(e) {
    const severity = this.state.severity;
    let index;

    if (e.target.checked) {
      severity.push(+e.target.value);
    } else {
      index = severity.indexOf(+e.target.value);
      severity.splice(index, 1);
    }
    this.setState({ severity: severity });
  }

  pendingSelectSeverity(e) {
    const severity = this.state.pendingSeverity;
    let index;
    debugger
    if (e.target.checked) {
      severity.push(+e.target.value);
    } else {
      index = severity.indexOf(+e.target.value);
      severity.splice(index, 1);
    }
    this.setState({ pendingSeverity: severity });
  }

  progressSelectSeverity(e) {
    const severity = this.state.progressSeverity;
    let index;

    if (e.target.checked) {
      severity.push(+e.target.value);
    } else {
      index = severity.indexOf(+e.target.value);
      severity.splice(index, 1);
    }
    this.setState({ progressSeverity: severity });
  }

  completedSelectSeverity(e) {
    const severity = this.state.completedSeverity;
    let index;

    if (e.target.checked) {
      severity.push(+e.target.value);
    } else {
      index = severity.indexOf(+e.target.value);
      severity.splice(index, 1);
    }
    this.setState({ completedSeverity: severity });
  }

  FilterData(param, value) {
    if (value == 5) {
      if (param == 0) {
        this.setState({ fAid: 5, all: 0 });
      } else {
        this.setState({ fAid: 0, all: 0 });
      }
    }
    if (value == 6) {
      if (param == 0) {
        this.setState({ oi: 6, all: 0 });
      } else {
        this.setState({ oi: 0, all: 0 });
      }
    }
    if (value == 7) {
      if (param == 0) {
        this.setState({ not: 7, all: 0 });
      } else {
        this.setState({ not: 0, all: 0 });
      }
    }
    if (value == 4) {
      if (param == 0) {
        this.setState({ fa: 4, all: 0 });
      } else {
        this.setState({ fa: 0, all: 0 });
      }
    }
    if (value == 3) {
      if (param == 0) {
        this.setState({ mtc: 3, all: 0 });
      } else {
        this.setState({ mtc: 0, all: 0 });
      }
    }
    if (value == 2) {
      if (param == 0) {
        this.setState({ rta: 2, all: 0 });
      } else {
        this.setState({ rta: 0, all: 0 });
      }
    }
    if (value == 1) {
      if (param == 0) {
        this.setState({ lti: 1, all: 0 });
      } else {
        this.setState({ lti: 0, all: 0 });
      }
    }
    if (value == "overdue") {
      if (param == 0) {
        this.setState({ overdue: 1, all: 0 });
      } else {
        this.setState({ overdue: 0, all: 0 });
      }
    }
    if (value == "all") {
      if (param == 0) {
        this.setState({
          all: 1,
          fa: 4,
          mtc: 3,
          rta: 2,
          lti: 1,
          not: 7,
          oi: 6,
          fAid: 5,
          severity: [1, 2, 3, 4, 5, 6, 7],
        });
      } else {
        this.setState({
          all: 0, fa: 0, mtc: 0, rta: 0, lti: 0, not: 0,
          oi: 0,
          fAid: 0, severity: []
        });
      }
    }
  }

  pendingFilterData = (param, value) => {
    if (value == 5) {
      if (param == 0) {
        this.setState({ pendingfirstAid: 5, progressAll: 0 });
      } else {
        this.setState({ pendingfirstAid: 0, progressAll: 0 });
      }
    }
    if (value == 6) {
      if (param == 0) {
        this.setState({ pendingOi: 6, progressAll: 0 });
      } else {
        this.setState({ pendingOi: 0, progressAll: 0 });
      }
    }
    if (value == 7) {
      if (param == 0) {
        this.setState({ pendingNotable: 7, progressAll: 0 });
      } else {
        this.setState({ pendingNotable: 0, progressAll: 0 });
      }
    }
    if (value == 4) {
      if (param == 0) {
        this.setState({ pendingFa: 4, pendingAll: 0 });
      } else {
        this.setState({ pendingFa: 0, pendingAll: 0 });
      }
    }
    if (value == 3) {
      if (param == 0) {
        this.setState({ pendingMtc: 3, pendingAll: 0 });
      } else {
        this.setState({ pendingMtc: 0, pendingAll: 0 });
      }
    }
    if (value == 2) {
      if (param == 0) {
        this.setState({ pendingRta: 2, pendingAll: 0 });
      } else {
        this.setState({ pendingRta: 0, pendingAll: 0 });
      }
    }
    if (value == 1) {
      if (param == 0) {
        this.setState({ pendingLti: 1, pendingAll: 0 });
      } else {
        this.setState({ pendingLti: 0, pendingAll: 0 });
      }
    }
    if (value == "overdue") {
      if (param == 0) {
        this.setState({ overdue: 1, pendingAll: 0 });
      } else {
        this.setState({ overdue: 0, pendingAll: 0 });
      }
    }
    if (value == "all") {
      if (param == 0) {
        this.setState({
          pendingAll: 1,
          pendingFa: 4,
          pendingMtc: 3,
          pendingRta: 2,
          pendingLti: 1,
          pendingfirstAid: 5,
          pendingOi: 6,
          pendingNotable: 7,
          pendingSeverity: [1, 2, 3, 4, 5, 6, 7],
        });
      } else {
        this.setState({
          pendingAll: 0,
          pendingFa: 0,
          pendingMtc: 0,
          pendingRta: 0,
          pendingLti: 0,
          pendingfirstAid: 0,
          pendingOi: 0,
          pendingNotable: 0,
          pendingSeverity: [],
        });
      }
    }
  };

  progressFilterData(param, value) {
    if (value == 5) {
      if (param == 0) {
        this.setState({ progressfirstAid: 5, progressAll: 0 });
      } else {
        this.setState({ progressfirstAid: 0, progressAll: 0 });
      }
    }
    if (value == 6) {
      if (param == 0) {
        this.setState({ progressOi: 6, progressAll: 0 });
      } else {
        this.setState({ progressOi: 0, progressAll: 0 });
      }
    }
    if (value == 7) {
      if (param == 0) {
        this.setState({ progressNotable: 7, progressAll: 0 });
      } else {
        this.setState({ progressNotable: 0, progressAll: 0 });
      }
    }
    if (value == 4) {
      if (param == 0) {
        this.setState({ progressFa: 4, progressAll: 0 });
      } else {
        this.setState({ progressFa: 0, progressAll: 0 });
      }
    }
    if (value == 3) {
      if (param == 0) {
        this.setState({ progressMtc: 3, progressAll: 0 });
      } else {
        this.setState({ progressMtc: 0, progressAll: 0 });
      }
    }
    if (value == 2) {
      if (param == 0) {
        this.setState({ progressRta: 2, progressAll: 0 });
      } else {
        this.setState({ progressRta: 0, progressAll: 0 });
      }
    }
    if (value == 1) {
      if (param == 0) {
        this.setState({ progressLti: 1, progressAll: 0 });
      } else {
        this.setState({ progressLti: 0, progressAll: 0 });
      }
    }
    if (value == "overdue") {
      if (param == 0) {
        this.setState({ overdue: 1, progressAll: 0 });
      } else {
        this.setState({ overdue: 0, progressAll: 0 });
      }
    }
    if (value == "all") {
      if (param == 0) {
        this.setState({
          progressAll: 1,
          progressFa: 4,
          progressMtc: 3,
          progressRta: 2,
          progressLti: 1,
          progressfirstAid: 5,
          progressOi: 6,
          progressNotable: 7,
          progressSeverity: [1, 2, 3, 4, 5, 6, 7],
        });
      } else {
        this.setState({
          progressAll: 0,
          progressFa: 0,
          progressMtc: 0,
          progressRta: 0,
          progressLti: 0,
          progressfirstAid: 0,
          progressOi: 0,
          progressNotable: 0,
          progressSeverity: [],
        });
      }
    }
  }

  completedFilterData(param, value) {
    if (value == 5) {
      if (param == 0) {
        this.setState({ completedfirstAid: 5, progressAll: 0 });
      } else {
        this.setState({ completedfirstAid: 0, progressAll: 0 });
      }
    }
    if (value == 6) {
      if (param == 0) {
        this.setState({ completedOi: 6, progressAll: 0 });
      } else {
        this.setState({ completedOi: 0, progressAll: 0 });
      }
    }
    if (value == 7) {
      if (param == 0) {
        this.setState({ completedNotable: 7, progressAll: 0 });
      } else {
        this.setState({ completedNotable: 0, progressAll: 0 });
      }
    }
    if (value == 4) {
      if (param == 0) {
        this.setState({ completedFa: 4, completedAll: 0 });
      } else {
        this.setState({ completedFa: 0, completedAll: 0 });
      }
    }
    if (value == 3) {
      if (param == 0) {
        this.setState({ completedMtc: 3, completedAll: 0 });
      } else {
        this.setState({ completedMtc: 0, completedAll: 0 });
      }
    }
    if (value == 2) {
      if (param == 0) {
        this.setState({ completedRta: 2, completedAll: 0 });
      } else {
        this.setState({ completedRta: 0, completedAll: 0 });
      }
    }
    if (value == 1) {
      if (param == 0) {
        this.setState({ completedLti: 1, completedAll: 0 });
      } else {
        this.setState({ completedLti: 0, completedAll: 0 });
      }
    }
    if (value == "deviation") {
      if (param == 0) {
        this.setState({ deviation: 1, completedAll: 0 });
      } else {
        this.setState({ deviation: 0, completedAll: 0 });
      }
    }
    if (value == "all") {
      if (param == 0) {
        this.setState({
          completedAll: 1,
          completedFa: 4,
          completedMtc: 3,
          completedRta: 2,
          completedLti: 1,
          completedfirstAid: 5,
          completedOi: 6,
          completedNotable: 7,
          completedSeverity: [1, 2, 3, 4, 5, 6, 7],
        });
      } else {
        this.setState({
          completedAll: 0,
          completedFa: 0,
          completedMtc: 0,
          completedRta: 0,
          completedLti: 0,
          completedfirstAid: 0,
          completedOi: 0,
          completedNotable: 0,
          completedSeverity: [],
        });
      }
    }
  }

  _handleServiceChange = async (event) => {
    const selectedServiceID = event.target.value;
    if (selectedServiceID) {
      await this.setState({ serviceSelect: selectedServiceID });
      this._getProject();
    }
  };

  _handlePendingServiceChange = async (event) => {
    
    const selectedServiceID = event.target.value;
    if (selectedServiceID) {
      await this.setState({ pendingServiceSelect: selectedServiceID });
      this._getProject("pending", selectedServiceID);
      this._getActivity()
    }
  };

  _handleProgressServiceChange = async (event) => {
    const selectedServiceID = event.target.value;
    if (selectedServiceID) {
      await this.setState({ progressServiceSelect: selectedServiceID });
      this._getProject("progress", selectedServiceID);
    }
  };

  _handleCompletedServiceChange = async (event) => {
    const selectedServiceID = event.target.value;
    if (selectedServiceID) {
      await this.setState({ completedServiceSelect: selectedServiceID });
      this._getProject("completed", selectedServiceID);
    }
  };

  _handleCompletedListFilter = () => {
    try {
      let { ...data } = this.state;

      const start_date = data.completedStartDate
        ? convertDateToUTC(data.completedStartDate, "YYYY-MM-DD")
        : "";
      const end_date = data.completedEndDate
        ? convertDateToUTC(data.completedEndDate, "YYYY-MM-DD")
        : "";

      this.props.iraCompletedListFilter(
        data.completedSeverity,
        data.completedServiceSelect,
        data.completedProjectSelect,
        data.completedActivitySelect,
        data.completedDepartmentSelect,
        start_date,
        end_date,
        data.deviation,
        data.completedFilterPageNo
      );
      this.completedModal(false);
      this.setState({
        completedFilterFlag: 1,
      });
    } catch (error) { }
  };

  _completedPaginationFilter = () => {
    try {
      let { ...data } = this.state;

      const start_date = data.completedStartDate
        ? convertDateToUTC(data.completedStartDate, "YYYY-MM-DD")
        : "";
      const end_date = data.completedEndDate
        ? convertDateToUTC(data.completedEndDate, "YYYY-MM-DD")
        : "";

      this.props.iraCompletedListFilter(
        data.completedSeverity,
        data.completedServiceSelect,
        data.completedProjectSelect,
        data.completedActivitySelect,
        data.completedDepartmentSelect,
        start_date,
        end_date,
        data.deviation
        // data.completedFilterPageNo,
      );
      this.completedModal(false);
      this.setState({
        completedFilterFlag: 1,
      });
    } catch (error) { }
  };

  _handleInProgressListFilter = () => {
    try {
      let { ...data } = this.state;

      const start_date = data.progressStartDate
        ? convertDateToUTC(data.progressStartDate, "YYYY-MM-DD")
        : "";
      const end_date = data.progressEndDate
        ? convertDateToUTC(data.progressEndDate, "YYYY-MM-DD")
        : "";

      this.props.iraProgressListFilter(
        data.progressSeverity,
        data.progressServiceSelect,
        data.progressProjectSelect,
        data.progressActivitySelect,
        data.progressDepartmentSelect,
        start_date,
        end_date,
        data.overdue,
        data.inprogressFilterPageNo
      );
      this.inProgressModal(false);
      this.setState({
        inprogressFilterFlag: 1,
      });
    } catch (error) { }
  };

  _inProgressFilterPagination = () => {
    try {
      let { ...data } = this.state;

      const start_date = data.progressStartDate
        ? convertDateToUTC(data.progressStartDate, "YYYY-MM-DD")
        : "";
      const end_date = data.progressEndDate
        ? convertDateToUTC(data.progressEndDate, "YYYY-MM-DD")
        : "";

      this.props.iraProgressListFilter(
        data.progressSeverity,
        data.progressServiceSelect,
        data.progressProjectSelect,
        data.progressActivitySelect,
        data.progressDepartmentSelect,
        start_date,
        end_date,
        data.overdue
        // data.inprogressFilterPageNo,
      );
      this.inProgressModal(false);
      this.setState({
        inprogressFilterFlag: 1,
      });
    } catch (error) { }
  };

  _handlePendingListFilter = () => {
    try {
      let { ...data } = this.state;

      const start_date = data.pendingStartDate
        ? convertDateToUTC(data.pendingStartDate, "YYYY-MM-DD")
        : "";
      const end_date = data.pendingEndDate
        ? convertDateToUTC(data.pendingEndDate, "YYYY-MM-DD")
        : "";

      this.props.iraPendingListFilter(
        data.pendingSeverity,
        data.pendingServiceSelect,
        data.pendingProjectSelect,
        data.pendingActivitySelect,
        data.pendingDepartmentSelect,
        start_date,
        end_date,
        data.pendingFilterPageNo
      );
      this.pendingModal(false);

      this.setState({
        pendingFilterFlag: 1,
      });
    } catch (error) { }
  };

  _pendingFilterPagination = () => {
    try {
      let { ...data } = this.state;

      const start_date = data.pendingStartDate
        ? convertDateToUTC(data.pendingStartDate, "YYYY-MM-DD")
        : "";
      const end_date = data.pendingEndDate
        ? convertDateToUTC(data.pendingEndDate, "YYYY-MM-DD")
        : "";

      this.props.iraPendingListFilter(
        data.pendingSeverity,
        data.pendingServiceSelect,
        data.pendingProjectSelect,
        data.pendingActivitySelect,
        data.pendingDepartmentSelect,
        start_date,
        end_date
        // data.pendingFilterPageNo
      );
      this.pendingModal(false);

      this.setState({
        pendingFilterFlag: 1,
      });
    } catch (error) { }
  };

  _handleFilterReset = async (moduleName = "pending") => {
    switch (moduleName) {
      case "pending":
        this.props.filterReset("pending");
        this.pendingResetState();
        this.pendingModal(false);
        break;
      case "progress":
        this.props.filterReset("progress");
        this.progressResetState();
        this.inProgressModal(false);
        break;
      case "completed":
        this.props.filterReset("completed");
        this.completedResetState();
        this.completedModal(false);
        break;
      default:
        break;
    }

    this.setState({
      pendingFilterFlag: 0,
      completedFilterFlag: 0,
      inprogressFilterFlag: 0,
    });
  };

  comapareTargetDate = (date = "") => {
    if (date) {
      const targetDate = moment(date, "DD/MM/YYYY");
      const today = moment();
      if (targetDate.isSame(today, "day")) {
        return true;
      }
      return false;
    }
  };

  _handleLightBox = async (list) => {
    console.log(list.map((item) => item.format));
    await this.setState({
      lightboxactive: true,
      fsToggler: !this.state.fsToggler,
      evidencelightbox: list.map((item) => item.link),
      evidencelightboxType: list.map((item) =>
        item.format == "img" ? "image" : item.format
      ),
    });
  };

  _handlePendingScrollbarPagination = () => {
    if (this.state.pendingFilterFlag == 1) {
      if (this.props.pendingFilterCount != this.props.iraPending.length) {
        this._pendingFilterPagination();
      }
    } else {
      if (this.props.iraPending.length != this.props.iraPendingCount) {
        this.props._getiraList(this.props.iraPending.length / 10 + 1);
      }
    }
  };

  _handleInprogressScrollbarPagination = () => {
    if (this.state.inprogressFilterFlag == 1) {
      if (this.props.iraProgress.length != this.props.inprogressFilterCount) {
        this._inProgressFilterPagination();
      }
    } else {
      if (this.props.iraProgress.length != this.props.iraProgressCount) {
        this.props._getiraProgressList(this.props.iraProgress.length / 10 + 1);
      }
    }
  };

  _handleCompletedScrollbarPagination = () => {
    if (this.state.completedFilterFlag == 1) {
      if (this.props.completeFilterCount != this.props.iraCompleted.length) {
        this._completedPaginationFilter();
      }
    } else {
      if (this.props.iraCompleted.length != this.props.iraCompletedCount) {
        this.props._getiraCompletedList(
          this.props.iraCompleted.length / 10 + 1
        );
      }
    }
  };

  __toggleGridView = (tabName) => {
    if (this.state.listMode !== tabName) {
      this.setState({ listMode: tabName })
    }
  }
  __generateTableBody = (data, isPending = false, isDone = false) => {
    const urlType = !isPending && !isDone ? "pending" : isPending && !isDone ? "progress" : "completed";
    return <tr>
      <td > {data.incident_type ?? data?.severity}</td>
      <td> <Link
        to={`/ira-detail/${data.ira_id}/kanban/${urlType}`}
      >{data.activity}</Link></td>
      <td>
        {data.evidence.length ? (
          <>
            {data.evidence[0]?.format === "video" ? <img src={require("assets/sls-icons/V2. New Development/Video Player (1).svg")}
              className="cursor-pointer svg-icons" onClick={() => this.setState({ videoUrl: data.evidence[0]?.link })} /> : <div
              >
              <img
                className="tracker-right cursor-pointer rounded"
                src={data.evidence[0]?.link}
                title="Evidence"
                onClick={() => this._handleLightBox(data.evidence)}
              />
            </div>

            }

          </>
        ) : <div
        >
          <img
            className="tracker-right "
            src={require("assets/images/icons/fimage.svg")}
            title="Evidence"
          />
        </div>}
      </td>
      <td>{data.service || "NA"}</td>
      <td>{data.project || "NA"}</td>
      <td> <img src={data.reported_by_pic} className="user-icon " /> {data.reported_by || "NA"} </td>
      <td>
        {!isPending && !isDone ? <div
          className="badge  badge-warning py-2 common-white-2"
        >{data.report_date || "NA"}</div> : data.report_date || "NA"}
      </td>
      {isPending ? <>
        <td>{data.team != undefined &&
          data.team.map((team, index) => (
            <div key={index}>
              {team.status == 1 && (
                <div className="people-lead border-0 d-flex align-items-center">
                  <img
                    src={team.pic}
                    className="people-img"
                    title={team.name}
                  />
                  <div >
                    <span className="font-16 font-weight-500">{team.name}</span>
                  </div>
                </div>
              )}
            </div>
          ))}</td>
        <td>
          {isPending && !isDone ? <div


            className="badge  badge-warning py-2 common-white-2"
          >{data.target_date || "NA"}</div> : data.target_date || "NA"}
        </td>
        {isDone ? <td><div
          className="badge badge-danger py-2"

        >{data.target_date || "NA"}</div> </td> : null}


        {/* <td className="overflow-auto ">
          <Scrollbars style={{ width: '6vw', height: "3vw" }}><div className="d-flex">{data.team != undefined &&
            data.team.map((team, index) => (
              <div key={index} className=" mr-2">
                {team.status == 0 && (
                  <div className="people-normal  ">
                    <img
                      src={team.pic}
                      className="people-normal-img"
                      title={team.name}
                    />
                  </div>
                )}
              </div>
            ))}</div>
          </Scrollbars></td> */}
      </>
        : null
      }
      {isPending || isDone ? <td>
        {(data.overdue ||
          data.overdue_days ||
          data.deviation_days) != "0 Days" && (
            <p className="nm-label">
              <span className="days">
                {data.overdue_days ||
                  data.overdue ||
                  data.deviation_days}
              </span>
            </p>
          )}
      </td> : null
      }

    </tr>
  }
  getFieldProps = (data, isPending = false, isDone = false) => {
    let columns = ["I - Type", "Activity", "Evidence", "Service", " Plant / Project", "Reported By", "Reported On",];
    if (isPending) {
      columns = [...columns, "Team Lead", "Due Date", `${isPending && !isDone ? "Overdue" : "Deviation"}`]
    }
    if (isDone) {
      columns.splice(columns.length - 2, 0, "Closed On");
    }
    return {
      data,
      columns,
      bodyRendrer: el => this.__generateTableBody(el, isPending, isDone)
    }
  }
  render() {
    const { iraPendingexport, iraProgressexport, iraCompletedexport } = this.props.irReportData ?? {}
    return (
      <div className="open-section nm-list-section kanban-board-wrapper">
        {!!this.state.lightboxactive && (
          <FsLightbox
            toggler={this.state.fsToggler}
            types={this.state.evidencelightboxType}
            disableLocalStorage={true}
            sources={this.state.evidencelightbox}
          />
        )}
        <div
          className="loader"
          style={{ display: this.state.dataLoading ? "block" : "none" }}
        >
          <LoadingImage />
        </div>
        <div className="mt-4">
          <div className="align-items-center d-flex justify-content-between ">

            <div className="d-flex">
              <div class="slds-form-element">

                <div className="slds-form-element__control slds-input-has-icon slds-input-has-icon_left">
                  <div className="input-wrapper">
                    <SearchIcon className="search-icon" style={{ height: "1vw", width: "1vw" }} />
                    <input
                      name="search-filter"
                      type="text"

                      id="text-input-id-46"
                      placeholder="Search by activity in UAUC, NM, IRA and Good Act...."
                      required
                      multiple
                      onChange={(e) => this.props.handleSearch(e)}
                      value={this.props.search}
                      className="slds-input"
                      style={{ width: "20vw", textOverflow: "ellipsis", flexGrow: 1 }}
                    />
                  </div>
                </div>


              </div>
            </div>
            <div>
              <div className="align-items-center d-flex">
                <div className="d-flex">
                  <div className="common-control-button-1 px-1 py-1 cursor-pointer common-border">
                    <img
                      src={this.state.listMode === "kanban" ? require("assets/sls-icons/Kanban/kanban.svg") : require("assets/sls-icons/Kanban/kanban1.svg")}
                      className="svg-kanban-uauc"
                      onClick={() => this.__toggleGridView("kanban")}
                    />
                  </div>

                  <div className="common-control-button-1 px-1 py-1 cursor-pointer common-border">
                    <img
                      src={this.state.listMode === "list" ? require("assets/sls-icons/Table/1.svg") : require("assets/sls-icons/Table/picklist_type.svg")}
                      className="svg-list-uauc"
                      onClick={() => this.__toggleGridView("list")}
                    />
                  </div>

                  <div>
                    <Workbook
                      filename="IRAList.xlsx"
                      element={
                        <Button className="icon-btn common-control-button-1 px-1 py-1 cursor-pointer common-border" title="Download Excel">
                          <img src={ExcelIcon} />
                        </Button>
                      }
                    >
                      <Workbook.Sheet
                        data={iraPendingexport}
                        name="Pending list"
                      >
                        <Workbook.Column label="ACTIVITY" value="activity" />
                        <Workbook.Column label="CONTRACTOR" value="contractor" />
                        <Workbook.Column label="DEPARTMENT" value="department" />
                        
                        <Workbook.Column
                          label="INCIDENT DATE"
                          value="incident_date"
                        />
                        <Workbook.Column
                          label="INCIDENT TIME"
                          value="incident_time"
                        />
                        <Workbook.Column label="OBSERVATION" value="observation" />
                        <Workbook.Column label="OVERDUE DAYS" value="overdue_days" />
                        <Workbook.Column label="PROJECT" value="project" />
                        <Workbook.Column label="REPORT DATE" value="report_date" />
                        <Workbook.Column label="REPORTED BY" value="reported_by" />
                        <Workbook.Column label="SERVICE" value="service" />
                        <Workbook.Column label="INCIDENT-TYPE" value="incident_type" />
                        <Workbook.Column label="LOCATION" value="location" />
                        <Workbook.Column label="ZONE" value="zone" />
                      </Workbook.Sheet>
                      <Workbook.Sheet
                        data={iraProgressexport}
                        name="InProgress list"
                      >
                        <Workbook.Column label="ACTIVITY" value="activity" />
                        <Workbook.Column label="CONTRACTOR" value="contractor" />
                        <Workbook.Column label="DEPARTMENT" value="department" />
                        
                        <Workbook.Column
                          label="INCIDENT DATE"
                          value="incident_date"
                        />
                        <Workbook.Column
                          label="INCIDENT TIME"
                          value="incident_time"
                        />
                        <Workbook.Column label="OBSERVATION" value="observation" />
                        <Workbook.Column label="OVERDUE DAYS" value="overdue_days" />
                        <Workbook.Column label="PROJECT" value="project" />
                        <Workbook.Column label="REPORT DATE" value="report_date" />
                        <Workbook.Column label="REPORTED BY" value="reported_by" />
                        <Workbook.Column label="SERVICE" value="service" />
                        <Workbook.Column label="INCIDENT-TYPE" value="incident_type" />
                        <Workbook.Column label="LOCATION" value="location" />
                        <Workbook.Column label="ZONE" value="zone" />
                      </Workbook.Sheet>
                      <Workbook.Sheet
                        data={iraCompletedexport}
                        name="Completed list"
                      >
                        <Workbook.Column label="ACTIVITY" value="activity" />
                        <Workbook.Column label="CONTRACTOR" value="contractor" />
                        <Workbook.Column label="DEPARTMENT" value="department" />
                        
                        <Workbook.Column
                          label="INCIDENT DATE"
                          value="incident_date"
                        />
                        <Workbook.Column
                          label="INCIDENT TIME"
                          value="incident_time"
                        />
                        <Workbook.Column label="OBSERVATION" value="observation" />
                        <Workbook.Column label="OVERDUE DAYS" value="overdue_days" />
                        <Workbook.Column label="PROJECT" value="project" />
                        <Workbook.Column label="REPORT DATE" value="report_date" />
                        <Workbook.Column label="REPORTED BY" value="reported_by" />
                        <Workbook.Column label="SERVICE" value="service" />
                        <Workbook.Column label="INCIDENT-TYPE" value="incident_type" />
                        <Workbook.Column label="LOCATION" value="location" />
                        <Workbook.Column label="ZONE" value="zone" />
                      </Workbook.Sheet>
                    </Workbook>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="common-seprator-grey-3 mb-4 mt-3"></div>
        </div>
        <div className={`open-divide ${this.state.listMode === "list" ? "w-100" : ""}`}>
          <div className="open-head pending">
            <h2>
              PENDING |{" "}
              {this.state.pendingFilterFlag == 1
                ? this.props.pendingFilterCount || 0
                : this.props.iraPendingCount || 0}
            </h2>
            <div className="d-flex align-items-center">
              <div
                className="search-bar-wrapper mr-2 py-1 px-1 "
              >
                <div className="mr-3">
                  <input
                    type="text"
                    placeholder="Search by category, activity, act, service, location & reported by"
                    name="search"
                    value={this.state.searchList}
                    onChange={(e) => this.props._handlePendingSearch(e)}
                  />

                </div>
                <div className="common-white-1">
                  <Search style={{ height: "15px" }} />
                </div>
              </div>
              <span title="Filter">
                <img src={require("assets/sls-icons/V2. New Development/funnel-s.svg")}
                  className="cursor-pointer svg-icons1"
                  onClick={() => {
                    this.pendingModal(true);
                  }}
                />
              </span>
              <InfoComponent
                tooltipText={"Assign team to start Investigation"}
              />
            </div>
          </div>

          {this.state.listMode === "list" ?
            <div className="mt-3 ">
              <DataTable {...this.getFieldProps(this.props.iraPending)}
                scrollProps={{
                  onScrollStop: this.props.iraPending.length != this.props.pendingFilterCount ||
                    this.props.iraPending.length != this.props.iraPendingCount ? this._handlePendingScrollbarPagination : {}
                }} />
            </div> :
            <Scrollbars
              style={{ height: `calc(100vh - 18vw)` }}
              renderTrackHorizontal={(props) => (
                <div
                  {...props}
                  className="track-horizontal"
                  style={{ display: "none" }}
                />
              )}
              renderThumbHorizontal={(props) => (
                <div
                  {...props}
                  className="thumb-horizontal"
                  style={{ display: "none" }}
                />
              )}
              renderThumbVertical={(props) => (
                <div
                  {...props}
                  style={{
                    position: "relative",
                    display: "block",
                    width: "100%",
                    cursor: "pointer",
                    borderRadius: "inherit",
                    backgroundColor: "rgba(0, 0, 0, 0.2)",
                    height: "100px",
                  }}
                />
              )}
              // onScrollStop = {(this.props.iraPending.length != this.props.iraPendingCount) ? this.props._getiraList(this.props.iraPending.length / 10 + 1) : null}

              onScrollStop={
                this.props.iraPending.length != this.props.pendingFilterCount ||
                  this.props.iraPending.length != this.props.iraPendingCount
                  ? this._handlePendingScrollbarPagination
                  : null
              }
            >
              {this.props.iraPending != undefined &&
                this.props.iraPending.map((data, index) => (
                  <div className="open-content" key={index}>
                    <Accordion defaultActiveKey="0">
                      <div
                        className="nm-box open-box"
                        style={{
                          borderColor: data.severity_color,
                        }}
                      >
                        <div className="open-bhead open-tracker-head">
                          <Link to={`/ira-detail/${data.ira_id}/kanban/pending`}>
                            <h4>
                              {data.incident_type ?? data?.severity} | {data.activity}
                            </h4>
                          </Link>
                          <div className="tracker-right">
                            {/* {(data.overdue || data.overdue_days) != "0 Days" && (
                            <p className="nm-label">
                              Overdue |{" "}
                              <span className="days">
                                {data.overdue_days || data.overdue}
                              </span>
                            </p>
                          )} */}
                            {data.evidence.length > 0 ? (
                              // <div
                              //   onClick={() =>
                              //     this._handleLightBox(data.evidence)
                              //   }
                              // >
                              //   <img
                              //     className="tracker-right cursor-pointer"
                              //     style={{
                              //       paddingLeft: "0.5vw",
                              //       height: "1.4vw",
                              //     }}
                              //     src={require("assets/images/icons/fimage.svg")}
                              //     title="Evidence"
                              //   />
                              // </div>
                              <>
                                {data.evidence[0]?.format === "video" ?
                                  <img src={require("assets/sls-icons/V2. New Development/Video Player (1).svg")}
                                    className="cursor-pointer svg-icons" onClick={() =>
                                      this._handleLightBox(data.evidence)
                                    } /> :
                                  <OverlayTrigger
                                    trigger="hover"
                                    placement="bottom"
                                    rootClose={true}

                                    overlay={
                                      <Popover
                                        id="popover-positioned-bottom"
                                        style={{ width: "15vw", borderRadius: "10px" }}
                                      >

                                        <div
                                          className="image-overlay-box mw-100"
                                        >
                                          {/* <div class="shadow-layer w-100"> </div> */}
                                          <img
                                            className="tracker-right  w-100"
                                            src={data.evidence[0]?.link}
                                            title="Evidence"
                                          />
                                        </div>

                                      </Popover>
                                    }
                                  >
                                    <div
                                    >
                                      <img
                                        className="tracker-right cursor-pointer mw-30 rounded-circle"
                                        src={data.evidence[0]?.link}
                                        title="Evidence"
                                      />
                                    </div>
                                  </OverlayTrigger>}
                              </>
                            ) : null}
                            <Accordion.Toggle
                              eventKey="0"
                              className="nm-toggle-button"
                            >
                              <ChevronDown className="completed-arrow cursor-pointer" />
                            </Accordion.Toggle>
                          </div>
                        </div>
                        <Accordion.Collapse eventKey="0">
                          <div className="open-bbody">
                            <Row>
                              <Col sm={6}>
                                <p className="nm-label">Service</p>
                                <p className="nm-text">{data.service}</p>
                              </Col>
                              <Col sm={6}>
                                <p className="nm-label">Plant/Project</p>
                                <p className="nm-text">{data.project}</p>
                              </Col>
                              <Col sm={6}>
                                <p className="nm-label">Reported By</p>
                                <div className="d-flex">
                                  <img src={data.reported_by_pic} className="user-icon " />
                                  <p className="nm-text">{data.reported_by}</p>

                                </div>
                              </Col>
                              <Col sm={6}>
                                <p className="nm-label">
                                  <span className="text-white bg-warning">
                                    Report Date
                                  </span>
                                </p>
                                <p className="nm-text">{data.report_date}</p>
                              </Col>
                            </Row>
                          </div>
                        </Accordion.Collapse>
                      </div>
                    </Accordion>
                  </div>
                ))}

              {/* {Number.isInteger(this.props.iraPending.length / 10) &&
              this.props.iraPending.length != 0 &&
              this.props.iraPending.length != this.props.iraPendingCount && (
                <button
                  className="filter-button1"
                  onClick={() =>
                    this.props._getiraList(
                      this.props.iraPending.length / 10 + 1
                    )
                  }
                >
                  Load more
                </button>
              )} */}
            </Scrollbars>}
        </div>

        <div className={`open-divide ${this.state.listMode === "list" ? "w-100" : ""}`}>
          <div className="open-head inprogress">
            <h2>
              IN PROGRESS |{" "}
              {this.state.inprogressFilterFlag == 1
                ? this.props.inprogressFilterCount || 0
                : this.props.iraProgressCount || 0}
            </h2>
            <div className="d-flex align-items-center">
              <div
                className="search-bar-wrapper mr-2 py-1 px-1 "
              >
                <div className="mr-3">
                  <input
                    type="text"
                    placeholder="Search by category, activity, act, service, location & reported by"
                    name="search"
                    value={this.state.searchList2}
                    onChange={(e) => this.props._handleProgressSearch(e)}
                  />

                </div>
                <div className="common-white-1">
                  <Search style={{ height: "15px" }} />
                </div>
              </div>
              <span title="Filter">
                <img src={require("assets/sls-icons/V2. New Development/funnel-s.svg")}
                  className="cursor-pointer svg-icons1"
                  onClick={() => {
                    this.inProgressModal(true);
                  }}
                />
              </span>
              <InfoComponent tooltipText={"Investigation In-progress"} />
            </div>
          </div>
          {this.state.listMode === "list" ?
            <div className="mt-3 ">
              <DataTable {...this.getFieldProps(this.props.iraProgress, true)}
                scrollProps={{
                  onScrollStop: this.props.iraProgress.length != this.props.iraProgressCount ? this._handleInprogressScrollbarPagination : {}
                }} />
            </div> :
            <Scrollbars
              style={{ height: `calc(100vh - 18vw)` }}
              renderTrackHorizontal={(props) => (
                <div
                  {...props}
                  className="track-horizontal"
                  style={{ display: "none" }}
                />
              )}
              renderThumbHorizontal={(props) => (
                <div
                  {...props}
                  className="thumb-horizontal"
                  style={{ display: "none" }}
                />
              )}
              renderThumbVertical={(props) => (
                <div
                  {...props}
                  style={{
                    position: "relative",
                    display: "block",
                    width: "100%",
                    cursor: "pointer",
                    borderRadius: "inherit",
                    backgroundColor: "rgba(0, 0, 0, 0.2)",
                    height: "100px",
                  }}
                />
              )}
              onScrollStop={
                this.props.iraProgress.length != this.props.iraProgressCount
                  ? this._handleInprogressScrollbarPagination
                  : null
              }
            >
              {this.props.iraProgress != undefined &&
                this.props.iraProgress.map((data, index) => (
                  <div className="open-content" key={index}>
                    <Accordion defaultActiveKey="0">
                      <div
                        className="nm-box open-box"
                        style={{
                          borderColor: data.severity_color,
                        }}
                      >
                        <div className="open-bhead open-tracker-head">
                          <Link to={`/ira-detail/${data.ira_id}/kanban/progress`}>
                            <h4>
                              {data.incident_type ?? data?.severity} | {data.activity}
                            </h4>
                          </Link>
                          <div className="tracker-right">
                            {(data.overdue || data.overdue_days) != "0 Days" && (
                              <p className="nm-label">
                                Overdue |{" "}
                                <span className="days">
                                  {data.overdue_days || data.overdue}
                                </span>
                              </p>
                            )}
                            {data.evidence.length ? (
                              <>
                                {data.evidence[0]?.format === "video" ?
                                  <img src={require("assets/sls-icons/V2. New Development/Video Player (1).svg")}
                                    className="cursor-pointer svg-icons" onClick={() =>
                                      this._handleLightBox(data.evidence)
                                    } /> :
                                  <OverlayTrigger
                                    trigger="hover"
                                    placement="bottom"
                                    rootClose={true}

                                    overlay={
                                      <Popover
                                        id="popover-positioned-bottom"
                                        style={{ width: "15vw", borderRadius: "10px" }}
                                      >

                                        <div
                                          className="image-overlay-box mw-100"
                                        >
                                          {/* <div class="shadow-layer w-100"> </div> */}
                                          <img
                                            className="tracker-right  w-100"
                                            src={data.evidence[0]?.link}
                                            title="Evidence"
                                          />
                                        </div>

                                      </Popover>
                                    }
                                  >
                                    <div
                                    >
                                      <img
                                        className="tracker-right cursor-pointer mw-30 rounded-circle ml-2"
                                        src={data.evidence[0]?.link}
                                        title="Evidence"
                                      />
                                    </div>
                                  </OverlayTrigger>}
                              </>
                            ) : null}

                            <Accordion.Toggle
                              eventKey="0"
                              className="nm-toggle-button"
                            >
                              <ChevronDown className="completed-arrow cursor-pointer" />
                            </Accordion.Toggle>
                          </div>
                        </div>
                        <Accordion.Collapse eventKey="0">
                          <div className="open-bbody">
                            <Row>
                              <Col sm={6}>
                                <p className="nm-label">Service</p>
                                <p className="nm-text">{data.service}</p>
                              </Col>
                              <Col sm={6}>
                                <p className="nm-label">Plant/Project</p>
                                <p className="nm-text">{data.project}</p>
                              </Col>
                              <Col sm={6}>
                                <p className="nm-label">Reported By</p>
                                <div className="d-flex">
                                  <img src={data.reported_by_pic} className="user-icon " />
                                  <p className="nm-text">{data.reported_by}</p>

                                </div>
                              </Col>
                              <Col sm={6}>
                                <p className="nm-label">Team Lead</p>
                                <div className="d-flex">
                                  <img src={data.assign_to_pic} className="user-icon " />
                                  <p className="nm-text">{data.assign_to || "NA"}</p>

                                </div>
                              </Col>
                              <Col sm={6}>
                                <p className="nm-label">Report Date</p>
                                <p className="nm-text">{data.report_date}</p>
                              </Col>
                              <Col sm={6}>
                                <p className="nm-label">
                                  <span
                                    className={`text-white ${this.comapareTargetDate(data.target_date)
                                      ? "bg-danger"
                                      : "bg-warning"
                                      }`}
                                  >
                                    Target Date
                                  </span>
                                </p>
                                <p className="nm-text">
                                  {data.target_date || "NA"}
                                </p>
                              </Col>
                            </Row>
                            <div className="people-aaray">
                              {data.team.map((team, index) => (
                                <div key={`people-${index}`}>
                                  {team.status == 1 && (
                                    <div className="people-lead">
                                      <img
                                        src={team.pic}
                                        className="people-img"
                                        title={team.name}
                                      />
                                      <div >
                                        <img className="image-position"
                                          src={require("assets/sls-icons/Crown/new_opportunity.svg")}
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ))}
                              {data.team.map((team, index) => (
                                <div key={`normal-people${index}`}>
                                  {team.status == 0 && (
                                    <div className="people-normal">
                                      <img
                                        src={team.pic}
                                        className="people-normal-img"
                                        title={team.name}
                                      />
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </Accordion.Collapse>
                      </div>
                    </Accordion>
                  </div>
                ))}
              {/* {Number.isInteger(this.props.iraProgress.length / 10) &&
              this.props.iraProgress.length != 0 &&
              this.props.iraProgress.length != this.props.iraProgressCount && (
                <button
                  className="filter-button1"
                  onClick={() =>
                    this.props._getiraProgressList(
                      this.props.iraProgress.length / 10 + 1
                    )
                  }
                >
                  Load more
                </button>
              )} */}
            </Scrollbars>}
        </div>

        <div className={`open-divide ${this.state.listMode === "list" ? "w-100" : ""}`}>
          <div className="open-head completed">
            <h2>
              COMPLETED |{" "}
              {this.state.completedFilterFlag == 1
                ? this.props.completeFilterCount
                : this.props.iraCompletedCount || 0}
            </h2>
            <div className="d-flex align-items-center">
              <div
                className="search-bar-wrapper mr-2 py-1 px-1 "
              >
                <div className="mr-3">
                  <input
                    type="text"
                    placeholder="Search by category, activity, act, service, location & reported by"
                    name="search"
                    value={this.state.searchList3}
                    onChange={(e) => this.props._handleCompletedSearch(e)}
                  />

                </div>
                <div className="common-white-1">
                  <Search style={{ height: "15px" }} />
                </div>
              </div>
              <span title="Filter">
                <img src={require("assets/sls-icons/V2. New Development/funnel-s.svg")}
                  className="cursor-pointer svg-icons1"
                  onClick={() => {
                    this.completedModal(true);
                  }}
                />
              </span>
              <InfoComponent tooltipText={"Investigation Completed"} />
            </div>
          </div>
          {this.state.listMode === "list" ?
            <div className="mt-3 ">
              <DataTable {...this.getFieldProps(this.props.iraCompleted, true, true)}
                scrollProps={{
                  onScrollStop: this.props.iraCompleted.length != this.props.iraCompletedCount ? this._handleCompletedScrollbarPagination : {}
                }} />
            </div> :
            <Scrollbars
              style={{ height: `calc(100vh - 18vw)` }}
              renderTrackHorizontal={(props) => (
                <div
                  {...props}
                  className="track-horizontal"
                  style={{ display: "none" }}
                />
              )}
              renderThumbHorizontal={(props) => (
                <div
                  {...props}
                  className="thumb-horizontal"
                  style={{ display: "none" }}
                />
              )}
              renderThumbVertical={(props) => (
                <div
                  {...props}
                  style={{
                    position: "relative",
                    display: "block",
                    width: "100%",
                    cursor: "pointer",
                    borderRadius: "inherit",
                    backgroundColor: "rgba(0, 0, 0, 0.2)",
                    height: "100px",
                  }}
                />
              )}
              onScrollStop={
                this.props.iraCompleted.length != this.props.iraCompletedCount
                  ? this._handleCompletedScrollbarPagination
                  : null
              }
            >
              {this.props.iraCompleted != undefined &&
                this.props.iraCompleted.map((data, index) => (
                  <div className="open-content" key={`ira-detail${index}`}>
                    <Accordion defaultActiveKey="0">
                      <div
                        className="nm-box open-box"
                        style={{
                          borderColor: data.severity_color,
                        }}
                      >
                        <div className="open-bhead open-tracker-head">
                          <Link
                            to={`/ira-detail/${data.ira_id}/kanban/completed`}
                          >
                            <h4>
                              {data.incident_type ?? data?.severity} | {data.activity}
                            </h4>
                          </Link>
                          <div className="tracker-right">
                            {(data.overdue ||
                              data.overdue_days ||
                              data.deviation_days) != "0 Days" && (
                                <p className="nm-label">
                                  Deviation |{" "}
                                  <span className="days">
                                    {data.overdue_days ||
                                      data.overdue ||
                                      data.deviation_days}
                                  </span>
                                </p>
                              )}
                            {data.evidence.length ? (
                              <>
                                {data.evidence[0]?.format === "video" ?
                                  <img src={require("assets/sls-icons/V2. New Development/Video Player (1).svg")}
                                    className="cursor-pointer svg-icons" onClick={() =>
                                      this._handleLightBox(data.evidence)
                                    } /> :
                                  <OverlayTrigger
                                    trigger="hover"
                                    placement="bottom"
                                    rootClose={true}

                                    overlay={
                                      <Popover
                                        id="popover-positioned-bottom"
                                        style={{ width: "15vw", borderRadius: "10px" }}
                                      >

                                        <div
                                          className="image-overlay-box mw-100"
                                        >
                                          {/* <div class="shadow-layer w-100"> </div> */}
                                          <img
                                            className="tracker-right  w-100"
                                            src={data.evidence[0]?.link}
                                            title="Evidence"
                                          />
                                        </div>

                                      </Popover>
                                    }
                                  >
                                    <div
                                    >
                                      <img
                                        className="tracker-right cursor-pointer mw-30 rounded-circle ml-2"
                                        src={data.evidence[0]?.link}
                                        title="Evidence"
                                      />
                                    </div>
                                  </OverlayTrigger>}
                              </>
                            ) : null}
                            <Accordion.Toggle
                              eventKey="0"
                              className="nm-toggle-button"
                            >
                              <ChevronDown className="completed-arrow cursor-pointer" />
                            </Accordion.Toggle>
                          </div>
                        </div>
                        <Accordion.Collapse eventKey="0">
                          <div className="open-bbody">
                            <Row>
                              <Col sm={6}>
                                <p className="nm-label">Service</p>
                                <p className="nm-text">{data.service}</p>
                              </Col>
                              <Col sm={6}>
                                <p className="nm-label">Plant/Project</p>
                                <p className="nm-text">{data.project}</p>
                              </Col>
                              <Col sm={6}>
                                <p className="nm-label">Reported By</p>
                                <div className="d-flex">
                                  <img src={data.reported_by_pic} className="user-icon " />
                                  <p className="nm-text">{data.reported_by}</p>

                                </div>
                              </Col>
                              <Col sm={6}>
                                <p className="nm-label">Team Lead</p>
                                <div className="d-flex">
                                  <img src={data.assign_to_pic} className="user-icon " />
                                  <p className="nm-text">{data.assign_to || "NA"}</p>

                                </div>
                              </Col>
                              <Col sm={6}>
                                <p className="nm-label">Report Date</p>
                                <p className="nm-text">{data.report_date}</p>
                              </Col>
                              <Col sm={6}>
                                <p className="nm-label">Target Date</p>
                                <p className="nm-text">
                                  {data.target_date || "NA"}
                                </p>
                              </Col>
                              <Col sm={12}>
                                <p className="nm-label">
                                  <span
                                    className="text-white"
                                    style={{ backgroundColor: "tomato" }}
                                  >
                                    Closing Date
                                  </span>
                                </p>
                                <p className="nm-text">
                                  {data.closing_date || "NA"}
                                </p>
                              </Col>

                            </Row>
                            <div className="people-aaray">
                              {data.team.map((team, index) => (
                                <div key={`progress-people${index}`}>
                                  {team.status == 1 && (
                                    <div className="people-lead">
                                      <img
                                        src={team.pic}
                                        className="people-img"
                                        title={team.name}
                                      />
                                      <div >
                                        <img className="image-position"
                                          src={require("assets/sls-icons/Crown/new_opportunity.svg")}
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ))}
                              {data.team.map((team, index) => (
                                <div key={index}>
                                  {team.status == 0 && (
                                    <div className="people-normal">
                                      <img
                                        src={team.pic}
                                        className="people-normal-img"
                                        title={team.name}
                                      />
                                    </div>
                                  )}
                                </div>
                              ))}
                              <div style={{ position: "absolute", right: "2vw" }}>
                                {/* <a href={`https://s3.ap-south-1.amazonaws.com/dev-api.clideoffice.com/IRA/IRA+Report.pdf`} activeClassName="active" target="_blank"><img src={require('assets/images/icons/fpdf.svg')} alt="completed pdf" /></a> */}
                                <Link
                                  to={`/ira-report/${data.ira_id}`}
                                  target="_blank"
                                >
                                  <img
                                    src={require("assets/images/icons/fpdf.svg")}
                                    alt="completed pdf"
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </Accordion.Collapse>
                      </div>
                    </Accordion>
                  </div>
                ))}

              {/* {Number.isInteger(this.props.iraCompleted.length / 10) &&
              this.props.iraCompleted != 0 &&
              this.props.iraCompleted.length !=
              this.props.iraCompletedCount && (
                <button
                  className="filter-button1"
                  onClick={() =>
                    this.props._getiraCompletedList(
                      this.props.iraCompleted.length / 10 + 1
                    )
                  }
                >
                  Load more
                </button>
              )} */}
            </Scrollbars>}
        </div>

        {/* Pending List filter */}
        <Modal
          centered
          show={this.state.pendingPopup}
          onHide={() => this.pendingModal(false)}
        >
          <Modal.Header
            className="align-items-center"
          ><div className="align-items-center d-flex justify-content-between">
              <div><span className="font-20 font-weight-500 common-white-2">Pending Filter</span></div>
            </div>
            <div className="d-flex  align-items-center">
              <div className="modal-controls">
                <button onClick={() => this._handleFilterReset("pending")}>
                  Reset
                </button>
                <button onClick={this._handlePendingListFilter}>
                  Apply
                </button>
              </div>
              <X style={{
                height: "1.4vw",
                width: "1.4vw"
              }} className="cursor-pointer mx-2 common-white-2" onClick={() => this.setState({
                pendingPopup: false
              })} />
            </div></Modal.Header>
          <Modal.Body>
            <div className="nm-filter-modal">
              <div className="form-group position-relative">
                <Row className="my-2">
                  <label>Incident Type</label>
                  <div className="col-sm-3">
                    <label className="check-box">
                      <input
                        name="abc"
                        type="checkbox"
                        checked={this.state.pendingAll}
                        onClick={() =>
                          this.pendingFilterData(this.state.pendingAll, "all")
                        }
                      />
                      <span className="checkmark1" ></span>
                    </label>
                  </div>
                </Row>


                <Row>
                  <Col sm={3}>
                    <label className="check-box">
                      FA
                      <input
                        value={4}
                        type="checkbox"
                        checked={this.state.pendingFa}
                        onChange={this.pendingSelectSeverity.bind(this)}
                        onClick={() => {
                          this.pendingFilterData(this.state.pendingFa, 4);
                        }}
                      />
                      <span className="checkmark1" />
                    </label>
                  </Col>
                  <Col sm={3}>
                    <label className="check-box">
                      TRA
                      <input
                        value={3}
                        type="checkbox"
                        checked={this.state.pendingMtc}
                        onChange={this.pendingSelectSeverity.bind(this)}
                        onClick={() => {
                          this.pendingFilterData(this.state.pendingMtc, 3);
                        }}
                      />
                      <span className="checkmark1" />
                    </label>
                  </Col>
                  <Col sm={3}>
                    <label className="check-box">
                      MTC
                      <input
                        value={2}
                        type="checkbox"
                        checked={this.state.pendingRta}
                        onChange={this.pendingSelectSeverity.bind(this)}
                        onClick={() => {
                          this.pendingFilterData(this.state.pendingRta, 2);
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={3}>
                    <label className="check-box">
                      LTI
                      <input
                        value={1}
                        type="checkbox"
                        checked={this.state.pendingLti}
                        onChange={this.pendingSelectSeverity.bind(this)}
                        onClick={() => {
                          this.pendingFilterData(this.state.pendingLti, 1);
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <label className="check-box">
                      First Aid
                      <input
                        value={5}
                        type="checkbox"
                        checked={this.state.pendingfirstAid}
                        onChange={this.pendingSelectSeverity.bind(this)}
                        onClick={() => {
                          this.pendingFilterData(this.state.pendingfirstAid, 5);
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={3}>
                    <label className="check-box">
                      OI
                      <input
                        value={6}
                        type="checkbox"
                        checked={this.state.pendingOi}
                        onChange={this.pendingSelectSeverity.bind(this)}
                        onClick={() => {
                          this.pendingFilterData(this.state.pendingOi, 6);
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={3}>
                    <label className="check-box">
                      Notable
                      <input
                        value={7}
                        type="checkbox"
                        checked={this.state.pendingNotable}
                        onChange={this.pendingSelectSeverity.bind(this)}
                        onClick={() => {
                          this.pendingFilterData(this.state.pendingNotable, 7);
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <Row>
                  <Col sm={6}>
                    <label>Service</label>
                    <select
                      className="form-control"
                      value={this.state.pendingServiceSelect}
                      onChange={this._handlePendingServiceChange}
                    >
                      <option value="">Select Service</option>
                      {this.state.pendingService.map((data, index) => (
                        <option value={data.service_id}>
                          {data.service_name}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col sm={6}>
                    <label>Plant/Project</label>
                    <select
                      className="form-control"
                      value={this.state.pendingProjectSelect}
                      onChange={(e) => {
                        this.setState({ pendingProjectSelect: e.target.value },()=>{
                          this._getActivity();
                          this._getDepartment()
                        });
                      }}
                    >
                      <option>Select Plant/Project</option>
                      {this.state.pendingProject.map((project, index) => (
                        <option value={project.project_id}>
                          {project.project}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <Row>
                  <Col sm={6}>
                    
                    {/* <CommonDropdown
                              title="Activity"
                              optionList={this.state.pendingActivitylist}
                              onChangeHandle={(e) => {
                                this.setState({
                                  pendingActivitySelect: e.target.value,
                                });
                              }}
                              isRequired={true}
                              value={this.state.pendingActivitySelect}
                              
                            /> */}
                    <label>Activity</label>
                    <select
                      className="form-control"
                      value={this.state.pendingActivitySelect}
                      onChange={(e) => {
                        this.setState({
                          pendingActivitySelect: e.target.value,
                        });
                      }}
                    >
                      <option>Select Activity</option>
                      {this.state.pendingActivitylist.map((data, index) => (
                        <option value={data.activity_id}>{data.actvity}</option>
                      ))}
                    </select>
                  </Col>
                  <Col sm={6}>
                    <label>Department</label>
                    <select
                      className="form-control"
                      value={this.state.pendingDepartmentSelect}
                      onChange={(e) => {
                        this.setState({
                          pendingDepartmentSelect: e.target.value,
                        });
                      }}
                    >
                      <option>Select Department</option>
                      {this.state.pendingDepartmentlist.map((data, index) => (
                        <option value={data.id}>{data.department}</option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <label>By Report Date</label>
                <Row>
                  <Col>
                    <div className="form-group calender-div">
                      <DatePicker
                        selected={this.state.pendingStartDate}
                        onChange={(jsDate) =>
                          this.setState({ pendingStartDate: jsDate })
                        }
                        placeholderText="Select Start Date"
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                      />
                      <Calendar className="calender-icon" />
                    </div>
                  </Col>
                  <Col sm={1}>
                    <label className="reminder-middle">To</label>
                  </Col>
                  <Col>
                    <div className="form-group calender-div">
                      <DatePicker
                        selected={this.state.pendingEndDate}
                        onChange={(jsDate) =>
                          this.setState({ pendingEndDate: jsDate })
                        }
                        placeholderText="Select End Date"
                        minDate={this.state.pendingStartDate}
                        dateFormat="dd/MM/yyyy"
                      />
                      <Calendar className="calender-icon" />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

          </Modal.Body>
        </Modal>

        {/* Progress Filter */}
        <Modal
          centered
          show={this.state.inProgressPopup}
          onHide={() => this.inProgressModal(false)}
        >

          <Modal.Header
            className="align-items-center"
          ><div className="align-items-center d-flex justify-content-between">
              <div><span className="font-20 font-weight-500 common-white-2">Inprogress Filter</span></div>
            </div>
            <div className="d-flex  align-items-center">
              <div className="modal-controls">
                <button onClick={() => this._handleFilterReset("progress")}>
                  Reset
                </button>
                <button onClick={this._handleInProgressListFilter}>
                  Apply
                </button>
              </div>
              <X style={{
                height: "1.4vw",
                width: "1.4vw"
              }} className="cursor-pointer mx-2 common-white-2" onClick={() => this.setState({
                inProgressPopup: false
              })} />
            </div></Modal.Header>

          <Modal.Body>
            <div className="nm-filter-modal">
              <div className="form-group position-relative">
                <Row className="my-2">
                  <label>Incident Type</label>
                  <div className="col-sm-3">
                    <label className="check-box">
                      <input
                        name="abc"
                        type="checkbox"
                        checked={this.state.progressAll}
                        onClick={() =>
                          this.progressFilterData(this.state.progressAll, "all")
                        }
                      />
                      <span className="checkmark1" ></span>
                    </label>
                  </div>
                </Row>
                <Row>
                  <Col sm={3}>
                    <label className="check-box">
                      FA
                      <input
                        value={4}
                        type="checkbox"
                        checked={this.state.progressFa}
                        onChange={this.progressSelectSeverity.bind(this)}
                        onClick={() => {
                          this.progressFilterData(this.state.progressFa, 4);
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={3}>
                    <label className="check-box">
                      TRA
                      <input
                        value={3}
                        type="checkbox"
                        checked={this.state.progressMtc}
                        onChange={this.progressSelectSeverity.bind(this)}
                        onClick={() => {
                          this.progressFilterData(this.state.progressMtc, 3);
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={3}>
                    <label className="check-box">
                      MTC
                      <input
                        value={2}
                        type="checkbox"
                        checked={this.state.progressRta}
                        onChange={this.progressSelectSeverity.bind(this)}
                        onClick={() => {
                          this.progressFilterData(this.state.progressRta, 2);
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={3}>
                    <label className="check-box">
                      LTI
                      <input
                        value={1}
                        type="checkbox"
                        checked={this.state.progressLti}
                        onChange={this.progressSelectSeverity.bind(this)}
                        onClick={() => {
                          this.progressFilterData(this.state.progressLti, 1);
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <label className="check-box">
                      First Aid
                      <input
                        value={5}
                        type="checkbox"
                        checked={this.state.progressfirstAid}
                        onChange={this.progressSelectSeverity.bind(this)}
                        onClick={() => {
                          this.progressFilterData(this.state.progressfirstAid, 5);
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={3}>
                    <label className="check-box">
                      OI
                      <input
                        value={6}
                        type="checkbox"
                        checked={this.state.progressOi}
                        onChange={this.progressSelectSeverity.bind(this)}
                        onClick={() => {
                          this.progressFilterData(this.state.progressOi, 6);
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={3}>
                    <label className="check-box">
                      Notable
                      <input
                        value={7}
                        type="checkbox"
                        checked={this.state.progressNotable}
                        onChange={this.progressSelectSeverity.bind(this)}
                        onClick={() => {
                          this.progressFilterData(this.state.progressNotable, 7);
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <Row>
                  <Col sm={3}>
                    <label className="check-box">
                      Overdue
                      <input
                        name="abc"
                        type="checkbox"
                        checked={this.state.overdue}
                        onClick={() =>
                          this.progressFilterData(this.state.overdue, "overdue")
                        }
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <Row>
                  <Col sm={6}>
                    <label>Service</label>
                    <select
                      className="form-control"
                      value={this.state.progressServiceSelect}
                      onChange={this._handleProgressServiceChange}
                    >
                      <option value="">Select Service</option>
                      {this.state.progressService.map((data, index) => (
                        <option value={data.service_id}>
                          {data.service_name}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col sm={6}>
                    <label>Plant/Project</label>
                    <select
                      className="form-control"
                      value={this.state.progressProjectSelect}
                      onChange={(e) => {
                        this.setState({ progressProjectSelect: e.target.value },()=>{
                          this._getActivity();
                          this._getDepartment()
                        });
                      }}
                    >
                      <option>Select Plant/Project</option>
                      {this.state.progressProject.map((project, index) => (
                        <option value={project.project_id}>
                          {project.project}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <Row>
                  <Col sm={6}>
                    <label>Activity</label>
                    <select
                      className="form-control"
                      value={this.state.progressActivitySelect}
                      onChange={(e) => {
                        this.setState({
                          progressActivitySelect: e.target.value,
                        });
                      }}
                    >
                      <option>Select Activity</option>
                      {this.state.progressActivitylist.map((data, index) => (
                        <option value={data.activity_id}>{data.actvity}</option>
                      ))}
                    </select>
                  </Col>
                  <Col sm={6}>
                    <label>Department</label>
                    <select
                      className="form-control"
                      value={this.state.progressDepartmentSelect}
                      onChange={(e) => {
                        this.setState({
                          progressDepartmentSelect: e.target.value,
                        });
                      }}
                    >
                      <option>Select Department</option>
                      {this.state.progressDepartmentlist.map((data, index) => (
                        <option value={data.id}>{data.department}</option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </div>

              <div className="form-group">
                <label>By Report Date</label>
                <Row>
                  <Col>
                    <div className="form-group calender-div">
                      <DatePicker
                        selected={this.state.progressStartDate}
                        onChange={(jsDate) =>
                          this.setState({ progressStartDate: jsDate })
                        }
                        placeholderText="Select Start Date"
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                      />
                      <Calendar className="calender-icon" />
                    </div>
                  </Col>
                  <Col sm={1}>
                    <label className="reminder-middle">To</label>
                  </Col>
                  <Col>
                    <div className="form-group calender-div">
                      <DatePicker
                        selected={this.state.progressEndDate}
                        onChange={(jsDate) =>
                          this.setState({ progressEndDate: jsDate })
                        }
                        placeholderText="Select End Date"
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                      />
                      <Calendar className="calender-icon" />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>

          </Modal.Body>
        </Modal>

        {/* Completed Filter */}
        <Modal
          centered
          show={this.state.completedPopup}
          onHide={() => this.completedModal(false)}
        >
          <Modal.Header
            className="align-items-center"
          ><div className="align-items-center d-flex justify-content-between">
              <div><span className="font-20 font-weight-500 common-white-2">Completed Filter</span></div>
            </div>
            <div className="d-flex  align-items-center">
              <div className="modal-controls">
                <button onClick={() => this._handleFilterReset("completed")}>
                  Reset
                </button>
                <button onClick={this._handleCompletedListFilter}>
                  Apply
                </button>
              </div>
              <X style={{
                height: "1.4vw",
                width: "1.4vw"
              }} className="cursor-pointer mx-2 common-white-2" onClick={() => this.setState({
                completedPopup: false
              })} />
            </div></Modal.Header>

          <Modal.Body>
            <div className="nm-filter-modal">
              <div className="form-group position-relative">
                <Row className="my-2">
                  <label>Incident Type</label>
                  <div className="col-sm-3">
                    <label className="check-box">
                      <input
                        name="abc"
                        type="checkbox"
                        checked={this.state.completedAll}
                        onClick={() =>
                          this.completedFilterData(this.state.completedAll, "all")
                        }
                      />
                      <span className="checkmark1" ></span>
                    </label>
                  </div>
                </Row>
                <Row>
                  <Col sm={3}>
                    <label className="check-box">
                      FA
                      <input
                        value={4}
                        type="checkbox"
                        checked={this.state.completedFa}
                        onChange={this.completedSelectSeverity.bind(this)}
                        onClick={() => {
                          this.completedFilterData(this.state.completedFa, 4);
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={3}>
                    <label className="check-box">
                      TRA
                      <input
                        value={3}
                        type="checkbox"
                        checked={this.state.completedMtc}
                        onChange={this.completedSelectSeverity.bind(this)}
                        onClick={() => {
                          this.completedFilterData(this.state.completedMtc, 3);
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={3}>
                    <label className="check-box">
                      MTC
                      <input
                        value={2}
                        type="checkbox"
                        checked={this.state.completedRta}
                        onChange={this.completedSelectSeverity.bind(this)}
                        onClick={() => {
                          this.completedFilterData(this.state.completedRta, 2);
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={3}>
                    <label className="check-box">
                      LTI
                      <input
                        value={1}
                        type="checkbox"
                        checked={this.state.completedLti}
                        onChange={this.completedSelectSeverity.bind(this)}
                        onClick={() => {
                          this.completedFilterData(this.state.completedLti, 1);
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <label className="check-box">
                      First Aid
                      <input
                        value={5}
                        type="checkbox"
                        checked={this.state.completedfirstAid}
                        onChange={this.completedSelectSeverity.bind(this)}
                        onClick={() => {
                          this.completedFilterData(this.state.completedfirstAid, 5);
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={3}>
                    <label className="check-box">
                      OI
                      <input
                        value={6}
                        type="checkbox"
                        checked={this.state.completedOi}
                        onChange={this.completedSelectSeverity.bind(this)}
                        onClick={() => {
                          this.completedFilterData(this.state.completedfirstAid, 6);
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={3}>
                    <label className="check-box">
                      Notable
                      <input
                        value={7}
                        type="checkbox"
                        checked={this.state.completedNotable}
                        onChange={this.completedSelectSeverity.bind(this)}
                        onClick={() => {
                          this.completedFilterData(this.state.completedfirstAid, 7);
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>

                </Row>
              </div>
              <div className="form-group">
                <Row>
                  <Col sm={3}>
                    <label className="check-box">
                      Deviation
                      <input
                        name="abc"
                        type="checkbox"
                        checked={this.state.deviation}
                        onClick={() =>
                          this.completedFilterData(
                            this.state.deviation,
                            "deviation"
                          )
                        }
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <Row>
                  <Col sm={6}>
                    <label>Service</label>
                    <select
                      className="form-control"
                      value={this.state.completedServiceSelect}
                      onChange={this._handleCompletedServiceChange}
                    >
                      <option value="">Select Service</option>
                      {this.state.service.map((data, index) => (
                        <option
                          value={data.service_id}
                          key={`serviceList-${index}`}
                        >
                          {data.service_name}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col sm={6}>
                    <label>Plant/Project</label>
                    <select
                      className="form-control"
                      value={this.state.completedProjectSelect}
                      onChange={(e) => {
                        this.setState({ completedProjectSelect: e.target.value },()=>{
                          this._getActivity();
                          this._getDepartment()
                        });
                      }}
                    >
                      <option>Select Plant/Project</option>
                      {this.state.completedProject.map((project, index) => (
                        <option
                          value={project.project_id}
                          key={`projectList-${index}`}
                        >
                          {project.project}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <Row>
                  <Col sm={6}>
                    <label>Activity</label>
                    <select
                      className="form-control"
                      value={this.state.completedActivitySelect}
                      onChange={(e) => {
                        this.setState({
                          completedActivitySelect: e.target.value,
                        });
                      }}
                    >
                      <option>Select Activity</option>
                      {this.state.completedActivitylist.map((data, index) => (
                        <option
                          value={data.activity_id}
                          key={`activityList-${index}`}
                        >
                          {data.actvity}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col sm={6}>
                    <label>Department</label>
                    <select
                      className="form-control"
                      value={this.state.completedDepartmentlist}
                      onChange={(e) => {
                        this.setState({
                          completedDepartmentlist: e.target.value,
                        });
                      }}
                    >
                      <option>Select Department</option>
                      {this.state.completedDepartmentlist.map((data, index) => (
                        <option value={data.id} key={`departmentList-${index}`}>
                          {data.department}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </div>

              <div className="form-group">
                <label>By Report Date</label>
                <Row>
                  <Col>
                    <div className="form-group calender-div">
                      <DatePicker
                        selected={this.state.completedStartDate}
                        onChange={(jsDate) =>
                          this.setState({ completedStartDate: jsDate })
                        }
                        placeholderText="Select Start Date"
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                      />
                      <Calendar className="calender-icon" />
                    </div>
                  </Col>
                  <Col sm={1}>
                    <label className="reminder-middle">To</label>
                  </Col>
                  <Col>
                    <div className="form-group calender-div">
                      <DatePicker
                        selected={this.state.completedEndDate}
                        onChange={(jsDate) =>
                          this.setState({ completedEndDate: jsDate })
                        }
                        placeholderText="Select End Date"
                        minDate={this.state.completedStartDate}
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                      />
                      <Calendar className="calender-icon" />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
