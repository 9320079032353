import React, { Component } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import funnel from "highcharts/modules/funnel.js";
import _ from "lodash";
import { Chart, ArcElement, Tooltip, Legend, Title } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { config } from "config.js";

Chart.register(ArcElement, Tooltip, Legend, Title);

funnel(Highcharts);

const chartStyle = { position: 'absolute', width: '60%', top: '50%', left: '19%', textAlign: 'center', marginTop: '0', lineHeight: '20px' }


export default class UaUcHalfGraph extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title,
      graphData: this.props.graphData,
      symbol: this.props.symbol ?? "",
      customTitles: this.props.customTitles ?? {},
      defaultHeigh: this.props.height ?? "80%",
      list: this.props.list ?? true,
      listData: this.props.listData ?? false,
      rotation: this.props.rotation ?? -90,
      circumference: this.props.circumference ?? 180,
      cutoutPercentage: this.props.cutoutPercentage ?? 80,
      textAlign: this.props.textAlign ?? "bottom"
    };
  }




  render() {
    const { rotation, circumference, cutoutPercentage, textAlign } = this.state;
    const textStyle = { fontWeight: this.props.fontWeight ?? "bold", fontSize: this.props.fontSize ?? "1vw" }
    const data = {
      labels: this.state.graphData.map(data => data.name),
      datasets: [{
        data: this.state.graphData.map(data => data.y),
        backgroundColor: this.state.graphData.map(data => data.color),
        borderWidth: 1,
        radius:  80,
        cutout: this.props.cutout ?? "75%",
      }]
    };
    const options = {
        cutoutPercentage,
        rotation,
        circumference,
      padding: 0,
      marginTop: 0,
      responsive: true,
      maintainAspectRatio: false,
      defaultFontSize: "10px",
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }
      },
      plugins: {
        datalabels: {
          color: '#000000',
          anchor: "start",
          align: "end",
          display: 'none',
          formatter: function (value, context) {
            return context.chart.data.labels[context.dataIndex];
          }
        },
        title: {
          display: false,
        },
        legend: {
          display: false
        },
        tooltip: {
          enabled: false,
          external: function (context) {
            console.log('context', context)
            // Tooltip Element
            let tooltipEl = document.getElementById('chartjs-tooltip');
            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = '<p></p>';
              document.body.appendChild(tooltipEl);
            }
            // Hide if no tooltip
            const tooltipModel = context.tooltip;
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }
            function getBody(bodyItem) {
              return bodyItem.lines;
            }
            // Set Text
            if (tooltipModel.body) {
              const bodyLines = tooltipModel.body.map(getBody);
              let innerHtml = `<div style="background: rgba(255,255,255, 0.8); border: 2px solid ${tooltipModel.labelColors[0].backgroundColor}; border-radius: 2px; padding: 8px;">`;
              const span = '<span>' + bodyLines[0] + '</span>';
              innerHtml += span;
              innerHtml += '</div>';

              let tableRoot = tooltipEl.querySelector('p');
              tableRoot.style.border = 'none !important';
              tableRoot.style.borderCollapse = 'collapse !important';
              tableRoot.innerHTML = innerHtml;
            }

            const position = context.chart.canvas.getBoundingClientRect();
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
            tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
            tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
            tooltipEl.style.pointerEvents = 'none';
          }
        }
      }
    }
    const chartConfig = {
      chart: {
        type: "pie",
        height: this.props.defaultHeigh ?? "100%",
      },
      credits: {
        enabled: false,
      },
      title: !_.isEmpty(this.props.customTitles)
        ? this.props.customTitles
        : null,
      yAxis: {
        title: {
          enabled: false,
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          shadow: false,
          showInLegend: true,
        },
      },
      tooltip: {
        formatter: function () {
          return "<b>" + this.point.name + "</b>: " + this.y;
        },
      },
      series: [
        {
          data: this.props.graphData,
          size: "100%",
          innerSize: "75%",
          dataLabels: {
            enabled: false,
          },
        },
      ],
    };

    return (
      this.state.listData ? 
      <Doughnut data={data} options={options} height={this.props.customTitles?.useHTML ? this.state.list == false ? this.props.height : '90%' : this.props.height ?? 'auto'} />
 
      :<div className="analysis-box">
        {this.state.title != '' && <h4>{this.state.title}</h4>}
        <Row>
          <Col sm={this.state.list ? 7 : 12} className="px-0">
            {config.isChartJsEnabled ? (
              <div style={{ position: 'relative' }}>
                <Doughnut data={data} options={options} height={this.props.customTitles?.useHTML ? this.state.list == false ? this.props.height : '90%' : this.props.height ?? 'auto'} />
                {this.props.customTitles?.useHTML && <div style={{...chartStyle,...this?.props?.styleObj}}>
                  <span style={textStyle}>{this.props?.total ?? 0}</span>
                </div>}
              </div>
            )
              : <HighchartsReact highcharts={Highcharts} options={chartConfig} />}
          </Col>
          {(this.state.list) && (<Col sm={5} className="sm-graph-modal-legend-block">
            {
              this.state.graphData && this.state.graphData.map((data, key) => (
                <div className="sm-graph-modal-legend-inner-block">
                  <div
                    className="sm-graph-modal-legend-box"
                    style={{ backgroundColor: data.color }}
                  ></div>
                  <div className="sm-graph-modal-legend-text">
                    {data.name} | {data.y} {this.props.symbol}
                  </div>
                </div>
              ))
            }
          </Col>)}
        </Row>
      </div>
    );
  }
}
