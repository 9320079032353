import React, { useEffect } from "react";
import { Nav } from "react-bootstrap";
import _ from "lodash";

const TabsV2 = ({
  tabs = [],
  currentItem = {},
  handleChange = () => {},
  extraValues = {  },
  isIntialSet = true
}) => {
  const [activeItem, setActiveItem] = React.useState(tabs[0] ?? {});

  const manageUpdate = (el) => {
    setActiveItem(el);
    handleChange(el);
  };


  useEffect(() => {
    if(isIntialSet){
      handleChange(tabs[0])
    }
  },[])

  React.useEffect(() => {
    if (!_.isEmpty(currentItem)) {
      setActiveItem(currentItem);
    }
  }, [currentItem]);

  function applyPostFixAndRemoveDuplicates(array, postFix) {
    if (!Array.isArray(array) || typeof postFix !== "object") {
      throw new Error(
        "Invalid input: array must be an array and postFix must be an object."
      );
    }

    const resultArray = [];

    const processedKeys = new Set(); // To keep track of processed keys

    array.forEach((item) => {
      if (typeof item === "object" && item !== null) {
        const newItem = { ...item }; // Copy the item

        // Iterate through the postFix object
        for (const [postFixKey, postFixValue] of Object.entries(postFix)) {
          // Check if the item has a value matching the postFix key
          for (const value of Object.values(item)) { // Corrected typo: Object.value -> Object.values
            if (value === postFixKey) { // Corrected typo: values -> value
              newItem.tabName +=  " " + `(${postFixValue})`; // Concatenate the postFix value with tabName
              processedKeys.add(postFixKey); // Mark the postFix key as processed
            }
          }
        }
        resultArray.push(newItem); // Push the modified item to the result array
      } else {
        resultArray.push(item); // Push non-object items as they are
      }
    });

    // Remove duplicates
    const uniqueArray = resultArray.filter(
      (item, index, self) =>
        index ===
        self.findIndex(
          (obj) =>
            obj.tabName === item.tabName && !processedKeys.has(obj.tabName)
        )
    );

    return uniqueArray.filter((el)=> !el.skip);
}
  const elements = applyPostFixAndRemoveDuplicates(tabs, extraValues) ?? [];
  return (
    <Nav variant="tabs">
      {elements?.map((el) => (
        <Nav.Item
          className={`   ${
            activeItem?.key === el.key
              ? " clide-v2-common-border-b-orange-1 "
              : ""
          }`}
        >
          <Nav.Link
            className={`   ${activeItem?.key === el.key ? "active " : ""}`}
            onClick={() => manageUpdate(el)}
          >
            {el.tabName}
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  );
};

export default React.memo(TabsV2);
