import React from 'react'
import ReactPlayer from 'react-player/lazy'
const VideoBox = ({ url, parameters,onVideoPlay  = {} }) => {
    return (
        <div>
            <ReactPlayer className='react-player' onPlay={onVideoPlay} style={{
                 minHeight: "8rem",
                maxHeight: "10rem"
            }} url={url} width='100%'
                config={{
                    file: {
                        attributes: {
                            controlsList: 'nodownload'
                        }
                    }
                }} controls styles={{}} {...parameters}  />
        </div>
    )
}

export default React.memo(VideoBox)