import React, { Component } from "react";
import AuthLeftBottom from "./AuthLeftBottom";
import Url from "app/shared/constants/Url";
import ConfirmPopupImage from "../../../../assets/images/CLIDEOffice.png";

export default class AuthLeft extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentData: {},
    };
  }

  componentWillMount = () => {
    import("app/shared/utility/UtilFunction").then((utilFunction) => {
      let responseObj = utilFunction.sharedAxiosAPI(Url.loginPageContent);

      responseObj
        .then(async (response) => {
          if (response.data.status === 200) {
            await this.setState({ contentData: response.data.data });
          }
        })
        .catch((error) => console.log(error));
    });
  };

  render() {
    return (
      <div className="sign-column w2">
        <div className="intro-p">
          <div className="logo">
            <img
              className="logo-img"
              src={require("assets/images/CLIDEOffice.png")}
              alt="logo"
            />
          </div>
          <div className="canvas-logo">
            <img src={this.state.contentData.gif_url} alt="login" />
          </div>
          <div className="intro-content">
            <div className="intro-title">
              <strong className="login-title">
                {this.state.contentData?.title}
              </strong>
            </div>
            <div className="intro-title">
              {this.state.contentData?.description}
            </div>
          </div>
          <div className="shareArticle">
            <div className="shareSocial">
              <ul className="socialList">
                <li>
                  <a
                    href="https://www.linkedin.com/showcase/clide-safety/?viewAsMember=true"
                    target="_blank"
                    title="View our Linkedin page"
                  >
                    <i className="fa-brands fa-linkedin-in" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/CLIDEo"
                    target="_blank"
                    title="View our Twitter page"
                  >
                    <i className="fa-brands fa-twitter" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/CLIDEOffice-107416677331001/?modal=admin_todo_tour"
                    target="_blank"
                    title="View our Facebook page"
                  >
                    <i className="fa-brands fa-facebook-f" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UC0pxWwgntN9AXOEH0gr_yug"
                    target="_blank"
                    title="Visit our youtube channel"
                  >
                    <i className="fa-brands fa-youtube" />
                  </a>
                </li>
                <li>
                  <a href="" target="_blank" title="Visit Market-Place">
                    <i className="fa-solid fa-shopping-cart" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.Clideoffice"
                    target="_blank"
                    title="Clide Analyser App"
                  >
                    <i className="fa-solid fa-play" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <a
              className="login-links"
              href="https://eclide.clideoffice.com"
              target="_blank"
            >
              <p className="footer-text">ECLIDE</p>
            </a>
            <span>|</span>
            <a
              className="login-links"
              href="https://clideoffice.com"
              target="_blank"
            >
              <p className="footer-text">CLIDEAnalyser </p>
            </a>
            <span>|</span>
            <a
              className="login-links"
              href="https://clideg.clideoffice.com"
              target="_blank"
            >
              <p className="footer-text">CLIDEG</p>
            </a>
          </div>
          <div className="powered-by">Powered by CLIDEG</div>
        </div>
      </div>
    );
  }
}
