import React, { Component } from "react";
import LoginLeft from "./AuthLeft";
import LoginRight from "./AuthRight";
import FooterLink from "./FooterLink";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import "assets/styles/_auth.scss"
const LinkList = [
  {
    name: "ECLIDE",
    link: "https://eclide.clideoffice.com",
    id: 101,
  },
  {
    name: "CLIDEAnalyser",
    link: "https://clideoffice.com",
    id: 202,
  },
  {
    name: "CLIDEG",
    link: "https://clideg.clideoffice.com",
    id: 303,
  },
];

export default class Auth extends Component {
  constructor(props) {
    super(props);
    this.authCss = null; // Initialize to null
  }

 
  render() {

    return (
     
      <div id="authContainer">
        <div className="sign-in-container" >
        <LoginRight />
          <LoginLeft />
          
        </div>
      </div>
    );
  }
}
