import React from "react";
//components/
import DetailReportingDetails from "./components/reportingDetails";
import DetailInvestigationTeam from "./components/investigationTeam";
import DetailGraphSection from "./components/graphSection";
const DetailRightSection = ({
  state,
  functionContent,
  variablesObj,
  chartsVariableObj,
}) => {
  return (
    <>
      <DetailReportingDetails
        state={state}
        functionContent={functionContent}
        variablesObj={variablesObj}
      />
      <DetailInvestigationTeam
        state={state}
        functionContent={functionContent}
        variablesObj={variablesObj}
      />
      <DetailGraphSection
        state={state}
        functionContent={functionContent}
        variablesObj={variablesObj}
        chartsVariableObj={chartsVariableObj}
      />
    </>
  );
};

export default DetailRightSection;
