import React, { Component, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Filter, Search, ChevronDown, Calendar, Eye } from "react-feather";
import { Scrollbars } from "react-custom-scrollbars";
import { ReactComponent as SearchIcon } from "assets/sls-icons/Search/search.svg";
import {
  Row,
  Col,
  ProgressBar,
  Accordion,
  Modal,
  Button,
} from "react-bootstrap";
import Url from "app/shared/constants/Url";
import axios from "axios";
import _ from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  SuccessToast,
  ErrorToast,
} from "app/shared/components/Popups/ToastMessage";
import CheckFormSettingField from "app/shared/utility/CheckFormSettingField";
import InfoComponent from "app/shared/utility/InfoComponent";
import moment from "moment";
import FsLightbox from "fslightbox-react";
import Workbook from "react-excel-workbook";
import ExcelIcon from "assets/images/icons/Excel.svg";
import AudioPlayer from "react-h5-audio-player";
import { AccordionWrapper } from "app/views/Nm/Tracker/CapaList";
import { DateTableComponent as DataTable } from "app/shared/components/ReusableComponents/DataTable";
import LoadingImage from "app/shared/components/Loader/LoadingImage";
import { ProgressBarWrapper } from "app/views/Ira/Detail";
import { Recorder } from "react-voice-recorder";
import "react-voice-recorder/dist/index.css";
import {
  Modal as SLDModal,
  Button as SLDButton,
  Checkbox as SLDCheckbox,
  Textarea as SLDTextarea,
} from "@salesforce/design-system-react";
import MyDropzone from "app/shared/components/DocumentModal/Dropzone";
import { DocumentPreviewer } from "app/shared/components/ReusableComponents/DocumentPreviewer/container";

const qs = require("qs");

const generateIntials = (name = "") => {
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

  let initials = [...name.matchAll(rgx)] || [];

  initials = (
    (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
  ).toUpperCase();
  return initials;
};
class CapaList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataLoading: false,
      currentPage: 1,
      iraHeaderCount: [],
      progress_list: [],
      completed_list: [],
      completed_number: "",
      inprogress_number: "",
      searchList: "",
      searchList2: "",
      searchList3: "",
      searchBtnClicked: false,
      searchBtnClicked2: false,
      searchCompleted: [],
      trackerFilterPopup: false,
      startDate: new Date(),
      endDate: new Date(),
      overdue: 0,
      service: [],
      listMode: "kanban",
      serviceSelect: "",
      project: [],
      projectSelect: "",
      R_person: [],
      extremeSelect: 0,
      extremeValue: 0,
      highSelect: 0,
      mediumSelect: 0,
      lowSelect: 0,
      all: 0,
      allValue: [],
      allS: [],
      severity: [],
      personSelect: [],
      CategoryFilter: [],
      dflag: JSON.parse(localStorage.getItem("userData")),
      actionTakenError: false,
      evidenceError: false,
      fsToggler: false,
      toggler: false,
      lightboxactive: false,
      evidencelightbox: [],
      evidencelightboxType: [],

      modalParams: {},
      recommendationNumber: null,
      capaParams: {},
      evidenceParams: {},
      deleteIds: [],
      observationText: "",
      correctiveItem: {},
      correctionId: null,
      declineMessage: "",
      isDeclineViewEnabled: false,
      recommendation_id: null,
      showPopupSearch: false,
      obs_rec: "",
      ira_id: null,
      activeService: "",
      activeProject: "",
    };
  }

  componentDidMount = () => {
    this._getService();
    this._getProject();
  };

  _getService = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.serviceList,
        qs.stringify({
          auth_key: token,
          user_id: userId,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({ service: response.data.data, dataLoading: false });

          var serviceobs = this.state.service.map((item) => item.service_id);
          this.setState({ serviceSelect: serviceobs[0] });
          this._getProject();
        }
        // console.log(this.state.service ? this.state.service : 0)
      })
      .catch((error) => {
        throw error;
      });
  };

  _getProject = async () => {
    let userId = localStorage.getItem("userId");
    let token = localStorage.getItem("token");

    await axios
      .post(
        Url.projectList,
        qs.stringify({
          auth_key: token,
          user_id: userId,
          service_id: this.state.serviceSelect,
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          this.setState({ project: response.data.data, dataLoading: false });

          var projectobs = this.state.project.map((item) => item.project_id);
          this.setState({ projectSelect: projectobs[0] });
        }
        // console.log('result: ',this.state.project ? this.state.project : 0)
      })
      .catch((error) => {
        throw error;
      });
  };

  _handleServiceChange = async (event) => {
    const selectedServiceID = event.target.value;
    if (selectedServiceID) {
      await this.setState({ serviceSelect: selectedServiceID });
      this._getProject();
    }
  };

  trackerModal = (visible, statusCheck) => {
    this.setState({ trackerFilterPopup: visible, statusCheck: statusCheck });
  };

  resetState() {
    this.setState({
      extremeSelect: 0,
      highSelect: 0,
      mediumSelect: 0,
      lowSelect: 0,
      startDate: null,
      endDate: null,
      serviceSelect: null,
      projectSelect: null,
      categorySelect: null,
      personSelect: null,
      overdue: 0,
      all: 0,
    });
  }

  selectSeverity = (e) => {
    const severity = this.state.severity;
    let index;

    if (e.target.checked) {
      severity.push(+e.target.value);
    } else {
      index = severity.indexOf(+e.target.value);
      severity.splice(index, 1);
    }
    this.setState({ severity: severity });
  };

  FilterData(param, value) {
    if (value == 4) {
      if (param == 0) {
        this.setState({ extremeSelect: 4, all: 0 });
      } else {
        this.setState({ extremeSelect: 0, all: 0 });
      }
    }
    if (value == 3) {
      if (param == 0) {
        this.setState({ highSelect: 3, all: 0 });
      } else {
        this.setState({ highSelect: 0, all: 0 });
      }
    }
    if (value == 2) {
      if (param == 0) {
        this.setState({ mediumSelect: 2, all: 0 });
      } else {
        this.setState({ mediumSelect: 0, all: 0 });
      }
    }
    if (value == 1) {
      if (param == 0) {
        this.setState({ lowSelect: 1, all: 0 });
      } else {
        this.setState({ lowSelect: 0, all: 0 });
      }
    }
    if (value == "overdue") {
      if (param == 0) {
        this.setState({ overdue: 1, all: 0 });
      } else {
        this.setState({ overdue: 0, all: 0 });
      }
    }
    if (value == "all") {
      if (param == 0) {
        this.setState({
          all: 1,
          extremeSelect: 4,
          highSelect: 3,
          mediumSelect: 2,
          lowSelect: 1,
          severity: [1, 2, 3, 4],
        });
      } else {
        this.setState({
          all: 0,
          extremeSelect: 0,
          highSelect: 0,
          mediumSelect: 0,
          lowSelect: 0,
          severity: [],
        });
      }
    }
  }
  comapareTargetDate = (date = "") => {
    if (date) {
      const targetDate = moment(date, "DD/MM/YYYY");
      const today = moment();
      if (targetDate.isSame(today, "day")) {
        return true;
      }
      return false;
    }
  };

  _handleLightBox = async (list) => {
    console.log(list.map((item) => item.format));
    await this.setState({
      lightboxactive: true,
      fsToggler: !this.state.fsToggler,
      evidencelightbox: list.map((item) => item.evidence),
      evidencelightboxType: list.map((item) => "image"),
    });
  };

  modalHideSearch = () => {
    this.setState({
      showPopupSearch: false,
    });
  };
  modalShowSearch = (rec) => {
    this.setState({
      showPopupSearch: true,
      obs_rec: rec,
    });
  };
  __toggleGridView = (tabName) => {
    if (this.state.listMode !== tabName) {
      this.setState({ listMode: tabName });
    }
  };
  __generateTableBody = (capaItem, idx, arr = []) => {
    return (
      <tr key={idx}>
        <td>{capaItem.recommendation?.recommendation || ""}</td>
        <td>{capaItem.recommendation?.status || ""}</td>
        <td>{capaItem.category}</td>
        <td>
          <div className="d-flex ">
            <img src={capaItem?.recommendedby_pic} className="user-icon mr-2" />
            <p className="font-16 common-grey-1">
              {capaItem?.recommendedby || ""}
            </p>
          </div>
        </td>
        <td>
          {capaItem?.tracking_responsible_person?.map((person, index) => (
            <div className="d-flex " key={index}>
              <img src={person?.person_pic} className="user-icon mr-2" />
              <p className="font-16 common-grey-1">{person.person_name}</p>
            </div>
          ))}
        </td>
        <td>{capaItem.overdue_deviation || ""}</td>
        <td>
          <div
            style={{
              backgroundColor: capaItem.priority_color,
            }}
            className="badge font-16 font-weight-500 py-2"
          >
            <div
              className="output-text"
              style={{ color: "#ffffff", textAlign: "center" }}
            >
              {capaItem.priority}
            </div>
          </div>
        </td>
        <td>
          <span
            style={{ color: "rgba(0, 0, 0, 0.7)" }}
            className={`${
              this.comapareTargetDate(capaItem.target_date)
                ? "bg-danger"
                : "bg-warning"
            } text-white  px-1 badge font-16 font-weight-500 py-2`}
          >
            {capaItem.target_date || "N/A"}
          </span>
        </td>
        {arr.length ? (
          <>
            <td>{capaItem.recommendation?.closing_date || "N/A"}</td>
            <td>
              {capaItem.recommendation?.recommendation_evidence?.length && (
                <div
                  style={{ display: "inline-block" }}
                  onClick={() =>
                    this._handleLightBox(
                      capaItem.recommendation?.recommendation_evidence
                    )
                  }
                >
                  <img
                    className="tracker-right cursor-pointer"
                    style={{ height: "1.8vw" }}
                    src={require("assets/images/icons/fimage.svg")}
                  />
                </div>
              )}
              {capaItem.recommendation?.observation_rec?.length > 0 ? (
                <div style={{ display: "inline-block" }}>
                  <h6
                    onClick={() =>
                      this.modalShowSearch(
                        capaItem.recommendation.observation_rec
                      )
                    }
                  >
                    <img
                      style={{
                        height: "1.8rem",
                        paddingLeft: "1rem",
                        verticalAlign: "initial",
                      }}
                      src={require("assets/images/icons/play.svg")}
                      className="cursor-pointer"
                    />
                  </h6>
                </div>
              ) : null}
            </td>
          </>
        ) : null}
      </tr>
    );
  };
  getFieldProps = (data, columns = []) => {
    return {
      data,
      columns: [
        "CAPA",
        "Status",
        "Category",
        "Proposed By ",
        "Assigned To ",
        `${columns?.length ? "Deviation" : "Overdue"}`,
        "Priority",
        "Due Date",
        ...columns,
      ],
      bodyRendrer: (el, idx) => this.__generateTableBody(el, idx, columns),
    };
  };
  _checkApproveDeclinedButton = (item) => {
    if (item.approve_button_status || item.decline_button_status) {
      return true;
    } else {
      return false;
    }
  };
  checkApprovePersmission = (
    currentObj,
    arr = [],
    listItem = {},
    recommendationNumber,
    ira_id
  ) => {
    const { user_id, designation_flag } = this.state.dflag;
    const el = arr.find((x) => x.person_id === user_id);
    const { send_for_approval_button_status } = listItem.recommendation;
    
    if (
      !_.isEmpty(el) ||
      ((designation_flag == 1 ||
        designation_flag == 3 ||
        listItem.recommendation?.teamLead_id == user_id) &&
        listItem.recommendation?.status !== "Pending") ||
      (this._checkApproveDeclinedButton(listItem.recommendation) &&
        listItem.recommendation?.status != "Approved")
    ) {
      this.setState({
        modalParams: listItem,
        recommendationNumber,
        correctiveItem: listItem,
        approval: send_for_approval_button_status,
        recommendList: listItem.recommendation,
        isApprovedPersonEnabled:(designation_flag == 1 ||
          designation_flag == 3 ||
          listItem.recommendation?.teamLead_id == user_id),
        observationText: listItem.recommendation.observation,
        recommendation_id: listItem.recommendation_id,
        ira_id,
        activeService: currentObj.service_id,
        activeProject: currentObj.project_id,
      });
    }
  };
  _checkIsResponsiblePerson = (correctiveItem) => {
    const userID = localStorage.getItem("userId");
    try {
      const assignedPerson =
        correctiveItem.tracking_responsible_person[0].person_id;
      if (userID == assignedPerson) {
        return true;
      }
      return false;
    } catch (error) {}
  };
  correctiveActionList = async () => {
    await this.setState((prevState) => {
      let { recommendList } = this.state;
      if (recommendList.isChecked) {
        recommendList.isChecked = !recommendList.isChecked;
      } else {
        recommendList.isChecked = true;
      }
      return { recommendList };
    });
  };

  renderRenderOnlyView = (dataItems, modalParams) => {
    return (
      <>
        {dataItems.approve_button_status == 1 ||
        dataItems.decline_button_status == 1 ||
        dataItems.status === "Approved" || (this.state?.isApprovedPersonEnabled && dataItems.status === "Declined") ? (
          <div className="m-4">
            <div>
              <div class="slds-form-element">
                <span
                  class="slds-form-element__label"
                  id="file-selector-primary-label-105"
                >
                  Corrective Action
                </span>
                <div>
                  <span className="slds-text-heading_small font-16 font-weight-500">
                    {modalParams?.recommendation?.recommendation}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div class="slds-form-element">
                <span
                  class="slds-form-element__label"
                  id="file-selector-primary-label-105"
                >
                  Action Taken
                </span>
                <div>
                  <span className="slds-text-heading_small font-16 font-weight-500">
                    {this.state.observationText}
                  </span>
                </div>
              </div>
            </div>
            <div class="slds-form-element">
              <span
                class="slds-form-element__label"
                id="file-selector-primary-label-105"
              >
                Evidence
              </span>
              <div className="my-1 ml-2 d-flex common-flex-gap flex-wrap">
                <DocumentPreviewer
                  documentArray={dataItems?.recommendation_evidence}
                  mappingKeys={{
                    id: "id",
                    url: "evidence",
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  };
  renderCommentView = (dataItems) => {
    return (
      <>
        {dataItems.recommendation_comments &&
        dataItems.recommendation_comments.length > 0 ? (
          <>
            <div className="mx-4">
              <div class="slds-form-element">
                <span class="slds-form-element__label font-20 font-weight-500">
                  Comments
                </span>
              </div>
              <div>
                <SLDSChat
                  chatArray={dataItems.recommendation_comments}
                  chatMappers={{
                    comment: "comment",
                    id: "comment_id",
                    personId: "commented_by_id",
                    avatar: "commented_by_pic",
                    name: "commented_by_name",
                    post_creationTimeStamp: "post_ago",
                  }}
                  inputPlaceHolder="Reply"
                  callback={(reply) => this.declineCommentUpdate(reply)}
                  isInputEnabled={dataItems.status === "Approved" ? false:true}
                />
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  };
  renderDeclineCommentView = () => {
    return this.state.isDeclineViewEnabled ? (
      <div className="mx-4 my-2 ">
        <SLDTextarea
          id="text-area-error"
          name="required-textarea-error"
          label="Decline Message"
          placeholder="Reason of decline"
          value={this.state.declineMessage}
          onChange={(e) =>
            this.setState({
              declineMessage: e.target.value,
            })
          }
        />
        <div className="my-2 ">
          <SLDButton
            variant="outline-brand"
            label="Cancel"
            onClick={() => {
              this.setState({
                isDeclineViewEnabled: false,
                declineMessage: "",
              });
            }}
          />
          <SLDButton
            variant="brand"
            label="Send"
            disabled={this.state.declineMessage === ""}
            onClick={this._declineStatusUpdate}
          />
        </div>
      </div>
    ) : null;
  };
  renderRecomendationUpdate = (modalParams) => {
    const dataItems = { ...this.state.recommendList };
    return (
      <>
        {(dataItems.update_button_status == 1 ||
          dataItems.send_for_approval_button_status == 1 ||
          dataItems.status == "Declined") &&
        this._checkIsResponsiblePerson(modalParams) ? (
          <>
            <div className="m-4">
              <SLDCheckbox
                id="checkbox-example"
                labels={{
                  label: modalParams?.recommendation?.recommendation,
                }}
                checked={dataItems.isChecked || dataItems.observation}
                onChange={(e) => {
                  this.correctiveActionList(dataItems.id);
                }}
              />
              <div>
                <SLDTextarea
                  // aria-describedby="error-1"
                  id="text-area-error"
                  name="required-textarea-error"
                  label="Action Taken"
                  required
                  // errorText={this.state.actionTakeError ? null : "This field is required"}
                  placeholder="Placeholder Text"
                  value={this.state.observationText}
                  onChange={(e) =>
                    this.setState({
                      observationText: e.target.value,
                      correctionId: dataItems.id,
                    })
                  }
                  disabled={dataItems.status == "Approval Pending"}
                />
                {this.state.actionTakenError ? (
                  <span className="common-red-3 font-weight-500 font-17">
                    This field is required
                  </span>
                ) : null}
              </div>
              <div class="slds-form-element">
              {dataItems.status !== "Approval Pending" ? (
                  <>
                <span
                  class="slds-form-element__label"
                  id="file-selector-primary-label-105"
                >
                  Add Evidence
                </span>
                <div>
                  <MyDropzone
                    uploadFile={(file) =>
                      this.photoCallbackFunction(file, dataItems.id)
                    }
                    type={"1"}
                  />
                  {this.state.evidenceError ? (
                    <span className="common-red-3 font-weight-500 font-17">
                      This field is required
                    </span>
                  ) : null}
                </div> </>) : null }
                <div className="my-4 ml-2 d-flex common-flex-gap flex-wrap">
                  <DocumentPreviewer
                    documentArray={dataItems?.recommendation_evidence}
                    mappingKeys={{
                      id: "id",
                      url: "evidence",
                    }}
                    isDeleteCallBackEnable={
                      (dataItems.update_button_status == 1 &&
                        !dataItems.send_for_approval_button_status) ||
                      dataItems.update_button_status == 1 ||
                      (dataItems.send_for_approval_button_status == 1 &&
                        dataItems.status !== "Approval Pending")
                    }
                    deleteItems={(ele) => {
                      this.setState({
                        deleteIds: [...this.state.deleteIds, ele],
                      });
                    }}
                  />
                  {/* {this.state.evidence.length > 0 && <DocumentPreviewer documentArray={[this.state.evidence]} mappingKeys={{
                    id: "id",
                    url: "evidence"
                  }} />
                  } */}
                </div>
              </div>
            </div>
          </>
        ) : null}
        {this.renderRenderOnlyView(dataItems, modalParams)}
        {this.renderDeclineCommentView(dataItems, modalParams)}
        {this.renderCommentView(dataItems, modalParams)}
      </>
    );
  };
  photoCallbackFunction = (file) => {
    this.setState({
      evidence: [file],
    });
  };
  UpdateRecommendFunction = (correctiveActionUpdateType) => {
    let userId = localStorage.getItem("userId");
    let userToken = localStorage.getItem("token");
    let data = new FormData();
    const isFormHavError = this.handleFormValidation();
    if (isFormHavError) {
      return false;
    }
    data.append("auth_key", userToken);
    data.append("user_id", userId);
    data.append("service_id", this.state.activeService);
    data.append("project_id", this.state.activeProject);
    data.append("ira_id", this.state.ira_id);
    data.append("rec_status", 0);
    data.append("recommendation_id", this.state.recommendList.id);
    data.append("corrective_action_id", this.state?.recommendation_id);
    data.append("observation", this.state.observationText);
    if (this.state.deleteIds.length) {
      this.state.deleteIds.forEach((el) => {
        data.append("delete_evidence_ids", el);
      });
    } else {
      data.append("delete_evidence_ids", "");
    }

    if (this.state.evidence?.length > 0) {
      this.state.evidence.map((item, i) => {
        data.append("file", item);
      });
    }
    if (correctiveActionUpdateType == "save") {
      this._updateCorrectiveAction(data);
    } else if (correctiveActionUpdateType == "saveSubmit") {
      this._saveSubmitCorrectiveAction(data);
    }
  };
  _updateCorrectiveAction = async (data) => {
    const url = Url.clientURL + "/listira/addrecommendationdata";
    await axios
      .post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      // .then(response => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.props.refetch();
          this.setState({
            modalParams: {},
          });
          SuccessToast("Done");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  _saveSubmitCorrectiveAction = async (data) => {
    const url = Url.clientURL + "/listira/saveandsendforapproval";
    await axios
      .post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      // .then(response => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.props.refetch();
          this.setState({
            modalParams: {},
          });
          SuccessToast("Send for approval");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  handleFormValidation = () => {
    const actionTakenError = !this.state.observationText;
    // const evidenceError = _.isEmpty(this.state.evidence);
    this.setState({
      actionTakenError,
    });
    return actionTakenError;
  };
  SendUpdateApiStepByStep = (type) => {
    this.UpdateRecommendFunction(type);
  };
  approveStatusUpdate = async () => {
    const url = Url.clientURL + "/listira/approvedstatusupdate";
    let userId = localStorage.getItem("userId");
    let userToken = localStorage.getItem("token");

    var data = {
      user_id: userId,
      auth_key: userToken,
      service_id: this.state.activeService,
      project_id: this.state.activeProject,
      ira_id: this.state.ira_id,
      multi_rec_id: this.state.recommendList.id,
      recommendation_id: this.state.recommendation_id,
    };

    console.warn(data);

    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    await fetch(url, {
      method: "POST",
      body: formBody,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      // .then(response => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.props.refetch();
          this.setState({
            modalParams: {},
          });
          SuccessToast("Approved");
          return;
        }
        ErrorToast("Something went wrong");
      })
      .catch((error) => {
        ErrorToast("Something went wrong");
      });
  };

  renderFooterAction = () => {
    const dataItems = { ...this.state.recommendList };
    const temp = [
      <SLDButton
        key="promptBtn"
        className="common-black-1"
        label="Close"
        onClick={() => {
          this.setState({
            modalParams: {},
          });
        }}
      />,
    ];

    if (dataItems.update_button_status == 1) {
      temp.push(
        <SLDButton
          variant="brand"
          className="common-orange-btn-layout mx-2 px-3 line-none-1"
          label="Save"
          onClick={() => {
            this.SendUpdateApiStepByStep("save");
          }}
        />
      );

      if (
        dataItems.update_button_status == 1 &&
        !dataItems.send_for_approval_button_status
      ) {
        temp.push(
          <SLDButton
            variant="brand"
            label="Send For Approval"
            className="common-orange-btn-layout mx-2 px-3 line-none-1"
            onClick={() => {
              this.SendUpdateApiStepByStep("saveSubmit");
            }}
          />
        );
      }
    }
    if (
      dataItems.send_for_approval_button_status == 1 &&
      dataItems.status !== "Approval Pending"
    ) {
      temp.push(
        <SLDButton
          variant="brand"
          label="Send For Approval"
          onClick={() => {
            this.SendUpdateApiStepByStep("saveSubmit");
          }}
        />
      );
    }
    if (dataItems.approve_button_status == 1) {
      temp.push(
        <SLDButton
          variant="brand"
          label="Approve"
          onClick={() => {
            this.approveStatusUpdate();
          }}
        />
      );
    }
    if (dataItems.decline_button_status == 1) {
      temp.push(
        <SLDButton
          variant="destructive"
          label="Decline"
          onClick={() => {
            this.setState({
              isDeclineViewEnabled: true,
            });
          }}
        />
      );
    }

    return temp;
  };
  _declineStatusUpdate = async () => {
    let userId = localStorage.getItem("userId");
    let userToken = localStorage.getItem("token");

    var data = {
      user_id: userId,
      auth_key: userToken,
      service_id: this.state.activeService,
      project_id: this.state.activeProject,
      ira_id: this.state.ira_id,
      multi_rec_id: this.state.recommendList.id,
      recommendation_id: this.state.recommendation_id,
      declined_reason: this.state.declineMessage,
    };

    console.warn(data);

    // var formBody = [];
    // for (var property in data) {
    //   var encodedKey = encodeURIComponent(property);
    //   var encodedValue = encodeURIComponent(data[property]);
    //   formBody.push(encodedKey + '=' + encodedValue);
    // }
    // formBody = formBody.join('&');

    await fetch(Url.clientURL + "/listira/declinedstatusupdate", {
      method: "POST",
      body: qs.stringify(data),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      // .then(response => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.props.refetch();
          this.setState({
            modalParams: {},
          });
          SuccessToast("Comment added successfully");
        }
      })
      .catch((error) => {});
  };
  declineCommentUpdate = async (comment) => {
    let userId = localStorage.getItem("userId");
    let userToken = localStorage.getItem("token");
    var data = {
      user_id: userId,
      auth_key: userToken,
      service_id: this.state.activeService,
      project_id: this.state.activeProject,
      ira_id: this.state.ira_id,
      corrective_action_id: this.state?.recommendation_id,
      recommendation_id: this.state.recommendList.id,
      comment,
    };

    console.warn(data);

    // var formBody = [];
    // for (var property in data) {
    //   var encodedKey = encodeURIComponent(property);
    //   var encodedValue = encodeURIComponent(data[property]);
    //   formBody.push(encodedKey + '=' + encodedValue);
    // }
    // formBody = formBody.join('&');

    await fetch(Url.clientURL + "/listira/irareccommentadd", {
      method: "POST",
      body: qs.stringify(data),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      // .then(response => response.json())
      .then((responseJson) => {
        if (responseJson.status == 200) {
          this.props.refetch();
          this.setState({
            modalParams: {},
          });
          SuccessToast("Replied successfully");
        }
      })
      .catch((error) => {});
  };
  render() {
    const { irReportData, search, handleSearch } = this.props ?? {};
    const {
      progressTrackerExport,
      completedTrackerExport,
      progressName,
      completeName,
    } = irReportData ?? {};
    const { modalParams } = this.state;
    return (
      <div className="open-section nm-section kanban-board-wrapper">
        {!!this.state.lightboxactive && (
          <FsLightbox
            toggler={this.state.fsToggler}
            types={this.state.evidencelightboxType}
            disableLocalStorage={true}
            sources={this.state.evidencelightbox}
          />
        )}
        <Modal
          className="reportPopup searchPopup"
          centered
          show={this.state.showPopupSearch}
          onHide={this.modalHideSearch}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div>
              <AudioPlayer
                src={this.state.obs_rec}
                showJumpControls={true}
                layout="horizontal-reverse"
                customVolumeControls={[]}
                customAdditionalControls={[]}
                onPlay={(e) => {
                  console.log(e);
                }}
              />
            </div>
          </Modal.Body>
        </Modal>
        <div
          className="loader"
          style={{ display: this.state.dataLoading ? "block" : "none" }}
        >
          <LoadingImage />
        </div>
        <div className="task-table-header mt-5">
          <div className="table-heading">
            <div className="mt-4">
              <div className="align-items-center d-flex justify-content-between ">
                <div className="d-flex">
                  {/* <span className="font-20 font-weight-500">UA/UC Open List</span> */}
                  <div class="slds-form-element">
                    <div className="slds-form-element__control slds-input-has-icon slds-input-has-icon_left">
                      <div className="input-wrapper">
                        <SearchIcon
                          className="search-icon"
                          style={{ height: "1vw", width: "1vw" }}
                        />
                        <input
                          name="search-filter"
                          type="text"
                          id="text-input-id-46"
                          placeholder="Search by activity in UAUC, NM, IRA and Good practices...."
                          required
                          multiple
                          onChange={(e) => this.props.handleSearch(e)}
                          value={this.props.search}
                          className="slds-input"
                          style={{ width: "20vw", textOverflow: "ellipsis" }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <Row>
                  <Col className="d-flex">
                    <label className="reminder-middle  mr-2 common-black-1 w-max">From :</label>
                    <div className=" calender-div common-datepick-layout-2">
                      <DatePicker
                        selected={startDate}
                        dateFormat="dd/MM/yyyy"
                         maxDate={endDate}
                        placeholderText="DD-MM-YYYY"
                      onChange={(jsDate) =>
                        setStartDate(jsDate)
                      }
                      />
                      <Calendar className="calender-icon" />
                    </div>
                  </Col>

                  <Col className="d-flex px-0">
                    <label className="reminder-middle mr-2 common-black-1">To : </label>
                    <div className=" calender-div common-datepick-layout-2" >
                      <DatePicker
                        selected={endDate}
                        dateFormat="dd/MM/yyyy"
                        minDate={startDate}
                        placeholderText="DD-MM-YYYY"
                      onChange={(jsDate) =>
                        setEndDate(jsDate)
                      }
                      />
                      <Calendar className="calender-icon" />
                    </div>
                  </Col>
                  <Col md={1} >
                    <RefreshCw className="cursor-pointer"
                     onClick={_handleResetList}
                    />
                  </Col>
                </Row> */}
                  {/* <div className="common-grey-border-30 d-flex   py-2 mt-2">

                    <input
                      type="text"
                      placeholder="Search Task"
                      className=" mr-3 border-0 font-16"
                    />
                    <Search className="cursor-pointer" />
                  </div> */}
                </div>
                <div>
                  <div className="d-flex align-items-center">
                    <div className="common-control-button-1 px-1 py-1 cursor-pointer common-border">
                      <img
                        src={
                          this.state.listMode === "kanban"
                            ? require("assets/sls-icons/Kanban/kanban.svg")
                            : require("assets/sls-icons/Kanban/kanban1.svg")
                        }
                        className="svg-kanban-uauc"
                        onClick={() => this.__toggleGridView("kanban")}
                      />
                    </div>

                    <div className="common-control-button-1 px-1 py-1 cursor-pointer common-border">
                      <img
                        src={
                          this.state.listMode === "list"
                            ? require("assets/sls-icons/Table/1.svg")
                            : require("assets/sls-icons/Table/picklist_type.svg")
                        }
                        className="svg-list-uauc"
                        onClick={() => this.__toggleGridView("list")}
                      />
                    </div>
                    <div>
                      <Workbook
                        filename="IRATrackerList.xlsx"
                        element={
                          <Button
                            className="icon-btn common-control-button-1 px-1 py-1 cursor-pointer common-border"
                            title="Download Excel"
                          >
                            <img src={ExcelIcon} />
                          </Button>
                        }
                      >
                        <Workbook.Sheet
                          data={progressTrackerExport}
                          name={"Inprogress Tracker list | " + progressName}
                        >
                          <Workbook.Column label="ACTIVITY" value="activity" />
                          <Workbook.Column label="CATEGORY" value="category" />
                          <Workbook.Column label="PROGRESS" value="progress" />
                          <Workbook.Column
                            label="TARGET DATE"
                            value="target_date"
                          />
                          <Workbook.Column
                            label="OVERDUE DAYS"
                            value="overdue_deviation"
                          />
                          <Workbook.Column label="PROJECT" value="project" />
                          <Workbook.Column
                            label="RECOMMENDATION"
                            value="recommendation"
                          />
                          <Workbook.Column
                            label="RECOMMENDED BY"
                            value="recommendedby"
                          />
                          <Workbook.Column label="SERVICE" value="service" />
                          <Workbook.Column label="SEVERITY" value="severity" />
                        </Workbook.Sheet>
                        <Workbook.Sheet
                          data={completedTrackerExport}
                          name={"Completed Tracker list | " + completeName}
                        >
                          <Workbook.Column label="ACTIVITY" value="activity" />
                          <Workbook.Column label="CATEGORY" value="category" />
                          <Workbook.Column label="PROGRESS" value="progress" />
                          <Workbook.Column
                            label="TARGET DATE"
                            value="target_date"
                          />
                          <Workbook.Column
                            label="OVERDUE DAYS"
                            value="overdue_deviation"
                          />
                          <Workbook.Column label="PROJECT" value="project" />
                          <Workbook.Column
                            label="RECOMMENDATION"
                            value="recommendation"
                          />
                          <Workbook.Column
                            label="RECOMMENDED BY"
                            value="recommendedby"
                          />
                          <Workbook.Column label="SERVICE" value="service" />
                          <Workbook.Column label="SEVERITY" value="severity" />
                        </Workbook.Sheet>
                      </Workbook>
                    </div>
                  </div>
                </div>
              </div>
              <div className="common-seprator-grey-3 mb-4 mt-3"></div>
            </div>
          </div>
        </div>
        {/* In progress tab */}
        <div
          className={`open-divide ${
            this.state.listMode === "list" ? "w-100" : ""
          }`}
        >
          <div className="open-head inprogress">
            <h2>IN PROGRESS | {this.props.progressListCount || 0}</h2>
            <div>
              <InfoComponent
                tooltipText={
                  "Corrective action implementation in-progress by responsible person"
                }
              />
            </div>
          </div>

          {/* Search box */}
          {/* <div className="search-box">
            <div className="search-input">
              <input
                type="text"
                placeholder="Search "
                name="search"
                value={this.props.searchList2}
                onChange={(e) => this.props._handleInputChange2(e)}
              />
              <button className="search-button">
                <Search />
              </button>
            </div>
            <button
              className="filter-button"
              onClick={() => this.trackerModal(true, 0)}
            >
              <Filter />
            </button>
          </div> */}

          <Scrollbars
            style={{ height: `calc(100vh - 18vw)` }}
            renderTrackHorizontal={(props) => (
              <div
                {...props}
                className="track-horizontal"
                style={{ display: "none" }}
              />
            )}
            renderThumbHorizontal={(props) => (
              <div
                {...props}
                className="thumb-horizontal"
                style={{ display: "none" }}
              />
            )}
            renderThumbVertical={(props) => (
              <div
                {...props}
                style={{
                  position: "relative",
                  display: "block",
                  width: "100%",
                  cursor: "pointer",
                  borderRadius: "inherit",
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  height: "100px",
                }}
              />
            )}
            onScrollStop={() => {
              if (
                Number.isInteger(this.props.progress_list.length) &&
                this.props.progress_list.length < this.props.progressListCount
              ) {
                this.props.getPaginationData(this.props.pageNumber + 1);
              }
            }}
          >
            {this.props.progress_list.map((data, index) => (
              <div className="open-content" key={data.ira_id}>
                <div
                  className="nm-box open-box"
                  style={{
                    borderColor: data.severity_color,
                    borderRadius: "1vw",
                  }}
                >
                  <div className="open-bhead open-tracker-head">
                    <Link
                      to={`/ira-detail/${data.ira_id}/kanban/completed/capa`}
                    >
                      <h4>
                        {data.incident_type || ""} | {data.activity || ""}
                      </h4>
                    </Link>
                    <div className="w-25">
                      <ProgressBarWrapper value={data.progress} />
                    </div>
                  </div>
                  <Row>
                    <Col sm={6}>
                      <p className="font-16 font-weight-500">Service</p>
                      <p className="font-16">{data.service}</p>
                    </Col>
                    <Col sm={6}>
                      <p className="font-16 font-weight-500">Plant/Project</p>
                      <p className="font-16">{data.project}</p>
                    </Col>
                  </Row>
                  <AccordionWrapper
                    items={[
                      {
                        title: "CAPA",
                        content: (
                          <>
                            <div className="open-bbody">
                              <hr className="mb-3 mt-1" />
                              {this.state.listMode === "list" ? (
                                <>
                                  <div className="mt-3 ">
                                    <DataTable
                                      {...this.getFieldProps(data.list)}
                                    />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <Scrollbars
                                    style={{
                                      height: `15vw`,
                                      minHeight: "12vw",
                                    }}
                                  >
                                    <div className="mr-3">
                                      {data.list.map((capaItem, index, arr) => (
                                        <div
                                          key={`inprogressList-${capaItem.recommendation_id}`}
                                          className={` ${
                                            arr.length - 1 !== index
                                              ? "border-bottom"
                                              : ""
                                          } py-2`}
                                        >
                                          <Row>
                                            <Col sm={12} className="my-2 ">
                                              <div className="d-flex d-flex space-between ">
                                                <div className="d-flex ">
                                                  <p className="font-16 font-weight-500 common-black-1">
                                                    Corrective Action {++index}
                                                  </p>{" "}
                                                  &nbsp; |
                                                  <span
                                                    style={{ color: "black" }}
                                                    className="ml-2 font-16 font-weight-500 common-grey-1 text-overflow-ellipsis cursor-pointer"
                                                  >
                                                    {capaItem.recommendation
                                                      ?.recommendation || ""}
                                                  </span>{" "}
                                                </div>
                                                <p
                                                  className="font-16 font-16 common-grey-1 badge border cursor-pointer"
                                                  onClick={() =>
                                                    this.checkApprovePersmission(
                                                      data,
                                                      capaItem.tracking_responsible_person,
                                                      capaItem,
                                                      index + 1,
                                                      data.ira_id
                                                    )
                                                  }
                                                >
                                                  {capaItem.recommendation
                                                    ?.status || ""}
                                                </p>
                                              </div>
                                            </Col>
                                            <Col sm={3}>
                                              <p className="font-16 font-weight-500 common-black-1">
                                                Priority
                                              </p>
                                              <div
                                                style={{
                                                  backgroundColor:
                                                    capaItem.priority_color,
                                                }}
                                                className="badge font-16 font-weight-500 py-2"
                                              >
                                                <div
                                                  className="output-text"
                                                  style={{
                                                    color: "#ffffff",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {capaItem.priority}
                                                </div>
                                              </div>
                                            </Col>
                                            <Col sm={3}>
                                              <p className="font-16 font-weight-500 common-black-1">
                                                Overdue
                                              </p>
                                              <p className="font-16 common-grey-1">
                                                {capaItem.overdue_deviation ||
                                                  ""}
                                              </p>
                                            </Col>
                                            <Col sm={3}>
                                              <p className="font-16 font-weight-500 common-black-1">
                                                Category
                                              </p>
                                              <p className="font-16 common-grey-1">
                                                {capaItem.category}
                                              </p>
                                            </Col>
                                            <Col sm={3}>
                                              <p className="font-16 font-weight-500 common-black-1">
                                                <span
                                                  style={{
                                                    color: "rgba(0, 0, 0, 0.7)",
                                                  }}
                                                  className={`${
                                                    this.comapareTargetDate(
                                                      capaItem.target_date
                                                    )
                                                      ? "bg-danger"
                                                      : "bg-warning"
                                                  } text-white rounded px-1`}
                                                >
                                                  Target Date
                                                </span>
                                              </p>
                                              <p className="font-16 common-grey-1">
                                                {capaItem.target_date || "N/A"}
                                              </p>
                                            </Col>
                                            <Col sm={3}>
                                              <p className="font-16 font-weight-500 common-black-1">
                                                Proposed By
                                              </p>
                                              <div className="d-flex ">
                                                <img
                                                  src={
                                                    capaItem?.recommendedby_pic
                                                  }
                                                  className="user-icon mr-2"
                                                />
                                                <p className="font-16 common-grey-1">
                                                  {capaItem?.recommendedby ||
                                                    ""}
                                                </p>
                                              </div>
                                            </Col>
                                            <Col sm={3}>
                                              <p className="font-16 font-weight-500 common-black-1">
                                                Assigned To
                                              </p>
                                              {capaItem.tracking_responsible_person.map(
                                                (person, index) => (
                                                  <div
                                                    className="d-flex "
                                                    key={index}
                                                  >
                                                    <img
                                                      src={person?.person_pic}
                                                      className="user-icon mr-2"
                                                    />
                                                    <p className="font-16 common-grey-1">
                                                      {person.person_name}
                                                    </p>
                                                  </div>
                                                )
                                              )}
                                            </Col>
                                          </Row>
                                        </div>
                                      ))}
                                    </div>
                                  </Scrollbars>
                                </>
                              )}
                            </div>
                          </>
                        ),
                      },
                    ]}
                  />
                </div>
              </div>
            ))}
            {Number.isInteger(
              this.props.progress_list.filter((item) => item.status == 0)
                .length / 10
            ) &&
              this.props.progress_list.filter((item) => item.status == 0) !=
                "" &&
              this.props.progress_no !=
                this.props.progress_list.filter((item) => item.status == 0)
                  .length && (
                <button
                  className="filter-button1"
                  onClick={() =>
                    this.props.iratrackerlist(
                      this.props.progress_list.length / 10 + 1
                    )
                  }
                >
                  Load more
                </button>
              )}
          </Scrollbars>
        </div>

        {/* Completed Tab */}
        <div
          className={`open-divide ${
            this.state.listMode === "list" ? "w-100" : ""
          }`}
        >
          <div className="open-head completed">
            <h2>COMPLETED | {this.props.completedListCount || 0}</h2>
            <div>
              <InfoComponent
                tooltipText={
                  "Corrective action implementation completed by responsible person"
                }
              />
            </div>
          </div>

          {/* <div className="search-box">
            <div className="search-input">
              <input
                type="text"
                placeholder="Search"
                name="search"
                value={this.props.searchList3}
                onChange={(e) => this.props._handleInputChange3(e)}
              />
              <button className="search-button">
                <Search />
              </button>
            </div>
            <button
              className="filter-button"
              onClick={() => this.trackerModal(true, 1)}
            >
              <Filter />
            </button>
          </div> */}

          <Scrollbars
            style={{ height: `calc(100vh - 18vw)` }}
            renderTrackHorizontal={(props) => (
              <div
                {...props}
                className="track-horizontal"
                style={{ display: "none" }}
              />
            )}
            renderThumbHorizontal={(props) => (
              <div
                {...props}
                className="thumb-horizontal"
                style={{ display: "none" }}
              />
            )}
            renderThumbVertical={(props) => (
              <div
                {...props}
                style={{
                  position: "relative",
                  display: "block",
                  width: "100%",
                  cursor: "pointer",
                  borderRadius: "inherit",
                  backgroundColor: "rgba(0, 0, 0, 0.2)",
                  height: "100px",
                }}
              />
            )}
            onScrollStop={() => {
              if (
                Number.isInteger(this.props.completed_list.length) &&
                this.props.completed_list.length <
                  this.props.completedListCount
              ) {
                this.props.getPaginationData(this.props.pageNumber + 1);
              }
            }}
          >
            {this.props.completed_list.map((data, index) => (
              <div className="open-content" key={index}>
                <div
                  className="nm-box open-box"
                  style={{
                    borderColor: data.severity_color,
                    borderRadius: "1vw",
                  }}
                >
                  <div className="open-bhead open-tracker-head">
                    <Link
                      to={`/ira-detail/${data.ira_id}/kanban/completed/capa`}
                    >
                      <h4>
                        {data.incident_type || ""} | {data.activity || ""}
                      </h4>
                    </Link>
                    <div className="w-25">
                      <ProgressBarWrapper value={data.progress} />
                    </div>
                  </div>
                  <Row>
                    <Col sm={6}>
                      <p className="font-16 font-weight-500">Service</p>
                      <p className="font-16">{data.service}</p>
                    </Col>
                    <Col sm={6}>
                      <p className="font-16 font-weight-500">Plant/Project</p>
                      <p className="font-16">{data.project}</p>
                    </Col>
                  </Row>
                  <AccordionWrapper
                    items={[
                      {
                        title: "CAPA",
                        content: (
                          <>
                            <div className="open-bbody">
                              <hr className="mb-3 mt-1" />
                              {this.state.listMode === "list" ? (
                                <>
                                  <div className="mt-3 ">
                                    <DataTable
                                      {...this.getFieldProps(data.list, [
                                        "Close Date",
                                        "Extras",
                                      ])}
                                    />
                                  </div>
                                </>
                              ) : (
                                <>
                                  <Scrollbars
                                    style={{
                                      height: `15vw`,
                                      minHeight: "12vw",
                                    }}
                                  >
                                    <div className="mr-3">
                                      {data.list.map((capaItem, index, arr) => (
                                        <div
                                          key={`inprogressList-${capaItem.recommendation_id} `}
                                          className={` ${
                                            arr.length - 1 !== index
                                              ? "border-bottom"
                                              : ""
                                          } py-2`}
                                        >
                                          <Row>
                                            <Col sm={12} className="my-2 ">
                                              <div className="d-flex d-flex space-between ">
                                                <div className="d-flex ">
                                                  <p className="font-16 font-weight-500 common-black-1">
                                                    Corrective Action {++index}
                                                  </p>{" "}
                                                  &nbsp; |
                                                  <span
                                                    style={{ color: "black" }}
                                                    className="ml-2 font-16 font-weight-500 common-grey-1 text-overflow-ellipsis"
                                                  >
                                                    {capaItem.recommendation
                                                      ?.recommendation || ""}
                                                  </span>{" "}
                                                </div>
                                                <p className="font-16 font-16 common-grey-1">
                                                  {capaItem.recommendation
                                                    ?.status || ""}
                                                </p>
                                              </div>
                                            </Col>
                                            <Col sm={3}>
                                              <p className="font-16 font-weight-500 common-black-1">
                                                Priority
                                              </p>
                                              <div
                                                style={{
                                                  backgroundColor:
                                                    capaItem.priority_color,
                                                }}
                                                className="badge font-16 font-weight-500 py-2"
                                              >
                                                <div
                                                  className="output-text"
                                                  style={{
                                                    color: "#ffffff",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {capaItem.priority}
                                                </div>
                                              </div>
                                            </Col>
                                            <Col sm={3}>
                                              <p className="font-16 font-weight-500 common-black-1">
                                                Deviation
                                              </p>
                                              <p className="font-16 common-grey-1">
                                                {capaItem.overdue_deviation ||
                                                  ""}
                                              </p>
                                            </Col>
                                            <Col sm={3}>
                                              <p className="font-16 font-weight-500 common-black-1">
                                                Category
                                              </p>
                                              <p className="font-16 common-grey-1">
                                                {capaItem.category}
                                              </p>
                                            </Col>
                                            <Col sm={3}>
                                              <p className="font-16 font-weight-500 common-black-1">
                                                <span
                                                  style={{
                                                    color: "rgba(0, 0, 0, 0.7)",
                                                  }}
                                                  className={`${
                                                    this.comapareTargetDate(
                                                      capaItem.target_date
                                                    )
                                                      ? "bg-danger"
                                                      : "bg-warning"
                                                  } text-white rounded px-1`}
                                                >
                                                  Target Date
                                                </span>
                                              </p>
                                              <p className="font-16 common-grey-1">
                                                {capaItem.target_date || "N/A"}
                                              </p>
                                            </Col>
                                            <Col sm={3}>
                                              <p className="font-16 font-weight-500 common-black-1">
                                                Proposed By
                                              </p>
                                              <div className="d-flex ">
                                                <img
                                                  src={
                                                    capaItem?.recommendedby_pic
                                                  }
                                                  className="user-icon mr-2"
                                                />
                                                <p className="font-16 common-grey-1">
                                                  {capaItem?.recommendedby ||
                                                    ""}
                                                </p>
                                              </div>
                                            </Col>
                                            <Col sm={3}>
                                              <p className="font-16 font-weight-500 common-black-1">
                                                Assigned To
                                              </p>
                                              {capaItem.tracking_responsible_person.map(
                                                (person, index) => (
                                                  <div
                                                    className="d-flex "
                                                    key={index}
                                                  >
                                                    <img
                                                      src={person?.person_pic}
                                                      className="user-icon mr-2"
                                                    />
                                                    <p className="font-16 common-grey-1">
                                                      {person.person_name}
                                                    </p>
                                                  </div>
                                                )
                                              )}
                                            </Col>
                                            <Col sm={3}>
                                              <p className="font-16 font-weight-500 common-black-1">
                                                {" "}
                                                Closing Date
                                              </p>
                                              <p
                                                key={index}
                                                className="font-16 common-grey-1"
                                              >
                                                {capaItem.recommendation
                                                  ?.closing_date || "N/A"}
                                              </p>
                                            </Col>
                                            <Col sm={3}>
                                              <p className="font-16 font-weight-500 common-black-1">
                                                {" "}
                                                Extras{" "}
                                              </p>
                                              {capaItem.recommendation
                                                ?.recommendation_evidence
                                                ?.length && (
                                                <div
                                                  style={{
                                                    display: "inline-block",
                                                  }}
                                                  onClick={() =>
                                                    this._handleLightBox(
                                                      capaItem.recommendation
                                                        ?.recommendation_evidence
                                                    )
                                                  }
                                                >
                                                  <img
                                                    className="tracker-right cursor-pointer"
                                                    style={{ height: "1.8vw" }}
                                                    src={require("assets/images/icons/fimage.svg")}
                                                  />
                                                </div>
                                              )}
                                              {capaItem.recommendation
                                                ?.observation_rec?.length >
                                              0 ? (
                                                <div
                                                  style={{
                                                    display: "inline-block",
                                                  }}
                                                >
                                                  <h6
                                                    onClick={() =>
                                                      this.modalShowSearch(
                                                        capaItem.recommendation
                                                          .observation_rec
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      style={{
                                                        height: "1.8rem",
                                                        paddingLeft: "1rem",
                                                        verticalAlign:
                                                          "initial",
                                                      }}
                                                      src={require("assets/images/icons/play.svg")}
                                                      className="cursor-pointer"
                                                    />
                                                  </h6>
                                                </div>
                                              ) : null}
                                            </Col>
                                          </Row>
                                        </div>
                                      ))}
                                    </div>
                                  </Scrollbars>
                                </>
                              )}
                            </div>
                          </>
                        ),
                      },
                    ]}
                  />
                </div>
              </div>
            ))}
            {Number.isInteger(
              this.props.completed_list.filter((item) => item.status == 1)
                .length / 10
            ) &&
              this.props.completed_list.filter((item) => item.status == 1) !=
                "" &&
              this.props.completed_no ==
                this.props.completed_list.filter((item) => item.status == 1)
                  .length && (
                <button
                  className="filter-button1"
                  onClick={() =>
                    this.props.iraTrackerCompletedList(
                      this.props.completed_list.length / 10 + 1
                    )
                  }
                >
                  Load more
                </button>
              )}
          </Scrollbars>
        </div>

        {/* <Modal centered show={this.state.trackerFilterPopup}>
          <Modal.Header closeButton onClick={() => this.trackerModal(false)}>
            <Modal.Title>Filter</Modal.Title>
            <Button
              type="submit"
              variant="outline-primary"
              className="modal-btn"
              onClick={() => {
                this.state.statusCheck == 0
                  ? this.props.nmTrackerListFilterProgress(
                      this.state.severity,
                      this.state.overdue,
                      this.state.serviceSelect,
                      this.state.projectSelect,
                      this.state.categorySelect,
                      this.state.startDate,
                      this.state.endDate,
                      this.state.personSelect,
                      this.state.statusCheck
                    )
                  : this.props.nmTrackerListFilterCompleted(
                      this.state.severity,
                      this.state.overdue,
                      this.state.serviceSelect,
                      this.state.projectSelect,
                      this.state.categorySelect,
                      this.state.startDate,
                      this.state.endDate,
                      this.state.personSelect,
                      this.state.statusCheck
                    );
              }}
            >
              DONE
            </Button>

            <Button
              type="submit"
              variant="outline-primary"
              className="modal-btn1"
              onClick={() => this.props.iratrackerlist()}
            >
              Reset
            </Button>
          </Modal.Header>
          <Modal.Body>
            <div className="nm-filter-modal">
              <div className="form-group position-relative">
                <label>Priority</label>
                <div className="along-label">
                  <label className="check-box">
                    All
                    <input
                      name="abc"
                      type="checkbox"
                      checked={this.state.all}
                      onClick={() => this.FilterData(this.state.all, "all")}
                    />
                    <span className="checkmark1"></span>
                  </label>
                </div>
                <Row>
                  <Col sm={6}>
                    <label className="check-box">
                      Extreme
                      <input
                        value={4}
                        type="checkbox"
                        checked={this.state.extremeSelect}
                        onChange={this.selectSeverity}
                        onClick={() => {
                          this.FilterData(this.state.extremeSelect, 4);
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label className="check-box">
                      High
                      <input
                        value={3}
                        type="checkbox"
                        checked={this.state.highSelect}
                        onChange={this.selectSeverity}
                        onClick={() => {
                          this.FilterData(this.state.highSelect, 3);
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label className="check-box">
                      Medium
                      <input
                        value={2}
                        type="checkbox"
                        checked={this.state.mediumSelect}
                        onChange={this.selectSeverity}
                        onClick={() => {
                          this.FilterData(this.state.mediumSelect, 2);
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label className="check-box">
                      Low
                      <input
                        value={1}
                        id="1"
                        type="checkbox"
                        checked={this.state.lowSelect}
                        onChange={this.selectSeverity}
                        onClick={() => {
                          this.FilterData(this.state.lowSelect, 1);
                        }}
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                  <Col sm={6}>
                    <label className="check-box">
                      Overdue
                      <input
                        name="abc"
                        type="checkbox"
                        checked={this.state.overdue}
                        onClick={() =>
                          this.FilterData(this.state.overdue, "overdue")
                        }
                      />
                      <span className="checkmark1"></span>
                    </label>
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <Row>
                  <Col sm={6}>
                    <label>By Service</label>
                    <select
                      className="form-control"
                      value={this.state.serviceSelect}
                      onChange={this._handleServiceChange}
                    >
                      <option value="">Select Service</option>
                      {this.state.service.map((service, index) => (
                        <option value={service.service_id}>
                          {service.service_name}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col sm={6}>
                    <label>By Project</label>
                    <select
                      className="form-control"
                      value={this.state.projectSelect}
                      onChange={(e) => {
                        this.setState({ projectSelect: e.target.value });
                      }}
                    >
                      <option>Select Project</option>
                      {this.state.project.map((project, index) => (
                        <option value={project.project_id}>
                          {project.project}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <Row>
                  <Col sm={6}>
                    <label>By Category</label>
                    <select
                      className="form-control"
                      value={this.state.categorySelect}
                      onChange={(e) => {
                        this.setState({ categorySelect: e.target.value });
                      }}
                    >
                      <option value="">Select Category</option>
                      {this.props.CategoryFilter.map((data, index) => (
                        <option value={data.category_id}>
                          {data.category}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col sm={6}>
                    <label>By Responsible Person</label>
                    <select
                      className="form-control"
                      value={this.state.personSelect}
                      onChange={(e) => {
                        this.setState({ personSelect: e.target.value });
                      }}
                    >
                      <option value="0">Select Responsible Person</option>
                      {this.props.R_person.map((data) => (
                        <option value={data.person_id}>
                          {data.person_name}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              </div>
              <div className="form-group">
                <label>By Start Date & End Date</label>
                <Row>
                  <Col>
                    <div className="form-group calender-div">
                      <DatePicker
                        selected={this.state.startDate}
                        onChange={(jsDate) =>
                          this.setState({ startDate: jsDate })
                        }
                      />
                      <Calendar className="calender-icon" />
                    </div>
                  </Col>
                  <Col sm={1}>
                    <label className="reminder-middle">To</label>
                  </Col>
                  <Col>
                    <div className="form-group calender-div">
                      <DatePicker
                        selected={this.state.endDate}
                        onChange={(jsDate) =>
                          this.setState({ endDate: jsDate })
                        }
                      />
                      <Calendar className="calender-icon" />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Modal.Body>
        </Modal> */}
        <SLDModal
          disableClose
          footer={this.renderFooterAction()}
          isOpen={Object.keys(modalParams).length}
          size="small"
          title={
            <span className=" font-18 font-weight-500  w-100">
              {modalParams?.category}
            </span>
          }
        >
          {this.renderRecomendationUpdate(modalParams)}
        </SLDModal>
      </div>
    );
  }
}

CapaList.defaultProps = {
  progress_list: [],
  completed_list: [],
  CategoryFilter: [],
  completedListCount: 0,
  inprogressListCount: 0,
};

export default CapaList;

export const SLDSChat = ({
  chatArray = [],
  chatMappers = {},
  dateTimePostFixMessage = "ago",
  inputPlaceHolder = "Type a new message",
  validationMessage = "Please type a message to continue",
  isCallbackEnable = true,
  callback,
  isInputEnabled = true
}) => {
  const [message, setMessage] = useState("");
  const [hasMessageError, setHasMessageError] = useState(false);
  const loggedInUserId = localStorage.getItem("userId");
  const messages = useMemo(() => {
    const temp = [];
    if (chatArray.length > 0) {
      chatArray.forEach((el) => {
        const isUserActive =
          parseInt(loggedInUserId) === el[chatMappers["personId"]];
        temp.push({
          comment: el[chatMappers["comment"]],
          id: el[chatMappers["id"]],
          avatar: el[chatMappers["avatar"]],
          name: el[chatMappers["name"]],
          personId: el[chatMappers["personId"]],
          post_creationTimeStamp: el[chatMappers["post_creationTimeStamp"]],
          containerPosition: isUserActive
            ? "slds-chat-listitem_outbound"
            : "slds-chat-listitem_inbound",
          messagePosition: isUserActive
            ? "slds-chat-message__text_outbound"
            : "slds-chat-message__text_inbound",
          isUserActive,
        });
      });
    }
    return temp;
  }, [chatArray, chatMappers]);
  const manageMessageUpload = (e) => {
    const { value = "" } = e.target;
    setMessage(value.trimStart());
    setHasMessageError(false);
  };
  const sendMessage = (message) => {
    if (message === "") {
      setHasMessageError(true);
      return false;
    }
    if (isCallbackEnable) {
      setMessage("");
      setHasMessageError(false);
      callback(message);
    }
  };
  let inputMessageFlag = 0;
  return (
    <div>
      {messages?.length > 0 ? (
        <div>
          <section role="log" className="slds-chat">
            <ul className="slds-chat-list">
              <li className="slds-chat-listitem slds-chat-listitem_bookend">
                <div className="slds-chat-bookend">
                  <span className="slds-icon_container slds-icon-utility-chat slds-chat-icon"></span>
                  <p>
                    Chat started by &nbsp;
                    <b>{messages[0]?.name}</b> •{" "}
                    {messages[0]?.post_creationTimeStamp}{" "}
                    {dateTimePostFixMessage}
                  </p>
                </div>
              </li>
              {messages?.map((el, idx) => {
                return (
                  <li
                    className={`slds-chat-listitem ${el?.containerPosition}`}
                    key={idx}
                  >
                    <div className="slds-chat-message">
                      {!el?.isUserActive ? (
                        <span
                          aria-hidden="true"
                          className="slds-avatar slds-avatar_circle slds-chat-avatar"
                        >
                          <abbr
                            className="slds-avatar__initials slds-avatar__initials_inverse"
                            title={el?.name}
                          >
                            {generateIntials(el?.name)}
                          </abbr>
                        </span>
                      ) : null}
                      <div className="slds-chat-message__body">
                        <div
                          className={`slds-chat-message__text ${el?.messagePosition}`}
                        >
                          <span>{el?.comment}</span>
                        </div>

                        <div
                          className="slds-chat-message__meta"
                          aria-label="said Amber Cann at 5:23 PM"
                        >
                          {el?.name} • {el?.post_creationTimeStamp}
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </section>
        </div>
      ) : null}
      {isInputEnabled ? <div className="my-2">
        <div
          className={`slds-form-element ${
            hasMessageError ? "slds-has-error" : null
          }`}
        >
          <div className="slds-form-element__control">
            <input
              type="text"
              id="text-input-id-93400334"
              placeholder={inputPlaceHolder}
              required=""
              value={message}
              onChange={manageMessageUpload}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (inputMessageFlag === 0) {
                    inputMessageFlag = 1;
                    setTimeout(() => {
                      sendMessage(message);
                      inputMessageFlag = 0;
                    }, 500);
                  }
                }
              }}
              aria-describedby="error-message-id-94"
              className="slds-input"
              aria-invalid={hasMessageError}
            />
          </div>
          <div className="d-flex space-between">
            <div>
              {hasMessageError ? (
                <div
                  className="slds-form-element__help"
                  id="error-message-id-94"
                >
                  {validationMessage}
                </div>
              ) : null}
            </div>
            <div className="d-flex justify-content-end my-1">
              <img
                src={require("assets/sls-icons/send.svg")}
                title={"Send"}
                className="cursor-pointer"
                onClick={() => {
                  if (inputMessageFlag === 0) {
                    inputMessageFlag = 1;
                    setTimeout(() => {
                      sendMessage(message);
                      inputMessageFlag = 0;
                    }, 500);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div> : null

      }
      
    </div>
  );
};
